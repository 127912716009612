import React from 'react';
import styled, { keyframes } from 'styled-components';
import Background from '../../../static/background.jpeg';
import { Logo } from '../../ui/Logo';

export const LoaderContainer = styled.div<{ showBackground: boolean }>`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-items: center;
  background-color: #012353;
  background-image: ${(props) => (props.showBackground ? `url(${Background})` : 'none')};
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  justify-self: stretch;
  justify-content: center;
  align-content: center;
`;

export const Loader = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
`;

export const LoaderLogo = styled.div<{ size?: number }>`
  background-color: rgba(0, 0, 0, 0.3);
  display: grid;
  position: absolute;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
`;

const slide = keyframes`
    0% { clip-path: inset(0  100% 0 0); }
    100% { clip-path: inset(0); }
  `;

export const MouraLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  animation: 2s ${slide} infinite;
`;

export const Loading: React.FC<{ showBackground?: boolean }> = ({ showBackground = true }) => {
  return (
    <LoaderContainer showBackground={showBackground}>
      <Loader>
        <MouraLogo>
          <Logo style="onlyIcon" />
        </MouraLogo>
      </Loader>
    </LoaderContainer>
  );
};

