import React from 'react';
import { sizes, VectorProps } from './Icon';

export const BatteryColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 31 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0552 1.99793C9.60466 1.99793 9.60466 1.99793 9.60342 1.53043C9.60342 1.23793 9.61087 0.94543 9.60342 0.65543C9.58604 0.18793 9.39492 0.00417969 8.93076 0.00292969C7.90067 0.00292969 6.87099 0.00292969 5.84173 0.00292969C5.37881 0.00292969 5.20009 0.16043 5.17031 0.62168C5.14983 0.963452 5.14983 1.30616 5.17031 1.64793C5.18396 1.93168 5.0735 2.00668 4.79799 2.00418C3.41791 1.99293 2.03908 2.00418 0.65901 1.99668C0.377287 1.99668 0.147688 2.06293 0 2.32043V5.88418C0.161339 6.16918 0.421965 6.21418 0.718581 6.21418C4.56756 6.21418 8.41489 6.21418 12.2606 6.21418H16.1215V1.99793H10.0552Z"
        fill="#FFBF31"
      />
      <path
        d="M30.0162 6.205C30.5685 6.205 30.7298 6.04125 30.7311 5.48625C30.7311 4.56958 30.7311 3.65292 30.7311 2.73625C30.7311 2.1525 30.5797 1.9975 30.0088 1.99625C28.6585 1.99625 27.309 1.99625 25.9604 1.99625C25.5521 1.99625 25.5533 1.99625 25.5509 1.5725C25.5509 1.25125 25.5608 0.9275 25.5384 0.60625C25.5062 0.15875 25.3275 0.00125 24.8856 0C23.8465 0 22.8068 0 21.7668 0C21.32 0 21.1264 0.18375 21.1078 0.635C21.0942 0.97625 21.0892 1.32 21.1078 1.66125C21.1227 1.9325 21.0172 2.0025 20.7603 2.00125C19.4113 1.99 18.061 2.00125 16.7119 2.00125H16.1895V6.205H30.0162Z"
        fill="#EAA00C"
      />
      <path
        d="M13.5424 21.6202C13.4732 21.6201 13.405 21.6029 13.3438 21.5702C13.2599 21.5238 13.1944 21.4498 13.1583 21.3606C13.1221 21.2714 13.1175 21.1724 13.1453 21.0802L14.4682 16.7052L12.2902 16.2139C12.2229 16.1992 12.1603 16.1679 12.108 16.1227C12.0558 16.0776 12.0155 16.02 11.9909 15.9553C11.9663 15.8905 11.958 15.8206 11.9669 15.7518C11.9758 15.6831 12.0016 15.6176 12.0419 15.5614L15.9861 10.0427C16.0204 9.99094 16.0663 9.94812 16.1201 9.91766V7.81641H2.5192C2.03766 7.81641 2.03518 7.81641 2.03518 8.30266C2.03518 13.1043 2.03518 17.9064 2.03518 22.7089C2.03452 22.8593 2.04698 23.0095 2.07241 23.1577C2.09147 23.3143 2.16669 23.4586 2.28394 23.5633C2.40118 23.6681 2.55237 23.7261 2.70908 23.7264C2.80961 23.7327 2.9089 23.7264 3.00942 23.7264H16.1213V18.7739L13.8601 21.4727C13.821 21.519 13.7723 21.5562 13.7174 21.5817C13.6625 21.6072 13.6028 21.6203 13.5424 21.6202Z"
        fill="#006EC7"
      />
      <path
        d="M28.1594 7.81641H16.1893V9.89515C16.2845 9.86143 16.3887 9.8641 16.4822 9.90265C16.5679 9.93945 16.639 10.0041 16.684 10.0864C16.729 10.1686 16.7453 10.2637 16.7304 10.3564L16.1855 13.7489V14.1564L18.6019 14.9064C18.6675 14.9267 18.727 14.963 18.7752 15.0121C18.8234 15.0613 18.8587 15.1217 18.878 15.188C18.8973 15.2542 18.9 15.3243 18.8858 15.3919C18.8717 15.4594 18.841 15.5224 18.7968 15.5751L16.1905 18.6901V23.7289H27.6915C27.802 23.7289 27.9124 23.7289 28.0216 23.7289C28.1679 23.724 28.308 23.6683 28.4182 23.5713C28.5283 23.4742 28.6018 23.3418 28.626 23.1964C28.6573 23.0182 28.6718 22.8374 28.6695 22.6564C28.6695 17.8856 28.6695 13.1139 28.6695 8.34141C28.6695 7.81641 28.6695 7.81641 28.1594 7.81641Z"
        fill="#0033AB"
      />
    </svg>
  );
};
