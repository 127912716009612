import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Footer } from '../../components/layout/Footer';
import { PageOptions } from '../../components/layout/PageOptions';
import { UserRegistration } from '../../components/layout/UserRegistration';
import { Button } from '../../components/ui/Button';
import { Drop } from '../../components/ui/Drop';
import { Input } from '../../components/ui/Input';
import { MultiDropdown } from '../../components/ui/MultiDropdown';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import {
  useAllGroupsQuery,
  useLineTitleListQuery,
  useRegisterUserMutation,
  UserProfile,
} from '../../hooks/graphqlQueries';

const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

const Info = styled.div`
  display: grid;
  grid-area: Info;
  grid-template:
    'Name Name LastName LastName' max-content
    'Email Email Password Password' max-content
    'Profile Line . .' max-content;
  row-gap: 19px;
  column-gap: 19px;
  align-items: end;

  @media (max-width: 830px) {
    grid-template:
      'Name' max-content
      'LastName' max-content
      'Email' max-content
      'Password' max-content
      'Line' max-content
      'Profile' max-content;
  }

  @media (max-width: 660px) {
    justify-content: center;
    align-items: center;
  }
`;

type Line = { id: string; name: string };
type UserInput = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  permissions: string | string[];
  profile: UserProfile;
  groupId?: string;
};

export const UserRegistrationPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const feedback = useFeedback();
  const location = useLocation();

  const routeData = location.state as { pagination: { page: number; limit: number } };

  const [linesQuery] = useLineTitleListQuery();
  const [, registerUser] = useRegisterUserMutation();
  const [selectedLines, setSelectedLines] = useState<Line[]>([]);
  const [groupsQuery] = useAllGroupsQuery();

  const [data, setData] = useState<UserInput>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    permissions: [],
    profile: UserProfile.Production,
  });

  const canRegister = useMemo(() => {
    for (const key in data) {
      const value = data[key as keyof UserInput] ?? '';
      if (key !== 'groupId' && value.length <= 0) return false;
    }

    return true;
  }, [data]);

  const handleRegister = (data: UserInput) => {
    if (!data.email.match(emailRegex)) {
      feedback.message({
        title: 'Email Inválido',
        subtitle: 'Por favor, informe um email válido.',
        animation: 'Error',
        onClose: () => setData({ ...data, password: '' }),
      });
      return;
    }

    registerUser(data)
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.createUser === null)
          feedback.message({ animation: 'Error', title: 'Erro ao cadastrar usuário' });
        else
          feedback.message({
            animation: 'Confetti',
            title: 'Usuário cadastrado com sucesso',
            onClose: () => navigate('..', { state: { pagination: routeData?.pagination } }),
          });
      })
      .catch(() => {
        feedback.message({ animation: 'Error', title: 'Erro ao cadastrar usuário' });
      });
  };

  return (
    <UserRegistration>
      <Title>Cadastrar Usuário</Title>
      <Info>
        <Input
          label="Nome"
          area="Name"
          value={data.firstName}
          width="376px"
          onChange={(e) => setData({ ...data, firstName: e.target.value })}
        />
        <Input
          label="Sobrenome"
          area="LastName"
          value={data.lastName}
          width="376px"
          onChange={(e) => setData({ ...data, lastName: e.target.value })}
        />
        <Input
          label="E-mail"
          area="Email"
          value={data.email}
          width="376px"
          onChange={(e) => setData({ ...data, email: e.target.value })}
        />
        <Input
          label="Senha"
          type="password"
          area="Password"
          value={data.password}
          width="376px"
          onChange={(e) => setData({ ...data, password: e.target.value })}
        />
        <Drop
          name=""
          value={t(data.profile)}
          options={Object.keys(UserProfile).map((value) => ({
            id: value,
            name: t(UserProfile[value as keyof typeof UserProfile]),
          }))}
          onSelect={(profile) => {
            if (UserProfile[profile.id as keyof typeof UserProfile])
              setData({ ...data, profile: UserProfile[profile.id as keyof typeof UserProfile] });
          }}
          errorMessage={Object.keys(UserProfile).length === 0 ? 'Não existe nenhum perfil cadastrado.' : undefined}
        />
        {(data.profile === UserProfile.Admin ||
          data.profile === UserProfile.Supervisor ||
          data.profile === UserProfile.Process) && (
          <MultiDropdown
            name="Linha"
            value={selectedLines.map((line) => line.name)}
            options={linesQuery.data?.lines?.value?.map((line) => ({ id: line.id, name: line.code })) ?? []}
            onBlur={(lines) => {
              setSelectedLines(lines);
              setData({
                ...data,
                permissions: lines.map((item) => item.id as string),
              });
            }}
            errorMessage={
              linesQuery.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined
            }
          />
        )}
        {(data.profile === UserProfile.Production || data.profile === UserProfile.Quality) && (
          <Drop
            name="Linha"
            value={selectedLines[0]?.name}
            options={linesQuery.data?.lines?.value?.map((line) => ({ id: line.id, name: line.code })) ?? []}
            onSelect={(line) => {
              if (line.id === '') {
                setSelectedLines([]);
                setData({ ...data, permissions: [] });
              } else {
                setSelectedLines([line]);
                setData({ ...data, permissions: [line.id] });
              }
            }}
            errorMessage={
              linesQuery.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined
            }
          />
        )}
        <Drop
          name="Grupo"
          value={data.groupId}
          options={groupsQuery.data?.groups?.value ?? []}
          onSelect={(group) => {
            setData({ ...data, groupId: group.id });
          }}
          errorMessage={
            groupsQuery.data?.groups?.value?.length === 0 ? 'Não existe nenhum grupo cadastrado.' : undefined
          }
        />
      </Info>
      <Footer>
        <PageOptions>
          <Button
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button title="Cadastrar" color="primary" disabled={!canRegister} onClick={() => handleRegister(data)} />
        </PageOptions>
      </Footer>
    </UserRegistration>
  );
};
