export const routes = {
    dashboard: '/',
    signin: '/signin',
    recoverPassword: '/recoverpasswd',
    scrap: '/scrap',
    overview: '/overview',
    stop: {
      index: '/stop',
      justifications: 'justification',
    },
    charts: {
      index: '/charts',
      oee: 'oee',
      production: 'production',
      productionRate: 'productionRate',
      capacity: 'capacity',
      stop: 'stop',
      scrap: 'scrap',
      cycleTime: 'cycleTime',
      periodAnalysis: 'periodAnalysis',
      parameter: 'parameter',
    },
    history: {
      index: '/history',
      line: 'line',
      machine: 'machine',
      stop: 'stop',
      microStop: 'micro-stop',
      scrap: 'scrap',
      productionCycles: 'productionCycles',
      production: 'production',
      parameters: 'parameters',
    },
    registration: {
      index: '/registration',
      add: 'add',
      edit: 'edit',
      lines: 'lines',
      machines: 'machine',
      users: 'users',
      defects: 'defect',
      stops: 'stop',
      categoryLabels: 'category-labels',
      reasonLabels: 'reason-labels',
      lossLabels: 'loss-labels',
      parameters: 'parameters',
      types: 'type',
      applications: 'applications',
      factories: 'factories',
      groups: 'groups',
      scraps: 'scraps',
    },
  };
  
  export const noMatchRoute = '*';
  export const wildcardRoutes = {
    registration: routes.registration.index + '/*',
    charts: routes.charts.index + '/*',
    history: routes.history.index + '/*',
    stops: routes.stop.index + '/*',
    defectRegistration: routes.registration.defects + '/*',
    stopCategoryRegistration: routes.registration.stops + '/*',
    lineRegistration: routes.registration.lines + '/*',
    userRegistration: routes.registration.users + '/*',
    lossRegistration: routes.registration.lossLabels + '/*',
    reasonRegistration: routes.registration.reasonLabels + '/*',
    machineRegistration: routes.registration.machines + '/*',
    parameterRegistration: routes.registration.parameters + '/*',
    typeRegistration: routes.registration.types + '/*',
    applicationRegistration: routes.registration.applications + '/*',
    factoriesRegistration: routes.registration.factories + '/*',
    groupsRegistration: routes.registration.groups + '/*',
    scrapRegistration: routes.registration.scraps + '/*',
  };