import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Bell } from './Bell';
import { Bottleneck } from './Bottleneck';
import { Check } from './Check';
import { Cross } from './Cross';
import { Forbidden } from './Forbidden';
import { Moon } from './Moon';
import { Hamburguer } from './Hamburguer';
import { Minus } from './Minus';
import { Open } from './Open';
import { Sun } from './Sun';
import { ArrowDown } from './ArrowDown';
import { House } from './House';
import { Stop } from './Stop';
import { Trashcan } from './Trashcan';
import { Wrench } from './Wrench';
import { Paper } from './Paper';
import { Exit } from './Exit';
import { OeeColored } from './OeeColored';
import { ProductionColored } from './ProductionColored';
import { ProductionTaxColored } from './ProductionTaxColored';
import { BatteryBrokenColored } from './BatteryBrokenColored';
import { BatteryColored } from './BatteryColored';
import { CycleTimeColored } from './CycleTimeColored';
import { StopColored } from './StopColored';
import { PeriodAnalysisColored } from './PeriodAnalysisColored';
import { Circle } from './Circle';
import { CircleChecked } from './CircleChecked';
import { ArrowLeft } from './ArrowLeft';
import { Export } from './Export';
import { MachineColored } from './MachineColored';
import { SettingsColored } from './SettingsColored';
import { ArrowRight } from './ArrowRight';
import { CircleFilled } from './CircleFilled';
import { Bars } from './Bars';
import { Overview } from './Overview';
import { ParamsColored } from './ParamsColored';
import { DefectColored } from './DefectColored';
import { LossColored } from './LossColored';
import { UserColored } from './UserColored';
import { ReasonColored } from './ReasonColored';
import { Eye } from './Eye';
import { EyeSlash } from './EyeSlash';
import { CirclePlus } from './CirclePlus';
import { User } from './User';
import { Connected } from './Connected';
import { Disconnected } from './Disconnected';

type StyledIconProps = {
  area: string;
  isClickable: boolean;
};
const StyledIcon = styled.span<StyledIconProps>`
  display: grid;
  grid-area: ${(props) => props.area};
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'unset')};
`;

export const sizes = {
  smaller: { width: 12, height: 12 },
  small: { width: 20, height: 20 },
  medium: { width: 28, height: 28 },
  large: { width: 32, height: 32 },
  xlarge: { width: 44, height: 44 },
};
type Size = keyof typeof sizes;

export type VectorProps = {
  color?: string;
  size?: Size;
};

const icons = {
  Sun,
  Open,
  Bell,
  Hamburguer,
  Minus,
  Check,
  Cross,
  Bottleneck,
  Moon,
  Forbidden,
  ArrowDown,
  House,
  Stop,
  Trashcan,
  Wrench,
  Paper,
  Exit,
  OeeColored,
  ProductionColored,
  ProductionTaxColored,
  PeriodAnalysisColored,
  StopColored,
  CycleTimeColored,
  BatteryColored,
  BatteryBrokenColored,
  ParamsColored,
  ReasonColored,
  LossColored,
  DefectColored,
  UserColored,
  Circle,
  CircleChecked,
  ArrowLeft,
  Export,
  MachineColored,
  SettingsColored,
  ArrowRight,
  CircleFilled,
  Overview,
  Bars,
  Eye,
  EyeSlash,
  CirclePlus,
  User,
  Connected,
  Disconnected,
};
export type Names = keyof typeof icons;

type IconProps = {
  name: Names;
  size?: VectorProps['size'];
  area?: string;
  color?: string;
  onClick?: () => void;
  dataCy?: string;
};
export const Icon: React.FC<IconProps> = ({ name, size = 'medium', area, color, onClick, dataCy }) => {
  const theme = useTheme();
  return (
    <StyledIcon
      data-cy={dataCy}
      area={area ?? 'unset'}
      onClick={() => onClick?.call(this)}
      isClickable={onClick !== undefined}
    >
      {React.createElement(icons[name], { color: color ?? theme.colors.icon.foreground, size })}
    </StyledIcon>
  );
};
