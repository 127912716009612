import React from 'react';
import { sizes, VectorProps } from './Icon';

export const PeriodAnalysisColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.197 12.506a2.51 2.51 0 01.577-1.637 2.468 2.468 0 013.19-.537c.508.31.888.791 1.074 1.36V.25h-.063c-5.716 0-11.2 2.283-15.247 6.348A21.829 21.829 0 00.38 21.938h16.816v-9.432z"
        fill="#FFBF31"
      />
      <path
        d="M19.73 26.938a2.555 2.555 0 01-1.782-.757 2.591 2.591 0 01-.75-1.794v-2.45H.38V22a21.83 21.83 0 006.325 15.38 21.517 21.517 0 0015.27 6.37h.062V26.937h-2.309z"
        fill="#006EC7"
      />
      <path
        d="M43.57 21.938H31.78a2.483 2.483 0 011.64.86 2.517 2.517 0 01-.179 3.454 2.479 2.479 0 01-1.72.686h-9.484V43.75a21.517 21.517 0 0015.23-6.392A21.83 21.83 0 0043.57 22v-.063z"
        fill="#0033AB"
      />
      <path d="M22.037.25v21.688H43.57A21.83 21.83 0 0037.245 6.62 21.517 21.517 0 0022.037.25z" fill="#EAA00C" />
    </svg>
  );
};
