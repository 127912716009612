import React from 'react';
import { sizes, VectorProps } from './Icon';

export const ReasonColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 45"
    >
      <path
        d="M39.257 44.75a3.812 3.812 0 0 1-2.72-1.135L25.49 32.49a3.874 3.874 0 0 1-1.141-2.744 3.9 3.9 0 0 1 1.127-2.75 3.845 3.845 0 0 1 2.73-1.136 3.822 3.822 0 0 1 2.725 1.15l11.045 11.125a3.901 3.901 0 0 1 .834 4.223 3.869 3.869 0 0 1-1.417 1.739 3.828 3.828 0 0 1-2.137.653Z"
        fill="#0033AB"
      />
      <path
        d="M17.383 34.375a16.577 16.577 0 0 1-6.427-1.278 16.673 16.673 0 0 1-5.442-3.673l3.423-3.448a11.902 11.902 0 0 0 8.446 3.524c3.168 0 6.207-1.267 8.447-3.524a12.076 12.076 0 0 0 3.499-8.507c0-3.191-1.259-6.252-3.499-8.508l3.423-3.447a16.943 16.943 0 0 1 4.594 8.656c.648 3.28.316 6.68-.955 9.769a16.88 16.88 0 0 1-6.183 7.587 16.7 16.7 0 0 1-9.326 2.849Z"
        fill="#EAA00C"
      />
      <path
        d="M5.514 29.424A16.971 16.971 0 0 1 .695 17.498 16.969 16.969 0 0 1 5.612 5.612 16.727 16.727 0 0 1 17.412.66a16.725 16.725 0 0 1 11.842 4.854L25.83 8.96a11.902 11.902 0 0 0-8.447-3.524 11.903 11.903 0 0 0-8.447 3.524A12.075 12.075 0 0 0 5.44 17.47c0 3.19 1.258 6.251 3.498 8.507l-3.423 3.448Z"
        fill="#FFBF31"
      />
      <path
        d="M17.911 20.775a1.005 1.005 0 0 0 .272-.909.981.981 0 0 0-.148-.36 1.05 1.05 0 0 0-.124-.155.968.968 0 0 0-.758-.291.929.929 0 0 0-.33.074 1.04 1.04 0 0 0-.447.372.991.991 0 0 0-.169.558c0 .066.006.131.02.196a.976.976 0 0 0 .273.516.999.999 0 0 0 .653.292h.05c.265 0 .52-.106.708-.293ZM18.195 17.17v-4.526c0-.265-.104-.519-.29-.707a.99.99 0 0 0-.703-.292h-.05a.99.99 0 0 0-.672.31 1.004 1.004 0 0 0-.273.69v4.526c0 .257.098.504.273.69a.99.99 0 0 0 .671.31h.051a.99.99 0 0 0 .702-.293c.187-.188.291-.442.291-.707Z"
        fill="#fff"
      />
      <path
        d="M15.036 9.055 8.799 20.21a2.528 2.528 0 0 0 .925 3.413c.377.22.804.335 1.24.337h6.185v-2.892a.998.998 0 0 1-.926-1.2.989.989 0 0 1 .273-.515 1.036 1.036 0 0 1 .323-.22.97.97 0 0 1 .33-.073v-.884a.99.99 0 0 1-.67-.31 1.004 1.004 0 0 1-.274-.69v-4.53c0-.257.097-.504.273-.69a.99.99 0 0 1 .671-.31V7.78a2.446 2.446 0 0 0-1.227.352 2.469 2.469 0 0 0-.886.923Z"
        fill="#006EC7"
      />
      <path
        d="m25.606 20.209-6.24-11.154a2.47 2.47 0 0 0-.931-.95 2.447 2.447 0 0 0-1.285-.325v3.863h.051a.99.99 0 0 1 .702.292c.187.188.291.442.291.708v4.526c0 .265-.104.52-.29.707a.99.99 0 0 1-.703.293h-.05v.885a1.003 1.003 0 0 1 .434.074c.12.052.23.126.324.217a1.056 1.056 0 0 1 .216.328.989.989 0 0 1 .075.383.937.937 0 0 1-.02.197.97.97 0 0 1-.147.362 1.007 1.007 0 0 1-.828.447h-.05v2.894h6.285a2.483 2.483 0 0 0 1.24-.336 2.527 2.527 0 0 0 .927-3.41Z"
        fill="#0033AB"
      />
    </svg>
  );
};
