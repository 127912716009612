export function formatStringNumber(input: string): string {  
  if (input === '')
    return input;
  else if (input === '.' || input === ',')
    return "0.";
  else {
    let numberAdjust = input;
    if (numberAdjust.match(/\.{1}/)) {
      const numberSplit = input.split('.'); 
      numberAdjust = numberSplit[0] + '.' + numberSplit.slice(1,).join('').replace(',', '');
    }
    const numberConvert = numberAdjust.replace(/[^\d,.]+/g, '').replace(',', '.');
    if (!isNaN(parseFloat(numberConvert)))
      return `${numberConvert}`;
    else return '';
  }
}