import React from 'react';
import { sizes, VectorProps } from './Icon';

export const ProductionTaxColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.879.006h8.527v17.538H11.38V2.506a2.5 2.5 0 012.5-2.5z" fill="#FFBF31" />
      <path d="M22.379.006h8.527a2.5 2.5 0 012.5 2.5v15.038H22.38V.006z" fill="#EAA00C" />
      <path d="M22.407.006H18.02V9.44h4.386V.006z" fill="#006EC7" />
      <path d="M26.791.006h-4.385V9.44h4.385V.006z" fill="#0033AB" />
      <path d="M2.719 17.544h19.687V35.08H.22V20.044a2.5 2.5 0 012.5-2.5z" fill="#FFBF31" />
      <path d="M22.406 17.544h19.688a2.5 2.5 0 012.5 2.5V35.08H22.406V17.544z" fill="#EAA00C" />
      <path d="M33.406 17.544H29.05v9.433h4.357v-9.433z" fill="#006EC7" />
      <path d="M37.764 17.544h-4.358v9.433h4.358v-9.433z" fill="#0033AB" />
      <path d="M22.406 35.081H.22v5.448h22.187v-5.448z" fill="#006EC7" />
      <path d="M44.594 35.081H22.406v5.448h22.188v-5.448z" fill="#0033AB" />
      <path d="M11.122 8.775a9 9 0 00-6.324 15.402l12.726-12.726a8.973 8.973 0 00-6.402-2.676z" fill="#006EC7" />
      <path d="M20.12 17.775a8.975 8.975 0 00-2.597-6.324L4.797 24.177a9 9 0 0015.324-6.402z" fill="#0033AB" />
      <path
        d="M8.497 21.525a1.119 1.119 0 01-.79-1.91l5.588-5.59a1.117 1.117 0 011.578 1.577l-5.587 5.59a1.115 1.115 0 01-.789.333zM8.55 16.266a1.398 1.398 0 100-2.795 1.398 1.398 0 000 2.795zM14.14 21.856a1.397 1.397 0 100-2.795 1.397 1.397 0 000 2.795z"
        fill="#fff"
      />
    </svg>
  );
};
