import React, { useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useOutsideClick } from '../../../hooks/outsideClick';
import {
  formatForCalendar,
  daysOfCalendar,
  chunk,
  titleOfCalendar,
  DaysOfWeekShort,
  MinutesOfHour,
  isToday,
  isSameMonth,
  setHours,
  setMinutes,
  isSameDay,
  limitTimeChoiceIfTrue,
  addMonths,
  subMonths,
  upsertDate,
} from '../../../scripts/calendar';

const Container = styled.div`
  width: min-content;
  position: relative;
  overflow: visible;
  z-index: 2;
`;

const CalendarInputHolder = styled.div`
  position: relative;
  width: min-content;
  height: min-content;
`;

const CalendarHolder = styled.div<{ position: 'left' | 'right' }>`
  position: absolute;
  top: 50px;
  display: grid;
  grid-auto-flow: column;

  ${(props) => {
    if (props.position === 'left')
      return css`
        justify-content: start;
        left: 0px;
      `;
    else
      return css`
        justify-content: end;
        right: 0px;
      `;
  }}
`;

const CalendarInput = styled.input`
  display: flex;
  width: 170px;
  height: 45px;
  padding: 0px 10px 0px 10px;
  background: transparent;

  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.drop.default.color};
  font-size: 16px;
  font-weight: 500;

  border: 1.5px solid ${(props) => props.theme.colors.drop.default.border};
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;

  ${CalendarInputHolder}:hover & {
    box-shadow: 0px 2px 10px rgba(0, 100, 199, 0.25);
    margin-top: 1px;
    cursor: pointer;
  }

  ::-webkit-calendar-picker-indicator {
    filter: ${(props) => (props.theme.id === 'T_001' ? 'invert(0)' : 'invert(1)')};
  }
`;

const CalendarHoverTop = styled.div`
  padding-bottom: 10px;
  display: flex;
`;

const CalendarHover = styled.div`
  user-select: none;
  overflow: hidden;
  cursor: default;
  width: 220px;
  height: 290px;
  padding: 10px;
  background-color: white;
  box-shadow: 0px 2px 10px #0064c73f;
`;

const CalendarTitle = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: black;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;
  height: max-content;
  text-align: center;
`;

const TableRow = styled.tr`
  color: white;
`;

const TableCell = styled.td<{ today: boolean; thisMonth: boolean; selected: boolean }>`
  width: 20px;
  height: 25px;

  font-size: 14px;
  border-radius: 2px;
  color: ${(props) => {
    if (props.selected) return '#fff';
    if (props.thisMonth) return '#000';
    else return '#9e9e9e';
  }};
  border: 1px solid ${(props) => (props.today ? '#9e9e9e' : 'transparent')};
  background-color: ${(props) => (props.selected ? '#0064c7' : 'transparent')};

  &:hover {
    background-color: #006dc775;
    cursor: pointer;
    color: white;
  }
`;

const TableHeader = styled.th`
  font-weight: 300;
  font-size: 13px;
  color: black;
`;

const CalendarToday = styled.span`
  margin: 5px 0px 0px auto;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
  color: black;
  text-align: end;
  padding: 3px;

  &:hover {
    background-color: #006dc775;
    cursor: pointer;
    color: white;
  }
`;

const Select = styled.div`
  background-color: white;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 310px;
  width: 50px;

  overscroll-behavior-y: contain;
  &::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }
`;

const Option = styled.option<{ selected: boolean }>`
  text-align: center;
  line-height: 25px;
  width: 100%;
  height: 30px;

  background-color: ${(props) => (props.selected ? '#0064c7' : 'transparent')};
  color: ${(props) => (props.selected ? 'white' : 'black')};
  scroll-snap-align: start;

  &:hover {
    background-color: #006dc775;
    cursor: pointer;
    color: white;
  }
`;

const CalendarSVG = styled.svg`
  position: absolute;
  left: 160px;
  top: 15px;
  width: 16px;
  height: 16px;

  fill: ${(props) => props.theme.colors.drop.default.color};
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 5.9078;
  paint-order: stroke fill markers;

  ${CalendarInputHolder}:hover & {
    cursor: pointer;
  }
`;

const ArrowSVG = styled.svg`
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 5px;
  fill: black;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3px;
  paint-order: stroke fill markers;

  &:hover {
    background-color: #006dc775;
    border-radius: 2px;
    fill: white;
  }
`;

const ArrowSVGUP = styled(ArrowSVG)``;
const ArrowSVGDown = styled(ArrowSVG)``;

type Props = {
  value?: Date;
  dataCy?: string;
  placeholder?: string;
  timestep?: number;
  selectTime?: boolean;
  position?: 'left' | 'right';
  onSelect?: (value: Date) => void;
};

export const StepCalendar: React.FC<Props> = ({
  dataCy,
  value,
  onSelect,
  timestep,
  placeholder,
  position = 'left',
  selectTime = true,
}) => {
  const [displayDate, setDisplayDate] = React.useState(new Date());
  const [calendar, showCalendar] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(value);

  const calendarRef = React.useRef<HTMLDivElement>(null);
  const calendarInputRef = React.useRef<HTMLInputElement>(null);
  useOutsideClick([calendarRef, calendarInputRef], () => showCalendar(false));

  const displayText = useMemo(() => formatForCalendar(selectedDate, placeholder), [selectedDate]);
  const displayMonth = useMemo(() => titleOfCalendar(displayDate), [displayDate]);
  const daysOfMonth = useMemo(() => daysOfCalendar(displayDate), [displayDate]);
  const daysByWeek = useMemo(() => chunk(daysOfMonth, 7), [daysOfMonth]);
  const { hours, minutes } = useMemo(() => limitTimeChoiceIfTrue(timestep, selectTime), [timestep, selectTime]);

  const handleCalendarInputClick = () => showCalendar((prev) => !prev);
  const handleDayClick = (date: Date) => setSelectedDate((prev) => upsertDate(prev, date));
  const handleArrowDownClick = () => setDisplayDate((prev) => addMonths(prev, 1));
  const handleArrowUpClick = () => setDisplayDate((prev) => subMonths(prev, 1));
  const handleSetHours = (hours: number) => setSelectedDate((prev) => setHours(prev, hours));
  const handleSetMinutes = (minutes: number) => setSelectedDate((prev) => setMinutes(prev, minutes));

  useEffect(() => {
    if (!onSelect || !selectedDate) return;
    if (selectedDate !== value) {
      setDisplayDate(selectedDate);
      onSelect(selectedDate);
    }
  }, [selectedDate, onSelect]);

  return (
    <Container data-cy={dataCy}>
      <CalendarInputHolder ref={calendarInputRef} onClick={handleCalendarInputClick}>
        <CalendarInput type="text" value={displayText} readOnly={true} />
        <CalendarSVG width="100.59" height="105.34" viewBox="64.217 107.53 100.59 105.34">
          <path d="m87.169 107.53c-3.0395 0-5.4865 2.447-5.4865 5.4865v6.0213h-0.95808c-9.1452 0-16.508 7.3624-16.508 16.508v60.817c0 9.1452 7.3624 16.508 16.508 16.508h67.57c9.1452 0 16.508-7.3624 16.508-16.508v-60.817c0-9.1452-7.3624-16.508-16.508-16.508h-0.95757v-6.0213c0-3.0395-2.447-5.4865-5.4865-5.4865h-5.4865c-3.0395 0-5.4865 2.447-5.4865 5.4865v6.0213h-32.735v-6.0213c0-3.0395-2.447-5.4865-5.4865-5.4865zm-8.5855 36.576h71.852v56.694h-71.852z" />
        </CalendarSVG>
      </CalendarInputHolder>
      {calendar && (
        <CalendarHolder ref={calendarRef} position={position}>
          <CalendarHover>
            <CalendarHoverTop>
              <CalendarTitle>{displayMonth}</CalendarTitle>
              <ArrowSVGDown viewBox="75.908 78.837 50.084 82.634" onClick={handleArrowDownClick}>
                <path d="m100.95 161.47a1.6325 1.6325 0 0 1-0.10697-0.0103 1.6327 1.6327 0 0 1-0.10438-0.0103 1.6325 1.6325 0 0 1-0.41341-0.10387 1.6325 1.6325 0 0 1-0.36536-0.21859 1.6327 1.6327 0 0 1-0.08165-0.0677 1.6325 1.6325 0 0 1-0.08216-0.0672l-23.41-23.408a1.6325 1.6325 0 0 1 0-2.3089 1.6325 1.6325 0 0 1 2.3089 0l20.623 20.621v-75.428a1.6325 1.6325 0 0 1 1.6325-1.633 1.6325 1.6325 0 0 1 1.633 1.633v75.427l20.621-20.621a1.6325 1.6325 0 0 1 2.3089 0 1.6325 1.6325 0 0 1 0 2.3089l-23.408 23.408a1.6325 1.6325 0 0 1-0.34314 0.25425 1.6327 1.6327 0 0 1-0.0904 0.0486 1.6325 1.6325 0 0 1-0.72089 0.17518z" />
              </ArrowSVGDown>
              <ArrowSVGUP viewBox="75.908 78.837 30.355 82.633" onClick={handleArrowUpClick}>
                <path d="m91.086 78.837a1.6325 1.6325 0 0 0-0.10697 0.01035 1.6326 1.6326 0 0 0-0.10438 0.01035 1.6325 1.6325 0 0 0-0.41341 0.10387 1.6325 1.6325 0 0 0-0.36536 0.21859 1.6326 1.6326 0 0 0-0.08165 0.0677 1.6325 1.6325 0 0 0-0.08216 0.06718l-23.41 23.408a1.6325 1.6325 0 0 0 0 2.3089 1.6325 1.6325 0 0 0 2.3089 0l20.623-20.621v75.426a1.6325 1.6325 0 0 0 1.6324 1.633 1.6325 1.6325 0 0 0 1.633-1.633v-75.426l20.621 20.621a1.6325 1.6325 0 0 0 2.3089 0 1.6325 1.6325 0 0 0 0-2.3089l-23.408-23.408a1.6325 1.6325 0 0 0-0.34313-0.25424 1.6326 1.6326 0 0 0-0.090433-0.048577 1.6325 1.6325 0 0 0-0.72088-0.17518z" />
              </ArrowSVGUP>
            </CalendarHoverTop>
            <Table>
              <thead>
                <TableRow>
                  {DaysOfWeekShort.map((day, i) => (
                    <TableHeader key={`${day}${i}`}>{day}</TableHeader>
                  ))}
                </TableRow>
              </thead>
              <tbody>
                {daysByWeek.map((week) => (
                  <TableRow key={week.join(',')}>
                    {week.map((day) => (
                      <TableCell
                        today={isToday(day)}
                        selected={isSameDay(day, selectedDate)}
                        thisMonth={isSameMonth(day, displayDate)}
                        key={day.toISOString()}
                        onClick={() => handleDayClick(day)}
                      >
                        {day.getDate().toString()}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </tbody>
            </Table>
            <CalendarToday onClick={() => handleDayClick(new Date())}>Hoje</CalendarToday>
          </CalendarHover>
          {hours && (
            <Select>
              {hours.map((hour) => (
                <Option
                  key={hour.toString()}
                  value={hour}
                  onClick={() => handleSetHours(hour)}
                  selected={selectedDate?.getHours() === hour}
                >
                  {hour.toString().padStart(2, '0')}
                </Option>
              ))}
            </Select>
          )}
          {minutes && (
            <Select>
              {MinutesOfHour.map((minute) => (
                <Option
                  key={minute.toString()}
                  value={minute}
                  onClick={() => handleSetMinutes(minute)}
                  selected={selectedDate?.getMinutes() === minute}
                >
                  {minute.toString().padStart(2, '0')}
                </Option>
              ))}
            </Select>
          )}
        </CalendarHolder>
      )}
    </Container>
  );
};
