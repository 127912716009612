import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Footer } from '../../components/layout/Footer';
import { PageOptions } from '../../components/layout/PageOptions';
import { UserRegistration } from '../../components/layout/UserRegistration';
import { Button } from '../../components/ui/Button';
import { Drop } from '../../components/ui/Drop';
import { Input } from '../../components/ui/Input';
import { MultiDropdown } from '../../components/ui/MultiDropdown';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import {
  MutationUpdateUserArgs,
  UpdateUserInput,
  useAllGroupsQuery,
  useLineTitleListQuery,
  UserProfile,
  useUpdateUserMutation,
} from '../../hooks/graphqlQueries';

const emailRegex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;

const Info = styled.div`
  display: grid;
  grid-area: Info;
  grid-template:
    'Name Name LastName LastName' max-content
    'Email Email Profile Line' max-content;
  row-gap: 19px;
  column-gap: 19px;
  align-items: end;

  @media (max-width: 830px) {
    grid-template:
      'Name' max-content
      'LastName' max-content
      'Email' max-content
      'Profile' max-content
      'Line' max-content;
  }

  @media (max-width: 660px) {
    justify-content: center;
    align-items: center;
  }
`;

export const UserEditPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const feedback = useFeedback();
  const location = useLocation();

  const [linesQuery] = useLineTitleListQuery();
  const [groupsQuery] = useAllGroupsQuery();
  const [, updateUser] = useUpdateUserMutation();

  const lines = useMemo(() => linesQuery.data?.lines?.value ?? [], [linesQuery.data]);
  const routeData = location.state as { data: MutationUpdateUserArgs; pagination: { page: number; limit: number } };
  const [data, setData] = useState<MutationUpdateUserArgs>(routeData?.data as MutationUpdateUserArgs);

  const canRegister = useMemo(() => {
    for (const key in data.user) {
      const value = data.user[key as keyof UpdateUserInput] ?? '';
      if (key !== 'groupId' && typeof value !== 'boolean' && value.length <= 0) return false;
    }

    return true;
  }, [data]);

  const handleUpdate = () => {
    if (!data.user?.email?.match(emailRegex)) {
      feedback.message({
        title: 'Email Inválido',
        subtitle: 'Por favor, informe um email válido.',
        animation: 'Error',
      });
      return;
    }

    updateUser({ id: data.id, ...data.user })
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.updateUser === null)
          feedback.message({ animation: 'Error', title: 'Erro ao editar usuário' });
        else {
          feedback.message({ animation: 'Confetti', title: 'Usuário editado com sucesso' });
          navigate('..', { state: { pagination: routeData?.pagination } });
        }
      })
      .catch(() => {
        feedback.message({ animation: 'Error', title: 'Erro ao editar usuário' });
      });
  };

  return (
    <UserRegistration>
      <Title>Editar Usuário</Title>
      <Info>
        <Input
          label="Nome"
          area="Name"
          value={data.user.firstName ?? ''}
          width="376px"
          onChange={(e) => setData({ ...data, user: { ...data.user, firstName: e.target.value } })}
        />
        <Input
          label="Sobrenome"
          area="LastName"
          value={data.user.lastName ?? ''}
          width="376px"
          onChange={(e) => setData({ ...data, user: { ...data.user, lastName: e.target.value } })}
        />
        <Input
          label="E-mail"
          area="Email"
          value={data.user.email ?? ''}
          width="376px"
          onChange={(e) => setData({ ...data, user: { ...data.user, email: e.target.value } })}
        />
        <Drop
          name=""
          value={t(data.user.profile ?? 'NO_PROFILE')}
          options={Object.keys(UserProfile).map((value) => ({
            id: value,
            name: t(UserProfile[value as keyof typeof UserProfile]),
          }))}
          onSelect={(profile) => {
            setData({
              ...data,
              user: { ...data.user, profile: UserProfile[profile.id as keyof typeof UserProfile] },
            });
          }}
          errorMessage={Object.keys(UserProfile).length === 0 ? 'Não existe nenhum perfil cadastrado.' : undefined}
        />
        {(data.user.profile === UserProfile.Admin ||
          data.user.profile === UserProfile.Supervisor ||
          data.user.profile === UserProfile.Process) && (
          <MultiDropdown
            name="Linha"
            value={data.user.permissions ?? []}
            options={lines.map((line) => ({ id: line.id, name: line.code })) ?? []}
            onBlur={(lines) => {
              setData({
                ...data,
                user: { ...data.user, permissions: lines.map((item) => item.id as string) },
              });
            }}
            errorMessage={lines.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined}
          />
        )}
        {data.user.profile === UserProfile.Production && (
          <Drop
            name="Linha"
            area="Line"
            value={
              lines.find((line) => {
                return line.id === data.user.permissions?.at(0);
              })?.code ?? ''
            }
            options={lines.map((line) => ({ id: line.id, name: line.code })) ?? []}
            onSelect={(line) => {
              if (line.id === '') {
                setData({ ...data, user: { ...data.user, permissions: [] } });
              } else {
                setData({ ...data, user: { ...data.user, permissions: [line.id] } });
              }
            }}
            errorMessage={lines.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined}
          />
        )}
        <Drop
          name="Grupo"
          size="large"
          value={data.user.groupId ?? ''}
          options={groupsQuery.data?.groups?.value ?? []}
          onSelect={(group) => {
            setData({ ...data, user: { ...data.user, groupId: group.id } });
          }}
          errorMessage={
            groupsQuery.data?.groups?.value?.length === 0 ? 'Não existe nenhum grupo cadastrado.' : undefined
          }
        />
      </Info>
      <Footer>
        <PageOptions>
          <Button
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button title="Salvar" color="primary" disabled={!canRegister} onClick={() => handleUpdate()} />
        </PageOptions>
      </Footer>
    </UserRegistration>
  );
};
