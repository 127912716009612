import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Footer } from '../../components/layout/Footer';
import { ItemRegistration } from '../../components/layout/ItemRegistration';
import { PageOptions } from '../../components/layout/PageOptions';
import { Button } from '../../components/ui/Button';
import { Input } from '../../components/ui/Input';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import { useRegisterItemMutation } from '../../hooks/graphqlQueries';

const InfoDetails = styled.div`
  display: grid;
  grid-area: InfoDetails;
  grid-template-columns: 1fr 1fr;
  row-gap: 19px;
  column-gap: 19px;
  align-items: end;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
`;

type Item = {
  id: string;
  name: string;
  code: string;
  description: string;
};

function createItemInfo() {
  return {
    id: '',
    name: '',
    code: '',
    description: '',
  };
}

export const ItemTypeRegistrationPage: React.FC = () => {
  const navigate = useNavigate();
  const feedback = useFeedback();
  const location = useLocation();

  const routeData = location.state as { pagination: { page: number; limit: number } };

  const [, registerItem] = useRegisterItemMutation();

  const [info, setInfo] = useState<Item>(createItemInfo());

  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    if (info.name.length > 0 && info.code.length > 0) setDisableSave(false);
    else setDisableSave(true);
  }, [info]);

  const handleSave = () => {
    const registerInfo = {
      itemId: info.id,
      name: info.name,
      code: info.code,
      description: info.description,
    };

    const dialog = () => {
      feedback.confirmation({
        title: 'Deseja cadastrar outro tipo de item?',
        onHandle: (result) => {
          result ? setInfo(createItemInfo()) : navigate('..', { state: { pagination: routeData?.pagination } });
        },
      });
    };

    registerItem(registerInfo)
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.createItemType === null)
          feedback.message({ animation: 'Error', title: 'Erro ao cadastrar tipo de item' });
        else
          feedback.message({
            animation: 'Confetti',
            title: 'Tipo de Item cadastrado com sucesso',
            onClose: () => dialog(),
          });
      })
      .catch(() => {
        feedback.message({ animation: 'Error', title: 'Erro ao cadastrar tipo de item' });
      });
  };

  return (
    <ItemRegistration>
      <Title>Cadastrar Tipo de Item</Title>
      <InfoDetails>
        <Input
          dataCy="name-field"
          label="Nome do Tipo"
          value={info.name}
          width="376px"
          onChange={(e) => setInfo({ ...info, name: e.target.value })}
        />
        <Input
          dataCy="code-field"
          label="Código do Tipo"
          value={info.code}
          width="376px"
          onChange={(e) => setInfo({ ...info, code: e.target.value })}
        />
        <Input
          dataCy="description-field"
          label="Descrição"
          maxLength={100}
          value={info.description}
          width="376px"
          onChange={(e) => setInfo({ ...info, description: e.target.value })}
        />
      </InfoDetails>
      <Footer>
        <PageOptions>
          <Button
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button
            dataCy="register-button"
            title="Salvar"
            color="primary"
            disabled={disableSave}
            onClick={() => handleSave()}
          />
        </PageOptions>
      </Footer>
    </ItemRegistration>
  );
};
