import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Sun: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1254 1.91553V3.48045M15.1254 28.5193V30.0842M29.2097 15.9999H27.6448M2.60594 15.9999H1.04102M25.0846 25.9591L23.9782 24.8527M6.27257 7.14708L5.16616 6.04068M25.0846 6.04068L23.9782 7.14708M6.27257 24.8527L5.16616 25.9591M21.3851 15.9999C21.3851 17.6601 20.7256 19.2522 19.5516 20.4262C18.3777 21.6001 16.7855 22.2596 15.1254 22.2596C13.4652 22.2596 11.873 21.6001 10.6991 20.4262C9.52516 19.2522 8.86565 17.6601 8.86565 15.9999C8.86565 14.3397 9.52516 12.7475 10.6991 11.5736C11.873 10.3997 13.4652 9.74017 15.1254 9.74017C16.7855 9.74017 18.3777 10.3997 19.5516 11.5736C20.7256 12.7475 21.3851 14.3397 21.3851 15.9999Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
