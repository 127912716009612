import React from 'react';
import { sizes, VectorProps } from './Icon';

export const ProductionColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.48 4.739h8.467v17.537H13.998V7.24c0-.663.262-1.3.727-1.768a2.473 2.473 0 011.755-.732z"
        fill="#FFBF31"
      />
      <path
        d="M24.92 4.739h8.466c.659 0 1.29.263 1.756.732a2.51 2.51 0 01.727 1.768v15.037h-10.95V4.74z"
        fill="#EAA00C"
      />
      <g filter="url(#prefix__filter0_d_0:1)">
        <path d="M24.919 4.739h-4.325v9.432h4.325V4.74z" fill="#006EC7" />
      </g>
      <g filter="url(#prefix__filter1_d_0:1)">
        <path d="M29.272 4.739h-4.325v9.432h4.325V4.74z" fill="#0033AB" />
      </g>
      <path
        d="M5.4 22.276h19.547v17.538H2.918V24.776c0-.663.261-1.299.727-1.767a2.473 2.473 0 011.755-.733z"
        fill="#FFBF31"
      />
      <g filter="url(#prefix__filter2_d_0:1)">
        <path d="M13.997 22.276H9.512v9.433h4.485v-9.433z" fill="#006EC7" />
      </g>
      <g filter="url(#prefix__filter3_d_0:1)">
        <path d="M18.417 22.276h-4.485v9.433h4.485v-9.433z" fill="#0033AB" />
      </g>
      <path
        d="M24.947 22.276h19.547c.658 0 1.29.264 1.755.733.466.468.727 1.104.727 1.767v15.038H24.947V22.276z"
        fill="#EAA00C"
      />
      <g filter="url(#prefix__filter4_d_0:1)">
        <path d="M35.867 22.276h-4.326v9.433h4.326v-9.433z" fill="#006EC7" />
      </g>
      <g filter="url(#prefix__filter5_d_0:1)">
        <path d="M40.194 22.276h-4.327v9.433h4.327v-9.433z" fill="#0033AB" />
      </g>
      <path d="M24.947 39.814H2.918v5.447h22.029v-5.447z" fill="#006EC7" />
      <path d="M46.976 39.814H24.947v5.447h22.03v-5.447z" fill="#0033AB" />
      <defs>
        <filter
          id="prefix__filter0_d_0:1"
          x={0.594}
          y={-8.261}
          width={44.324}
          height={49.433}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={10} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_0:1" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_0:1" result="shape" />
        </filter>
        <filter
          id="prefix__filter1_d_0:1"
          x={4.947}
          y={-8.261}
          width={44.324}
          height={49.433}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={10} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_0:1" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_0:1" result="shape" />
        </filter>
        <filter
          id="prefix__filter2_d_0:1"
          x={-10.488}
          y={9.276}
          width={44.484}
          height={49.433}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={10} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_0:1" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_0:1" result="shape" />
        </filter>
        <filter
          id="prefix__filter3_d_0:1"
          x={-6.068}
          y={9.276}
          width={44.484}
          height={49.433}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={10} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_0:1" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_0:1" result="shape" />
        </filter>
        <filter
          id="prefix__filter4_d_0:1"
          x={11.541}
          y={9.276}
          width={44.326}
          height={49.433}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={10} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_0:1" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_0:1" result="shape" />
        </filter>
        <filter
          id="prefix__filter5_d_0:1"
          x={15.867}
          y={9.276}
          width={44.326}
          height={49.433}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={7} />
          <feGaussianBlur stdDeviation={10} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_0:1" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_0:1" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
