import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { format, parseISO } from 'date-fns';
import { Icon } from '../../ui/Icon';
import { Logo } from '../../ui/Logo';
import { SideBar } from '../../ui/SideBar';
import { Notification } from '../Notification/Notification';
import { Link } from 'react-router-dom';
import { routes } from '../../../Routes';

export const StyledNavBar = styled.div`
  display: grid;
  grid-area: Top;

  grid-template:
    'Logo . Date Time Theme Notification Menu'
    100% /
    max-content 1fr max-content max-content max-content max-content max-content;

  column-gap: 32px;
  height: 90px;
  padding-left: 55px;
  padding-right: 55px;
  background-color: ${(props) => props.theme.colors.navbar.background};
  box-shadow: 0px 2px 20px rgba(1, 35, 83, 0.15);

  align-items: center;
  justify-content: center;

  @media (max-width: 720px) {
    grid-template:
      'Logo . Theme Notification Menu'
      100% /
      max-content 1fr max-content max-content max-content;
  }

  @media (max-width: 560px) {
    padding-left: 25px;
    padding-right: 25px;
    column-gap: 15px;
  }

  @media (max-width: 315px) {
    grid-template:
      'Logo Theme Notification Menu'
      100% /
      max-content max-content max-content max-content;
    column-gap: 15px;
  }
`;

const Text = styled.span<{ area: string }>`
  display: grid;
  grid-area: ${(props) => props.area};
  font-family: ${(props) => props.theme.font};
  font-weight: 500;
  font-size: 20px;
  color: ${(props) => props.theme.colors.navbar.color};
  pointer-events: none;

  @media (max-width: 720px) {
    display: none;
  }
`;

type Props = {
  setTheme: (theme: 'light' | 'dark') => void;
};
export const NavBar: React.FC<Props> = ({ setTheme }) => {
  const theme = useTheme();
  const isDark = theme.id !== 'T_001';

  const [time, setTime] = useState(new Date().toISOString());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toISOString());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <StyledNavBar>
      <Link to={routes.dashboard}>
        <Logo style="horizontal" />
      </Link>
      <Text area="Date">{format(parseISO(time), 'dd/MM/yyyy')}</Text>
      <Text area="Time">{format(parseISO(time), 'HH:mm:ss')}</Text>
      <Icon
        name={isDark ? 'Sun' : 'Moon'}
        color={theme.colors.navbar.color}
        size={isDark ? 'medium' : 'small'}
        area="Theme"
        onClick={() => setTheme(isDark ? 'light' : 'dark')}
      />
      <Notification />
      <SideBar />
    </StyledNavBar>
  );
};
