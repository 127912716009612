import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Connected: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={sizes[size].width}
      height={sizes[size].height}
      fill='none'
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="drop" transform="translate(66.144108, 66.144108)">
          <path
            d="M114.431169,84.2612793 L129.516113,99.346224 C166.005654,62.8566837 222.355026,58.3310613 263.770806,85.7693569 L349.541895,-9.76285719e-12 L379.711784,30.1698893 L293.942427,115.940978 C321.380723,157.356758 316.8551,213.70613 280.36556,250.195671 L295.450505,265.280615 L265.280615,295.450505 L250.195671,280.36556 C213.70613,316.8551 157.356758,321.380723 115.940978,293.942427 L30.1698893,379.711784 L8.52651283e-14,349.541895 L85.7693569,263.770806 C58.3310613,222.355026 62.8566837,166.005654 99.346224,129.516113 L84.2612793,114.431169 L114.431169,84.2612793 Z M129.516113,159.686003 C104.522559,184.679557 104.522559,225.202116 129.516113,250.195671 C153.617041,274.296598 192.157566,275.157346 217.289893,252.777913 L220.025781,250.195671 L129.516113,159.686003 Z M159.686003,129.516113 L250.195671,220.025781 C275.189225,195.032227 275.189225,154.509668 250.195671,129.516113 C226.094743,105.415186 187.554218,104.554438 162.421891,126.933871 L159.686003,129.516113 Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};
