import React from 'react';
import { Handle, Position } from 'react-flow-renderer';
import { StatesWithColor } from '../../../scripts/colorOfState';
import { MachineCard } from '../../ui/MachineCard/MachineCard';

type NodeProps = {
  data: {
    id: string;
    name: string;
    status: StatesWithColor;
    production: number;
    disabled: boolean;
    stopTime: number;
    bottleneckIdealCycle: number;
    bottleneckProduction: number;
    isBottleneck: boolean;
    idealCycle: number;
    stopCycle: number;
    clock: Date;
    time: string;
    shiftStartAt?: Date;
    timeLastItem: string;
    itemTypeCode?: string;
    itemTypeName?: string;
  };
};

export const DefaultNode = React.memo((props: NodeProps) => {
  const {
    id,
    name,
    status,
    disabled,
    production,
    stopTime,
    bottleneckIdealCycle,
    bottleneckProduction,
    isBottleneck,
    stopCycle,
    idealCycle,
    shiftStartAt,
    clock,
    time,
    timeLastItem,
    itemTypeCode,
    itemTypeName,
  } = props.data;

  return (
    <>
      <Handle id="t" type="source" position={Position.Top} style={{ top: 0, left: 70, visibility: 'hidden' }} />
      <Handle id="l" type="source" position={Position.Left} style={{ top: 50, left: 0, visibility: 'hidden' }} />
      <Handle id="r" type="source" position={Position.Right} style={{ top: 50, right: 0, visibility: 'hidden' }} />
      <Handle id="b" type="source" position={Position.Bottom} style={{ bottom: 0, left: 70, visibility: 'hidden' }} />
      <MachineCard
        id={id}
        name={name}
        status={status}
        disabled={disabled}
        production={production}
        stopTime={stopTime}
        bottleneckIdealCycle={bottleneckIdealCycle}
        bottleneckProduction={bottleneckProduction}
        shiftStartAt={shiftStartAt}
        isBottleneck={isBottleneck}
        stopCycle={stopCycle}
        idealCycle={idealCycle}
        time={time}
        clock={clock}
        timeLastItem={timeLastItem}
        itemTypeCode={itemTypeCode}
        itemTypeName={itemTypeName}
      />
    </>
  );
});
