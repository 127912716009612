import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { Icon } from '../Icon';

const sizes = {
  small: { text: '16px', width: '136px', height: '45px' },
  medium: { text: '16px', width: '179px', height: '45px' },
  large: { text: '16px', width: '377px', height: '45px' },
};
type Size = keyof typeof sizes;

type Color = keyof DefaultTheme['colors']['drop'];

const Container = styled.div`
  position: relative;
  cursor: pointer;
  user-select: none;
`;

type BoxProps = {
  visible: boolean;
};
const Box = styled.div<BoxProps>`
  display: ${(props) => (props.visible ? 'grid' : 'none')};
  position: absolute;
  grid-template-rows: repeat(6, max-content);
  row-gap: 5px;
  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #001738;
  z-index: 5;

  width: 300px;
  height: 50px;
  padding: 10px;
  top: -15px;
  border-radius: 5px;
  background-color: #ffffff;

  align-items: center;
  justify-content: space-between;
`;

const BoxTitle = styled.span`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  color: black;
`;

export const StyledLabel = styled.label`
  display: inline-block;
  height: 20px;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 5px;
  padding-left: 5px;
  color: ${(props) => props.theme.colors.input.color};
`;

const StyledDropdownBox = styled.div<{ size: Size; disabled: boolean; color: Color }>`
  display: flex;
  width: calc(${(props) => sizes[props.size].width} - 20px);
  max-width: 120vw;
  height: ${(props) => sizes[props.size].height};
  padding: 0px 10px 0px 10px;

  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.drop[props.color].color};
  font-size: 16px;
  font-weight: 500;

  border: 1.5px solid ${(props) => props.theme.colors.drop[props.color].border};
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;

  &&:hover {
    box-shadow: 0px 2px 10px rgba(0, 100, 199, 0.25);
    position: relative;
    top: 1px;
  }
`;

const DropDown = styled.div<{ size: Size; visible: boolean; color: Color }>`
  position: absolute;
  z-index: 5;
  display: ${(props) => (props.visible ? 'grid' : 'none')};
  min-width: calc(${(props) => sizes[props.size].width});/*Added by charles*/
  width: auto; /*Added by charles*/
  max-width: 120vw;
  max-height: 202px;
  overflow-x: hidden;
  overflow-y: scroll;

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.drop[props.color].scrollbar.color};

  /* Edge, Chrome */
  &&::-webkit-scrollbar {
    width: 12px;
  }

  &&::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.drop[props.color].item.background};
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.drop[props.color].scrollbar.color};
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid ${(props) => props.theme.colors.drop[props.color].item.background};
  }

  background-color: ${(props) => props.theme.colors.drop[props.color].item.background};
  color: ${(props) => props.theme.colors.drop[props.color].color};
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-weight: 500;

  align-items: center;
`;

const Item = styled.div<{ size: Size; color: Color }>`
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 10px;
  min-height: 50px;
  /*max-width: calc(${(props) => sizes[props.size].width} - 16px);*/
  word-break: normal; /*Added by charles*/
  padding: 0px 8px 0px 8px;
  align-items: center;
  background-color: ${(props) => props.theme.colors.drop[props.color].item.background};

  &&:hover {
    background-color: ${(props) => props.theme.colors.drop[props.color].item.backgroundHover};
  }
`;

const Text = styled.span<{ color: Color }>`
  display: flex;
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.drop[props.color].item.color};
  font-size: 16px;
  font-weight: 500;
  /*word-break: break-all;*//*Added by charles*/

  ${Item}:hover & {
    color: ${(props) => props.theme.colors.drop[props.color].item.colorHover};
  } ;
`;

const Selected = styled.span`
  overflow: hidden;
  direction: ltr;
  text-overflow: ellipsis;
  padding-right: 5px;
  white-space: nowrap;
`;

type Props = {
  name: string;
  label?: string;
  color?: Color;
  options: { id: string; name: string }[];
  onBlur: (values: { id: string; name: string }[]) => void;
  disabled?: boolean;
  value: string[];
  dataCy?: string;
  size?: Size;
  errorMessage?: string;
};

export const MultiDropdown: React.FC<Props> = ({
  name,
  label,
  color = 'default',
  options,
  onBlur,
  disabled = false,
  value = [],
  dataCy,
  size = 'medium',
  errorMessage,
}) => {
  const theme = useTheme();
  const [dropDown, setDropDown] = useState(false);
  const [selected, setSelected] = useState<{ id: string; name: string }[]>([]);
  const [boxVisibility, setBoxVisibility] = useState(false);

  useEffect(() => {
    if (value.length === 0) {
      setSelected([]);
    } else {
      const valueSelected: { id: string; name: string }[] = [];
      options.forEach((option) => {
        if (value.find((e) => e === option.id || e === option.name)) valueSelected.push(option);
      });
      setSelected(valueSelected);
    }
  }, [value, options]);

  color = disabled ? 'gray' : color;

  return (
    <Container
      data-cy={dataCy}
      onMouseLeave={() => {
        if (dropDown) {
          onBlur(selected);
          setDropDown(false);
        }
      }}
      onMouseOver={() => setBoxVisibility(true)}
      onMouseOut={() => setBoxVisibility(false)}
    >
      {errorMessage && (
        <Box visible={boxVisibility}>
          <BoxTitle data-cy="box-error">{errorMessage}</BoxTitle>
        </Box>
      )}
      {label && <StyledLabel data-cy="multidrop-label">{label}</StyledLabel>}
      <StyledDropdownBox
        size={size}
        color={color}
        onClick={() => {
          !disabled && setDropDown(!dropDown);
          if (dropDown) onBlur(selected);
        }}
        disabled={disabled}
      >
        <Selected>
          <bdi>{selected.length === 0 || value.length === 0 ? name : selected.map((el) => el.name).toString()}</bdi>
        </Selected>
        <Icon name="ArrowDown" size="smaller" color={theme.colors.drop[color].color} />
      </StyledDropdownBox>

      <DropDown size={size} color={color} visible={dropDown && !disabled}>
        <Item
          size={size}
          color={color}
          onClick={() => {
            const newSelected = options.map((value) => value);
            if (selected.length !== options.length) {
              setSelected(newSelected);
            } else {
              setSelected([]);
            }
          }}
        >
          <Icon
            name={selected.length !== options.length ? 'Circle' : 'CircleChecked'}
            size="small"
            color={theme.colors.drop[color].item.color}
          />
          <Text color={color}>Selecionar tudo</Text>
        </Item>
        {options.map((value, index) => {
          return (
            <Item
              size={size}
              color={color}
              key={index}
              onClick={() => {
                const index = selected.findIndex((e) => e.id == value.id);
                let newSelected = [];

                if (index === -1) newSelected = [...selected, value];
                else if (selected.length > 1) newSelected = [...selected.slice(0, index), ...selected.slice(index + 1)];
                else newSelected = selected;

                setSelected(newSelected);
              }}
            >
              <Icon
                name={selected.findIndex((e) => e.id == value.id) === -1 ? 'Circle' : 'CircleChecked'}
                size="small"
                color={theme.colors.drop[color].item.color}
              />
              <Text color={color}>{value.name}</Text>
            </Item>
          );
        })}
      </DropDown>
    </Container>
  );
};
