import React, { useState } from 'react';
import { useRecoverPasswordMutation } from '../../../hooks/graphqlQueries';
import { useFeedback } from '../../../context/feedback';
import { Button } from '../../ui/Button';
import { Input } from '../../ui/Input';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../Routes';

export const PopupContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 1em;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
  grid-template:
    'Title' max-content
    'Input' max-content
    'Button' max-content;

  Title {
    width: max-content;
    height: max-content;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  Input {
    width: max-content;
    height: max-content;
    padding-top: 1em;
    padding-bottom: 1em;
  }

  Button {
    width: 10em;
    height: 3em;
    margin-bottom: 1em;
  }
`;

const Title = styled.span`
  display: grid;
  grid-area: Title;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  color: #012353;
`;

type Props = {
  emailInput: string;
};

export const SendEmailRecovery: React.FC<Props> = ({ emailInput }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(emailInput);
  const [disableButton, setDisableButton] = useState(false);

  const feedback = useFeedback();
  const [, sendRecoveryEmail] = useRecoverPasswordMutation();

  function showMessageEmailSent(): void {
    feedback.message({
      animation: 'Applause',
      title: 'Email enviado com sucesso',
      onClose: () => navigate(routes.recoverPassword),
    });
  }

  function showErrorMessage(): void {
    feedback.message({
      animation: 'Error',
      title: 'Ocorreu um problema ao recuperar a senha',
      subtitle: 'Tente novamente',
    });
  }

  async function doRecoverPassword(): Promise<void> {
    setDisableButton(true);
    try {
      const response = await sendRecoveryEmail({ email: email });
      if (!response.data || response.error) showErrorMessage();
      else showMessageEmailSent();
    } catch {
      showErrorMessage();
    }
  }

  return (
    <PopupContainer>
      <Title>Recuperação de senha</Title>
      <Input
        type="email"
        placeholder="Digite um email cadastrado"
        value={email}
        onChange={(event) => {
          setEmail(event.target.value);
          setDisableButton(false);
        }}
      ></Input>
      <Button onClick={doRecoverPassword} title="Enviar" color="primary" size="small" disabled={disableButton}></Button>
    </PopupContainer>
  );
};
