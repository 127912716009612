import React from 'react';
import { sizes, VectorProps } from './Icon';

export const EyeSlash: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 4.50195C13.76 4.50195 16 6.74195 16 9.50195C16 10.152 15.87 10.762 15.64 11.332L18.56 14.252C20.07 12.992 21.26 11.362 21.99 9.50195C20.26 5.11195 15.99 2.00195 10.99 2.00195C9.59 2.00195 8.25 2.25195 7.01 2.70195L9.17 4.86195C9.74 4.63195 10.35 4.50195 11 4.50195ZM1 1.77195L3.28 4.05195L3.74 4.51195C2.08 5.80195 0.78 7.52195 0 9.50195C1.73 13.892 6 17.002 11 17.002C12.55 17.002 14.03 16.702 15.38 16.162L15.8 16.582L18.73 19.502L20 18.232L2.27 0.501953L1 1.77195ZM6.53 7.30195L8.08 8.85195C8.03 9.06195 8 9.28195 8 9.50195C8 11.162 9.34 12.502 11 12.502C11.22 12.502 11.44 12.472 11.65 12.422L13.2 13.972C12.53 14.302 11.79 14.502 11 14.502C8.24 14.502 6 12.262 6 9.50195C6 8.71195 6.2 7.97195 6.53 7.30195ZM10.84 6.52195L13.99 9.67195L14.01 9.51195C14.01 7.85195 12.67 6.51195 11.01 6.51195L10.84 6.52195Z"
        fill={color}
        fill-opacity="0.5"
      />
    </svg>
  );
};
