import React, { useEffect, useMemo, useState } from 'react';
import { Dashboard } from '../components/layout/Dashboard';
import { MachineDiagram } from '../components/business/MachineDiagram';
import { ScrollView } from '../components/ui/ScrollView';
import {
  MachineStatus,
  useDashboardQuery,
  useFactoriesNameQuery,
  useLineDashboardListQuery,
  useWorkshiftAtQuery,
} from '../hooks/graphqlQueries';
import { Loading } from '../components/layout/Loading';
import { ComparisonCard, ProgressCard, SimpleValueCard } from '../components/ui/Cards';
import { useMedia } from '../hooks/mediaQuery';
import { isWithinWorkshift } from '../scripts/workshiftInterval';
import { Timeline } from '../components/business/Timeline';
import { addSeconds, parseISO } from 'date-fns';
import { useFutureData } from '../hooks/futureData';
import { useClock } from '../hooks/clock';
import { useFutureLineMetrics } from '../hooks/futureLineMetrics';
import { useTimeline } from '../hooks/timeline';
import { Drop } from '../components/ui/Drop';
import styled from 'styled-components';
import { Input } from '../components/ui/Input';

const Container = styled.div<{ area?: string }>`
  display: flex;
  flex-wrap: wrap;
  grid-area: ${(props) => props.area ?? 'initial'};
  column-gap: 15px;
  row-gap: 15px;
  justify-content: left;
`;

export const DashboardPage: React.FC = () => {
  const isLarge = useMedia('(min-width: 1600px)');
  const isxLarge = useMedia('(min-width: 1680px)');
  const isxxLarge = useMedia('(min-width: 1900px)');

  const [currentFactory, setCurrentFactory] = useState(() => localStorage.getItem('selected-factory'));
  const [factoryName, setFactoryName] = useState(() => localStorage.getItem('selected-factory-name'));
  const [currentLine, setCurrentLine] = useState(() => localStorage.getItem('selected-line'));
  const [lineName, setLineName] = useState(() => localStorage.getItem('selected-line-name'));

  const [factoriesQuery] = useFactoriesNameQuery({ requestPolicy: 'network-only' });
  const [linesQuery] = useLineDashboardListQuery({
    pause: currentFactory === null,
    requestPolicy: 'network-only',
    variables: { factoryId: currentFactory ?? '', disabled: false },
  });
  const [workshiftQuery, refetchWorkshift] = useWorkshiftAtQuery();
  const [dashboardQuery, refetchDashboard] = useDashboardQuery({
    pause: !currentLine || currentLine === '',
    variables: { lineId: currentLine ?? '' },
  });
  const [timeline, resetTimeline] = useTimeline(currentLine);

  const factories = useMemo(
    () => factoriesQuery.data?.factories?.value?.filter((fact) => (fact.lines?.length ?? 0) > 0) ?? [],
    [factoriesQuery.data]
  );
  const dashboard = useMemo(
    () => (currentLine !== '' ? dashboardQuery.data : null),
    [dashboardQuery.data, currentLine]
  );
  const lines = useMemo(() => linesQuery.data?.lines?.value?.filter((line) => !line.disabled) ?? [], [linesQuery.data]);
  const workshift = useMemo(() => workshiftQuery.data?.workshiftAt, [workshiftQuery.data]);
  const machines = useMemo(() => dashboard?.line?.machines ?? [], [dashboard]);
  const conveyors = useMemo(() => dashboard?.conveyors?.value ?? [], [dashboard]);
  const currentState = useMemo(() => dashboard?.currentStateOfLine, [dashboard]);
  const bottleneck = useMemo(() => dashboard?.line?.nominalBottleneck, [dashboard]);
  const thresholds = useMemo(
    () => dashboard?.currentStateOfLine?.machine?.currentRuntimeOptions?.eventThresholds ?? undefined,
    [dashboard]
  );

  const clock = useClock(1000);
  const lastEvent = useMemo(() => timeline[timeline.length - 1], [timeline]);
  const firstEvent = useMemo(() => timeline[0], [timeline]);
  const futureData = useFutureData({
    idealCycle: bottleneck?.idealCycleTime,
    stopCycle: thresholds?.stopCycle,
    lastEvent,
    workshift,
    clock,
  });
  const futureMetrics = useFutureLineMetrics({
    clock,
    production: currentState?.production,
    stopTime: currentState?.stopTime,
    smallStopTime: currentState?.smallStopTime,
    idealCycle: bottleneck?.idealCycleTime ?? 1,
    thresholds,
    workshift,
    lastEvent,
  });

  useEffect(() => {
    if (currentFactory !== null) return;
    if (factories.length > 0 && factories[0]) setCurrentFactory('');
  }, [factories]);

  useEffect(() => {
    if (currentLine !== null && lines.find((el) => el.id === currentLine)) return;
    if (lines.length > 0 && lines[0]) setCurrentLine('');
  }, [lines]);

  useEffect(() => {
    if (workshift && lastEvent && workshift.endingAt < lastEvent.start.toISOString()) {
      resetTimeline();
      refetchWorkshift();
    }
  }, [timeline]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentLine !== '' && currentLine) refetchDashboard();
    }, 5000);
    return () => clearTimeout(timer);
  }, [dashboardQuery.data]);

  useEffect(() => {
    const timer = setTimeout(() => refetchWorkshift(), 5000);
    return () => clearTimeout(timer);
  }, [workshiftQuery.data]);

  useEffect(() => {
    const workshiftAt = workshiftQuery.data?.workshiftAt;
    if (!workshiftAt || !firstEvent) return;
    if (!isWithinWorkshift(firstEvent.start, workshiftAt)) resetTimeline();
  }, [workshiftQuery]);

  function handleSelectFactory(factory: { id: string; name: string }) {
    localStorage.setItem('selected-factory', factory.id);
    localStorage.setItem('selected-factory-name', factory.name);
    setCurrentFactory(factory.id);
    setFactoryName(factory.name);
  }

  function handleSelectLine(line: { id: string; name: string }) {
    localStorage.setItem('selected-line', line.id);
    localStorage.setItem('selected-line-name', line.name);
    setCurrentLine(line.id);
    setLineName(line.name);
    resetTimeline();
  }

  if (linesQuery.fetching) return <Loading showBackground={false} />;

  return (
    <Dashboard>
      <Container area="Title">
        <Drop
          name="UN. Geradora"
          options={
            factories.filter((fact) => {
              const enabledLines = fact.lines?.filter((line) => line.disabled === false);
              if (enabledLines && enabledLines.length > 0) {
                return { id: fact.id, name: fact.name };
              }
              return false;
            }) ?? []
          }
          onSelect={(fact) => {
            handleSelectFactory(fact);
            setCurrentLine('');
            setLineName('');
            resetTimeline();
          }}
          value={factoryName?.toString()}
        />
        <Drop
          name="Linha"
          options={lines.map((line) => ({ id: line.id, name: line.name })) ?? []}
          onSelect={(line) => {
            handleSelectLine(line);
          }}
          size={'large'}
          value={lineName?.toString()}
          disabled={currentFactory?.toString() === ''}
        />
        {currentState?.itemType && (
          <Input value={currentState.itemType?.name} disabled={true} onChange={(e)=>{e.target.value}} />
        )}
      </Container>

      <ScrollView area="Card">
        <ProgressCard title="OEE" value={futureMetrics.oee} />
        <ProgressCard title="Disponibilidade" value={futureMetrics.availability} />
        <ProgressCard title="Performance" value={futureMetrics.performance} />
        <SimpleValueCard title="Produção" value={futureMetrics.production} unit="Pçs" />
        <SimpleValueCard title="Projeção" value={~~futureMetrics.projection} unit="Pçs" />
        <ComparisonCard
          title="Parada"
          left={{ value: currentState?.stopCount, unit: 'qtd' }}
          right={{ value: futureMetrics.stopTime / 60, unit: 'min' }}
        />
        {isLarge && (
          <ComparisonCard
            title="Microparada"
            left={{ value: currentState?.smallStopCount, unit: 'qtd' }}
            right={{ value: futureMetrics.smallStopTime / 60, unit: 'min' }}
          />
        )}
        {isxLarge && <SimpleValueCard title="Velocidade Média" value={futureMetrics.meanSpeed * 60} unit="Pçs/min" />}
        {isxxLarge && <SimpleValueCard title="Ciclo" value={futureMetrics.cycleTime / 60} unit="min" />}
      </ScrollView>
      <MachineDiagram
        area="Diagram"
        lineId={dashboardQuery.data?.line?.id}
        machineData={machines
          .filter(
            (machine) =>
              machine.positionX !== null &&
              machine.positionX !== undefined &&
              machine.positionY !== null &&
              machine.positionX !== undefined
          )
          .sort((a, b) => (b.positionY ?? 0) - (a.positionY ?? 0))
          .map((machine) => {
            return {
              id: machine.id,
              name: machine.name,
              clock: clock,
              shiftStartAt: workshift?.startingAt,
              disabled: machine.disabled ?? false,
              time: machine.currentState?.time ?? undefined,
              timeLastItem: machine.lastItemTime ?? undefined,
              itemTypeCode: machine.currentState?.itemType?.code ?? undefined,
              itemTypeName: machine.currentState?.itemType?.name ?? undefined,
              status: machine.currentState?.status ?? MachineStatus.NotMonitored,
              bottleneckId: bottleneck?.id ?? machine.id,
              production: machine.currentState?.production ?? 0,
              stopCount: machine.currentState?.stopCount ?? 0,
              stopTime: machine.currentState?.stopTime ?? 0,
              positionX: machine?.positionX ?? 0,
              positionY: machine?.positionY ?? 0,
              bottleneckIdealCycle: bottleneck?.idealCycleTime ?? 1,
              bottleneckProduction: bottleneck?.currentState?.production ?? 0,
              isBottleneck: machine.currentState?.isNominalBottleneck ?? false,
              idealCycle: machine?.idealCycleTime ?? 1,
              stopCycle: machine.currentRuntimeOptions?.eventThresholds?.stopCycle ?? 300,
            };
          })}
        conveyorData={conveyors.map((conveyor) => ({
          id: conveyor.id,
          origin: conveyor.origin?.id as string,
          target: conveyor.target?.id as string,
          speed: conveyor.origin?.currentState?.speed ?? 0,
          status: conveyor.origin?.currentState?.status ?? MachineStatus.NotMonitored,
          handleFrom: conveyor.handleFrom,
          handleTo: conveyor.handleTo,
        }))}
      />
      {workshift && (
        <Timeline
          area="Timeline"
          events={timeline.concat(futureData)}
          shift={{ start: parseISO(workshift.startingAt), end: addSeconds(parseISO(workshift.endingAt), 1) }}
        />
      )}
    </Dashboard>
  );
};
