import React from 'react';
import { getSmoothStepPath, EdgeProps } from 'react-flow-renderer';
import styled from 'styled-components';
import { colorOfState, StatesWithColor } from '../../../scripts/colorOfState';

type StyledPathProps = {
  state: StatesWithColor;
  speed: number;
};

const StyledPath = styled.path<StyledPathProps>`
  stroke: ${(props) => colorOfState(props.theme)[props.state]};
  stroke-width: ${(props) => (props.speed === 0 ? '4px' : '1px')};
  -webkit-animation-duration: ${(props) => props.speed}s;
  animation-duration: ${(props) => props.speed}s;
`;

export function DefaultEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd,
}: EdgeProps<{
  status: StatesWithColor;
  speed: number;
}>) {
  const edgePath = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
  return (
    <StyledPath
      state={data?.status ?? 'NOT_MONITORED'}
      speed={data?.speed ?? 0}
      id={id}
      className="react-flow__edge-path"
      d={edgePath}
      markerEnd={markerEnd}
    />
  );
}
