import React, { useEffect, useMemo, useState } from 'react';
import { Title } from '../../components/ui/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import styled from 'styled-components';
import { RegisterList } from '../../components/layout/RegisterList';
import { RegisterTable } from '../../components/ui/RegisterTable';
import {
  useDeleteParameterMutation,
  useLineTitleListQuery,
  useMachinesFromLineQuery,
  useParameterListQuery,
  UserProfile,
} from '../../hooks/graphqlQueries';
import { useFeedback } from '../../context/feedback';
import { Drop } from '../../components/ui/Drop';
import { useTranslation } from 'react-i18next';
import { usePermission } from '../../context/permission';
import { PageOptions } from '../../components/layout/PageOptions';
import { Footer } from '../../components/layout/Footer';
import { asString } from 'ts-runtime-typecheck';
import { getPagination } from '../../scripts/pagination';
import { format, parseISO } from 'date-fns';
import { downloadFile, makeCSVFromTable } from '../../scripts/exportFiles';

type attributeType = {
  name: string;
  attr: string;
};

const attributes: attributeType[] = [
  { name: 'Nome', attr: 'name' },
  { name: 'Código', attr: 'code' },
  { name: 'Máquina', attr: 'machine' },
  { name: 'Limite Inferior', attr: 'inferiorLimit' },
  { name: 'Limite Superior', attr: 'superiorLimit' },
  { name: 'Unidade', attr: 'unit' },
  { name: 'Tipo', attr: 'type' },
];

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-area: Drop;
  column-gap: 15px;
  row-gap: 15px;
  justify-content: right;
`;

const Table = styled.div`
  display: grid;
  grid-area: Table;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 1168px) {
    &&:after {
      font-size: 12px;
      padding-top: 5px;
      content: 'Deslize a tabela para os lados';
    }
  }
`;

type Filter = {
  lineId?: string;
  lineName?: string;
  machineId?: string;
  machineName?: string;
};

type PageParameters = { page: number; limit: number };
const pageParameters = { page: 1, limit: 10 };

export const ParameterListPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const feedback = useFeedback();
  const profile = usePermission();
  const location = useLocation();
  const routeData = location.state as { pagination: { page: number; limit: number } };

  const [parameters, setParameters] = useState<PageParameters>(routeData?.pagination ?? pageParameters);

  const [lineOptions] = useLineTitleListQuery();

  const rescuedFilters = sessionStorage.getItem('GlobalRegistrationFilter');
  const storagedFilters: Filter = rescuedFilters ? JSON.parse(rescuedFilters) : pageParameters;
  const [filters, setFilters] = useState<Filter>(storagedFilters);

  const [machineName] = useMachinesFromLineQuery({
    pause: filters.lineId === undefined,
    requestPolicy: 'network-only',
    variables: { lineId: filters.lineId ?? '' },
  });
  const [isExportData, setIsExportData] = useState(false);
  const dataExportLimit = parseInt(asString(process.env['DATA_EXPORT_LIMIT']));
  const [parametersQuery, refetch] = useParameterListQuery({
    requestPolicy: 'network-only',
    variables: { ...getPagination(parameters.page, parameters.limit, { dataExportLimit, isExportData }), ...filters },
  });

  const [, deleteParameter] = useDeleteParameterMutation();
  const parametersData = useMemo(() => parametersQuery.data?.parameters?.value ?? [], [parametersQuery.data]);

  useEffect(() => {
    sessionStorage.setItem('GlobalRegistrationFilter', JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    refetch();
  }, [parameters]);

  const data = useMemo(
    () =>
      parametersData.map((parameter) => {
        return {
          table: {
            name: parameter.name,
            code: parameter.code,
            machine: parameter.machine?.code ?? '',
            superiorLimit: parameter.superiorLimit?.toString() ?? '',
            inferiorLimit: parameter.inferiorLimit?.toString() ?? '',
            type: t(parameter.type),
            unit: t(parameter.unit),
          },
          value: {
            ...parameter,
            machineId: parameter.machine?.id,
            machineName: parameter.machine?.name,
            lineId: parameter.machine?.line?.id,
            lineCode: parameter.machine?.line?.code,
          },
        };
      }),
    [parametersData]
  );

  const [tableData, setTableData] = useState<typeof data>(data);
  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');

  useEffect(() => {
    if (isExportData) {
      downloadFile(
        `parametro-${thisDate}`,
        makeCSVFromTable(
          attributes,
          data.map((v) => v.table)
        )
      );
      setIsExportData(false);

      if (parametersData.length === dataExportLimit)
        feedback.message({
          animation: 'Error',
          title: 'Limite de dados atingido!',
          subtitle: `Não é possível exportar mais que ${dataExportLimit} linhas de dados.`,
        });
    } else setTableData(data);
  }, [data]);

  return (
    <RegisterList>
      <Title>Parâmetros cadastrados</Title>
      <Filters>
        <Drop
          name="Linha"
          options={
            lineOptions.data?.lines?.value?.map((line) => ({
              id: line.id,
              name: line.code,
            })) ?? []
          }
          onSelect={(line) => {
            setParameters({ ...parameters, page: 1 });
            setFilters({ machineId: undefined, machineName: undefined, lineId: line.id, lineName: line.name });
          }}
          value={filters.lineName}
          errorMessage={
            lineOptions.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined
          }
        />
        <Drop
          name="Máquina"
          options={
            machineName.data?.machines?.value?.map((machine) => ({
              id: machine.id,
              name: machine.code,
            })) ?? []
          }
          onSelect={(machine) => {
            setParameters({ ...parameters, page: 1 });
            setFilters({ ...filters, machineId: machine.id, machineName: machine.name });
          }}
          value={filters.machineName}
          disabled={!filters.lineId}
          errorMessage={
            machineName.data?.machines?.value?.length === 0 ? 'Não existe nenhuma máquina cadastrada.' : undefined
          }
        />
      </Filters>
      <Table>
        <RegisterTable
          attributes={attributes}
          content={tableData}
          onDelete={
            ![UserProfile.Production, UserProfile.Quality].includes(profile.permission as UserProfile)
              ? (parameter) => {
                  feedback.confirmation({
                    title:
                      'Deseja mesmo excluir esse parâmetro? Essa operação é irreversível e irá exluir também todos os dados associados ao parâmetro.',
                    onHandle: (result) => {
                      if (result) {
                        deleteParameter({ id: parameter['id']?.toString() ?? '' })
                          .then(() => {
                            feedback.message({
                              animation: 'Confetti',
                              title: 'Parâmetro deletado com sucesso',
                            });

                            parameters.page === 1 ? refetch() : setParameters({ ...parameters, page: 1 });
                          })
                          .catch(() => {
                            feedback.message({
                              animation: 'Error',
                              title: 'Erro ao deletar parâmetro',
                            });
                          });
                      }
                    },
                  });
                }
              : undefined
          }
          onEdit={
            ![UserProfile.Production].includes(profile.permission as UserProfile)
              ? (parameter) => {
                  navigate('edit', { state: { data: parameter, pagination: { ...parameters } } });
                }
              : undefined
          }
        />
      </Table>
      <Footer>
        <PageOptions>
          <Button color="light" title="Menu cadastros" icon="ArrowLeft" onClick={() => navigate('..')} />
          {![UserProfile.Production, UserProfile.Quality].includes(profile.permission as UserProfile) && (
            <Button
              dataCy="register-button"
              color="primary"
              title="Cadastrar"
              icon="CirclePlus"
              iconSize="small"
              onClick={() => navigate('add', { state: { pagination: { ...parameters } } })}
            />
          )}
          <Button title="Exportar" icon="Export" disabled={data.length == 0} onClick={() => setIsExportData(true)} />
        </PageOptions>
        <Pagination
          numberOfItems={parameters.limit}
          onSelectNumber={(number) => setParameters({ limit: number, page: 1 })}
          page={parameters.page}
          totalPages={Math.ceil((parametersQuery.data?.parameters?.count ?? parameters.limit) / parameters.limit)}
          onPrevious={() => {
            setParameters({ ...parameters, page: parameters.page - 1 });
          }}
          onNext={() => {
            setParameters({ ...parameters, page: parameters.page + 1 });
          }}
        />
      </Footer>
    </RegisterList>
  );
};
