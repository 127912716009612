import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Paper: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.2003 5.82783L11.7837 0.411159C11.627 0.254492 11.4153 0.166992 11.1945 0.166992H2.4445C1.52367 0.166992 0.777832 0.912826 0.777832 1.83366V20.167C0.777832 21.0878 1.52367 21.8337 2.4445 21.8337H15.7778C16.6987 21.8337 17.4445 21.0878 17.4445 20.167V6.41699C17.4445 6.19616 17.357 5.98449 17.2003 5.82783ZM11.6112 16.0003H4.9445C4.4845 16.0003 4.11116 15.627 4.11116 15.167C4.11116 14.707 4.4845 14.3337 4.9445 14.3337H11.6112C12.0712 14.3337 12.4445 14.707 12.4445 15.167C12.4445 15.627 12.0712 16.0003 11.6112 16.0003ZM13.2778 12.667H4.9445C4.4845 12.667 4.11116 12.2937 4.11116 11.8337C4.11116 11.3737 4.4845 11.0003 4.9445 11.0003H13.2778C13.7378 11.0003 14.1112 11.3737 14.1112 11.8337C14.1112 12.2937 13.7378 12.667 13.2778 12.667ZM11.6112 6.83366C11.1512 6.83366 10.7778 6.46033 10.7778 6.00033V1.75366L15.8578 6.83366H11.6112Z"
        fill={color}
      />
    </svg>
  );
};
