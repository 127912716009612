import React from 'react';
import styled from 'styled-components';

const StyledLossRegistration = styled.div`
  display: grid;
  grid-area: 'Content';
  grid-template:
    'Title .' max-content
    'Name Desc' max-content
    'Footer Footer' max-content;
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  width: max-content;
  max-width: 95vw;
  margin: 55px auto;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template:
      'Title' max-content
      'Name' max-content
      'Desc' max-content
      'Footer' max-content;
    max-width: 90vw;
    margin: 55px auto;
    align-items: center;
  }
`;

type Props = {
  children: React.ReactNode;
};
export const LossRegistration: React.FC<Props> = ({ children }) => (
  <StyledLossRegistration>{children}</StyledLossRegistration>
);
