import React, { Fragment, useMemo, useState } from 'react';
import styled from 'styled-components';
import { scaleTime } from 'd3-scale';
import { captureWidth } from '../../../hooks/dynamicWitdh';
import { colorOfState, StatesWithColor } from '../../../scripts/colorOfState';
import { Event } from '../../../scripts/timeline';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';

const Content = styled.div<{ area: string }>`
  grid-area: ${(props) => props.area};
  height: min-content;
  position: relative;
  width: 100%;
  height: 50px;
`;

const Svg = styled.svg<{ height: number }>`
  height: ${(props) => props.height}px;
  overflow: visible;
  width: 100%;
`;

const Line = styled.line`
  stroke: ${(props) => props.theme.colors.timeline.color};
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const Text = styled.text`
  fill: ${(props) => props.theme.colors.timeline.color};
  font-family: ${(props) => props.theme.font};
  user-select: none;
  font-size: 16px;
`;

const Rect = styled.rect<{ state: StatesWithColor }>`
  fill: ${(props) => colorOfState(props.theme)[props.state]};

  &:hover {
    cursor: pointer;
    stroke: white;
    stroke-width: 1px;
    opacity: 1;
  }
`;

const Hover = styled.div<{ state: StatesWithColor; left: number }>`
  position: absolute;
  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #001738;

  display: grid;
  width: 155px;
  left: ${(props) => props.left}px;
  padding: 6px;
  top: -10px;
  background-color: white;
  box-shadow: 0px 2px 20px ${(props) => colorOfState(props.theme)[props.state]};
  border-radius: 5px;
  align-items: center;
  justify-content: space-evenly;
`;

export interface Props {
  area?: string;
  events: Event[];
  legend?: boolean;
  height?: number;
  hoverInfo?: boolean;
  placeholder?: boolean;
  shift: { start: Date; end: Date };
}

export const Timeline: React.FC<Props> = ({
  area = 'initial',
  placeholder = false,
  hoverInfo = true,
  legend = true,
  height = 15,
  shift,
  events,
}: Props) => {
  const [focus, setFocus] = useState<Event | undefined>();
  const { width, ref } = captureWidth<HTMLDivElement>();
  const scale = useMemo(() => scaleTime().domain([shift.start, shift.end]).range([0, width]), [shift, width]);
  const ticks = useMemo(() => (legend ? scale.ticks(8) : []), [scale]);
  const debouncedFocusEvent = useMemo(() => debounce(setFocus, 100), []);

  const offset = legend ? 30 : 0;
  const totalHeight = height + offset;

  return (
    <Content ref={ref} area={area}>
      <Svg height={totalHeight}>
        {ticks.map((tick) => {
          const hours = tick.getHours();
          const position = scale(tick);
          return (
            <Fragment key={hours}>
              <Text x={position - 10} y="15">{`${hours}h`}</Text>
              <Line x1={position} x2={position} y1="20" y2="29" />
            </Fragment>
          );
        })}
        <Line x1="0" x2={width} y1="29" y2="29" />
        {placeholder && <Rect x="0" y={offset} width={width} height={height} state="NOT_MONITORED" />}
        {events.map((event) => {
          const start = scale(new Date(event.start).getTime());
          const end = scale(new Date(event.end).getTime());

          return (
            <Rect
              key={new Date(event.end).getTime()}
              state={event.status}
              x={start}
              y={offset}
              width={end - start}
              height={height}
              onMouseOver={() => debouncedFocusEvent(event)}
              onFocus={() => debouncedFocusEvent(event)}
              onMouseOut={() => debouncedFocusEvent(undefined)}
              onBlur={() => debouncedFocusEvent(undefined)}
            />
          );
        })}
      </Svg>
      {focus && hoverInfo && (
        <Hover state={focus.status} left={Math.min(scale(focus.start.getTime()), width - 155)}>
          {format(focus.start.getTime(), 'HH:mm:ss.S')}
          &nbsp;-&nbsp;
          {format(focus.end, 'HH:mm:ss.S')}
        </Hover>
      )}
    </Content>
  );
};
