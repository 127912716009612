import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Footer } from '../../components/layout/Footer';
import { LineRegistration } from '../../components/layout/LineRegistration';
import { PageOptions } from '../../components/layout/PageOptions';
import { Button } from '../../components/ui/Button';
import { Checkbox } from '../../components/ui/Checkbox';
import { Drop } from '../../components/ui/Drop';
import { Input } from '../../components/ui/Input';
import { PositionGrid } from '../../components/ui/PositionGrid/PositionGrid';
import { TextBox } from '../../components/ui/TextBox';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import { usePermission } from '../../context/permission';
import {
  useFactoriesNameQuery,
  useMachinesQuery,
  UserProfile,
  useUpdateLineMutation,
  useUpdateMachineMutation,
} from '../../hooks/graphqlQueries';

const Info = styled.div`
  display: grid;
  grid-area: Info;
  grid-template-columns: 1fr 1fr;
  row-gap: 19px;
  column-gap: 19px;
  align-items: end;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
`;

const DropContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

  @media (max-width: 500px) {
    row-gap: 20px;
    grid-template-columns: 1fr;
  }
`;

const FormGroup = styled.div`
  display: grid;
  padding: 1em 0.5em 1em 0.5em;
  justify-content: center;
  border: 1px solid palevioletred;
  border-radius: 3px;
  border-radius: 0.4cm;
  border-color: ${(props) => props.theme.colors.drop.gray.border};
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

  @media (max-width: 500px) {
    row-gap: 20px;
    grid-template-columns: 1fr;
  }
`;

const FormGroupText = styled.label`
  display: flex;
  position: absolute;
  height: 20px;
  transform: translateY(-105%);
  background-color: ${(props) => props.theme.colors.body};
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 5px;
  padding-left: 5px;
  color: ${(props) => props.theme.colors.input.color};
`;

export const StyledLabel = styled.label`
  display: inline-block;
  height: 20px;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 5px;
  padding-left: 5px;
  color: ${(props) => props.theme.colors.input.color};
`;

export const CheckDropContainer = styled.div`
  display: grid;
  gap: 5px;
`;

type Line = {
  id: string;
  name: string;
  code: string;
  description: string;
  stopsThreshold: string;
  autoLastMachine: boolean;
  autoBottleneckMachine: boolean;
  newLastMachineAuto?: boolean;
  factory: {
    id: string;
    name: string;
  };
  lastMachine?: {
    id: string;
    name: string;
  };
  newLastMachine?: {
    id: string;
    name: string;
  };
  nominalBottleneck?: {
    id: string;
    name: string;
  };
};

const newLine = {
  id: '',
  name: '',
  code: '',
  description: '',
  stopsThreshold: '',
  factory: {
    id: '',
    name: '',
  },
};

export const LineEditPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const profile = usePermission();
  const feedback = useFeedback();
  const routeData = location.state as { data: Line; pagination: { page: number; limit: number } };
  const [info, setInfo] = useState<Line>((routeData?.data as Line) ?? newLine);
  const [factoryQuery] = useFactoriesNameQuery();
  const [, updateLine] = useUpdateLineMutation();
  const [disableSave, setDisableSave] = useState(true);

  const [machinesQuery] = useMachinesQuery({ variables: { lineId: info.id } });

  const machines = useMemo(() => machinesQuery.data?.machines?.value, [machinesQuery]);
  const [machinePosition, setMachinePosition] = useState<{ id: string; positionX?: number; positionY?: number }[]>([]);
  const [, updateMachine] = useUpdateMachineMutation();

  useEffect(() => {
    for (const key in info) {
      if (key !== 'id' && info[key as keyof Line] === '') {
        setDisableSave(true);
        return;
      }
      if (key === 'factory' && info['factory'].id === '') {
        setDisableSave(true);
        return;
      }
    }
    setDisableSave(false);
  }, [info]);

  const handleSave = () => {
    let stopsThresholdSec = parseFloat(info.stopsThreshold);
    if (isNaN(stopsThresholdSec)) stopsThresholdSec = 0;
    else {
      stopsThresholdSec = Math.round(stopsThresholdSec * 60);
    }

    machinePosition.map((value) => {
      updateMachine({ id: value.id, positionX: value.positionX, positionY: value.positionY });
    });

    updateLine({
      ...info,
      stopsThreshold: stopsThresholdSec,
      lastMachineId: info.autoLastMachine ? null : info.lastMachine?.id,
      newLastMachineId: info.newLastMachineAuto ? null : info.newLastMachine?.id,
      bottleneckMachineId: info.autoBottleneckMachine ? null : info.nominalBottleneck?.id,
    })
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.updateLine === null)
          feedback.message({ animation: 'Error', title: 'Erro ao editar linha' });
        else {
          feedback.message({ animation: 'Confetti', title: 'Linha editada com sucesso' });
          navigate('..', { state: { pagination: routeData?.pagination } });
        }
      })
      .catch(() => {
        feedback.message({ animation: 'Error', title: 'Erro ao editar linha' });
      });
  };

  return (
    <LineRegistration>
      <Title>{info.id ? 'Editar Linha' : 'Cadastrar Linha'}</Title>
      <Info>
        <Input
          label="Nome da Linha"
          value={info.name}
          width="406px"
          onChange={(e) => setInfo({ ...info, name: e.target.value })}
        />
        <Input label="ID da Linha" value={info.id} width="406px" disabled={true} onChange={(e) => e} />
        <Input
          label="Código da Linha"
          value={info.code}
          width="406px"
          onChange={(e) => setInfo({ ...info, code: e.target.value })}
        />
        <Input
          label="Descrição"
          maxLength={100}
          value={info.description}
          width="406px"
          onChange={(e) => setInfo({ ...info, description: e.target.value })}
        />
        <Input
          label="Tempo para notificar paradas (minutos)"
          value={info.stopsThreshold}
          width="406px"
          onChange={(e) => {
            const valueNumbers = e.target.value.replace(/[^\d,.]+/g, '').replace(',', '.');
            setInfo({ ...info, stopsThreshold: valueNumbers });
          }}
        />
        <DropContainer>
          <Drop
            label={info.id && 'Planta'}
            name="Planta"
            value={info.factory.name}
            options={factoryQuery.data?.factories?.value?.map((fact) => ({ id: fact.id, name: fact.name })) ?? []}
            onSelect={(factory) => setInfo({ ...info, factory: { id: factory.id, name: factory.name } })}
          />
          <CheckDropContainer>
            <Checkbox
              label="Máquina Gargalo"
              dataCy="bottleneck-machine-check"
              value={!info.autoBottleneckMachine}
              onChange={(check) => {
                setInfo({ ...info, autoBottleneckMachine: check });
              }}
            />
            <Drop
              dataCy="bottleneck-machine-drop"
              name="Automático"
              value={info.autoBottleneckMachine ? 'Automático' : info.nominalBottleneck?.name}
              options={
                info.autoBottleneckMachine
                  ? [{ id: `${Math.random()}`, name: 'Automático' }]
                  : machinesQuery.data?.machines?.value?.map((machine) => ({ id: machine.id, name: machine.name })) ??
                    []
              }
              onSelect={(machine) => setInfo({ ...info, nominalBottleneck: { id: machine.id, name: machine.name } })}
              disabled={info.autoBottleneckMachine}
            />
          </CheckDropContainer>
        </DropContainer>
        <FormGroup>
          <FormGroupText>Última Máquina</FormGroupText>
          <TextBox
            label={'Atual'}
            size={'medium'}
            value={
              info.autoLastMachine
                ? `Automático (${info.lastMachine?.name ?? '?'})`
                : info.lastMachine?.name ?? 'Sem máquina'
            }
          />
          <CheckDropContainer>
            <Checkbox
              label="Próximo Turno"
              value={!(info.newLastMachineAuto ?? info.autoLastMachine)}
              onChange={(check) => {
                setInfo({ ...info, newLastMachineAuto: check });
              }}
            />
            <Drop
              dataCy="machine-drop"
              name="Automático"
              value={info.newLastMachineAuto ? 'Automático' : info.newLastMachine?.name}
              options={
                info.newLastMachineAuto
                  ? [{ id: `${Math.random()}`, name: 'Automático' }]
                  : machinesQuery.data?.machines?.value?.map((machine) => ({
                      id: machine.id,
                      name: machine.name,
                    })) ?? []
              }
              onSelect={(machine) => setInfo({ ...info, newLastMachine: { id: machine.id, name: machine.name } })}
              disabled={info.newLastMachineAuto}
            />
          </CheckDropContainer>
        </FormGroup>
      </Info>
      <PositionGrid
        listName="Máquinas"
        gridName="Grade de Posições"
        area={{ width: 2850, height: 1875 }}
        positions={
          machines?.map((value) => ({
            id: value.id,
            name: value.name,
            positionX: value.positionX ?? undefined,
            positionY: value.positionY ?? undefined,
          })) ?? []
        }
        grid={{ lines: 15, columns: 15 }}
        onPositioned={(positioned) => setMachinePosition(positioned)}
      />
      <Footer>
        <PageOptions>
          <Button
            dataCy="back-button"
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          {![UserProfile.Production, UserProfile.Quality].includes(profile.permission as UserProfile) ? (
            <Button
              dataCy="save-button"
              title="Salvar"
              color="primary"
              disabled={disableSave}
              onClick={() => handleSave()}
            />
          ) : undefined}
        </PageOptions>
      </Footer>
    </LineRegistration>
  );
};
