import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Chart as ChartPageContainer } from '../components/layout/Chart';
import { Button } from '../components/ui/Button/Button';
import { Title } from '../components/ui/Title';
import { Outlet, useNavigate, useOutletContext, useMatch } from 'react-router-dom';
import { routes } from '../Routes';
import { Drop } from '../components/ui/Drop';
import { useFactoriesNameQuery, useLinesNamesQuery } from '../hooks/graphqlQueries';
import { downloadFile, makeCSV } from '../scripts/exportFiles';
import { format, parseISO } from 'date-fns';
import { PageOptions } from '../components/layout/PageOptions';
import { Footer } from '../components/layout/Footer';
import { StyledErrorMessage } from '../components/ui/Input/Input';
import { ErrorType } from '../scripts/periodFilterValidator';
//import { asString } from 'ts-runtime-typecheck';
//import { useTranslation } from 'react-i18next';
import { FilterValid } from './ChartFilterInterval';

const Filters = styled.div`
  display: grid;
  grid-area: Filters;
  grid-template-columns: repeat(auto-fit, minmax(179px, max-content));
  row-gap: 20px;
  column-gap: 20px;
  align-items: center;
`;

const ChartContainer = styled.div`
  display: grid;
  grid-area: LineChart;
  margin: 50px 0px;
`;

type Charts = Record<
  keyof Omit<typeof routes.charts, 'index'>,
  { name: string; hasPeriod: boolean; hasCount: boolean; hasCheckBox: boolean, mandatoryFields?: string[] }
>;
type ChartName = keyof Charts;
const charts: Charts = {
  oee: { name: 'OEE', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: [] },
  production: { name: 'Produção', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: [] },
  productionRate: { name: 'Taxa de Produção', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: ['factory', 'line', 'period'] },
  stop: { name: 'Parada', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: [] },
  scrap: { name: 'Scrap', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: [] },
  cycleTime: { name: 'Tempo de Ciclo', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: [] },
  periodAnalysis: { name: 'Análise de Período', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: [] },
  parameter: { name: 'Parâmetro', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: [] },
  capacity: { name: 'Capacidade', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: [] },
};

type FiltersType = {
  isLoading: boolean;
  factory?: { id: string; name: string };
  line?: { id: string; name: string };
  period: number;
};

export type ExportData = (string | number)[][];
export type ChartContextType = {
  setExportData: (data: ExportData) => void;
  filters: FiltersType | null;
};

const initialParameters: FiltersType = {
  isLoading: false,
  period: 5,
};

const periodOptions = [
  {
    id: '5',
    name: '5 minutos',
  },
  {
    id: '15',
    name: '15 minutos',
  },
  {
    id: '60',
    name: '1 hora',
  },
];

export const ChartFilterLineRate: React.FC = () => {
  const rescuedFilters = sessionStorage.getItem('ChartFilter');
  //const { t } = useTranslation();
  const storagedFilters: FiltersType = rescuedFilters ? JSON.parse(rescuedFilters) : null;

  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');
  const [isFiltersValid] = useState<FilterValid>({ general: false, period: false });
  //const maxPeriodFilterLimit = parseInt(asString(process.env['PARAMETER_PERIOD_FILTER_LIMIT'] || '30'));
  const [errors] = useState<ErrorType>();

  const [filters, setFilters] = useState<FiltersType>(storagedFilters ?? initialParameters);
  const [exportData, setExportData] = useState<ExportData>();
  const [factoryName] = useFactoriesNameQuery({
    requestPolicy: 'network-only',
  });
  const [lineName] = useLinesNamesQuery({
    pause: filters.factory === undefined,
    requestPolicy: 'network-only',
    variables: { factoryId: filters.factory?.id ?? '' },
  });
  const navigate = useNavigate();
  const match = useMatch('/charts/:chartId');
  /*const title = useMemo(() => {
    const chartId = match?.params.chartId;
    if (chartId && chartId in charts) return charts[chartId as ChartName];
    return 'Gráfico não encontrado';
  }, [match]);*/
  const chart = useMemo(() => {
    const chartId = match?.params.chartId;
    if (chartId && chartId in charts) return charts[chartId as ChartName];
    else return undefined;
  }, [match]);

  const returnMandatoryMark = (fieldMatch:string):string => {
    const fields = chart?.mandatoryFields || [];
    const value = fields.find(key => key == fieldMatch)
    if(value)
      return '*';
    return '';
  }
/*
  useEffect(() => {
    const validation = { general: false, period: false };
    const error_text = t('ERROR.PERIOD_BIGGER_THAN').split('|') || "  ";
    const newPeriod = maxPeriodFilterLimit;
    const mandatoryFields = chart?.mandatoryFields || []

    const result = periodFilterValidator(
      {
        startDate: new Date(filter.startDate || thisDate),
        endDate: new Date(filter.endDate || thisDate),
        maxPeriodLimit: newPeriod
      },
      {
        limitGreaterThenSuperior:  `${error_text[0]} ${newPeriod} ${error_text[1]}`
      }
    )

    setErrors(result);

    if (Object.values(result).length == 0) {
      validation.period = true;
    }

    const validAtributtes:number = countValidAttributes(filter, mandatoryFields)
    if(validAtributtes == mandatoryFields.length){
      validation.general = !validation.period ? false : true;
    }

    setIsFiltersValid(validation);
  }, [filter]);*/

  useEffect(() => {
    setFilters((prev) => ({ ...prev, isLoading: lineName.fetching }));
  }, [lineName]);

  useEffect(() => {
    sessionStorage.setItem('ChartFilter', JSON.stringify({ ...filters, isLoading: false }));
  }, [filters]);

  const title = useMemo(() => chart?.name ?? 'Gráfico não encontrado', [chart]);
  const thisTitle = title
    .normalize('NFD')
    .replace(/[^a-zA-Z\s]/g, '')
    .replace(/ /g, '-')
    .toLowerCase();

  return (
    <ChartPageContainer>
      <Title>{title}</Title>
      <Filters>
        <Drop
          dataCy="factory-drop"
          label={`Unidade ${returnMandatoryMark('factory')}`}
          name="Unidade"
          options={factoryName.data?.factories?.value?.map((factory) => ({ id: factory.id, name: factory.name })) ?? []}
          onSelect={(factory) =>
            setFilters({
              ...filters,
              factory,
              line: undefined,
            })
          }
          value={filters.factory?.name}
          errorMessage={
            factoryName.data?.factories?.value?.length === 0 ? 'Não existe nenhuma fábrica cadastrada.' : undefined
          }
        />
        <Drop
          dataCy="line-drop"
          label={`Linha ${returnMandatoryMark('line')}`}
          name="Linha"
          options={lineName.data?.lines?.value?.map((line) => ({ id: line.id, name: line.code })) ?? []}
          onSelect={(line) => setFilters({ ...filters, line })}
          value={filters.line?.name}
          disabled={!filters.factory}
          errorMessage={lineName.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined}
        />
        <Drop
          dataCy="period-drop"
          label={`Período ${returnMandatoryMark('period')}`}
          name="Período"
          options={periodOptions}
          onSelect={(value) => setFilters({ ...filters, period: parseInt(value.id) })}
          value={filters.period ? `${filters.period.toString()} minutos` : ''}
          errorMessage={periodOptions.length === 0 ? 'Não existe nenhum período cadastrado.' : undefined}
        />
      </Filters>
      {errors?.limitGreaterThenSuperior && (
          <StyledErrorMessage data-cy="field-message" maxWidth='parent'>
            { errors?.limitGreaterThenSuperior}
          </StyledErrorMessage>
      )}
      <ChartContainer>
        <Outlet context={{ filters, setExportData, isFiltersValid }} />
      </ChartContainer>
      <Footer>
        <PageOptions>
          <Button title="Menu gráficos" color="light" icon="ArrowLeft" onClick={() => navigate(routes.charts.index)} />
          <Button
            onClick={() => downloadFile(`grafico-${thisTitle}-${thisDate}`, makeCSV(exportData))}
            title="Exportar"
            disabled={exportData?.length == 0}
            icon="Export"
          />
        </PageOptions>
      </Footer>
    </ChartPageContainer>
  );
};

export function useContext() {
  type ContextType = {
    filters: FiltersType | null;
    setExportData: React.Dispatch<React.SetStateAction<ExportData | undefined>>;
  };
  return useOutletContext<ContextType>();
}
