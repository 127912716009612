import React from 'react';
import styled from 'styled-components';
import Background from '../../../static/background.jpeg';

export const LoginContainer = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-items: center;
  background-color: #012353;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  justify-self: stretch;
  justify-content: center;
  align-content: center;
`;

export const LoginForm = styled.form`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(8, max-content);
  justify-content: center;
  align-items: center;
  row-gap: 10px;
`;

type Props = {
  children: React.ReactNode;
  onSubmit: () => void;
};
export const Login: React.FC<Props> = ({ children, onSubmit }) => {
  return (
    <LoginContainer>
      <LoginForm
        onSubmit={(evt) => {
          evt.preventDefault();
          onSubmit();
        }}
      >
        {children}
      </LoginForm>
    </LoginContainer>
  );
};
