import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  PeriodAnalysisData,
  useMachinesFromLineQuery,
  usePeriodAnalysisChartQuery,
  WorkShift,
} from '../../hooks/graphqlQueries';
import { Event } from '../../scripts/timeline';
import { Timeline } from '../../components/business/Timeline';
import styled from 'styled-components';
import { Icon } from '../../components/ui/Icon';
import { addSeconds, parseISO, addMilliseconds, format } from 'date-fns';
import { toEndShiftC, toStartShiftA } from '../../scripts/times';
import { useOutletContext } from 'react-router-dom';
import { LoaderLogo, MouraLogo } from '../../components/layout/Loading/Loading';
import { Logo } from '../../components/ui/Logo';
import { ChartContextType } from '../ChartFilterLine';

const initialData = {
  metric: {
    capacity: 0,
    production: 0,
    loss: 0,
  },
  fullStatus: {
    stop: 0,
    normal: 0,
    slow: 0,
  },
  timeline: [],
};

const fakeWorkshift = {
  start: parseISO('2022-02-22T03:00:00.000Z'),
  end: parseISO('2022-02-22T11:00:00.000Z'),
};

const Content = styled.div`
  height: 100%;
  width: 100%;
`;

const Chart = styled.div`
  display: grid;
  grid-template:
    'ChartTitle ChartTitle ChartTitle Metrics Metrics' 30px
    'Status ChartTimeline ChartTimeline ChartTimeline ChartTimeline' 80px;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
    grid-template:
      'ChartTitle'
      'Metrics' 45px
      'Status' 150px
      'ChartTimeline';
  }
`;

const Caption = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, max-content));
  align-items: center;
  row-gap: 15px;
  column-gap: 15px;
  justify-content: left;
  margin-bottom: 40px;

  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  color: ${(props) => props.theme.colors.chart.customChart.periodAnalysisChart.legend};

  span {
    padding-left: 10px;
  }
`;

const CaptionItem = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: left;
  min-width: 100px;
`;

const Metrics = styled.div`
  display: grid;
  grid-area: Metrics;
  grid-template: 'CapacityMetric ProductionMetric LossMetric';
  column-gap: 25px;
  justify-content: flex-end;

  @media (max-width: 620px) {
    column-gap: 15px;
    justify-content: space-evenly;
  }
`;

const MetricText = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.chart.customChart.periodAnalysisChart.metric};
`;

const CapacityMetric = styled(MetricText)`
  grid-area: CapacityMetric;
`;

const ProductionMetric = styled(MetricText)`
  grid-area: ProductionMetric;
`;

const LossMetric = styled(MetricText)`
  grid-area: LossMetric;
`;

const WorkShiftTitle = styled.div`
  grid-area: ChartTitle;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  color: ${(props) => props.theme.colors.chart.customChart.periodAnalysisChart.title};
`;

const Title = styled.div`
  grid-area: ChartTitle;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 175%;
  word-break: break-all;
  margin-bottom: 15px;
  color: ${(props) => props.theme.colors.chart.customChart.periodAnalysisChart.title};
`;

const TimelineContainer = styled.div`
  position: relative;
  grid-area: ChartTimeline;
  margin: 10px 30px 10px 10px;
  align-self: right;
`;

const StatusBar = styled.div`
  grid-area: Status;
  display: flex;
  position: relative;
  width: 250px;
  height: 25px;
  justify-content: space-around;
  border: solid 2px ${(props) => props.theme.colors.timeline.color};
  border-radius: 4px;
  cursor: pointer;

  div {
    height: 25px;

    &:nth-child(1n) {
      background-color: #d63624;
    }

    &:nth-child(2n) {
      background-color: #eaa00c;
    }

    &:nth-child(3n) {
      background-color: #1e9852;
    }
  }
`;

const Label = styled.span<{ show: boolean }>`
  position: absolute;
  display: ${(props) => (props.show ? 'grid' : 'none')};
  gap: 2px 8px;
  grid-template-columns: max-content max-content;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #001738;
  padding: 10px;
  top: -100px;
  box-shadow: 0px 2px 20px #788195;
  border-radius: 5px;
  background-color: #ffffff;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
`;

const numberFormat: Intl.NumberFormatOptions = {
  maximumFractionDigits: 2,
  minimumIntegerDigits: 1,
  useGrouping: false,
};

type ExportData = (string | number)[][];

export const PeriodAnalysisChart: React.FC = () => {
  const { filter, setExportData } = useOutletContext<ChartContextType>();
  const [label, setLabel] = useState(false);

  const initialExportData = [
    ['máquina'],
    ['turno'],
    ['capacidade'],
    ['produção'],
    ['perda'],
    ['estado'],
    ['inicio'],
    ['fim'],
  ];

  const translatedEvents = {
    SMALL_STOP: 'Microparada',
    STOP: 'Parada',
    NOT_MONITORED: 'Não monitorado',
    PAUSED: 'Pausado',
    NORMAL: 'Normal',
    STARTUP: 'Iniciado',
    SLOW: 'Lento',
  };

  const [exportData, setThisExportData] = useState<ExportData>(initialExportData);

  const [{ data: result, fetching: resultFetching }] = usePeriodAnalysisChartQuery({
    pause: !filter.shift || !filter.date || !filter.line,
    requestPolicy: 'network-only',
    variables: {
      filter: {
        lineId: filter.line?.id ?? '',
        workShift: filter.shift?.id as unknown as WorkShift,
        startDate: toStartShiftA(new Date(filter?.date ?? Date.now())),
        endDate: toEndShiftC(new Date(filter?.date ?? Date.now())),
      },
    },
  });

  const [{ data: machinesQuery, fetching: machinesFetching }] = useMachinesFromLineQuery({
    pause: filter?.line === undefined,
    requestPolicy: 'network-only',
    variables: { lineId: filter?.line?.id ?? '' },
  });

  const machines = useMemo(
    () => machinesQuery?.machines?.value?.filter((q) => filter?.multipleMachines?.some((f) => q.id === f.id)),
    [machinesQuery, filter]
  );
  const chartData = useMemo(() => result?.periodAnalysisChart, [result]);
  const workshift = useMemo(() => chartData?.workshift, [chartData]);
  const shift = useMemo(() => {
    if (!workshift) return fakeWorkshift;
    return { start: parseISO(workshift.startingAt), end: addSeconds(parseISO(workshift.endingAt), 1) };
  }, [chartData]);

  useEffect(() => {
    exportData.length = 0;
    exportData.push(...initialExportData);

    machines?.map((machine) => {
      const value: PeriodAnalysisData = chartData?.data?.find((e) => e.machineId === machine.id) ?? {
        machineId: machine.id,
        machineCode: machine.code,
        ...initialData,
      };

      value.timeline
        .filter((data) => data.delta > 0)
        .map<Event>((data) => ({
          start: addMilliseconds(parseISO(data.time), -(data.delta * 1000)),
          end: parseISO(data.time),
          status: data.status,
        }))
        /**
         * Join timeline status into one
         */
        .reduce((acc, curr) => {
          if (acc) {
            const last = acc[acc.length - 1];
            if (last?.status === curr.status) {
              last.end = curr.end;
              acc[acc.length - 1] = last;
              return acc;
            } else {
              acc.push(curr);
              return acc;
            }
          } else {
            return [curr];
          }
        }, ([] as Event[]) || undefined)
        .map((event) => {
          exportData[0]?.push(value.machineCode);
          exportData[1]?.push(filter.shift?.name);
          exportData[2]?.push(value.metric.capacity.toLocaleString('en-US', numberFormat));
          exportData[3]?.push(value.metric.production.toLocaleString('en-US', numberFormat));
          exportData[4]?.push(value.metric.loss.toLocaleString('en-US', numberFormat));
          exportData[5]?.push(translatedEvents[event.status]);
          exportData[6]?.push(format(parseISO(event.start.toISOString()), 'dd/MM/yyyy HH:mm:ss'));
          exportData[7]?.push(format(parseISO(event.end.toISOString()), 'dd/MM/yyyy HH:mm:ss'));
        });

      setThisExportData(exportData);
    });
  }, [machines, chartData]);

  useEffect(() => {
    setExportData(exportData);
  }, [exportData]);

  if (resultFetching || machinesFetching)
    return (
      <Container>
        <LoaderLogo data-cy="loading">
          <MouraLogo>
            <Logo style="onlyIcon" />
          </MouraLogo>
        </LoaderLogo>
      </Container>
    );

  return (
    <Content>
      <Caption>
        <CaptionItem>Legenda:</CaptionItem>
        <CaptionItem>
          <Icon name="CircleFilled" size="smaller" color="#D63624"></Icon>
          <span>Parado (minutos)</span>
        </CaptionItem>
        <CaptionItem>
          <Icon name="CircleFilled" size="smaller" color="#EAA00C"></Icon>
          <span>Lento (minutos)</span>
        </CaptionItem>
        <CaptionItem>
          <Icon name="CircleFilled" size="smaller" color="#1E9852"></Icon>
          <span>Rodando (minutos)</span>
        </CaptionItem>
        <CaptionItem>
          <Icon name="CircleFilled" size="smaller" color="#788195"></Icon>
          <span>Sem dados</span>
        </CaptionItem>
      </Caption>
      {machines?.map((machine, index) => {
        const value: PeriodAnalysisData = chartData?.data?.find((e) => e.machineId === machine.id) ?? {
          machineId: machine.id,
          machineCode: machine.code,
          ...initialData,
        };

        let normal = 0;
        let slow = 0;
        let stop = 0;

        const total = value.fullStatus.normal + value.fullStatus.slow + value.fullStatus.stop;
        if (total > 0) {
          normal = (value.fullStatus.normal / total) * 100;
          slow = (value.fullStatus.slow / total) * 100;
          stop = (value.fullStatus.stop / total) * 100;
        }

        const minutesNormal = Math.round(value.fullStatus.normal / 6) / 10;
        const minutesSlow = Math.round(value.fullStatus.slow / 6) / 10;
        const minutesStop = Math.round(value.fullStatus.stop / 6) / 10;

        return (
          <Fragment key={index}>
            <Title>{value.machineCode}</Title>
            <Chart key={value.machineId}>
              <WorkShiftTitle>{filter.shift?.name ?? ''}</WorkShiftTitle>
              <Metrics>
                <CapacityMetric>
                  Capacidade: {value.metric.capacity.toLocaleString('en-US', numberFormat)}
                </CapacityMetric>
                <ProductionMetric>
                  Produção: {value.metric.production.toLocaleString('en-US', numberFormat)}
                </ProductionMetric>
                <LossMetric>Perda: {value.metric.loss.toLocaleString('en-US', numberFormat)}</LossMetric>
              </Metrics>
              <StatusBar onMouseEnter={() => setLabel(true)} onMouseLeave={() => setLabel(false)}>
                <Label show={label}>
                  <Icon name="CircleFilled" size="smaller" color="#D63624"></Icon>
                  <span>Parado ({`${minutesStop} min`})</span>
                  <Icon name="CircleFilled" size="smaller" color="#EAA00C"></Icon>
                  <span>Lento ({`${minutesSlow} min`})</span>
                  <Icon name="CircleFilled" size="smaller" color="#1E9852"></Icon>
                  <span>Rodando ({`${minutesNormal} min`})</span>
                  <Icon name="CircleFilled" size="smaller" color="#788195"></Icon>
                  <span>Sem dados</span>
                </Label>
                <svg width="250" height="25" viewBox="0 0 100 10">
                  {total > 0 && (
                    <>
                      <rect x="0" y="-2" width={normal} height="25" fill="#1E9852" />
                      <rect x={normal} y="-2" width={slow} height="25" fill="#EAA00C" />
                      <rect x={normal + slow} y="-2" width={stop} height="25" fill="#D63624" />
                    </>
                  )}
                  {total === 0 && <rect x="0" y="-2" width="250" height="25" fill="#788195" />}
                </svg>
              </StatusBar>
              <TimelineContainer>
                <Timeline
                  placeholder={true}
                  events={value.timeline
                    .filter((data) => data.delta > 0)
                    .map<Event>((data) => ({
                      start: addMilliseconds(parseISO(data.time), -(data.delta * 1000)),
                      end: parseISO(data.time),
                      status: data.status,
                    }))}
                  shift={shift}
                />
              </TimelineContainer>
            </Chart>
          </Fragment>
        );
      })}
    </Content>
  );
};
