import { MetadataNotification, NotificationType } from '../../../hooks/graphqlQueries';
import { differenceInMinutes } from 'date-fns';

export type NotificationItem = {
  id: string;
  lineId: string;
  title: string;
  originId?: string | null;
  scope: string;
  message: string;
  time: string;
  type: NotificationType;
  metadata: MetadataNotification;
};

export type GroupedNotificationItem = NotificationItem & { count?: number };

export const groupNotification = (visibleNotifications: NotificationItem[]): GroupedNotificationItem[] => {
  const invertedVisibleNotifications = [...visibleNotifications].reverse();
  let serie: { [key: string]: GroupedNotificationItem } = {};
  let diff = 0;
  let initial = 0;
  let array: GroupedNotificationItem[] = [];
  const groupingInterval = 10; // in minutes

  invertedVisibleNotifications.forEach((value, index) => {
    diff = differenceInMinutes(new Date(value.time), new Date(invertedVisibleNotifications[initial]?.time ?? 0));

    if (diff > groupingInterval) {
      // grouping
      invertedVisibleNotifications.slice(initial, index).forEach((value) => {
        const count = (serie[value.type]?.count ?? 0) + 1;
        if (value.type === NotificationType.LineStop) array.push(value);
        else
          serie[value.type] = {
            ...value,
            count,
            message:
              count > 1
                ? `${count == 2 ? serie[value.type]?.scope : serie[value.type]?.message},${value?.scope}`
                : `${value.message}`,
          };
      });
      array = [...array, ...Object.values(serie)];
      initial = index;
      diff = 0;
      serie = {};
    }
  });
  return [...array, ...invertedVisibleNotifications.slice(initial, invertedVisibleNotifications.length)].reverse();
};

type NotificationByScope = { [key: string]: NotificationItem };
type Rule = (self: WhenNotify, newNotifications: NotificationItem[]) => boolean;

export class WhenNotify {
  private notificationByScope: NotificationByScope = {};
  private oldFirstNotificationId: string = localStorage.getItem('oldFirstNotificationId') ?? '';
  private rules: Rule[] = [];

  public getNotificationByScope(): NotificationByScope {
    return this.notificationByScope;
  }

  public getOldFirstNotification(): string {
    return this.oldFirstNotificationId;
  }

  public setOldFirstNotification(notification: NotificationItem): void {
    this.oldFirstNotificationId = notification.id;
    localStorage.setItem('oldFirstNotificationId', notification.id);
  }

  public addRule(callback: (self: WhenNotify, newNotifications: NotificationItem[]) => boolean): WhenNotify {
    this.rules.push(callback);
    return this;
  }

  public evaluate(newNotifications: NotificationItem[]): boolean {
    return this.rules.every((rule: Rule) => rule(this, newNotifications));
  }
}
