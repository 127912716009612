import { useEffect, useState } from "react";

export type VersionCheckOptions = {
    deploymentUrl: string;
    scriptId: string;
    cicleTime: number
  };

export const UseVersionCheck = ({deploymentUrl, scriptId, cicleTime}:VersionCheckOptions) => {
    const [isNewVersionAvailable, setIsNewVersionAvailable] = useState(false);
    const SCRIPT_REJEX_MAIN = new RegExp(`.*<script id="${scriptId}".*/(app.*.js).*$`, "gim");
    useEffect(() => {
        const compareVersions = async () => {
            try {
                // request the index.html file from the deployment
                const fetchedPage = await fetch(deploymentUrl, { method: 'get', mode: 'cors' });
                // get the text from the response
                const loadedText = await fetchedPage.text();

                // get the main.js file to get hash
                const matchResponses = await SCRIPT_REJEX_MAIN.exec(loadedText) ?? [];
                const remoteMainScript = matchResponses.length > 0 ? matchResponses[1] : undefined;
                if (remoteMainScript === undefined) {
                    console.log("Could not find main script in app.html");
                    setIsNewVersionAvailable(false);
                    return;
                }
                
                // get the current version hash from current deployment
                let currentMainScript = undefined;
                
                // get text representation of document
                const scriptTag:HTMLScriptElement = document.getElementById(scriptId) as HTMLScriptElement;

                if(scriptTag){
                    const regexArray = /^.*\/(app.*\.js).*$/gim.exec(scriptTag?.src ?? '');
                    if(regexArray){
                        currentMainScript = regexArray[1];
                    }
                }

                // if the current main script or the remote main script is undefined, we can't compare
                // but if they are there, compare them
                setIsNewVersionAvailable(
                    !!currentMainScript && !!remoteMainScript && currentMainScript !== remoteMainScript
                );
                //console.log("Current main script: ", currentMainScript);
                //console.log("Remote main script: ", remoteMainScript);                
            } catch (error) {
                setIsNewVersionAvailable(false);
            }
        }
        
        // compare versions every x seconds
        const createdInterval = setInterval(compareVersions, cicleTime);
        return () => {
            // clear the interval when the component unmounts
            clearInterval(createdInterval)
        };
    }, [deploymentUrl]);
    
    // return the state
    return { isNewVersionAvailable };
}