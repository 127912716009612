import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../components/ui/Button';
import { Input } from '../../components/ui/Input';
import { LineRegistration } from '../../components/layout/LineRegistration';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import { useCreateFactoryMutation } from '../../hooks/graphqlQueries';
import { Drop } from '../../components/ui/Drop';
import timezones from '../../timezones.json';
import { PageOptions } from '../../components/layout/PageOptions';
import { Footer } from '../../components/layout/Footer';

const Info = styled.div`
  display: grid;
  grid-area: Info;
  grid-template-columns: 1fr 1fr;
  row-gap: 19px;
  column-gap: 19px;
  align-items: end;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
`;

type Factory = {
  name: string;
  code: string;
  timezone: string;
};

const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const newFactory = {
  name: '',
  code: '',
  timezone: localTimezone,
};

export const FactoryRegistrationPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const feedback = useFeedback();
  const routeData = location.state as { data: Factory; pagination: { page: number; limit: number } };

  const [, registerFactory] = useCreateFactoryMutation();

  const [info, setInfo] = useState<Factory>(newFactory);
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    for (const key in info) {
      if (key !== 'name' && info[key as keyof Factory] === '') {
        setDisableSave(true);
        return;
      }
    }
    setDisableSave(false);
  }, [info]);

  const handleSave = () => {
    const dialog = () => {
      feedback.confirmation({
        title: 'Deseja cadastrar outra fábrica?',
        onHandle: (result) => {
          result ? setInfo(newFactory as Factory) : navigate('..', { state: { pagination: routeData?.pagination } });
        },
      });
    };

    registerFactory({
      ...info,
      name: info.name,
      code: info.code,
      timezone: info.timezone,
    })
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.createFactory === null)
          feedback.message({ animation: 'Error', title: 'Erro ao cadastrar fábrica' });
        else
          feedback.message({ animation: 'Confetti', title: 'Fábrica cadastrada com sucesso', onClose: () => dialog() });
      })
      .catch(() => {
        feedback.message({ animation: 'Error', title: 'Erro ao cadastrar fábrica' });
      });
  };

  return (
    <LineRegistration>
      <Title>Cadastrar Fábrica</Title>
      <Info>
        <Input
          label="Nome da Fábrica"
          value={info.name}
          width="376px"
          onChange={(e) => setInfo({ ...info, name: e.target.value })}
        />
        <Input
          label="Código da Fábrica"
          value={info.code}
          width="376px"
          onChange={(e) => setInfo({ ...info, code: e.target.value })}
        />
        <Drop
          label="Fuso Horário"
          size="large"
          name={''}
          required
          options={timezones.name.map((value, index) => {
            return { id: index.toString(), name: value };
          })}
          onSelect={(selected) => {
            setInfo({ ...info, timezone: selected.name });
          }}
          value={info.timezone}
        />
      </Info>
      <Footer>
        <PageOptions>
          <Button
            dataCy="back-button"
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button
            dataCy="save-button"
            title="Salvar"
            color="primary"
            disabled={disableSave}
            onClick={() => handleSave()}
          />
        </PageOptions>
      </Footer>
    </LineRegistration>
  );
};
