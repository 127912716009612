import React from 'react';
import { sizes, VectorProps } from './Icon';

export const CycleTimeColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.513.261h1.85c.095.259.328.163.504.189a22.66 22.66 0 016.27 1.855c7.734 3.57 12.891 11.931 12.434 20.168-1.828 2.068-3.847 3.952-5.788 5.905-.496.5-1.17.55-1.673.048-1.972-1.965-4.011-3.866-5.848-5.966-.038-.335-.1-.669-.113-1-.052-1.541-.584-2.913-1.436-4.169-1.51-2.23-3.68-3.301-6.283-3.6C24.388 12 26.1 10.052 27.95 8.25c.97-.95.96-1.53.011-2.491-1.813-1.84-3.63-3.672-5.45-5.498z"
        fill="#EAA00C"
      />
      <path
        d="M22.512.261l5.45 5.491c.95.962.958 1.542-.011 2.492-1.85 1.809-3.563 3.756-5.522 5.444-1.877.05-3.646.457-5.199 1.595-2.123 1.554-3.183 3.702-3.483 6.28-1.833-2.104-3.869-4.01-5.85-5.968-.553-.546-1.212-.423-1.861.224C4.157 17.707 2.286 19.608.41 21.5v-2.036c.328-.265.225-.67.29-1C2.476 9.517 7.71 3.725 16.339 1.04c1.076-.334 2.198-.52 3.3-.775l2.874-.005z"
        fill="#FFBF31"
      />
      <path
        d="M43.572 22.473c-.16 6.23-2.533 11.473-7.094 15.652-4.206 3.855-9.209 5.715-14.911 5.61-1.994-1.75-3.762-3.726-5.649-5.582-.814-.802-.801-1.44 0-2.25 1.644-1.667 3.3-3.32 4.942-4.988.327-.331.674-.592 1.15-.64 2.132 0 3.978-.737 5.555-2.176 1.654-1.511 2.436-3.449 2.699-5.638 1.837 2.1 3.876 4 5.848 5.967.504.5 1.174.45 1.673-.05 1.945-1.953 3.954-3.837 5.787-5.905z"
        fill="#0033AB"
      />
      <path
        d="M15.924 38.153c-.816-.802-.802-1.44 0-2.25 1.643-1.667 3.3-3.32 4.942-4.988.326-.331.673-.592 1.15-.64-2.21-.04-4.105-.867-5.749-2.326-1.728-1.773-2.51-3.924-2.513-6.385-1.833-2.104-3.868-4.009-5.849-5.968-.554-.546-1.213-.422-1.862.224C4.16 17.707 2.282 19.6.41 21.5v3.059c.276.125.176.387.209.591.76 4.766 2.778 8.888 6.196 12.294.041-.268.222-.438.4-.609-.178.171-.359.341-.398.609.614.743 1.365 1.335 2.13 1.905 3.742 2.805 7.916 4.375 12.619 4.384-1.994-1.747-3.762-3.724-5.642-5.58z"
        fill="#006EC7"
      />
    </svg>
  );
};
