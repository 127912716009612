import { differenceInSeconds, format, parseISO } from 'date-fns';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Stops } from '../components/layout/Stops/Stops';
import { Drop } from '../components/ui/Drop';
import { MultiDropdown } from '../components/ui/MultiDropdown';
import { Pagination } from '../components/ui/Pagination';
import { RegisterTableStop } from '../components/ui/RegisterTableStop';
import { AttributeType } from '../components/ui/RegisterTableStop/RegisterTableStop';
import { Title } from '../components/ui/Title/Title';
import {
  JustificationState,
  useJustifiableStopsQuery,
  useLineTitleListQuery,
  WorkShift,
} from '../hooks/graphqlQueries';
import { formatDurationInTable } from '../scripts/calendar';

type WORKSHIFTS = 'SHIFT_A' | 'SHIFT_B' | 'SHIFT_C' | 'NO_SHIFT';

const shiftTimes = [
  { id: WorkShift.ShiftA, name: t(WorkShift.ShiftA) },
  { id: WorkShift.ShiftB, name: t(WorkShift.ShiftB) },
  { id: WorkShift.ShiftC, name: t(WorkShift.ShiftC) },
];

const Table = styled.div`
  display: grid;
  grid-area: Table;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 1168px) {
    &&:after {
      font-size: 12px;
      padding-top: 5px;
      content: 'Deslize a tabela para os lados';
    }
  }
`;

const Filters = styled.div`
  display: grid;
  grid-area: Filters;
  grid-template-columns: repeat(auto-fit, minmax(179px, max-content));
  column-gap: 20px;
  row-gap: 15px;
  justify-content: right;
`;

const attributes: Array<AttributeType> = [
  { name: 'Máquina', attr: 'machineCode', dataCy: 'table-col-machine' },
  { name: 'Data', attr: 'date', dataCy: 'table-col-date' },
  { name: 'Início', attr: 'init', dataCy: 'table-col-start-time' },
  { name: 'Fim', attr: 'ended', dataCy: 'table-col-end-time' },
  { name: 'Duração', attr: 'duration', dataCy: 'table-col-duration' },
  { name: 'Turno', attr: 'workShift', dataCy: 'table-col-shift' },
];

type FiltersType = {
  line?: string;
  shift: WorkShift[];
};

type Parameters = {
  page: number;
  filters: FiltersType;
  description: string;
  name: string;
};

const initialParameters: Parameters = {
  page: 1,
  filters: {
    line: '',
    shift: [],
  },
  description: '',
  name: '',
};

export const StopsPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [parameters, setParameters] = useState(initialParameters);

  const [lineOptions] = useLineTitleListQuery();

  const [justifiableStops, refetch] = useJustifiableStopsQuery({
    requestPolicy: 'network-only',
    variables: {
      offset: (parameters.page - 1) * 5,
      limit: 5,
      lineId: parameters.filters.line,
      states: [JustificationState.Pending],
      workShift: parameters.filters.shift,
    },
  });

  const justStop = useMemo(() => justifiableStops.data?.justifiableStops?.value ?? [], [justifiableStops.data]);
  const count = useMemo(() => justifiableStops.data?.justifiableStops?.count ?? 0, [justifiableStops.data]);

  useEffect(() => {
    refetch();
  }, [parameters.filters]);

  return (
    <Stops>
      <Title data-cy="stops">Paradas a justificar</Title>
      <Filters>
        <Drop
          name="Linha"
          options={
            lineOptions.data?.lines?.value?.map((line) => ({
              id: line.id,
              name: line.code,
            })) ?? []
          }
          onSelect={(line) => {
            setParameters({ ...parameters, filters: { ...parameters.filters, line: line.id } });
          }}
          value={parameters.filters.line}
          errorMessage={
            lineOptions.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined
          }
        />
        <MultiDropdown
          name="Turno"
          value={parameters.filters.shift ?? []}
          options={shiftTimes}
          onBlur={(shifts) => {
            setParameters({
              ...parameters,
              page: 1,
              filters: { ...parameters.filters, shift: shifts.map((item) => item.id as WorkShift) },
            });
          }}
          errorMessage={shiftTimes.length === 0 ? 'Não existe nenhum turno cadastrado.' : undefined}
        />
      </Filters>

      <Table>
        <RegisterTableStop
          attributes={attributes}
          content={justStop.map((stop) => {
            let ended = 'Em andamento';
            let duration = 'Parada em andamento';
            const init = format(parseISO(stop.startedAt), 'HH:mm:ss').toString();
            if (stop.endedAt) {
              ended = format(parseISO(stop.endedAt), 'HH:mm:ss').toString();
              const durationNumber = differenceInSeconds(
                parseISO(stop.endedAt).getTime(),
                parseISO(stop.startedAt).getTime()
              );
              duration = formatDurationInTable(durationNumber);
            }

            const date = format(parseISO(stop.startedAt), 'yyyy-MM-dd').toString();
            const workShift = (stop.workShift?.toString() ?? 'NO_SHIFT') as WORKSHIFTS;

            return {
              id: stop.id,
              lineCode: stop.line?.code ?? '',
              lineId: stop.line?.id ?? '',
              factory: stop.line?.factory?.id ?? '',
              machine: stop.justification?.machine ?? '',
              machineCode: stop?.machine?.code ?? '',
              stopTag: stop.justification?.stop ?? '',
              reasonTag: stop.justification?.reason ?? '',
              lossTag: stop.justification?.loss ?? '',
              user: stop.justification?.user ?? '',
              description: stop.justification?.description ?? '',
              affectAvailability: stop.affectAvailability ?? true,
              startedAt: stop.startedAt,
              endedAt: stop.endedAt ?? '',
              workShift: t(workShift),
              date,
              init,
              ended,
              duration,
            };
          })}
          onRegister={(justStop) => {
            navigate('justification', { state: justStop });
          }}
        />
      </Table>
      <Pagination
        page={parameters.page}
        totalPages={Math.ceil(count / 5)}
        onPrevious={() => setParameters({ ...parameters, page: parameters.page - 1 })}
        onNext={() => setParameters({ ...parameters, page: parameters.page + 1 })}
      />
    </Stops>
  );
};
