import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Icon } from '../Icon';
import { Drop } from '../Drop';

const PaginationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-area: Pagination;
  flex: 1;
  column-gap: 10px;
  row-gap: 15px;
  justify-content: right;
  align-content: flex-start;

  @media (max-width: 430px) {
    justify-content: center;
  }
`;

const ItemsPerPage = styled.div`
  display: grid;
  grid-area: ItemsPerPage;
  align-items: center;
  grid-template-columns: repeat(2, max-content);
  justify-content: left;
  column-gap: 5px;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 660px) {
    justify-content: center;
    align-items: center;
  }
`;

const PageCount = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-area: PageCount;
  position: relative;
`;

const ContainerButtons = styled.div`
  display: flex;
  column-gap: 7px;
  position: relative;
`;

const CurrentPage = styled.div`
  display: flex;
  width: min-content;
  height: 45px;
  justify-content: center;
  align-content: center;
  padding: 0px 18px 0px 18px;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 45px;
  color: ${(props) => props.theme.colors.pageCount.color};

  border: 1px solid ${(props) => props.theme.colors.pageCount.color};
  box-sizing: border-box;
  border-radius: 5px;
`;

const TotalPages = styled.div`
  display: flex;
  width: max-content;
  height: 45px;
  justify-content: center;
  align-content: center;
  margin-left: 10px;
  margin-right: 10px;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 45px;
  color: ${(props) => props.theme.colors.pageCount.color};
`;

const PageButton = styled.div<{ label: string; visible: boolean }>`
  width: 45px;
  height: 45px;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  position: relative;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;

  background: ${(props) => props.theme.colors.pageCount.background};
  border-radius: 5px;

  &&:hover {
    box-shadow: 0px 2px 15px 0px rgba(0, 110, 199, 0.6);
    &&::after {
      position: absolute;
      font-family: ${(props) => props.theme.font};
      font-size: 14px;
      font-style: normal;
      font-weight: 500;

      width: max-content;
      left: -58px;
      top: -35px;
      padding: 6px;

      color: rgba(0, 110, 199, 1);
      box-shadow: 0px 2px 15px rgba(0, 110, 199, 0.6);
      border-radius: 5px;
      background-color: #ffffff;
      content: '${(props) => props.label}';
    }
  }
`;

const itemsPerPage = [
  { id: '5', name: '5' },
  { id: '10', name: '10' },
  { id: '15', name: '15' },
  { id: '30', name: '30' },
  { id: '50', name: '50' },
  { id: '100', name: '100' },
];

type Props = {
  numberOfItems?: number;
  page: number;
  totalPages: number;
  onPrevious?: () => void;
  onNext?: () => void;
  onSelectNumber?: (number: number) => void;
};

export const Pagination: React.FC<Props> = ({
  numberOfItems,
  page,
  totalPages,
  onPrevious,
  onNext,
  onSelectNumber,
}) => {
  const theme = useTheme();

  return (
    <PaginationContainer>
      {numberOfItems && onSelectNumber && (
        <ItemsPerPage>
          <span>Mostrar:</span>
          <Drop
            size="smaller"
            direction="up"
            name=""
            required
            value={numberOfItems.toString()}
            options={itemsPerPage}
            onSelect={(number) => onSelectNumber(parseInt(number.id))}
          />
        </ItemsPerPage>
      )}
      <PageCount>
        <CurrentPage>{page}</CurrentPage>
        {totalPages <= 1 && <TotalPages>de {page}</TotalPages>}
        {totalPages > 1 && <TotalPages>de {totalPages}</TotalPages>}
        <ContainerButtons>
          <PageButton label="Voltar página" onClick={onPrevious} visible={page > 1}>
            <Icon name="ArrowLeft" size="smaller" color={theme.colors.pageCount.icon.color} />
          </PageButton>
          <PageButton label="Passar página" onClick={onNext} visible={page < totalPages}>
            <Icon name="ArrowRight" size="smaller" color={theme.colors.pageCount.icon.color} />
          </PageButton>
        </ContainerButtons>
      </PageCount>
    </PaginationContainer>
  );
};
