import React from 'react';
import styled from 'styled-components';

const StyledOverview = styled.div`
  display: grid;
  grid-area: 'Content';
  grid-template:
    'Title .' max-content
    '. Unit' max-content
    'Table Table' max-content
    'Caption .' max-content
    'Footer Footer ' max-content;
  grid-row-gap: 20px;
  width: max-content;
  max-width: 95vw;
  margin: 55px auto;

  @media (max-width: 660px) {
    grid-template-columns: 1fr;
    grid-template:
      'Title'
      'Unit'
      'Table'
      'Caption'
      'Pagination'
      'Footer';
    max-width: 90vw;
    margin: 55px auto;
    align-items: center;
  }
`;

type Props = {
  children: React.ReactNode;
};
export const Overview: React.FC<Props> = ({ children }) => <StyledOverview>{children}</StyledOverview>;
