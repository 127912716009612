import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Bars: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.252 18.625h4.594V.25H7.252v18.375Zm-6.89 0h4.593V9.437H.361v9.188Zm13.78-12.633v12.633h4.594V5.992h-4.593Z"
        fill={color}
      />
    </svg>
  );
};
