import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
  display: flex;
  grid-area: Footer;
  width: 100%;
  flex-wrap: wrap-reverse;
  row-gap: 30px;
  column-gap: 20px;
  justify-content: space-between;
 
`;

type Props = {
  children: React.ReactNode;
};
export const Footer: React.FC<Props> = ({ children }) => <StyledFooter>{children}</StyledFooter>;
