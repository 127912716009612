export type LimitsType = {
  startDate: Date;
  endDate: Date;
  maxPeriodLimit: number;
};

export type ErrorType = {
  limitGreaterThenSuperior: string;
};

export const periodFilterValidator = ({ startDate, endDate, maxPeriodLimit }: LimitsType, errorMessages: ErrorType) => {

  const daysInMilliseconds = 1000 * 60 * 60 * 24;
  const timeDiffInDays = (endDate.getTime() - startDate.getTime()) / daysInMilliseconds;
  const errors = <ErrorType>{};
  
  let isPeriodValid = false;
  
  if (timeDiffInDays <= maxPeriodLimit) {
    isPeriodValid = true;
  }

  if (!isPeriodValid) {
    errors.limitGreaterThenSuperior = errorMessages.limitGreaterThenSuperior;
  }

  return errors;
};
