import React, { useEffect, useState, useMemo } from 'react';
import { Chart } from '../../components/ui/Chart';
import { useTheme } from 'styled-components';
import { CycleTimeResponse, useCycleTimeQuery, WorkShift } from '../../hooks/graphqlQueries';
import { useOutletContext } from 'react-router-dom';
import { ChartContextType } from '../ChartFilterInterval';
type ChartType = 'LINE' | 'BAR' | 'STACKBAR';

type BarTemplate = { x: Array<string>; y: Array<number>; hover: Array<string> };
function newBar() {
  return { x: new Array<string>(), y: new Array<number>(), hover: new Array<string>() } as BarTemplate;
}

function addPoint(bar: BarTemplate, x: string | undefined | null, y: number, hover: string) {
  if (!x) return;
  bar.x.push(x);
  bar.y.push(y);
  bar.hover.push(`<b>${hover}</b><br><b>Quantidade</b>: ${y}<br><b>Tempo de ciclo</b>: ${x}<br>`);
}

const mountBarChart = (
  color: { cycleTimeColor: string },
  stateData: CycleTimeResponse[],
  filterBy: OptionsFilterByType,
  machine?: string
) => {
  if (filterBy.id === 'count') stateData?.sort((a, b) => a.count - b.count);
  else stateData?.sort((a, b) => a.delta - b.delta);

  const cycleTime: BarTemplate = newBar();

  if (stateData ?? 0 > 0) {
    for (const state of stateData) {
      addPoint(cycleTime, state.delta.toString(), state.count ?? 0, machine ?? '');
    }
  }

  const chartComposition = [
    {
      x: cycleTime.x,
      y: cycleTime.y,
      textposition: 'auto',
      name: 'Tempo de Ciclo',
      hoverData: cycleTime.hover,
      hoverLabel: {
        bgcolor: '#fff',
        bordercolor: '',
        font: {
          color: '',
        },
      },
      chartType: 'BAR' as ChartType,
      marker: { color: color.cycleTimeColor },
      color: color.cycleTimeColor,
    },
  ];

  return {
    exportData: [
      ['delta', ...cycleTime.x],
      ['count', ...cycleTime.y],
    ],
    chartData: chartComposition,
  };
};

type OptionsFilterByType = { id: string; name: string };

const options: OptionsFilterByType[] = [
  {
    id: 'count',
    name: 'Quantidade',
  },
  {
    id: 'time',
    name: 'Tempo',
  },
];

export const CycleTimeChart: React.FC = () => {
  const { filter, setExportData, isFiltersValid } = useOutletContext<ChartContextType>();
  const theme = useTheme();

  const [filterBy, setFilterBy] = useState<OptionsFilterByType>(options[0] as OptionsFilterByType);
  const cycleTimeChartColor = useMemo(() => {
    return {
      cycleTimeColor: theme.colors.chart.customChart.cycleTimeChart.cycleTime,
    };
  }, [theme]);

  const [cycleTimeQuery] = useCycleTimeQuery({
    //pause: filter?.machine === undefined || filter?.line === undefined,
    pause: !isFiltersValid.general,
    requestPolicy: 'network-only',
    variables: {
      filter: {
        lineId: filter?.line?.id,
        machineId: filter?.machine?.id,
        workShift: (filter?.multipleShifts?.map((s) => s.id) as WorkShift[]) ?? [],
        startDate: filter?.startDate,
        endDate: filter?.endDate,
        minCount: Number(filter?.minCount?.name),
        maxCount: Number(filter?.maxCount?.name),
      },
    },
  });
  const cycleTimeData = useMemo(() => cycleTimeQuery.data?.cycleTime, [cycleTimeQuery]);
  const data = useMemo(() => {
    const machine = filter?.machine?.name?.split('.');
    const machineName = machine?.at(-1);
    return mountBarChart(cycleTimeChartColor, cycleTimeData as CycleTimeResponse[], filterBy, machineName);
  }, [cycleTimeData, cycleTimeChartColor, filter, filterBy]);

  useEffect(() => {
    setExportData(data.exportData);
  }, [data]);

  return (
    <Chart
      chartType={'BAR'}
      data={data.chartData}
      optionFilterByInitial={options[0]}
      optionsFilterBy={options}
      selectFilterBy={(filterBy) => setFilterBy(filterBy)}
      xaxis={{ type: 'category' }}
      loading={cycleTimeQuery.fetching || filter?.loading === true}
    />
  );
};
