import { format, parseISO } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/ui/Button';
import { Calendar } from '../../components/ui/Calendar';
import { Drop } from '../../components/ui/Drop';
import { AttributeType, HistoryTable } from '../../components/ui/HistoryTable';
import { MultiDropdown } from '../../components/ui/MultiDropdown';
import { Pagination } from '../../components/ui/Pagination';
import { Title } from '../../components/ui/Title';
import { History } from '../../components/layout/History';
import { useNavigate } from 'react-router-dom';
import {
  useLinesFromFactoriesQuery,
  useMachinesFromLineQuery,
  useScrapFactoriesNameQuery,
  useScrapsQuery,
  WorkShift,
} from '../../hooks/graphqlQueries';
import { useTranslation } from 'react-i18next';
import { downloadFile, makeCSVFromTable } from '../../scripts/exportFiles';
import { toEndShiftC, toStartShiftA } from '../../scripts/times';
import { Icon } from '../../components/ui/Icon';
import { ClearFilter } from '../../components/layout/History/History';
import i18n from '../../i18n';
import { LoaderLogo, MouraLogo } from '../../components/layout/Loading/Loading';
import { Logo } from '../../components/ui/Logo';
import { useFeedback } from '../../context/feedback';
import { asString } from 'ts-runtime-typecheck';
import { getPagination } from '../../scripts/pagination';
import { Footer } from '../../components/layout/Footer';
import { PageOptions } from '../../components/layout/PageOptions';
import { parsePageFilters } from '../../scripts/parses';
import { isEnabledAction, countValidAttributes } from '../../scripts/objectHelper';
import { StyledErrorMessage } from '../../components/ui/Input/Input';
import { periodFilterValidator, ErrorType } from '../../scripts/periodFilterValidator';

const attributes: Array<AttributeType> = [
  { name: 'Linha', attr: 'line', dataCy: 'table-col-line' },
  { name: 'Máquina', attr: 'machine', dataCy: 'table-col-machine' },
  { name: 'Item', attr: 'item', dataCy: 'table-col-item' },
  { name: 'Falha', attr: 'defect', dataCy: 'table-col-defect' },
  { name: 'Turno', attr: 'workShift', dataCy: 'table-col-work-shift' },
  { name: 'Quantidade', attr: 'amount', dataCy: 'table-col-amount' },
  { name: 'Responsável', attr: 'user', dataCy: 'table-col-user' },
  { name: 'Grupo Detector', attr: 'detectorGroup', dataCy: 'table-col-group' },
  { name: 'Grupo Gerador', attr: 'generatorGroup', dataCy: 'table-col-generator-sector' },
  { name: 'Descrição', attr: 'description', dataCy: 'table-col-description' },
  { name: 'Data', attr: 'date', dataCy: 'table-col-date' },
];

const shiftTimes = [
  { id: WorkShift.ShiftA, name: 'Turno A' },
  { id: WorkShift.ShiftB, name: 'Turno B' },
  { id: WorkShift.ShiftC, name: 'Turno C' },
];

const Filters = styled.div`
  display: grid;
  grid-area: Filters;
  grid-template-columns: repeat(auto-fit, minmax(179px, max-content));
  column-gap: 20px;
  row-gap: 10px;
  width: 1fr;
  justify-content: right;

  @media (max-width: 660px) {
    justify-content: center;
  }
`;

const Table = styled.div`
  display: grid;
  grid-area: Table;
  justify-content: space-around;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 1168px) {
    &&:after {
      font-size: 12px;
      padding-top: 5px;
      content: 'Deslize a tabela para os lados';
    }
  }
`;

const fmt: Intl.NumberFormatOptions = {
  maximumFractionDigits: 2,
  minimumIntegerDigits: 1,
  useGrouping: false,
};

type PageFilters = {
  page: number;
  limit: number;
  filters: {
    factory?: { id: string; name: string };
    line?: { id: string; name: string };
    machine?: { id: string; name: string };
    workShift: WorkShift[];
    startDate: Date;
    endDate: Date;
  };
};

const initialParameters: PageFilters = {
  page: 1,
  limit: 10,
  filters: {
    factory: undefined,
    line: undefined,
    machine: undefined,
    workShift: [],
    startDate: toStartShiftA(new Date()),
    endDate: toEndShiftC(new Date()),
  },
};

export const ScrapHistoryPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const feedback = useFeedback();

  const rescuedFilters = sessionStorage.getItem('GlobalHistoryFilter');
  const storagedFilters: PageFilters = parsePageFilters(rescuedFilters, initialParameters) as PageFilters;
  const [parameters, setParameters] = useState(
    storagedFilters
      ? {
          ...storagedFilters,
          limit: 10,
          page: 1,
          filters: {
            ...storagedFilters.filters,
            startDate: new Date(storagedFilters.filters.startDate),
            endDate: new Date(storagedFilters.filters.endDate),
          },
        }
      : initialParameters
  );

  const [factoryName] = useScrapFactoriesNameQuery({ requestPolicy: 'network-only' });
  const [lineName] = useLinesFromFactoriesQuery();
  const [machineName] = useMachinesFromLineQuery({
    pause: parameters.filters.line?.id === undefined,
    requestPolicy: 'network-only',
    variables: { lineId: parameters.filters.line?.id ?? '' },
  });

  const [isExportData, setIsExportData] = useState(false);
  const dataExportLimit = parseInt(asString(process.env['DATA_EXPORT_LIMIT']));
  const [isFiltersValid, setIsFiltersValid] = useState({ general: false, period: false });
  const maxPeriodFilterLimit = parseInt(asString(process.env['SCRAP_PERIOD_FILTER_LIMIT'] || '30'));
  const periodStartFilterLimit = parseInt(asString(process.env['SCRAP_PERIOD_FILTER_START_LIMIT'] || '7'));
  const [errors, setErrors] = useState<ErrorType>();

  const [scrapsQuery] = useScrapsQuery({
    pause: !isFiltersValid.general,
    variables: {
      ...getPagination(parameters.page, parameters.limit, { dataExportLimit, isExportData }),
      ...parameters.filters,
      lineId: parameters.filters.line?.id,
      factoryId: parameters.filters.factory?.id,
      machineId: parameters.filters.machine?.id,
    },
    requestPolicy: 'network-only',
  });
  const scraps = useMemo(() => scrapsQuery.data?.scraps?.value ?? [], [scrapsQuery.data]);
  const count = useMemo(() => scrapsQuery.data?.scraps?.count ?? 0, [scrapsQuery.data]);

  useEffect(() => {
    //refetch();
    const countFilter = countValidAttributes(parameters.filters, ['factory', 'line', 'machine']);
    const calculatedPeriod = countFilter * periodStartFilterLimit;
    const newPeriod = calculatedPeriod < maxPeriodFilterLimit ? calculatedPeriod : maxPeriodFilterLimit;
    const validation = { general: false, period: false };
    const error_text = t('ERROR.PERIOD_BIGGER_THAN').split('|') || " | ";

    const result = periodFilterValidator(
      {
        startDate: parameters.filters.startDate,
        endDate: parameters.filters.endDate,
        maxPeriodLimit: newPeriod
      },
      {
        limitGreaterThenSuperior:  `${error_text[0]} ${newPeriod} ${error_text[1]}`
      }
    )

    setErrors(result);

    if (Object.values(result).length == 0) {
      validation.period = true;
    }

    isEnabledAction(
      parameters.filters,
      (result) => {
        validation.general = !validation.period ? false : result;
      },
      { ignoreFields: ['line', 'machine', 'workShift'] }
    );

    if(!validation.general){
      setTableData([])
    }

    setIsFiltersValid(validation);
  }, [parameters.filters]);

  useEffect(() => {
    sessionStorage.setItem('GlobalHistoryFilter', JSON.stringify(parameters));
  }, [parameters]);

  const data = useMemo(
    () =>
      scraps.map((value) => {
        const language = i18n.language;

        return {
          id: value.id,
          machine: value.machine?.code ?? '',
          line: value.machine?.line?.code ?? '',
          item: value.details?.item ?? '',
          defect: value.details?.scrapPossibleFailure ?? '',
          workShift: t(value.workShift ?? 'NO_SHIFT'),
          amount: value.amount.toLocaleString(language, fmt),
          user: value.details?.user ?? '',
          description: value.details?.description ?? '',
          date: format(parseISO(value.date), 'dd.MM.yyyy'),
          detectorGroup: value.details?.detectorGroup ?? '',
          generatorGroup: value.details?.generatorGroup ?? '',
        };
      }),
    [scraps]
  );

  const exportData = useMemo(
    () =>
      scraps.map((value) => {
        const language = i18n.language;

        return {
          id: value.id,
          machine: value.machine?.code.split('.')[4] ?? '',
          line: value.machine?.line?.code ?? '',
          item: value.details?.item ?? '',
          defect: value.details?.scrapPossibleFailure ?? '',
          workShift: t(value.workShift ?? 'NO_SHIFT'),
          amount: value.amount.toLocaleString(language, fmt),
          user: value.details?.user ?? '',
          description: value.details?.description ?? '',
          date: format(parseISO(value.date), 'dd/MM/yyyy HH:mm:ss'),
          detectorGroup: value.details?.detectorGroup ?? '',
          generatorGroup: value.details?.generatorGroup ?? '',
        };
      }),
    [scraps]
  );

  const [tableData, setTableData] = useState<typeof data>(data);
  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');

  useEffect(() => {
    if (isExportData) {
      downloadFile(`historico-scrap-${thisDate}`, makeCSVFromTable(attributes, exportData));
      setIsExportData(false);

      if (data.length === dataExportLimit)
        feedback.message({
          animation: 'Error',
          title: 'Limite de dados atingido!',
          subtitle: `Não é possível exportar mais que ${dataExportLimit} linhas de dados.`,
        });
    } else setTableData(data);
  }, [data]);

  return (
    <History>
      <Title>Histórico Scrap</Title>
      <Filters>
        <Drop
          label="Unidade*"
          name="Unidade"
          value={parameters.filters.factory?.name}
          options={factoryName.data?.factories?.value ?? []}
          onSelect={(factory) =>
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, factory, line: undefined, machine: undefined },
            })
          }
          errorMessage={
            factoryName.data?.factories?.value?.length === 0 ? 'Não existe nenhuma unidade cadastrada.' : undefined
          }
        />
        <Drop
          label="Linha"
          name="Linha"
          value={parameters.filters.line?.name}
          options={lineName.data?.lines?.value?.filter(line => line.factory?.id === parameters.filters.factory?.id).map((line) => ({ id: line.id, name: line.code })) ?? []}
          onSelect={(line) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, line, machine: undefined },
            });
          }}
          disabled={!parameters.filters.factory?.id}
          errorMessage={lineName.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined}
        />
        <Drop
          label="Máquina"
          name="Máquina"
          value={parameters.filters.machine?.name}
          options={machineName.data?.machines?.value?.map((machine) => ({ id: machine.id, name: machine.code })) ?? []}
          onSelect={(machine) =>
            setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, machine } })
          }
          disabled={!parameters.filters.line?.id}
          errorMessage={
            machineName.data?.machines?.value?.length === 0 ? 'Não existe nenhuma máquina cadastrada.' : undefined
          }
        />
        <MultiDropdown
          label="Turno"
          name="Turno"
          value={parameters.filters.workShift ?? []}
          options={shiftTimes}
          onBlur={(shifts) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, workShift: shifts.map((el) => el.id as WorkShift) },
            });
          }}
          errorMessage={shiftTimes.length === 0 ? 'Não existe nenhum turno cadastrado.' : undefined}
        />
        <Calendar
          label="Data inicial*"
          placeholder="Data inicial"
          value={format(parameters.filters.startDate, 'yyyy-MM-dd')}
          onChange={(e) => {
            const startDate = parseISO(e.target.value);
            if (e.target.value.length == 10)
              setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, startDate } });
            }
          }
          onBlur={(date) => {
            const startDate = parseISO(date.target.value);
            if (date.target.value.length == 10)
              setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, startDate } });
          }}
        />
        <Calendar
          label="Data final*"
          placeholder="Data final"
          value={format(parameters.filters.endDate, 'yyyy-MM-dd')}
          onChange={(e) => {
            const endDate = parseISO(e.target.value);
            if (e.target.value.length == 10)
              setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, endDate } });
            }
          }
          onBlur={(date) => {
            const endDate = parseISO(date.target.value);
            if (date.target.value.length == 10)
              setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, endDate } });
          }}
        />
      </Filters>
      {parameters.filters.line && (
        <ClearFilter
          onClick={() => {
            setParameters(initialParameters);
            sessionStorage.removeItem('GlobalHistoryFilter');
          }}
        >
          <Icon name="Cross" size="smaller" color="white" />
          <span>Limpar Filtros</span>
        </ClearFilter>
      )}
      {errors?.limitGreaterThenSuperior && (
          <StyledErrorMessage data-cy="field-message" maxWidth='parent'>
            { errors?.limitGreaterThenSuperior}
          </StyledErrorMessage>
      )}
      <Table>
        <HistoryTable attributes={attributes} content={tableData} size={'large'} loading={scrapsQuery.fetching && (!isExportData)}/>
      </Table>
      <Footer>
        <PageOptions>
          <Button title="Menu históricos" color="light" icon="ArrowLeft" onClick={() => navigate('..')} />
          <Button title="Exportar" icon="Export" disabled={data.length == 0} onClick={() => setIsExportData(true)} />
        </PageOptions>
        <Pagination
          numberOfItems={parameters.limit}
          onSelectNumber={(number) => {
            setParameters({ ...parameters, page: 1, limit: number });
          }}
          page={parameters.page}
          totalPages={Math.ceil(count / parameters.limit)}
          onPrevious={() => setParameters({ ...parameters, page: parameters.page - 1 })}
          onNext={() => setParameters({ ...parameters, page: parameters.page + 1 })}
        />
      </Footer>

      {isExportData && (
        <LoaderLogo data-cy="loading">
          <MouraLogo>
            <Logo style="onlyIcon" />
          </MouraLogo>
        </LoaderLogo>
      )}
    </History>
  );
};
