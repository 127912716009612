import React from 'react';
import { sizes, VectorProps } from './Icon';

export const DefectColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      fill="none"
      viewBox="0 0 43 45"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.162 32.25v12.125h20.974v-5.091a4.25 4.25 0 1 1 0-8.431v-4.59H8.148a5.986 5.986 0 0 0-5.986 5.987Zm9.875-1.428a4.25 4.25 0 1 1-.005 8.5 4.25 4.25 0 0 1 .008-8.5h-.003Z"
        fill="#006EC7"
      />
      <path
        d="M26.847 35.07a4.25 4.25 0 0 1-3.712 4.214v5.09h19.657V32.25a5.986 5.986 0 0 0-5.986-5.986H23.135v4.593a4.25 4.25 0 0 1 3.712 4.213Zm6.313-4.25a4.25 4.25 0 1 1 0 8.495 4.25 4.25 0 0 1 0-8.493v-.002Z"
        fill="#0033AB"
      />
      <path
        d="M16.943 2.946c-.454 0-.454 0-.455-.467 0-.293.007-.585 0-.875-.018-.468-.21-.652-.677-.653h-3.11c-.467 0-.647.158-.677.619a8.784 8.784 0 0 0-.008 1.026c.015.284-.096.359-.368.356-1.39-.01-2.778 0-4.168-.007-.284 0-.516.066-.664.324v3.563c.163.285.427.325.727.324h15.51v-4.21h-6.11Z"
        fill="#FFBF31"
      />
      <path
        d="M37.047 7.154c.557 0 .72-.164.72-.719v-2.75c0-.584-.155-.739-.73-.74h-4.076c-.41 0-.41 0-.412-.424 0-.32.01-.645-.012-.965-.032-.447-.212-.606-.657-.606h-3.141c-.45 0-.645.183-.664.634-.014.341-.019.685 0 1.026.015.271-.091.341-.35.34-1.36-.011-2.719 0-4.078 0h-.526v4.204h13.926Z"
        fill="#EAA00C"
      />
      <path
        d="M23.05 7.154h.07V2.946h-.07v4.208ZM20.456 22.568a.419.419 0 0 1-.4-.539l1.332-4.374-2.193-.488a.419.419 0 0 1-.25-.652L22.919 11a.419.419 0 0 1 .133-.125v-2.11H9.356c-.485 0-.489 0-.489.486v14.406c-.001.15.011.3.038.449a.646.646 0 0 0 .641.569h13.506v-4.953l-2.276 2.699a.42.42 0 0 1-.32.147Z"
        fill="#FFBF31"
      />
      <path
        d="M35.177 8.765H23.122v2.078a.415.415 0 0 1 .295.008.42.42 0 0 1 .25.454l-.548 3.392v.408l2.433.75a.417.417 0 0 1 .202.67L23.13 19.64v5.037h11.584c.11 0 .221 0 .331-.006a.636.636 0 0 0 .609-.532c.032-.178.047-.36.045-.54V9.285c-.006-.52-.008-.52-.521-.52Z"
        fill="#EAA00C"
      />
      <path
        d="M8.166 6.329v4.526a1 1 0 0 0 .951 1V5.326a1 1 0 0 0-.951 1.003ZM8.784 12.818a.999.999 0 0 0-.45.37 1.1 1.1 0 0 0-.094.173 1.022 1.022 0 0 0 .094.944 1.011 1.011 0 0 0 .782.445v-2a1 1 0 0 0-.332.074v-.006Z"
        fill="#fff"
      />
      <path
        d="M9.878 14.458a1.008 1.008 0 0 0-.514-1.697.998.998 0 0 0-1.182 1.181 1.012 1.012 0 0 0 .934.807h.051c.266 0 .521-.104.711-.29ZM10.168 10.855V6.33a1 1 0 0 0-1-1h-.05a1 1 0 0 0-.952 1v4.526a1 1 0 0 0 .951 1h.052a1 1 0 0 0 1-1Z"
        fill="#fff"
      />
      <path
        d="M6.987 2.739.706 13.893a2.514 2.514 0 0 0 2.18 3.75h6.23V14.75a1.011 1.011 0 0 1-.876-.619 1.093 1.093 0 0 1-.057-.188.972.972 0 0 1 0-.393.998.998 0 0 1 .15-.361.928.928 0 0 1 .278-.279.999.999 0 0 1 .172-.092 1 1 0 0 1 .333-.068v-.886a1 1 0 0 1-.951-1V6.329a1 1 0 0 1 .95-1V1.464a2.476 2.476 0 0 0-2.128 1.275Z"
        fill="#006EC7"
      />
      <path
        d="M17.63 13.893 11.348 2.739a2.478 2.478 0 0 0-2.23-1.275v3.862h.05a1 1 0 0 1 1 1v4.526a1 1 0 0 1-1 1h-.05v.898a.981.981 0 0 1 .764.291 1.016 1.016 0 0 1-.001 1.424c-.192.184-.447.286-.713.285h-.05v2.894h6.33a2.514 2.514 0 0 0 2.182-3.751Z"
        fill="#0033AB"
      />
    </svg>
  );
};
