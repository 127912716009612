import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Login } from '../components/layout/Login';
import { LoginInput } from '../components/ui/LoginInput';
import { useTranslation } from 'react-i18next';
import { Logo } from '../components/ui/Logo';
import { useLoginMutation } from '../hooks/graphqlQueries';
import { Button } from '../components/ui/Button';
import { useFeedback } from '../context/feedback';
import { useNavigate } from 'react-router';
import { routes } from '../Routes';
import { Loading } from '../components/layout/Loading';
import { Popup } from '../components/layout/Popup';
import { SendEmailRecovery } from '../components/layout/SendEmailRecovery';
import { usePermission } from '../context/permission';

const LinkedLabel = styled.a`
  display: flex;
  font-family: ${(props) => props.theme.font};
  font-weight: 500;
  font-size: 16px;

  margin-top: 10px;
  justify-self: center;
  cursor: pointer;

  /* BACK INPUT */
  color: #f8fcff;
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
`;

const MouraLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
`;

export const SignInPage: React.FC = () => {
  const { t } = useTranslation();
  const feedback = useFeedback();
  const navigate = useNavigate();
  const profile = usePermission();

  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [password, setPassword] = useState('');
  const [step, setStep] = useState(0);

  const [{ error, data, fetching }, signIn] = useLoginMutation();

  const [showRecovery, setShowRecovery] = useState(false);

  useEffect(() => {
    if (data?.login?.token) {
      localStorage.setItem('accessToken', data.login.token);
      navigate(routes.dashboard);
    }
    if (data?.login?.refreshToken) {
      localStorage.setItem('refreshToken', data.login.refreshToken);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      feedback.message({
        animation: 'Error',
        title: 'Ocorreu um problema ao realizar o login',
        subtitle: 'Tente novamente',
      });
    }
  }, [error]);

  const loginSteps = [
    /* Email */
    {
      element: (
        <>
          <LoginInput
            dataCy="email-input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {showRecovery && (
            <Popup onClose={() => setShowRecovery(false)} isOutSideClick={true} zIndex={1}>
              <SendEmailRecovery emailInput={email} />
            </Popup>
          )}
          <Button
            dataCy="login-button"
            title="Continuar"
            color="primary"
            onClick={() => setStep(1)}
            disabled={email.length < 5}
          />
          <LinkedLabel onClick={() => setShowRecovery(true)}>{t('password_recovery')}</LinkedLabel>
        </>
      ),
    },
    /* Password */
    {
      element: (
        <>
          <LoginInput
            dataCy="psw-input"
            type="password"
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button dataCy="login-button" title="Entrar" color="primary" />
          <LinkedLabel onClick={() => setStep(0)}>« Entrar com outro E-mail</LinkedLabel>
        </>
      ),
    },
  ];

  const handleLogin = async () => {
    const user = await signIn({ email, password });
    profile.setPermission(user.data?.login?.profile);

    if (user.data?.login?.firstName){
      localStorage.setItem('xxxx_user', user.data?.login?.firstName.concat(' ').concat(user.data?.login?.lastName));
      localStorage.setItem('me', user.data?.login?.firstName);
      localStorage.setItem('email', email);
    }
  };

  if (fetching) return <Loading />;
  return (
    <Login onSubmit={() => handleLogin()}>
      <MouraLogo>
        <Logo style="vertical" />
      </MouraLogo>
      {loginSteps[step]?.element}
    </Login>
  );
};
