import React from 'react';

interface IProps<G> {
  template: React.ComponentType<{ onAddItem: (data: G) => void }>;
  item: React.ComponentType<G & { id: string; onDeleteItem: (id: string) => void }>;
  values: { id: string; data: G }[];
  onDeleteItem: (id: string) => void;
  onAddItem: (data: G) => void;
}
export const ListComponent = <G extends object>(props: IProps<G>) => {
  return (
    <>
      <props.template onAddItem={(data) => props.onAddItem(data)} />
      {props.values.map((value) => {
        return (
          <props.item
            key={value.id + value.data}
            id={value.id}
            {...value.data}
            onDeleteItem={() => props.onDeleteItem(value.id)}
          />
        );
      })}
    </>
  );
};
