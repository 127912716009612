import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Wrench: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9347 0.0079767C12.2077 -0.0603046 10.4586 0.564227 9.13982 1.88298C7.49404 3.52876 7.27949 5.49845 8.0298 7.368L1.08807 14.3097C-0.2136 15.6114 -0.2136 17.7216 1.08807 19.0233C2.38973 20.3249 4.49994 20.3249 5.80161 19.0233L12.7433 12.0815C14.6129 12.8319 16.5826 12.6173 18.2284 10.9715C19.9867 9.21319 20.5089 6.69079 19.8039 4.47412L15.9448 8.33317L12.6115 7.49984L11.7782 4.16651L15.6372 0.307456C15.0831 0.131206 14.5104 0.0307371 13.9347 0.0079767ZM3.44484 14.9998C4.36567 14.9998 5.1115 15.7457 5.1115 16.6665C5.1115 17.5873 4.36567 18.3332 3.44484 18.3332C2.524 18.3332 1.77817 17.5873 1.77817 16.6665C1.77817 15.7457 2.524 14.9998 3.44484 14.9998Z"
        fill={color}
      />
    </svg>
  );
};
