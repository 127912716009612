import React, { useEffect, useMemo, useState } from 'react';
import { Chart } from '../../components/ui/Chart';
import { useTheme } from 'styled-components';
import { useAggregatedStopTimeByQuery, StopTimeBy, StopTimeByObject, WorkShift } from '../../hooks/graphqlQueries';
import { useOutletContext } from 'react-router-dom';
import { ChartContextType } from '../ChartFilterInterval';
import { getI18n } from 'react-i18next';

type ChartType = 'LINE' | 'BAR' | 'STACKBAR';

const stopChartTemplate = (stop: StopTimeByObject[]) => {
  return stop?.length > 0
    ? stop.map((value) => ({
        x: value.name ?? 'Não Justificada',
        y: value.duration,
        hover: `Parada(min):${new Intl.NumberFormat(getI18n().language, {
          maximumFractionDigits: 2,
          useGrouping: false,
        }).format(value?.duration ?? 0)}`,
      }))
    : undefined;
};

const mountParetoChart = (color: { barColor: string; lineColor: string }, stopComponent: StopTimeByObject[]) => {
  // formats all queries to x, y and hover format
  const stopComponentMount = stopChartTemplate(stopComponent);

  const components = stopComponentMount ?? [];

  // makes all data lists one for the chart model and calculates the accumulation
  const chartDataTemplate = [...components]
    .filter((v) => v)
    .reduce(
      function (prev, curr, index) {
        return {
          x: [...prev.x, curr?.x ?? 'Não Justificado'],
          y: [...prev.y, curr?.y ?? 0],
          acc: [...prev.acc, (prev.acc[index - 1] ?? 0) + (curr?.y ?? 0)],
          hover: [...prev.hover, curr?.hover ?? ''],
        };
      },
      {
        x: new Array<string>(),
        y: new Array<number>(),
        hover: new Array<string>(),
        acc: new Array<number>(),
      }
    );

  // calculates pareto accumulation and assembles the data into the chart model
  const totalStop = chartDataTemplate.y.reduce((prev, curr) => prev + curr, 0);

  const accumulationLine = chartDataTemplate.acc.map((value) => parseFloat(((value / totalStop) * 100).toFixed(2)));

  const paretoChartComposition = [
    {
      x: chartDataTemplate.x,
      y: chartDataTemplate.y,
      name: 'Parada (minutos)',
      hoverData: chartDataTemplate.hover,
      text: chartDataTemplate.hover,
      textposition: 'center',
      chartType: 'BAR' as ChartType,
      marker: {
        color: color.barColor,
      },
      color: color.barColor,
    },
    {
      x: chartDataTemplate.x,
      y: accumulationLine,
      name: 'Acumulado (porcentagem)',
      yaxis: 'y2',
      chartType: 'LINE' as ChartType,
      marker: {
        color: color.lineColor,
      },
      color: color.lineColor,
    },
  ];

  return {
    exportData: [
      ['origem', ...chartDataTemplate.x],
      ['parada', ...chartDataTemplate.y],
      ['acumulado', ...accumulationLine],
    ],
    chartData: paretoChartComposition,
  };
};

const filterBySelect: { [key in string]: StopTimeBy } = {
  '1': StopTimeBy.Stop,
  '2': StopTimeBy.Reason,
  '3': StopTimeBy.Loss,
  '4': StopTimeBy.Machine,
};

type OptionsFilterByType = { id: string; name: string };

const options: OptionsFilterByType[] = [
  {
    id: '1',
    name: 'Parada',
  },
  {
    id: '2',
    name: 'Motivo',
  },
  {
    id: '3',
    name: 'Perda',
  },
  {
    id: '4',
    name: 'Máquina',
  },
];

export const StopChart: React.FC = () => {
  const { filter, setExportData, isFiltersValid } = useOutletContext<ChartContextType>();
  const [filterBy, setFilterBy] = useState<OptionsFilterByType>(options[1] as OptionsFilterByType);
  const theme = useTheme();

  const paretoChartColor = useMemo(() => {
    return {
      barColor: theme.colors.chart.customChart.stopChart.barColor,
      lineColor: theme.colors.chart.customChart.stopChart.lineColor,
    };
  }, [theme]);

  const [AggregatedStopTimeByQuery] = useAggregatedStopTimeByQuery({
    //pause: filter.factory === undefined || filter.line === undefined,
    pause: !isFiltersValid.general,
    requestPolicy: 'network-only',
    variables: {
      filter: {
        by: filterBySelect[filterBy.id],
        lineId: filter?.line?.id,
        machineId: filter?.machine?.id,
        hideUnjustified: filter.hideUnjustified,
        workShift: (filter?.multipleShifts?.map((s) => s.id) ?? []) as WorkShift[],
        startDate: filter?.startDate,
        endDate: filter?.endDate,
      },
    },
  });

  const showFetching = AggregatedStopTimeByQuery.fetching || filter?.loading === true;
  const aggregatedStopTimeBy = useMemo(
    () => AggregatedStopTimeByQuery.data?.aggregatedStopTimeBy?.data,
    [AggregatedStopTimeByQuery]
  );

  const data = useMemo(
    () => mountParetoChart(paretoChartColor, aggregatedStopTimeBy as StopTimeByObject[]),
    [aggregatedStopTimeBy, paretoChartColor]
  );

  useEffect(() => {
    setExportData(data.exportData);
  }, [data]);

  return (
    <Chart
      chartType={'BAR'}
      data={data.chartData}
      optionFilterByInitial={options[1]}
      optionsFilterBy={options}
      selectFilterBy={(filterBy) => setFilterBy(filterBy)}
      loading={showFetching}
    />
  );
};
