import React, { useEffect, useMemo } from 'react';
import { Chart } from '../../components/ui/Chart';
import { useTheme } from 'styled-components';
import { WorkShift, useOeeChartQuery, MachineState } from '../../hooks/graphqlQueries';
import { t } from 'i18next';
import { format, parseISO } from 'date-fns';
import { ChartContextType } from '../ChartFilterInterval';
import { useOutletContext } from 'react-router-dom';

type ChartType = 'LINE' | 'BAR' | 'STACKBAR';

type LineTemplate = { x: Array<Date>; y: Array<number>; hover: Array<string> };

function newLine() {
  return { x: new Array<Date>(), y: new Array<number>(), hover: new Array<string>() } as LineTemplate;
}

function addPoint(line: LineTemplate, x: Date, y: number, workShift: string, hover: string) {
  const yPercent = Math.round(y * 100);
  const timeString = format(x, 'dd/MM/yyyy HH:mm:ss.S');
  line.x.push(x);
  line.y.push(yPercent);
  line.hover.push(`${workShift}<br><b>${hover}</b>: ${yPercent}%<br><b>${timeString}</b>`);
}

const mountLineChart = (
  color: { oeeColor: string; availabilityColor: string; performanceColor: string },
  stateData: MachineState[]
) => {
  const exportX: Array<string> = [];
  const oee: LineTemplate = newLine();
  const availability: LineTemplate = newLine();
  const performance: LineTemplate = newLine();

  if (stateData?.length > 0) {
    for (const state of stateData) {
      exportX.push(format(parseISO(state.time), 'dd/MM/yyyy HH:mm:ss'));
      const date = parseISO(state.time);
      addPoint(oee, date, state.oee, t(state.workShift), 'OEE');
      addPoint(availability, date, state.availability, t(state.workShift), 'Disponibilidade');
      addPoint(performance, date, state.performance, t(state.workShift), 'Performance');
    }
  }

  const chartComposition = [
    {
      x: oee.x,
      y: oee.y,
      name: 'OEE',
      hoverData: oee.hover,
      chartType: 'LINE' as ChartType,
      line: { color: color?.oeeColor ?? [] },
      color: color.oeeColor,
    },
    {
      x: availability?.x,
      y: availability?.y,
      name: 'Disponibilidade',
      hoverData: availability?.hover ?? [],
      chartType: 'LINE' as ChartType,
      line: { color: color.availabilityColor },
      color: color.availabilityColor,
    },
    {
      x: performance.x,
      y: performance.y,
      name: 'Performance',
      hoverData: performance?.hover ?? [],
      chartType: 'LINE' as ChartType,
      line: { color: color.performanceColor },
      color: color.performanceColor,
    },
  ];

  return {
    exportData: [
      ['time', ...exportX],
      ['oee', ...oee.y],
      ['disponibilidade', ...availability.y],
      ['performance', ...performance.y],
    ],
    chartData: chartComposition,
  };
};

export const OEEChart: React.FC = () => {
  const { filter, setExportData, isFiltersValid } = useOutletContext<ChartContextType>();
  const theme = useTheme();

  const lineChartColor = useMemo(() => {
    return {
      oeeColor: theme.colors.chart.customChart.oeeChart.oee,
      availabilityColor: theme.colors.chart.customChart.oeeChart.availability,
      performanceColor: theme.colors.chart.customChart.oeeChart.performance,
    };
  }, [theme]);

  const [stateQuery] = useOeeChartQuery({
    pause: !isFiltersValid.general,
    requestPolicy: 'network-only',
    variables: {
      lineId: filter?.line?.id,
      machineId: filter?.machine?.id,
      onlyLast: filter?.machine ? false : true,
      workShift: (filter?.multipleShifts?.map((s) => s.id) ?? []) as WorkShift[],
      startDate: filter?.startDate,
      endDate: filter?.endDate,
    },
  });
  const state = useMemo(() => stateQuery.data?.machineStates?.value, [stateQuery]);
  const data = useMemo(() => mountLineChart(lineChartColor, state as MachineState[]), [state, lineChartColor]);

  useEffect(() => {
    setExportData(data.exportData);
  }, [data]);

  useEffect(() => {
    if(!isFiltersValid.general){
      //setExportData([]);
    }
  }, [isFiltersValid]);

  return <Chart chartType={'LINE'} data={data.chartData} loading={stateQuery.fetching || filter?.loading === true} />;
};
