import { useMemo } from 'react';
import { oee as calculateOEE } from '../core/oee';
import { EventThresholds, Workshift } from './graphqlQueries';
import { Event } from './timeline';

type FutureLineMetricsParams = {
  clock: Date;
  idealCycle?: number;
  stopTime?: number;
  production?: number;
  smallStopTime?: number;
  thresholds?: EventThresholds;
  workshift?: Workshift;
  lastEvent?: Event;
};

type FutureLineMetrics = {
  production: number;
  projection: number;
  cycleTime: number;
  oee: number;
  availability: number;
  performance: number;
  stopTime: number;
  smallStopTime: number;
  meanSpeed: number;
  speed: number;
};

/**
 * Given the input parameters, calculate the future line metrics.
 */
export function useFutureLineMetrics({
  clock,
  thresholds,
  workshift,
  lastEvent,
  stopTime = 0,
  idealCycle = 0,
  production = 0,
  smallStopTime = 0,
}: FutureLineMetricsParams): FutureLineMetrics {
  return useMemo(() => {
    if (!workshift) {
      return {
        production,
        stopTime,
        smallStopTime,
        projection: 0,
        oee: 0,
        availability: 0,
        performance: 0,
        meanSpeed: 0,
        cycleTime: 0,
        speed: 0,
      };
    }

    const lastEventAt = new Date(lastEvent?.end ?? workshift.startingAt);
    let elapsedTime = (clock.getTime() - new Date(workshift.startingAt).getTime()) / 1000;
    if (elapsedTime < 0) elapsedTime = 0;
    let remainingTime = (new Date(workshift.endingAt).getTime() - clock.getTime()) / 1000;
    if (remainingTime < 0) remainingTime = 0;
    let cycleTime = (clock.getTime() - lastEventAt.getTime()) / 1000;
    if (cycleTime < 0) cycleTime = 0;
    const meanSpeed = elapsedTime === 0 ? 0 : production / elapsedTime;
    const projection = production + meanSpeed * remainingTime;
    const speed = cycleTime === 0 ? 0 : 1 / cycleTime;
    let futureStopTime = stopTime;
    let futureSmallStopTime = smallStopTime;

    if (thresholds) {
      if (cycleTime > thresholds.stopCycle) futureStopTime += cycleTime;
      else if (cycleTime > thresholds.smallStopCycle) futureSmallStopTime += cycleTime;
    }

    const oeeResult = calculateOEE({
      acceptedCount: production,
      rejectedCount: 0,
      idealCycleTime: idealCycle,
      totalTime: elapsedTime,
      stopTime: futureStopTime,
    });

    return {
      production,
      projection,
      cycleTime,
      speed,
      oee: oeeResult.oee,
      availability: oeeResult.availability,
      performance: oeeResult.performance,
      stopTime: futureStopTime,
      smallStopTime: futureSmallStopTime,
      meanSpeed: meanSpeed,
    };
  }, [clock, production, stopTime, idealCycle, smallStopTime, thresholds, workshift, lastEvent]);
}
