import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Exit: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg width={sizes[size].width} height={sizes[size].height} viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0058 0.244167L16.4225 5.66083C16.5792 5.8175 16.6667 6.02917 16.6667 6.25V9.87646H7.10649C6.43184 9.87646 5.88493 10.3242 5.88493 10.8765C5.88493 11.4287 6.43184 11.8765 7.10649 11.8765H16.6667V20C16.6667 20.9208 15.9208 21.6667 15 21.6667H1.66667C0.745833 21.6667 0 20.9208 0 20V1.66667C0 0.745833 0.745833 0 1.66667 0H10.4167C10.6375 0 10.8492 0.0875002 11.0058 0.244167Z"
        fill={color}
      />
      <path
        d="M16.6667 11.8765H20.3254C21 11.8765 21.5469 11.4287 21.5469 10.8765C21.5469 10.3242 21 9.87646 20.3254 9.87646H16.6667V11.8765Z"
        fill={color}
      />
      <path
        d="M21.4125 5.751C21.051 5.33344 20.4195 5.28796 20.0019 5.64942C19.5844 6.01088 19.5389 6.64241 19.9003 7.05998L23.2672 10.9494L19.9207 14.5842C19.5467 14.9905 19.5728 15.6231 19.9791 15.9972C20.3854 16.3713 21.018 16.3451 21.3921 15.9388L25.3433 11.6473C25.6877 11.2731 25.6965 10.7 25.3637 10.3155L21.4125 5.751Z"
        fill={color}
      />
    </svg>
  );
};
