import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Icon } from '../Icon';

const InputContainer = styled.div<{ area: string }>`
  display: grid;
  grid-area: ${(props) => props.area};
  width: 270px;
`;

export const StyledLabel = styled.label`
  width: 100%;
  align-self: center;
  justify-self: start;
  margin: 0;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`;

export const StyledInputComponent = styled.div`
  flex: 1;
  display: flex;
  height: 44px;
  margin: 10px 0 10px 0;
  padding-left: 13px;
  padding-right: 14px;
  overflow: hidden;
  flex-direction: row-reverse;
  align-items: center;
  background: ${(props) => props.theme.colors.input.background};
  border-radius: ${(props) => props.theme.colors.input.borderRadius};
  &:focus-within {
    color: ${(props) => props.theme.colors.input.focus.color};
    box-shadow: 0px 2px 20px ${(props) => props.theme.colors.input.focus.boxShadow};
  }
  &:required:valid {
    color: ${(props) => props.theme.colors.input.valid.color};
    box-shadow: 0px 2px 20px ${(props) => props.theme.colors.input.valid.boxShadow};
  }
  &required:not(:placeholder-shown):invalid {
    color: ${(props) => props.theme.colors.input.invalid.color};
    box-shadow: 0px 2px 20px ${(props) => props.theme.colors.input.invalid.boxShadow};
  }
`;

export const StyledInput = styled.input`
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const IconContainer = styled.div`
  cursor: pointer;
`;

type Props = {
  type?: 'text' | 'password' | 'email' | 'phone';
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  area?: string;
  dataCy?: string;
};
export const LoginInput: React.FC<Props> = ({
  type = 'text',
  placeholder,
  value,
  area = 'unset',
  onChange,
  dataCy,
}: Props) => {
  const isSecureEntry = type === 'password' ? true : false;
  const [hidden, setHidden] = useState(true);
  const theme = useTheme();

  return (
    <InputContainer area={area}>
      <StyledLabel>{placeholder}</StyledLabel>
      <StyledInputComponent>
        {isSecureEntry ? (
          <>
            {hidden ? (
              <IconContainer style={{ paddingTop: 4 }}>
                <Icon onClick={() => setHidden(false)} name="Eye" size="small" color={theme.colors.input.icon.color} />
              </IconContainer>
            ) : (
              <IconContainer>
                <Icon
                  onClick={() => setHidden(true)}
                  name="EyeSlash"
                  size="small"
                  color={theme.colors.input.icon.color}
                />
              </IconContainer>
            )}
            <StyledInput
              data-cy={dataCy}
              type={hidden ? 'password' : 'text'}
              value={value}
              onChange={onChange}
              autoComplete="off"
              required
            />
          </>
        ) : (
          <StyledInput data-cy={dataCy} type={type} name={type} value={value} onChange={onChange} required />
        )}
      </StyledInputComponent>
    </InputContainer>
  );
};
