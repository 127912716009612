import styled from 'styled-components';

export const Title = styled.span`
  display: grid;
  grid-area: Title;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  height: max-content;
  color: ${(props) => props.theme.colors.title.color};
`;
