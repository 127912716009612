import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DefectRegistration } from '../../components/layout/DefectRegistration';
import { Footer } from '../../components/layout/Footer';
import { PageOptions } from '../../components/layout/PageOptions';
import { Button } from '../../components/ui/Button';
import { Input } from '../../components/ui/Input';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import { useRegisterDefectMutation, useUpdateDefectMutation } from '../../hooks/graphqlQueries';

type Defect = {
  id: string;
  name: string;
  description: string;
};
export const DefectRegistrationPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const feedback = useFeedback();

  const routeData = location.state as { data: Defect; pagination: { page: number; limit: number } };

  const [disableSave, setDisableSave] = useState(true);
  const [info, setInfo] = useState<Defect>((routeData?.data as Defect) ?? { id: '', name: '', description: '' });

  const [, registerDefect] = useRegisterDefectMutation();
  const [, updateDefect] = useUpdateDefectMutation();

  useEffect(() => {
    if (info.name.length > 0) setDisableSave(false);
    else setDisableSave(true);
  }, [info]);

  const handleSave = () => {
    const dialog = () => {
      feedback.confirmation({
        title: 'Deseja cadastrar outro defeito?',
        onHandle: (result) => {
          result
            ? setInfo({ id: '', name: '', description: '' })
            : navigate('..', { state: { pagination: routeData?.pagination } });
        },
      });
    };

    if (info.id) {
      updateDefect(info)
        .then((result) => {
          if (result.error) {
            feedback.apiError(result.error);
          } else if (result.data?.updateScrapPossibleFailure === null)
            feedback.message({ animation: 'Error', title: 'Erro ao editar defeito' });
          else {
            feedback.message({ animation: 'Confetti', title: 'Defeito editado com sucesso' });
            navigate('..', { state: { pagination: routeData?.pagination } });
          }
        })
        .catch(() => {
          feedback.message({ animation: 'Error', title: 'Erro ao editar defeito' });
        });
    } else {
      registerDefect(info)
        .then((result) => {
          if (result.error) {
            feedback.apiError(result.error);
          } else if (result.data?.createScrapPossibleFailure === null)
            feedback.message({ animation: 'Error', title: 'Erro ao cadastrar defeito' });
          else
            feedback.message({
              animation: 'Confetti',
              title: 'Defeito cadastrado com sucesso',
              onClose: () => dialog(),
            });
        })
        .catch(() => {
          feedback.message({ animation: 'Error', title: 'Erro ao cadastrar defeito' });
        });
    }
  };

  return (
    <DefectRegistration>
      <Title>{info.id ? 'Editar Defeito' : 'Cadastrar Defeito'}</Title>
      <Input
        label="Nome"
        value={info.name}
        area="Name"
        width="376px"
        onChange={(e) => setInfo({ ...info, name: e.target.value })}
      />
      <Input
        label="Descrição"
        maxLength={100}
        value={info.description}
        area="Desc"
        width="376px"
        onChange={(e) => setInfo({ ...info, description: e.target.value })}
      />
      <Footer>
        <PageOptions>
          <Button
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button title="Salvar" color="primary" disabled={disableSave} onClick={() => handleSave()} />
        </PageOptions>
      </Footer>
    </DefectRegistration>
  );
};
