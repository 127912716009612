import React, { useEffect, useMemo, useState } from 'react';
import { Title } from '../../components/ui/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import styled from 'styled-components';
import { RegisterList } from '../../components/layout/RegisterList';
import { RegisterTable } from '../../components/ui/RegisterTable';
import { useDeleteFactoryMutation, useFactoriesQuery } from '../../hooks/graphqlQueries';
import { useFeedback } from '../../context/feedback';
import { PageOptions } from '../../components/layout/PageOptions';
import { Footer } from '../../components/layout/Footer';
import { getPagination } from '../../scripts/pagination';
import { downloadFile, makeCSVFromTable } from '../../scripts/exportFiles';
import { format, parseISO } from 'date-fns';
import { asString } from 'ts-runtime-typecheck';

type attributeType = {
  name: string;
  attr: string;
};

const attributes: attributeType[] = [
  { name: 'Nome', attr: 'name' },
  { name: 'Código', attr: 'code' },
  { name: 'Fuso Horário', attr: 'timezone' },
];

const Table = styled.div`
  display: grid;
  grid-area: Table;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 1168px) {
    &&:after {
      font-size: 12px;
      padding-top: 5px;
      content: 'Deslize a tabela para os lados';
    }
  }
`;

type PageParameters = { page: number; limit: number };
const initialParameters = { page: 1, limit: 10 };

export const FactoryListPage: React.FC = () => {
  const navigate = useNavigate();
  const feedback = useFeedback();
  const location = useLocation();
  const routeData = location.state as { pagination: { page: number; limit: number } };

  const [parameters, setParameters] = useState<PageParameters>(routeData?.pagination ?? initialParameters);
  const [isExportData, setIsExportData] = useState(false);
  const dataExportLimit = parseInt(asString(process.env['DATA_EXPORT_LIMIT']));

  const [factoriesQuery, refetch] = useFactoriesQuery({
    requestPolicy: 'network-only',
    variables: { ...getPagination(parameters.page, parameters.limit, { dataExportLimit, isExportData }) },
  });

  const [, deleteFactory] = useDeleteFactoryMutation();
  const factories = useMemo(() => factoriesQuery.data?.factories?.value ?? [], [factoriesQuery.data]);

  useEffect(() => {
    refetch();
  }, [parameters]);

  const data = useMemo(
    () =>
      factories.map((factory) => {
        return {
          table: {
            name: factory.name,
            code: factory.code,
            timezone: factory.timezone,
          },
          value: { ...factory },
        };
      }),
    [factories]
  );

  const [tableData, setTableData] = useState<typeof data>(data);
  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');

  useEffect(() => {
    if (isExportData) {
      downloadFile(
        `fabricas-${thisDate}`,
        makeCSVFromTable(
          attributes,
          data.map((v) => v.table)
        )
      );
      setIsExportData(false);

      if (factories.length === dataExportLimit)
        feedback.message({
          animation: 'Error',
          title: 'Limite de dados atingido!',
          subtitle: `Não é possível exportar mais que ${dataExportLimit} linhas de dados.`,
        });
    } else setTableData(data);
  }, [data]);

  return (
    <RegisterList>
      <Title>Fábricas cadastradas</Title>
      <Table>
        <RegisterTable
          attributes={attributes}
          content={tableData}
          onDelete={(factory) => {
            feedback.confirmation({
              title:
                'Deseja mesmo excluir essa fábrica? Essa operação é irreversível e irá exluir também todos os dados associados a fábrica.',
              onHandle: (result) => {
                if (result) {
                  deleteFactory({ id: factory['id']?.toString() ?? '' })
                    .then(() => {
                      feedback.message({
                        animation: 'Confetti',
                        title: 'Fábrica deletada com sucesso',
                      });

                      parameters.page === 1 ? refetch() : setParameters({ ...parameters, page: 1 });
                    })
                    .catch(() => {
                      feedback.message({
                        animation: 'Error',
                        title: 'Erro ao deletar fábrica',
                      });
                    });
                }
              },
            });
          }}
          onEdit={(factory) => {
            navigate('edit', { state: { data: factory, pagination: { ...parameters } } });
          }}
        />
      </Table>
      <Footer>
        <PageOptions>
          <Button color="light" title="Menu cadastros" icon="ArrowLeft" onClick={() => navigate('..')} />
          <Button
            dataCy="register-button"
            color="primary"
            title="Cadastrar"
            icon="CirclePlus"
            iconSize="small"
            onClick={() => navigate('add', { state: { pagination: { ...parameters } } })}
          />
          <Button title="Exportar" icon="Export" disabled={data.length == 0} onClick={() => setIsExportData(true)} />
        </PageOptions>
        <Pagination
          numberOfItems={parameters.limit}
          onSelectNumber={(number) => setParameters({ limit: number, page: 1 })}
          page={parameters.page}
          totalPages={Math.ceil((factoriesQuery.data?.factories?.count ?? parameters.limit) / parameters.limit)}
          onPrevious={() => {
            setParameters({ ...parameters, page: parameters.page - 1 });
          }}
          onNext={() => {
            setParameters({ ...parameters, page: parameters.page + 1 });
          }}
        />
      </Footer>
    </RegisterList>
  );
};
