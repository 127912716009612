import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../components/ui/Button';
import { Input } from '../../components/ui/Input';
import { LineRegistration } from '../../components/layout/LineRegistration';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import { useUpdateFactoryMutation } from '../../hooks/graphqlQueries';
import { Drop } from '../../components/ui/Drop';
import timezones from '../../timezones.json';
import { PageOptions } from '../../components/layout/PageOptions';
import { Footer } from '../../components/layout/Footer';

const Info = styled.div`
  display: grid;
  grid-area: Info;
  grid-template-columns: 1fr 1fr;
  row-gap: 19px;
  column-gap: 19px;
  align-items: end;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
`;

type Factory = {
  id: string;
  name: string;
  code: string;
  timezone: string;
};

function createFactoryInfo() {
  return {
    name: '',
    code: '',
    timezone: '',
  };
}

export const FactoryEditPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const feedback = useFeedback();
  const routeData = location.state as { data: Factory; pagination: { page: number; limit: number } };
  const [, updateFactory] = useUpdateFactoryMutation();

  const factoryBefore = { ...(routeData?.data as Factory) };

  const [info, setInfo] = useState<Factory>(factoryBefore ?? createFactoryInfo());

  const handleSave = () => {
    updateFactory(info)
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.updateFactory === null)
          feedback.message({ animation: 'Error', title: 'Erro ao editar fábrica' });
        else {
          feedback.message({ animation: 'Confetti', title: 'Fábrica editada com sucesso' });
          navigate('..', { state: { pagination: routeData?.pagination } });
        }
      })
      .catch(() => {
        feedback.message({ animation: 'Error', title: 'Erro ao editar fábrica' });
      });
  };

  return (
    <LineRegistration>
      <Title>Editar Fábrica</Title>
      <Info>
        <Input
          label="Nome da Fábrica"
          value={info.name}
          width="376px"
          onChange={(e) => setInfo({ ...info, name: e.target.value })}
        />
        <Input
          label="Código da Fábrica"
          value={info.code}
          width="376px"
          onChange={(e) => setInfo({ ...info, code: e.target.value })}
        />
        <Drop
          label="Fuso Horário"
          size="large"
          name={''}
          options={timezones.name.map((value, index) => {
            return { id: index.toString(), name: value };
          })}
          onSelect={(selected) => {
            setInfo({ ...info, timezone: selected.name });
          }}
          value={info.timezone}
        />
      </Info>
      <Footer>
        <PageOptions>
          <Button
            dataCy="back-button"
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button dataCy="save-button" title="Salvar" color="primary" onClick={() => handleSave()} />
        </PageOptions>
      </Footer>
    </LineRegistration>
  );
};
