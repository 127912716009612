import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const animations = {
  Applause: 'https://i.imgur.com/lcegz4m.gif',
  Confetti: 'https://i.imgur.com/l9RvQ0Q.gif',
  Check: 'https://i.imgur.com/AuBWMyz.gif',
  Tool: 'https://i.imgur.com/Ri0p7fr.gif',
  Error: 'https://i.imgur.com/48KIa3Q.gif',
};
type Animation = keyof typeof animations;

const StyledAnimation = styled.img`
  width: 115px;
`;

const StyledFeedback = styled.div<{ show: boolean }>`
  position: fixed;
  display: flex;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  top: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: visibility 0.25s, opacity 0.25s linear;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.feedback.background};
  opacity: 0.8;
`;

const Box = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 6px;
  z-index: 2;
  background-color: ${(props) => props.theme.colors.feedback.foreground};
  border-radius: 15px;
  padding-top: 55px;
  padding-bottom: 55px;

  justify-content: center;
  align-items: center;
  justify-items: center;

  @media (min-width: 400px) {
    width: 375px;
  }
`;

const Title = styled.span`
  font-family: ${(props) => props.theme.font};
  font-size: 28px;
  color: ${(props) => props.theme.colors.feedback.text};
  font-weight: 600;
  width: 250px;
  text-align: center;
`;

const Subtitle = styled.span`
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  color: ${(props) => props.theme.colors.feedback.subtitle};
  text-align: center;
  width: 215px;
`;

export type Props = {
  animation: Animation | undefined;
  title: string;
  subtitle?: string;
  visible?: boolean;
  onClose?: () => void;
};
export const Feedback: React.FC<Props> = ({ animation = undefined, title, subtitle, visible = false, onClose }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!visible) onClose?.call(this);
  }, [visible]);

  if (animation) {
    return (
      <StyledFeedback show={visible && loaded}>
        <Overlay />
        <Box>
          <StyledAnimation src={animations[animation]} alt="gif" onLoad={() => setLoaded(true)} />
          <Title data-cy="feedback-title">{title}</Title>
          {subtitle && <Subtitle data-cy="feedback-subtitle">{subtitle}</Subtitle>}
        </Box>
      </StyledFeedback>
    );
  } else {
    return <></>;
  }
};
