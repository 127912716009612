import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Bottleneck: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.898 3.758l5.445 5.117v9.014M19.405 3.758L13.96 8.875v9.014"
        stroke={color}
        strokeWidth={2}
        strokeLinecap="round"
      />
      <circle cx={10.759} cy={7.994} r={0.768} fill={color} />
      <circle cx={14.445} cy={4.526} r={0.768} fill={color} />
      <circle cx={7.343} cy={4.526} r={0.768} fill={color} />
      <circle cx={10.759} cy={2.068} r={0.768} fill={color} />
      <circle cx={15.444} cy={1.07} r={0.768} fill={color} />
      <circle cx={5.807} cy={1.07} r={0.768} fill={color} />
      <circle cx={10.759} cy={12.243} r={0.768} fill={color} />
      <circle cx={10.759} cy={16.492} r={0.768} fill={color} />
    </svg>
  );
};
