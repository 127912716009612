import React from 'react';
import { sizes, VectorProps } from './Icon';

export const OeeColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.22087 17.9201H11.1032C10.9229 19.2536 10.8333 20.598 10.8352 21.9439H16.4547C16.4539 20.5954 16.5668 19.2493 16.7923 17.9201H22.0048V12.4314H18.361C19.309 10.105 20.5289 7.90067 21.9949 5.86512L22.006 5.88137V0.276367H21.9825C21.6871 0.276367 21.3942 0.285117 21.1025 0.297617C20.3918 0.326784 19.6886 0.390534 18.9927 0.488867C8.53795 1.96387 0.458578 11.0164 0.423828 21.9439H5.70955C5.71424 20.5865 5.88599 19.2349 6.22087 17.9201V17.9201ZM14.4119 7.49636C13.6422 9.01056 12.9843 10.5798 12.4436 12.1914L12.3654 12.4314H8.7812C10.2564 10.3746 12.1818 8.68728 14.4082 7.50011L14.4119 7.49636Z"
        fill="#FFBF31"
      />
      <path
        d="M18.6539 32.0639H22.0048V26.5752H16.9164C16.6152 25.0597 16.4626 23.5182 16.461 21.9727H10.8352C10.8397 23.5143 10.9642 25.0531 11.2075 26.5752H6.34994C5.92349 25.0933 5.70747 23.558 5.70831 22.0152C5.70831 22.0027 5.70831 21.9889 5.70831 21.9752H0.423828C0.423828 21.9889 0.423828 22.0027 0.423828 22.0152C0.423828 33.0302 8.60373 42.1564 19.164 43.5614C19.7175 43.6347 20.2781 43.6872 20.8457 43.7189C21.218 43.7389 21.6015 43.7489 21.9825 43.7502H22.0048V37.9439C20.6944 36.1024 19.5718 34.1324 18.6539 32.0639ZM9.13243 32.0639H12.6074C13.1636 33.6332 13.8305 35.1604 14.6031 36.6339C12.4694 35.5317 10.6016 33.9718 9.13118 32.0639H9.13243Z"
        fill="#006EC7"
      />
      <path
        d="M38.3024 22.0125C38.3032 23.5554 38.0872 25.0907 37.6607 26.5725H32.8876C33.125 25.0425 33.2424 23.4961 33.2388 21.9475H27.6192C27.627 23.4998 27.4823 25.049 27.1873 26.5725H22.0059V32.0613H25.4722C24.5474 34.1682 23.41 36.1737 22.0778 38.0463L22.0059 37.9413V43.75C22.3695 43.75 22.7331 43.74 23.093 43.7225C23.7446 43.69 24.3887 43.6263 25.0241 43.5363C35.4988 42.0525 43.5856 32.9675 43.5856 22.015C43.5856 21.9925 43.5856 21.9688 43.5856 21.9463H38.3011C38.3011 21.9663 38.3024 21.99 38.3024 22.0125ZM29.5764 36.5425C30.3228 35.097 30.9673 33.6004 31.505 32.0638H34.8758C33.4458 33.9194 31.6391 35.4464 29.5764 36.5425V36.5425Z"
        fill="#0033AB"
      />
      <path
        d="M24.9745 0.4825C24.354 0.39625 23.7247 0.335 23.0881 0.30375L23.0384 0.25L23.0881 0.30375C22.748 0.28625 22.4059 0.2775 22.0617 0.2775V5.95875C23.5094 7.96651 24.7175 10.139 25.6608 12.4312H22.0059V17.92H27.2568C27.49 19.2485 27.6108 20.5946 27.618 21.9438H33.2376C33.2336 20.5976 33.1387 19.2532 32.9533 17.92H37.7935C38.1284 19.2348 38.3002 20.5864 38.3048 21.9438H43.5906C43.5521 11 35.4529 1.94125 24.9745 0.4825ZM31.6763 12.4312C31.6639 12.3912 31.6502 12.35 31.6366 12.3062C31.0813 10.655 30.403 9.04833 29.6074 7.5C31.8324 8.68782 33.7561 10.3756 35.2295 12.4325L31.6763 12.4312Z"
        fill="#EAA00C"
      />
    </svg>
  );
};
