import React from 'react';
import { sizes, VectorProps } from './Icon';

export const MachineColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.96484 34.5875V46.7125H25.79V41.625C25.6134 41.647 25.4356 41.6583 25.2576 41.6588C24.6774 41.6648 24.1021 41.5502 23.5678 41.3222C23.0335 41.0942 22.5517 40.7576 22.1524 40.3335C21.753 39.9094 21.4449 39.407 21.2471 38.8575C21.0493 38.3081 20.9662 37.7234 21.0029 37.1401C21.0396 36.5568 21.1954 35.9875 21.4604 35.4675C21.7255 34.9476 22.0942 34.4884 22.5435 34.1185C22.9928 33.7486 23.513 33.476 24.0716 33.3177C24.6302 33.1595 25.2152 33.119 25.79 33.1988V28.6013H10.9083C9.33212 28.6016 7.82056 29.2324 6.70602 30.355C5.59147 31.4775 4.96517 33 4.96484 34.5875V34.5875ZM14.7693 33.16C15.6039 33.16 16.4197 33.4093 17.1136 33.8763C17.8075 34.3433 18.3484 35.007 18.6677 35.7836C18.9871 36.5602 19.0707 37.4147 18.9079 38.2391C18.7451 39.0636 18.3432 39.8208 17.753 40.4152C17.1629 41.0096 16.4111 41.4144 15.5925 41.5783C14.774 41.7423 13.9256 41.6582 13.1545 41.3365C12.3835 41.0148 11.7245 40.4701 11.2608 39.7712C10.7971 39.0723 10.5497 38.2506 10.5497 37.41C10.5497 36.8517 10.6589 36.2988 10.8711 35.783C11.0833 35.2672 11.3944 34.7986 11.7864 34.4039C12.1785 34.0092 12.644 33.6962 13.1562 33.4828C13.6685 33.2694 14.2175 33.1597 14.7718 33.16H14.7693Z"
        fill="#006EC7"
      />
      <path
        d="M29.4788 37.4075C29.479 38.4424 29.1044 39.4419 28.4251 40.2184C27.7457 40.9949 26.8085 41.495 25.7891 41.625V46.7175H45.3062V34.5925C45.3058 33.005 44.6795 31.4825 43.565 30.36C42.4504 29.2374 40.9389 28.6066 39.3627 28.6063H25.7891V33.2C26.8068 33.3298 27.7427 33.8285 28.4218 34.6029C29.1009 35.3773 29.4766 36.3743 29.4788 37.4075V37.4075ZM35.7474 33.1575C36.582 33.1575 37.3978 33.4068 38.0917 33.8738C38.7856 34.3408 39.3265 35.0045 39.6459 35.7811C39.9652 36.5577 40.0488 37.4122 39.886 38.2366C39.7232 39.0611 39.3213 39.8183 38.7312 40.4127C38.141 41.0071 37.3892 41.4119 36.5706 41.5758C35.7521 41.7398 34.9037 41.6557 34.1326 41.334C33.3616 41.0123 32.7026 40.4676 32.2389 39.7687C31.7753 39.0698 31.5278 38.2481 31.5278 37.4075C31.5284 36.2816 31.9726 35.202 32.7628 34.4057C33.553 33.6093 34.6246 33.1613 35.7425 33.16L35.7474 33.1575Z"
        fill="#0033AB"
      />
      <path
        d="M19.6411 5.28376C19.1906 5.28376 19.1906 5.28376 19.1894 4.81626C19.1894 4.52376 19.1968 4.23126 19.1894 3.94126C19.172 3.47376 18.9809 3.29001 18.5167 3.28876C17.4866 3.28876 16.4569 3.28876 15.4277 3.28876C14.9647 3.28876 14.786 3.44626 14.7562 3.90751C14.7358 4.24928 14.7358 4.59199 14.7562 4.93376C14.7699 5.21751 14.6594 5.29251 14.3839 5.29001C13.0039 5.27876 11.625 5.29001 10.2449 5.28251C9.96322 5.28251 9.73363 5.34876 9.58594 5.60626V9.17001C9.74728 9.45501 10.0079 9.50001 10.3045 9.50001C14.1535 9.50001 18.0008 9.50001 21.8465 9.50001H25.7075V5.28376H19.6411Z"
        fill="#FFBF31"
      />
      <path
        d="M39.6022 9.49125C40.1544 9.49125 40.3158 9.3275 40.317 8.7725C40.317 7.85584 40.317 6.93917 40.317 6.0225C40.317 5.43875 40.1656 5.28375 39.5947 5.2825C38.2444 5.2825 36.895 5.2825 35.5463 5.2825C35.138 5.2825 35.1393 5.2825 35.1368 4.85875C35.1368 4.5375 35.1467 4.21375 35.1244 3.8925C35.0921 3.445 34.9134 3.2875 34.4716 3.28625C33.4324 3.28625 32.3928 3.28625 31.3528 3.28625C30.906 3.28625 30.7124 3.47 30.6938 3.92125C30.6801 4.2625 30.6751 4.60625 30.6938 4.9475C30.7087 5.21875 30.6032 5.28875 30.3463 5.2875C28.9972 5.27625 27.6469 5.2875 26.2979 5.2875H25.7754V9.49125H39.6022Z"
        fill="#EAA00C"
      />
      <path d="M25.707 9.49125H25.7753V5.28375H25.707V9.49125Z" fill="#FFBF31" />
      <path
        d="M23.1283 24.9062C23.0591 24.9061 22.9909 24.889 22.9298 24.8562C22.8459 24.8099 22.7804 24.7359 22.7442 24.6467C22.7081 24.5575 22.7035 24.4585 22.7312 24.3662L24.0542 19.9912L21.8761 19.5C21.8088 19.4853 21.7462 19.454 21.694 19.4088C21.6417 19.3636 21.6015 19.3061 21.5768 19.2413C21.5522 19.1766 21.544 19.1067 21.5529 19.0379C21.5618 18.9691 21.5876 18.9037 21.6279 18.8475L25.572 13.3287C25.6063 13.277 25.6522 13.2342 25.706 13.2037V11.1025H12.1051C11.6236 11.1025 11.6211 11.1025 11.6211 11.5887C11.6211 16.3904 11.6211 21.1925 11.6211 25.995C11.6205 26.1454 11.6329 26.2955 11.6584 26.4437C11.6774 26.6004 11.7526 26.7447 11.8699 26.8494C11.9871 26.9541 12.1383 27.0121 12.295 27.0125C12.3955 27.0187 12.4948 27.0125 12.5954 27.0125H25.7073V22.06L23.4461 24.7587C23.4069 24.8051 23.3582 24.8423 23.3033 24.8678C23.2485 24.8933 23.1888 24.9064 23.1283 24.9062Z"
        fill="#FFBF31"
      />
      <path
        d="M37.7453 11.1025H25.7752V13.1812C25.8705 13.1475 25.9747 13.1502 26.0681 13.1887C26.1539 13.2255 26.2249 13.2902 26.2699 13.3725C26.3149 13.4547 26.3313 13.5498 26.3163 13.6425L25.7715 17.035V17.4425L28.1878 18.1925C28.2534 18.2127 28.3129 18.2491 28.3611 18.2982C28.4093 18.3473 28.4447 18.4078 28.464 18.474C28.4833 18.5403 28.486 18.6104 28.4718 18.6779C28.4576 18.7455 28.427 18.8085 28.3827 18.8612L25.7764 21.9762V27.015H37.2775C37.3879 27.015 37.4984 27.015 37.6076 27.015C37.7539 27.0101 37.894 26.9544 38.0041 26.8573C38.1143 26.7603 38.1877 26.6278 38.212 26.4825C38.2432 26.3042 38.2578 26.1234 38.2554 25.9425C38.2554 21.1716 38.2554 16.4 38.2554 11.6275C38.2554 11.1025 38.2554 11.1025 37.7453 11.1025Z"
        fill="#EAA00C"
      />
    </svg>
  );
};
