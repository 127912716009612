import React from 'react';
import { sizes, VectorProps } from './Icon';

export const StopColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.712 12.641a2.226 2.226 0 013.158 0l6.135 6.176V.25a21.517 21.517 0 00-15.27 6.37A21.83 21.83 0 00.41 22h18.435l-6.133-6.178a2.258 2.258 0 010-3.18z"
        fill="#FFBF31"
      />
      <path
        d="M12.713 31.36a2.258 2.258 0 010-3.181l6.104-6.146H.41A21.83 21.83 0 006.746 37.39a21.516 21.516 0 0015.259 6.36V25.182L15.87 31.36a2.226 2.226 0 01-3.158 0z"
        fill="#006EC7"
      />
      <path
        d="M31.299 31.359a2.226 2.226 0 01-3.159 0l-6.134-6.177V43.75c5.727 0 11.22-2.291 15.27-6.37A21.83 21.83 0 0043.6 22H25.166l6.133 6.177a2.258 2.258 0 010 3.182z"
        fill="#0033AB"
      />
      <path
        d="M43.6 22a21.83 21.83 0 00-6.304-15.359A21.517 21.517 0 0022.064.25h-.058v18.567l.058-.058 6.075-6.119a2.226 2.226 0 013.159 0 2.258 2.258 0 010 3.181L25.166 22H43.6z"
        fill="#EAA00C"
      />
    </svg>
  );
};
