import { format, parseISO } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/ui/Button';
import { Calendar } from '../../components/ui/Calendar';
import { Drop } from '../../components/ui/Drop';
import { AttributeType, HistoryTable } from '../../components/ui/HistoryTable';
import { MultiDropdown } from '../../components/ui/MultiDropdown';
import { Pagination } from '../../components/ui/Pagination';
import { Title } from '../../components/ui/Title';
import { History } from '../../components/layout/History';
import { useNavigate } from 'react-router-dom';
import {
  StopTagType,
  useAllStopTagsQuery,
  useJustifiableStopsQuery,
  useLinesFromFactoryQuery,
  useMachinesFromLineQuery,
  useScrapFactoriesNameQuery,
  WorkShift,
} from '../../hooks/graphqlQueries';
import { useTranslation } from 'react-i18next';
import { downloadFile, makeCSVFromTable } from '../../scripts/exportFiles';
import { toEndShiftC, toStartShiftA } from '../../scripts/times';
import { ClearFilter } from '../../components/layout/History/History';
import { Icon } from '../../components/ui/Icon';
import { LoaderLogo, MouraLogo } from '../../components/layout/Loading/Loading';
import { Logo } from '../../components/ui/Logo';
import { useFeedback } from '../../context/feedback';
import { asString } from 'ts-runtime-typecheck';
import { getPagination } from '../../scripts/pagination';
import { Footer } from '../../components/layout/Footer';
import { PageOptions } from '../../components/layout/PageOptions';
import { parsePageFilters } from '../../scripts/parses';
import { isEnabledAction, countValidAttributes } from '../../scripts/objectHelper';
import { StyledErrorMessage } from '../../components/ui/Input/Input';
import { periodFilterValidator, ErrorType } from '../../scripts/periodFilterValidator';

const attributes: Array<AttributeType> = [
  { name: 'Linha', attr: 'line', dataCy: 'table-col-line' },
  { name: 'Máquina', attr: 'machine', dataCy: 'table-col-machine' },
  { name: 'Turno', attr: 'workShift', dataCy: 'table-col-work-shift' },
  { name: 'Afeta disponibilidade', attr: 'affectAvailability', dataCy: 'table-col-affect-availability' },
  { name: 'Tempo', attr: 'duration', dataCy: 'table-col-duration' },
  { name: 'Data', attr: 'date', dataCy: 'table-col-date' },
  { name: 'Hora inicial', attr: 'startTime', dataCy: 'table-col-start-time' },
  { name: 'Hora final', attr: 'endTime', dataCy: 'table-col-end-time' },
  { name: 'Máquina causa', attr: 'machineReason', dataCy: 'table-col-machine-reason' },
  { name: 'Parada', attr: 'stop', dataCy: 'table-col-stop' },
  { name: 'Perda', attr: 'loss', dataCy: 'table-col-loss' },
  { name: 'Motivo', attr: 'reason', dataCy: 'table-col-reason' },
  { name: 'Descrição', attr: 'description', dataCy: 'table-col-description' },
  { name: 'Responsável', attr: 'user', dataCy: 'table-col-user' },
];

const shiftTimes = [
  { id: WorkShift.ShiftA, name: 'Turno A' },
  { id: WorkShift.ShiftB, name: 'Turno B' },
  { id: WorkShift.ShiftC, name: 'Turno C' },
];

const Filters = styled.div`
  display: grid;
  grid-area: Filters;
  grid-template-columns: repeat(auto-fit, minmax(179px, max-content));
  column-gap: 20px;
  row-gap: 10px;
  width: 1fr;
  justify-content: right;

  @media (max-width: 660px) {
    justify-content: center;
  }
`;

const Table = styled.div`
  display: grid;
  grid-area: Table;
  justify-content: space-around;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 1168px) {
    &&:after {
      font-size: 12px;
      padding-top: 5px;
      content: 'Deslize a tabela para os lados';
    }
  }
`;

type PageFilters = {
  page: number;
  limit: number;
  filters: {
    factory?: { id: string; name: string };
    line?: { id: string; name: string };
    machine?: { id: string; name: string };
    stopTag?: string[];
    reasonTag?: string[];
    lossTag?: string[];
    workShift: WorkShift[];
    startDate: Date;
    endDate: Date;
  };
};

const initialParameters: PageFilters = {
  page: 1,
  limit: 10,
  filters: {
    factory: undefined,
    line: undefined,
    machine: undefined,
    stopTag: undefined,
    reasonTag: undefined,
    lossTag: undefined,
    workShift: [],
    startDate: toStartShiftA(new Date()),
    endDate: toEndShiftC(new Date()),
  },
};

export const StopHistoryPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const feedback = useFeedback();

  const rescuedFilters = sessionStorage.getItem('GlobalHistoryFilter');
  const storagedFilters: PageFilters = parsePageFilters(rescuedFilters, initialParameters) as PageFilters;
  const [parameters, setParameters] = useState(
    storagedFilters
      ? {
          ...storagedFilters,
          page: 1,
          limit: 10,
          filters: {
            ...storagedFilters.filters,
            startDate: new Date(storagedFilters.filters.startDate),
            endDate: new Date(storagedFilters.filters.endDate),
          },
        }
      : initialParameters
  );

  const [factoryName] = useScrapFactoriesNameQuery({ requestPolicy: 'network-only' });
  const [lineName] = useLinesFromFactoryQuery({
    pause: parameters.filters.factory === undefined,
    requestPolicy: 'network-only',
    variables: { factoryId: parameters.filters.factory?.id ?? '' },
  });
  const [machineName] = useMachinesFromLineQuery({
    pause: parameters.filters.line?.id === undefined,
    variables: {
      lineId: parameters.filters.line?.id ?? '',
    },
  });
  const [stopName] = useAllStopTagsQuery({
    requestPolicy: 'network-only',
    variables: { tagType: StopTagType.Category },
  });
  const [reasonName] = useAllStopTagsQuery({
    requestPolicy: 'network-only',
    variables: { tagType: StopTagType.Reason },
  });
  const [lossName] = useAllStopTagsQuery({
    requestPolicy: 'network-only',
    variables: { tagType: StopTagType.Loss },
  });

  const [isExportData, setIsExportData] = useState(false);
  const dataExportLimit = parseInt(asString(process.env['DATA_EXPORT_LIMIT']));
  const [isFiltersValid, setIsFiltersValid] = useState({ general: false, period: false });
  const maxPeriodFilterLimit = parseInt(asString(process.env['STOP_PERIOD_FILTER_LIMIT'] || '30'));
  const periodStartFilterLimit = parseInt(asString(process.env['STOP_PERIOD_FILTER_START_LIMIT'] || '7'));
  const [errors, setErrors] = useState<ErrorType>();

  const [stopsQuery] = useJustifiableStopsQuery({
    pause: !isFiltersValid.general,
    variables: {
      ...getPagination(parameters.page, parameters.limit, { dataExportLimit, isExportData }),
      //states: [JustificationState.Justified, JustificationState.Expired, JustificationState.Pending],
      ...parameters.filters,
      factoryId: parameters.filters.factory?.id,
      lineId: parameters.filters.line?.id,
      machineId: parameters.filters.machine?.id,
    },
    requestPolicy: 'network-only',
  });
  const stops = useMemo(() => stopsQuery.data?.justifiableStops?.value ?? [], [stopsQuery.data]);
  const count = useMemo(() => stopsQuery.data?.justifiableStops?.count ?? 0, [stopsQuery.data]);

  useEffect(() => {
    //refetch();
    const countFilter = countValidAttributes(parameters.filters, ['factory', 'line', 'machine', 'stopTag', 'reasonTag', 'lossTag', 'workShift']);
    const calculatedPeriod = countFilter * periodStartFilterLimit;
    const newPeriod = calculatedPeriod < maxPeriodFilterLimit ? calculatedPeriod : maxPeriodFilterLimit;
    const validation = { general: false, period: false };
    const error_text = t('ERROR.PERIOD_BIGGER_THAN').split('|') || " | ";

    const result = periodFilterValidator(
      {
        startDate: parameters.filters.startDate,
        endDate: parameters.filters.endDate,
        maxPeriodLimit: newPeriod
      },
      {
        limitGreaterThenSuperior:  `${error_text[0]} ${newPeriod} ${error_text[1]}`
      }
    )

    setErrors(result);

    if (Object.values(result).length == 0) {
      validation.period = true;
    }

    isEnabledAction(
      parameters.filters,
      (result) => {
        validation.general = !validation.period ? false : result;
      },
      { ignoreFields: ['machine', 'stopTag', 'reasonTag', 'lossTag', 'workShift'] }
    );

    if(!validation.general){
      setTableData([])
    }

    setIsFiltersValid(validation);
  }, [parameters.filters]);

  useEffect(() => {
    sessionStorage.setItem('GlobalHistoryFilter', JSON.stringify(parameters));
  }, [parameters]);

  const data = useMemo(
    () =>
      stops.map((value) => {
        return {
          id: value.id,
          machine: value.machine?.code?.split('.')[4] ?? '',
          line: value.line?.code ?? '',
          machineReason: value.justification?.machineCode ?? '',
          stop: value.justification?.stop ?? '',
          reason: value.justification?.reason ?? '',
          loss: value.justification?.loss ?? '',
          affectAvailability: value.affectAvailability ? 'Sim' : 'Não',
          workShift: t(value.workShift ?? 'NO_SHIFT'),
          user: value.justification?.user ?? '',
          description: value.justification?.description ?? '',
          startTime: format(parseISO(value.startedAt), 'HH:mm:ss.S'),
          endTime: format(parseISO(value.endedAt), 'HH:mm:ss.S'),
          duration:
            Math.floor((parseISO(value.endedAt).getTime() - parseISO(value.startedAt).getTime()) / 1000 / 60) + ' min',
          date: format(parseISO(value.startedAt), 'dd.MM.yyyy'),
        };
      }),
    [stops]
  );

  const exportData = useMemo(
    () =>
      stops.map((value) => {
        return {
          id: value.id,
          machine: value.machine?.code?.split('.')[4] ?? '',
          line: value.line?.code ?? '',
          machineReason: value.justification?.machineCode ?? '',
          stop: value.justification?.stop ?? '',
          reason: value.justification?.reason ?? '',
          loss: value.justification?.loss ?? '',
          affectAvailability: value.affectAvailability ? 'Sim' : 'Não',
          workShift: t(value.workShift ?? 'NO_SHIFT'),
          user: value.justification?.user ?? '',
          description: value.justification?.description ?? '',
          startTime: format(parseISO(value.startedAt), 'HH:mm:ss.S'),
          endTime: format(parseISO(value.endedAt), 'HH:mm:ss.S'),
          duration:
            Math.floor((parseISO(value.endedAt).getTime() - parseISO(value.startedAt).getTime()) / 1000 / 60) + ' min',
          date: format(parseISO(value.startedAt), 'dd/MM/yyyy HH:mm:ss'),
        };
      }),
    [stops]
  );

  const [tableData, setTableData] = useState<typeof data>(data);
  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');

  useEffect(() => {
    if (isExportData) {
      downloadFile(`historico-parada-${thisDate}`, makeCSVFromTable(attributes, exportData));
      setIsExportData(false);

      if (data.length === dataExportLimit)
        feedback.message({
          animation: 'Error',
          title: 'Limite de dados atingido!',
          subtitle: `Não é possível exportar mais que ${dataExportLimit} linhas de dados.`,
        });
    } else setTableData(data);
  }, [data]);

  return (
    <History>
      <Title data-cy="stop-history">Histórico de Paradas</Title>
      <Filters>
        <Drop
          label="Unidade*"
          name="Unidade"
          value={parameters.filters.factory?.name}
          options={factoryName.data?.factories?.value ?? []}
          onSelect={(factory) =>
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, factory, line: undefined, machine: undefined },
            })
          }
          errorMessage={
            factoryName.data?.factories?.value?.length === 0 ? 'Não existe nenhuma unidade cadastrada.' : undefined
          }
        />
        <Drop
          label="Linha*"
          name="Linha"
          value={parameters.filters.line?.name}
          options={lineName.data?.lines?.value?.map((line) => ({ id: line.id, name: line.code })) ?? []}
          onSelect={(line) =>
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, line, machine: undefined },
            })
          }
          disabled={!parameters.filters.factory?.id}
          errorMessage={lineName.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined}
        />
        <Drop
          label="Máquina"
          name="Máquina"
          value={parameters.filters.machine?.name}
          options={machineName.data?.machines?.value?.map((machine) => ({ id: machine.id, name: machine.code })) ?? []}
          onSelect={(machine) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, machine },
            });
          }}
          disabled={!parameters.filters.line?.id}
          errorMessage={
            machineName.data?.machines?.value?.length === 0 ? 'Não existe nenhuma máquina cadastrada.' : undefined
          }
        />
        <MultiDropdown
          label="Parada"
          name="Parada"
          value={parameters.filters.stopTag ?? []}
          options={stopName.data?.stopTags?.value ?? []}
          onBlur={(stop) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, stopTag: stop.map((el) => el.name) },
            });
          }}
          errorMessage={
            stopName.data?.stopTags?.value?.length === 0 ? 'Não existe nenhuma parada cadastrada.' : undefined
          }
        />
        <MultiDropdown
          label="Perda"
          name="Perda"
          value={parameters.filters.lossTag ?? []}
          options={lossName.data?.stopTags?.value ?? []}
          onBlur={(loss) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, lossTag: loss.map((el) => el.name) },
            });
          }}
          errorMessage={
            lossName.data?.stopTags?.value?.length === 0 ? 'Não existe nenhuma perda cadastrada.' : undefined
          }
        />
        <MultiDropdown
          label="Motivo"
          name="Motivo"
          value={parameters.filters.reasonTag ?? []}
          options={reasonName.data?.stopTags?.value ?? []}
          onBlur={(reason) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, reasonTag: reason.map((el) => el.name) },
            });
          }}
          errorMessage={
            reasonName.data?.stopTags?.value?.length === 0 ? 'Não existe nenhum motivo cadastrado.' : undefined
          }
        />

        <MultiDropdown
          label="Turno"
          name="Turno"
          value={parameters.filters.workShift ?? []}
          options={shiftTimes}
          onBlur={(shifts) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, workShift: shifts.map((el) => el.id as WorkShift) },
            });
          }}
          errorMessage={shiftTimes.length === 0 ? 'Não existe nenhum turno cadastrado.' : undefined}
        />
        <Calendar
          label="Data inicial*"
          placeholder="Data inicial"
          value={format(parameters.filters.startDate, 'yyyy-MM-dd')}
          onChange={(e) => {
            const startDate = parseISO(e.target.value);
            if (e.target.value.length == 10)
              setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, startDate } });
            }
          }
          onBlur={(date) => {
            const startDate = parseISO(date.target.value);
            if (date.target.value.length == 10)
              setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, startDate } });
          }}
        />
        <Calendar
          label="Data final*"
          placeholder="Data final"
          value={format(parameters.filters.endDate, 'yyyy-MM-dd')}
          onChange={(e) => {
            const endDate = parseISO(e.target.value);
            if (e.target.value.length == 10)
              setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, endDate } });
            }
          }
          onBlur={(date) => {
            const endDate = parseISO(date.target.value);
            if (date.target.value.length == 10)
              setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, endDate } });
          }}
        />
      </Filters>
      {parameters.filters.line && (
        <ClearFilter
          onClick={() => {
            setParameters(initialParameters);
            sessionStorage.removeItem('GlobalHistoryFilter');
          }}
        >
          <Icon name="Cross" size="smaller" color="white" />
          <span>Limpar Filtros</span>
        </ClearFilter>
      )}
      {errors?.limitGreaterThenSuperior && (
          <StyledErrorMessage data-cy="field-message" maxWidth='parent'>
            { errors?.limitGreaterThenSuperior}
          </StyledErrorMessage>
      )}
      <Table>
        <HistoryTable attributes={attributes} content={tableData} size={'large'} loading={stopsQuery.fetching && (!isExportData)}/>
      </Table>
      <Footer>
        <PageOptions>
          <Button title="Menu históricos" color="light" icon="ArrowLeft" onClick={() => navigate('..')} />
          <Button title="Exportar" icon="Export" disabled={data.length == 0} onClick={() => setIsExportData(true)} />
        </PageOptions>
        <Pagination
          numberOfItems={parameters.limit}
          onSelectNumber={(number) => {
            setParameters({ ...parameters, page: 1, limit: number });
          }}
          page={parameters.page}
          totalPages={Math.ceil(count / parameters.limit)}
          onPrevious={() => setParameters({ ...parameters, page: parameters.page - 1 })}
          onNext={() => setParameters({ ...parameters, page: parameters.page + 1 })}
        />
      </Footer>

      {isExportData && (
        <LoaderLogo data-cy="loading">
          <MouraLogo>
            <Logo style="onlyIcon" />
          </MouraLogo>
        </LoaderLogo>
      )}
    </History>
  );
};
