{
    "password_recovery": "Esqueci minha senha",
    "SHIFT_A": "Turno A",
    "SHIFT_B": "Turno B",
    "SHIFT_C": "Turno C",
    "NO_SHIFT": "Sem turno",
    "ANGLE": "Ângulo",
    "ANGULAR_SPEED": "Velocidade angular",
    "AREA": "Área",
    "CURRENT": "Corrente",
    "DIMENTIONLESS": "Sem dimensão",
    "ENERGY": "Energia",
    "LENGTH": "Comprimento",
    "LINEAR_SPEED": "Velocidade linear",
    "MASS": "Massa",
    "NO_UNIT": "Sem unidade",
    "UNIT": "Unidade",
    "POWER": "Potência",
    "PRESSURE": "Pressão",
    "TEMPERATURE": "Temperatura",
    "TIME": "Tempo",
    "VOLTAGE": "Tensão",
    "VOLUME": "Volume",
    "ADMIN": "Administrador",
    "PROCESS": "Processo",
    "PRODUCTION": "Produção",
    "SUPERVISOR": "Supervisor",
    "QUALITY": "Qualidade",
    "NO_PROFILE": "Sem Perfil",
    "name": "nome",
    "tagType": "tipo de tag",
    "MEASUREMENT": "Medição",
    "SETPOINT": "Setpoint",
    "NO_TYPE": "Sem tipo",
    "ERROR": {
        "IDEAL_BIGGER_THEN_NORMAL": "Tempo de ciclo normal deve ser maior que o ciclo ideal.",
        "NORMAL_BIGGER_THEN_SMALL_STOP": "Tempo de microparada deve ser maior que o ciclo normal.",
        "SMALL_STOP_BIGGER_THEN_STOP": "Tempo de parada deve ser maior que o tempo de microparada.",
        "INFERIOR_GREATER_THEN_SUPERIOR": "O limite superior deve ser maior que o limite inferior.",
        "PERIOD_BIGGER_THAN": "Para os filtros selecionados escolha um período menor que | dias."
    },
    "NO_SECTOR": "Sem setor",
    "ASSEMBLY": "Montagem",
    "FINISHING": "Acabamento",
    "FORMATION": "Formação",
    "FORCE": "Força",
    "FREQUENCY": "Frequência",
    "ELECTRICAL_RESISTANCE": "Resistência Elétrica"
}