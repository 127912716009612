import { t } from 'i18next';
import { isOptArrayOf, isString, isStruct } from 'ts-runtime-typecheck';
import { CombinedError } from 'urql';
import { FeedbackProps } from '../components/layout/Feedback';

type Message = {
  title: string;
  subtitle?: string;
};

const errorMessages: Record<string, Message> = {
  PERMISSION_DENIED: {
    title: 'Acesso Negado',
    subtitle: 'Você não tem permissão para acessar esta página',
  },
  NOT_AUTHENTICATED: {
    title: 'Você não está Autenticado',
    subtitle: 'Faça o login para acessar esta página',
  },
  ALREADY_EXISTS: {
    title: 'Valor já existe',
    subtitle: 'Já existe um registro com este valor',
  },
  PASSWORD_REPEATED: {
    title: 'A Senha já foi Usada',
    subtitle: 'Use uma senha diferente das últimas 10 usadas',
  },
  RECOVERY_FAILED: {
    title: 'Falha ao recuperar a senha',
    subtitle: 'Verifique se as informações usadas estão corretas',
  },
  UNDOCUMENTED_ERROR: {
    title: 'Erro Desconhecido',
    subtitle: 'Esse erro não foi documentado ainda',
  },
};

const isM3SError = isStruct({ code: isString, description: isString, targets: isOptArrayOf(isString) });
export function errorFromGraphql(combinedError: CombinedError): FeedbackProps {
  const error = combinedError.graphQLErrors[0];
  if (isM3SError(error) && error.code in errorMessages) {
    const { title, subtitle } = errorMessages[error.code] as Message;
    const targets = error.targets?.map((target) => t(target))?.join(', ');
    return {
      animation: 'Error',
      title: title,
      subtitle: `${subtitle}${targets ? ` (${targets})` : ''}`,
    };
  }

  return {
    animation: 'Error',
    title: 'Erro Desconhecido',
    subtitle: combinedError.message,
  };
}
