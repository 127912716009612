import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Forbidden: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2021 9C17.2021 13.1421 13.8443 16.5 9.70215 16.5C7.502 16.5 5.52313 15.5526 4.1512 14.0436C4.20091 14.0218 4.24901 13.9942 4.29458 13.9607L16.1815 5.22025C16.8303 6.3301 17.2021 7.62163 17.2021 9ZM15.299 4.00732L3.406 12.7522C3.35763 12.7877 3.31477 12.828 3.27758 12.8719C2.59495 11.7417 2.20215 10.4167 2.20215 9C2.20215 4.85786 5.56001 1.5 9.70215 1.5C11.9269 1.5 13.9255 2.4687 15.299 4.00732ZM18.7021 9C18.7021 13.9706 14.6727 18 9.70215 18C4.73159 18 0.702148 13.9706 0.702148 9C0.702148 4.02944 4.73159 0 9.70215 0C14.6727 0 18.7021 4.02944 18.7021 9Z"
        fill={color}
      />
    </svg>
  );
};
