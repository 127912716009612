export type ThresholdType = {
  idealCycleTime?: string;
  normalCycle?: string;
  smallStopCycle?: string;
  stopCycle?: string;
};

export type ErrorType = {
  idealBiggerThenNormal: string;
  normalBiggerThenSmallStop: string;
  smallStopBiggerThenStop: string;
};

export const thresholdsValidator = (
  { idealCycleTime, normalCycle, smallStopCycle, stopCycle }: ThresholdType,
  errorMessages: ErrorType
) => {
  const errors = <ErrorType>{};

  if (idealCycleTime && normalCycle && parseFloat(idealCycleTime) >= parseFloat(normalCycle)) {
    errors.idealBiggerThenNormal = errorMessages.idealBiggerThenNormal;
  }
  if (normalCycle && smallStopCycle && parseFloat(normalCycle) >= parseFloat(smallStopCycle)) {
    errors.normalBiggerThenSmallStop = errorMessages.normalBiggerThenSmallStop;
  }
  if (smallStopCycle && stopCycle && parseFloat(smallStopCycle) >= parseFloat(stopCycle)) {
    errors.smallStopBiggerThenStop = errorMessages.smallStopBiggerThenStop;
  }
  return errors;
};
