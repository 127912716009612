import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Open: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.2354 2.82457C9.64961 2.82457 9.9854 2.48878 9.9854 2.07457C9.9854 1.66036 9.64961 1.32457 9.2354 1.32457V2.82457ZM17.3821 9.41883C17.3821 9.00462 17.0463 8.66883 16.6321 8.66883C16.2179 8.66883 15.8821 9.00462 15.8821 9.41883H17.3821ZM8.39191 9.20167C8.09814 9.49368 8.09671 9.96855 8.38873 10.2623C8.68074 10.5561 9.15561 10.5575 9.44938 10.2655L8.39191 9.20167ZM17.6813 1.02539H18.4313C18.4313 0.611177 18.0955 0.275391 17.6813 0.275391V1.02539ZM12.6452 0.275391C12.231 0.275391 11.8952 0.611177 11.8952 1.02539C11.8952 1.4396 12.231 1.77539 12.6452 1.77539V0.275391ZM16.9313 5.90408C16.9313 6.31829 17.2671 6.65408 17.6813 6.65408C18.0955 6.65408 18.4313 6.31829 18.4313 5.90408H16.9313ZM9.2354 1.32457H3.52393V2.82457H9.2354V1.32457ZM0.773926 4.07457V15.0254H2.27393V4.07457H0.773926ZM3.52393 17.7754H14.6321V16.2754H3.52393V17.7754ZM17.3821 15.0254V9.41883H15.8821V15.0254H17.3821ZM9.44938 10.2655L18.21 1.55731L17.1526 0.49347L8.39191 9.20167L9.44938 10.2655ZM17.6813 0.275391H12.6452V1.77539H17.6813V0.275391ZM16.9313 1.02539V5.90408H18.4313V1.02539H16.9313ZM14.6321 17.7754C16.1509 17.7754 17.3821 16.5442 17.3821 15.0254H15.8821C15.8821 15.7157 15.3225 16.2754 14.6321 16.2754V17.7754ZM0.773926 15.0254C0.773926 16.5442 2.00514 17.7754 3.52393 17.7754V16.2754C2.83357 16.2754 2.27393 15.7157 2.27393 15.0254H0.773926ZM3.52393 1.32457C2.00514 1.32457 0.773926 2.55579 0.773926 4.07457H2.27393C2.27393 3.38422 2.83357 2.82457 3.52393 2.82457V1.32457Z"
        fill={color}
      />
    </svg>
  );
};
