import React, { useEffect, useMemo } from 'react';
import { Chart } from '../../components/ui/Chart';
import { useTheme } from 'styled-components';
import { Period, ProductionResponse, useProductionChartQuery, WorkShift } from '../../hooks/graphqlQueries';
import { useOutletContext } from 'react-router-dom';
import { ChartContextType } from '../ChartFilterInterval';
import { formatDateForChart } from '../../scripts/formatDateForGraphics';
import { format, parseISO } from 'date-fns';

type ChartType = 'LINE' | 'BAR' | 'STACKBAR';

type BarTemplate = { x: Array<string>; y: Array<number>; hover: Array<string> };
function newBar() {
  return { x: new Array<string>(), y: new Array<number>(), hover: new Array<string>() } as BarTemplate;
}

function addPoint(bar: BarTemplate, x: string | undefined | null, y: number, hover: string) {
  if (!x) return;
  const prod = Math.round(y * 100) / 100;
  bar.x.push(x);
  bar.y.push(prod);
  bar.hover.push(`<b>${hover}</b>: ${prod}<br>`);
}

const mountStackBarChart = (
  color: { productionColor: string; projectionColor: string; accumulatedColor: string },
  stateData: ProductionResponse[],
  startDate: string,
  endDate: string
) => {
  const accumulated: BarTemplate = newBar();
  const projection: BarTemplate = newBar();
  const production: BarTemplate = newBar();

  if (stateData ?? 0 > 0) {
    for (const state of stateData) {
      addPoint(accumulated, state.day, state.accumulated ?? 0, 'Prod. Acumulada');
      addPoint(projection, state.day, state.projection ?? 0, 'Projeção');
      addPoint(production, state.day, state.production ?? 0, 'Produção');
    }
  }

  const formattedDates = formatDateForChart(production.x, startDate, endDate);

  const chartComposition = [
    {
      x: formattedDates,
      y: production.y,
      textposition: 'auto',
      name: 'Produção',
      width: 0.2,
      hoverData: production.hover,
      hoverLabel: {
        bgcolor: '',
        bordercolor: '',
        font: {
          color: '',
        },
      },
      chartType: 'BAR' as ChartType,
      marker: { color: color.productionColor },
      color: color.productionColor,
    },
    {
      x: formattedDates,
      y: projection.y,
      textposition: 'auto',
      name: 'Projeção',
      width: 0.2,
      hoverData: projection.hover,
      hoverLabel: {
        bgcolor: '',
        bordercolor: '',
        font: {
          color: '',
        },
      },
      chartType: 'BAR' as ChartType,
      marker: { color: color.projectionColor },
      color: color.projectionColor,
    },
    {
      x: formattedDates,
      y: accumulated.y,
      textposition: 'auto',
      name: 'Prod. Acumulada',
      width: 0.2,
      hoverData: accumulated.hover,
      hoverLabel: {
        bgcolor: '',
        bordercolor: '',
        font: {
          color: '',
        },
      },
      chartType: 'BAR' as ChartType,
      marker: { color: color.accumulatedColor },
      color: color.accumulatedColor,
    },
  ];

  return {
    exportData: [
      ['hora', ...production.x.map((time) => format(parseISO(time), 'dd/MM/yyyy HH:mm:ss'))],
      ['produção', ...production.y],
      ['projeção', ...projection.y],
      ['prod. acumulada', ...accumulated.y],
    ],
    chartData: chartComposition,
  };
};

export const ProductionChart: React.FC = () => {
  const { filter, setExportData, isFiltersValid } = useOutletContext<ChartContextType>();
  const theme = useTheme();

  const productionChartColor = useMemo(() => {
    return {
      productionColor: theme.colors.chart.customChart.productionChart.production,
      projectionColor: theme.colors.chart.customChart.productionChart.projection,
      accumulatedColor: theme.colors.chart.customChart.productionChart.accumulated,
    };
  }, [theme]);

  const [stateQuery] = useProductionChartQuery({
    pause: !isFiltersValid.general,
    requestPolicy: 'network-only',
    variables: {
      lineId: filter?.line?.id,
      machineId: filter?.machine?.id,
      workShift: (filter.multipleShifts?.map((s) => s.id) as WorkShift[]) ?? [],
      period: filter.period?.id as Period,
      startDate: filter.startDate,
      endDate: filter.endDate,
    },
  });
  const state = useMemo(() => stateQuery.data?.production, [stateQuery]);
  const data = useMemo(
    () => mountStackBarChart(productionChartColor, state as ProductionResponse[], filter.startDate, filter.endDate),
    [state, productionChartColor]
  );

  useEffect(() => {
    setExportData(data.exportData);
  }, [data]);
 
  return (
    <Chart
      chartType={'BAR'}
      xaxis={{ type: 'category' }}
      data={data.chartData}
      loading={stateQuery.fetching || filter?.loading === true}
    />
  );
};
