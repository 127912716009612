import React from 'react';
import styled from 'styled-components';

const StyledPageOptions = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  flex: 1.1;
  grid-area: Options;
  column-gap: 19px;
  row-gap: 15px;
  justify-content: left;
  align-content: flex-end;

  @media (max-width: 430px) {
    justify-content: center;
  }
`;

type Props = {
  children: React.ReactNode;
};
export const PageOptions: React.FC<Props> = ({ children }) => <StyledPageOptions>{children}</StyledPageOptions>;
