import React, { DragEvent, useMemo } from 'react';
import styled from 'styled-components';
// import './CssPosition.css';

const handleDragStart = (event: DragEvent<HTMLDivElement>) => {
  const element = event.currentTarget as HTMLElement;
  event.dataTransfer.setData('text/plain', element.dataset['id'] as string);
  event.dataTransfer.setData('text/html', element.outerHTML);
};

const handleDragEnd = (
  event: DragEvent<HTMLDivElement>,
  onPositioned: (posicioned: { id: string; line: string; column: string }) => void,
  onUnPositioned: (unPosicioned: { id: string }) => void
) => {
  const element = event.currentTarget as HTMLElement;
  const parent = element.parentNode as HTMLElement;
  // const firstChild = element.firstChild as HTMLElement;

  if (parent.dataset['id'] == 'item-list') {
    element.style.width = '175px';
    element.style.height = '15px';
    element.style.padding = '10px 20px 10px 20px';
    element.style.margin = '5px 0 0 0';
    // firstChild.style.fontSize = '12px';
    onUnPositioned ? onUnPositioned({ id: element.dataset['id'] ?? '' }) : null;
  } else {
    element.style.width = '100%';
    element.style.height = '100%';
    element.style.padding = '0px 0px 0px 0px';
    element.style.margin = '0px 0 0 0';
    // firstChild.style.fontSize = '6px';
    const parentId = (parent.dataset['id'] ?? '').split(',');
    onPositioned
      ? onPositioned({ id: element.dataset['id'] ?? '', line: parentId[0] ?? '', column: parentId[1] ?? '' })
      : null;
  }
};

const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
  event.preventDefault();
  const children = event.currentTarget.firstChild;
  const targetId = event.currentTarget.dataset['id'];

  if (targetId == 'item-list' || !children) event.dataTransfer.dropEffect = 'move';
  else event.dataTransfer.dropEffect = 'none';
};

const handleDrop = (event: DragEvent<HTMLDivElement>) => {
  event.preventDefault();
  const id = event.dataTransfer.getData('text/plain') as string;
  const component = document.querySelector(`[data-id="${id}"]`);

  if (component) event.currentTarget.appendChild(component);
};

const TextWrapStyle = styled.span`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
`;

const Text = styled(TextWrapStyle)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;

  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.121634px;
  color: ${(props) => props.theme.colors.notificationDrawer.message.textColor};
`;

const PositionBoard = styled.div`
  display: grid;
  grid-area: PositionBoard;
  grid-template:
    'ListName GridName' max-content
    'ListItem GridPosition' max-content;
  background-color: #ffff;
  border: 1px solid #585858;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
  width: 100%;
  margin: 30px 0 30px 0;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template:
      'ListName' max-content
      'ListItem' max-content
      'GridName' max-content
      'GridPosition' max-content;
    max-width: 90vw;
  }
`;

const ListName = styled.span`
  display: grid;
  grid-area: ListName;
  font-size: 30px;
  line-height: 15px;
  padding: 30px 30px 30px 30px;
  align-self: center;
  justify-self: center;
  letter-spacing: 0.121634px;
  color: ${(props) => props.theme.colors.notificationDrawer.message.textColor};
`;

const GridName = styled.span`
  display: grid;
  grid-area: GridName;
  font-size: 30px;
  align-self: center;
  justify-self: center;
  padding: 30px 30px 30px 30px;
  letter-spacing: 0.121634px;
  color: ${(props) => props.theme.colors.notificationDrawer.message.textColor};
`;

const ListItem = styled.div`
  display: grid;
  grid-area: ListItem;
  max-width: 245px;
  overflow: hidden;
  background-color: #ffff;
  grid-auto-rows: max-content;
  max-height: 600px;
  padding-top: 10px;
  padding-bottom: 10px;

  overflow-x: hidden;
  overflow-y: scroll;

  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    max-width: 100%;
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.notification.scroll};

  /* Edge, Chrome */
  &&::-webkit-scrollbar {
    width: 5px;
  }

  &&::-webkit-scrollbar-track {
    margin-top: 1px;
    margin-bottom: 2px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.notification.scroll};
    border-radius: 10px;
  }

  @media (max-width: 800px) {
    width: '100%';
    max-height: 100px;
  }
`;

const Item = styled.div`
  display: grid;
  cursor: pointer;
  background-color: rgb(215, 214, 214);
  row-gap: 10px;
  width: 175px;
  word-break: break-all;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  margin: 5px 0 0 0;

  &&:hover {
    background-color: ${(props) => props.theme.colors.notificationDrawer.HoverbackgroundColor};
    border-radius: 5px;
  }
`;

const GridPositionContainer = styled.div`
  display: grid;
  grid-area: GridPosition;
  text-align: center;
  margin: auto;
  background-color: rgb(215, 214, 214);
  border: 1px solid #585858;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
`;

const GridPosition = styled.table`
  border-spacing: 5px;
  margin: auto;
  clear: both;
`;

const Cell = styled.td<{ clicked?: boolean }>`
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: ${(props) => (props.clicked ? '#fff' : '#fff')};
  width: 50px;
  height: 38px;
  padding: 2px 2px 2px 2px;

  &:hover {
    border: 2px solid #888;
  }

  @media (max-width: 800px) {
    width: 40px;
    height: 30px;
  }
`;

const calcPositionFromIndex = (line: number, column: number, grid: Grid, area: Resolution) => {
  const positionX = (area.width / grid.columns) * column;
  const positionY = (area.height / grid.lines) * line;
  return { positionX, positionY };
};
const calcIndexFromPosition = (width: number, height: number, grid: Grid, area: Resolution) => {
  const line = Math.max(Math.round(height / (area.height / grid.lines)), 0);
  const column = Math.max(Math.round(width / (area.width / grid.columns)), 0);
  return { line, column };
};

type Position = { id: string; name?: string; positionX?: number; positionY?: number };

type GroupedPosition = { [key: string]: Position };

type Grid = {
  lines: number;
  columns: number;
};

type Resolution = {
  width: number;
  height: number;
};

type Props = {
  listName: string;
  gridName: string;
  area: Resolution;
  positions: Position[];
  grid: { lines: number; columns: number };
  onPositioned?: (posicioned: Position[]) => void;
};

const groupedIdPosition: GroupedPosition = {};

export const PositionGrid: React.FC<Props> = ({ listName, gridName, area, positions, grid, onPositioned }) => {
  const groupedIndex = useMemo<GroupedPosition>(() => {
    return positions.reduce((result, item) => {
      if (item.positionX !== undefined && item.positionY !== undefined) {
        const position = calcIndexFromPosition(item.positionX, item.positionY, grid, area);
        result[`${position.line},${position.column}`] = item;
      }
      return result;
    }, {} as GroupedPosition);
  }, [positions]);

  const positioned = (positioned: { id: string; line: string; column: string }) => {
    const calcPosition = calcPositionFromIndex(parseInt(positioned.line), parseInt(positioned.column), grid, area);
    groupedIdPosition[positioned.id] = {
      ...(groupedIdPosition[positioned.id] as Position),
      id: positioned.id,
      positionX: calcPosition.positionX,
      positionY: calcPosition.positionY,
    };
    onPositioned ? onPositioned(Object.values(groupedIdPosition)) : null;
  };

  const unPositioned = (unPositioned: { id: string }) => {
    groupedIdPosition[unPositioned.id] = {
      ...(groupedIdPosition[unPositioned.id] as Position),
      id: unPositioned.id,
      positionX: undefined,
      positionY: undefined,
    };
    onPositioned ? onPositioned(Object.values(groupedIdPosition)) : null;
  };

  return (
    <PositionBoard>
      <ListName>{listName}</ListName>
      <ListItem onDragOver={handleDragOver} onDrop={handleDrop} data-id={`item-list`}>
        {positions
          .filter((value) => value.positionY === undefined && value.positionX === undefined)
          .map((item, i) => (
            <Item
              key={item.id}
              draggable
              onDragStart={handleDragStart}
              onDragEnd={(event) => handleDragEnd(event, positioned, unPositioned)}
              data-id={item.id}
            >
              <Text>{`${item.name}-${i}`}</Text>
            </Item>
          ))}
      </ListItem>
      <GridName>{gridName}</GridName>
      <GridPositionContainer>
        <GridPosition>
          <tbody>
            {new Array<boolean[]>(grid.lines).fill(new Array<boolean>(grid.columns).fill(false)).map((cells, j) => (
              <tr key={`${j}`}>
                {cells.map((cell, i) => (
                  <Cell
                    key={`${j},${i}`}
                    clicked={cell}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    data-id={`${j},${i}`}
                  >
                    {groupedIndex[`${j},${i}`] ? (
                      <Item
                        style={{ width: '100%', height: '100%', padding: '0px 0px 0px 0px', margin: '0px 0 0 0' }}
                        key={(groupedIndex[`${j},${i}`] as Position).id}
                        draggable
                        onDragStart={handleDragStart}
                        onDragEnd={(event) => handleDragEnd(event, positioned, unPositioned)}
                        data-id={(groupedIndex[`${j},${i}`] as Position).id}
                      >
                        <Text>{`${(groupedIndex[`${j},${i}`] as Position).name}`}</Text>
                      </Item>
                    ) : null}
                  </Cell>
                ))}
              </tr>
            ))}
          </tbody>
        </GridPosition>
      </GridPositionContainer>
    </PositionBoard>
  );
};
