import md5 from 'md5';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { MachineRegistration } from '../../components/layout/MachineRegistration';
import { Button } from '../../components/ui/Button';
import { Drop } from '../../components/ui/Drop';
import { MultiDropdown } from '../../components/ui/MultiDropdown';
import { Input } from '../../components/ui/Input';
import { ListComponent } from '../../components/ui/ListComponent';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import {
  Unit,
  useLineTitleListQuery,
  useRegisterMachineMutation,
  useScrapDefectNameQuery,
} from '../../hooks/graphqlQueries';
import { TagItem } from './TagItem';
import { TagTemplate } from './TagTemplate';
import { thresholdsValidator, ErrorType } from '../../scripts/thresholdsValidator';
import { formatStringNumber } from '../../scripts/formatStringNumber';
import { Checkbox } from '../../components/ui/Checkbox';
import { isEnabledAction } from '../../scripts/objectHelper';
import { PageOptions } from '../../components/layout/PageOptions';
import { Footer } from '../../components/layout/Footer';

const InfoDetails = styled.div`
  display: grid;
  grid-area: InfoDetails;
  grid-template-columns: 1fr 1fr;
  row-gap: 19px;
  column-gap: 19px;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    grid-template-columns: 100vw;
    justify-content: center;
    align-items: center;
  }
`;

const Divider = styled.hr`
  grid-area: Divider;
  border-width: 3px 0px 0px 0px;
  border-top: 3px solid #ffff;
  width: 100%;
  margin: 15px 0px 15px 0px;
`;

const InfoTags = styled.div`
  display: grid;
  grid-area: InfoTags;
  grid-template-columns: 1fr;
  row-gap: 19px;
  column-gap: 10px;
  align-items: end;

  @media (max-width: 800px) {
    justify-content: center;
    align-items: center;
  }
`;

type Machine = {
  name: string;
  code: string;
  line: string;
  lineId: string;
  description: string;
  unit: Unit;
  idealCycleTime: string;
  normalCycle: string;
  smallStopCycle: string;
  stopCycle: string;
  positionX?: number;
  positionY?: number;
  possibleProblems: { stop: string; reason: string; loss: string }[];
  possibleDefects: string[] | undefined;
  mandatoryJustification: boolean;
};

function createMachineInfo() {
  return {
    name: '',
    code: '',
    line: '',
    lineId: '',
    unit: Unit.NoUnit,
    description: '',
    idealCycleTime: '',
    normalCycle: '',
    smallStopCycle: '',
    stopCycle: '',
    possibleProblems: [],
    possibleDefects: undefined,
    mandatoryJustification: false,
  };
}

export const MachineRegistrationPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const feedback = useFeedback();
  const location = useLocation();

  const routeData = location.state as { data: Machine; pagination: { page: number; limit: number } };
  const [lineOptions] = useLineTitleListQuery();
  const [, registerMachine] = useRegisterMachineMutation();
  const [errors, setErrors] = useState<ErrorType>();
  const [defects] = useScrapDefectNameQuery({ requestPolicy: 'network-only' });

  const [disableSave, setDisableSave] = useState(true);
  const [info, setInfo] = useState<Machine>(createMachineInfo());
  const problems = useMemo(() => {
    return (
      info.possibleProblems?.map((problem) => {
        return {
          id: md5(problem.stop + problem.loss + problem.reason),
          data: { stop: problem.stop, loss: problem.loss, reason: problem.reason },
        };
      }) ?? []
    );
  }, [info.possibleProblems.length]);

  useEffect(() => {
    const result = thresholdsValidator(
      {
        idealCycleTime: info.idealCycleTime,
        normalCycle: info.normalCycle,
        smallStopCycle: info.smallStopCycle,
        stopCycle: info.stopCycle,
      },
      {
        idealBiggerThenNormal: t('ERROR.IDEAL_BIGGER_THEN_NORMAL'),
        normalBiggerThenSmallStop: t('ERROR.NORMAL_BIGGER_THEN_SMALL_STOP'),
        smallStopBiggerThenStop: t('ERROR.SMALL_STOP_BIGGER_THEN_STOP'),
      }
    );

    setErrors(result);

    if (Object.values(result).length > 0) {
      setDisableSave(true);
    } else {
      isEnabledAction(
        info,
        (result) => {
          setDisableSave(!result);
        },
        { ignoreFields: ['runtimeOptions', 'possibleProblems', 'possibleDefects', 'positionX', 'positionY'] }
      );
    }
  }, [info]);

  const handleSave = () => {
    const registerInfo = {
      lineId: info.lineId,
      name: info.name,
      code: info.code,
      description: info.description,
      idealCycleTime: parseFloat(info.idealCycleTime),
      unit: info.unit,
      positionX: info.positionX,
      positionY: info.positionY,
      possibleProblems: info.possibleProblems,
      smallStopCycle: parseFloat(info.smallStopCycle),
      normalCycle: parseFloat(info.normalCycle),
      stopCycle: parseFloat(info.stopCycle),
      possibleDefects: info.possibleDefects,
      mandatoryJustification: info.mandatoryJustification,
    };

    const dialog = () => {
      feedback.confirmation({
        title: 'Deseja cadastrar outra máquina?',
        onHandle: (result) => {
          result ? setInfo(createMachineInfo()) : navigate('..', { state: { pagination: routeData?.pagination } });
        },
      });
    };

    registerMachine(registerInfo)
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.createMachine === null)
          feedback.message({ animation: 'Error', title: 'Erro ao cadastrar máquina' });
        else
          feedback.message({ animation: 'Confetti', title: 'Máquina cadastrada com sucesso', onClose: () => dialog() });
      })
      .catch(() => {
        feedback.message({ animation: 'Error', title: 'Erro ao cadastrar máquina' });
      });
  };

  return (
    <MachineRegistration>
      <Title>Cadastrar Máquina</Title>
      <InfoDetails>
        <Input
          dataCy="name-field"
          label="Nome da Máquina"
          value={info.name}
          width="376px"
          onChange={(e) => setInfo({ ...info, name: e.target.value })}
        />
        <Input
          dataCy="code-field"
          label="TAG"
          value={info.code}
          width="376px"
          onChange={(e) => setInfo({ ...info, code: e.target.value })}
        />
        <Input
          dataCy="description-field"
          label="Descrição"
          maxLength={100}
          value={info.description}
          width="376px"
          onChange={(e) => setInfo({ ...info, description: e.target.value })}
        />
        <Input
          dataCy="ideal-cycle-field"
          label="Tempo de Ciclo Ideal (s)"
          value={info.idealCycleTime.toString()}
          width="376px"
          onChange={(e) => {
            const valueAsNumber = formatStringNumber(e.target.value);
            setInfo({ ...info, idealCycleTime: valueAsNumber });
          }}
        />
        <Input
          dataCy="small-stop-cycle-field"
          label="Tempo de microparada  (s)"
          value={info.smallStopCycle.toString()}
          width="376px"
          onChange={(e) => {
            const valueAsNumber = formatStringNumber(e.target.value);
            setInfo({ ...info, smallStopCycle: valueAsNumber });
          }}
          errorMessage={errors?.normalBiggerThenSmallStop ? true : false}
          message={errors?.normalBiggerThenSmallStop}
        />
        <Input
          dataCy="stop-cycle-field"
          label="Tempo de parada  (s)"
          value={info.stopCycle.toString()}
          width="376px"
          onChange={(e) => {
            const valueAsNumber = formatStringNumber(e.target.value);
            setInfo({ ...info, stopCycle: valueAsNumber });
          }}
          errorMessage={errors?.smallStopBiggerThenStop ? true : false}
          message={errors?.smallStopBiggerThenStop}
        />
        <Input
          dataCy="normal-cycle-field"
          label="Tempo de Ciclo Normal  (s)"
          value={info.normalCycle.toString()}
          width="376px"
          onChange={(e) => {
            const valueAsNumber = formatStringNumber(e.target.value);
            setInfo({ ...info, normalCycle: valueAsNumber });
          }}
          renderMessage
          errorMessage={errors?.idealBiggerThenNormal ? true : false}
          message={errors?.idealBiggerThenNormal}
        />
        <div></div>
        <Drop
          dataCy="line-drop"
          name={'Linha'}
          label={'Linha'}
          size={'large'}
          value={info.line}
          options={lineOptions.data?.lines?.value?.map((line) => ({ id: line.id, name: line.code })) ?? []}
          onSelect={(line) => setInfo({ ...info, line: line.name, lineId: line.id })}
          errorMessage={
            lineOptions.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined
          }
        />
        <Checkbox
          dataCy="mandatoty-stops"
          size="large"
          label="Notificar Paradas para esta Máquina"
          value={info.mandatoryJustification}
          onChange={(check) => {
            setInfo({ ...info, mandatoryJustification: !check });
          }}
        />
        <Drop
          dataCy="unit-drop"
          name=""
          label={'Unidade de medida'}
          size={'large'}
          value={t(info.unit)}
          required
          options={Object.keys(Unit).map((value) => ({ id: value, name: t(Unit[value as keyof typeof Unit]) }))}
          onSelect={(unit) => setInfo({ ...info, unit: Unit[unit.id as keyof typeof Unit] })}
          errorMessage={Object.keys(Unit).length === 0 ? 'Não existe nenhuma unidade de medida cadastrada.' : undefined}
        />
        <MultiDropdown
          dataCy="defect-drop"
          name="Defeito"
          label="Possíveis defeitos"
          value={info.possibleDefects ?? []}
          size={'large'}
          options={defects.data?.scrapPossibleFailures?.value ?? []}
          onBlur={(options) => {
            setInfo({ ...info, possibleDefects: options.map((defect) => defect.name) });
          }}
        />
      </InfoDetails>
      <Divider />
      <InfoTags>
        <ListComponent
          item={TagItem}
          template={TagTemplate}
          values={problems}
          onDeleteItem={(id) => {
            const updated = problems;
            const index = updated.findIndex((e) => e.id === id);
            if (index === -1) return;
            else {
              updated.splice(index, 1);
              setInfo({ ...info, possibleProblems: updated.map(({ data }) => data) });
            }
          }}
          onAddItem={(data) => {
            const updated = info.possibleProblems;
            const id = md5(data.stop + data.loss + data.reason);
            if (problems.findIndex((e) => e.id === id) === -1) {
              updated.push(data);
              setInfo({ ...info, possibleProblems: updated });
            }
          }}
        />
      </InfoTags>
      <Footer>
        <PageOptions>
          <Button
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button
            dataCy="register-button"
            title="Salvar"
            color="primary"
            disabled={disableSave}
            onClick={() => handleSave()}
          />
        </PageOptions>
      </Footer>
    </MachineRegistration>
  );
};
