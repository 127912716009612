import React, { useCallback, useRef, useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { useOutsideClick } from '../../../hooks/outsideClick';
import { Icon } from '../../ui/Icon';

const Container = styled.div<{ hidden: boolean; isOutSideClick: boolean; zIndex: number }>`
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.zIndex};
  background-color: ${(props) =>
    props.isOutSideClick ? props.theme.colors.popup.opacityBackground : props.theme.colors.popup.background};
  justify-content: center;
  align-items: center;
`;

const Center = styled.div`
  display: flex;
  position: absolute;
  background: ${(props) => props.theme.colors.popup.centerBackground};
  border-radius: 15px;
`;

const CloseButton = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 39.83px;
  height: 40px;
  left: calc(100% - 64px);
  top: calc(0% + 20px);
  opacity: 0.5;

  &&:hover {
    cursor: pointer;
  }

  @media (max-width: 620px) {
    left: calc(100% - 50px);
    top: calc(0% + 10px);
  }
`;

type Props = {
  isOutSideClick?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  zIndex?: number;
};

export const Popup: React.FC<Props> = ({ isOutSideClick = false, onClose, children, zIndex = 7 }) => {
  const theme = useTheme();
  const [hidden, setHidden] = useState(false);
  const [iconColor, setIconColor] = useState(theme.colors.popup.closeButton);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (hidden) {
      onClose ? onClose() : null;
    }
  }, [hidden]);

  if (isOutSideClick) {
    useOutsideClick(
      wrapperRef,
      useCallback(() => setHidden(true), [setHidden])
    );
  }

  return (
    <Container hidden={hidden} isOutSideClick={isOutSideClick} zIndex={zIndex}>
      <Center ref={wrapperRef}>
        {!isOutSideClick ? (
          <CloseButton
            onMouseOver={() => setIconColor(theme.colors.popup.closeButtonHover)}
            onMouseLeave={() => setIconColor(theme.colors.popup.closeButton)}
            onClick={() => setHidden(true)}
          >
            <Icon name={'Stop'} size="xlarge" color={iconColor} />
          </CloseButton>
        ) : null}
        {children}
      </Center>
    </Container>
  );
};
