import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Overview: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.101.583C5.351.583.695 5.25.695 11S5.35 21.416 11.1 21.416c5.76 0 10.427-4.666 10.427-10.416S16.862.583 11.101.583Zm7.219 6.25h-3.073a16.3 16.3 0 0 0-1.438-3.708 8.364 8.364 0 0 1 4.51 3.708ZM11.11 2.708a14.673 14.673 0 0 1 1.99 4.125h-3.98a14.673 14.673 0 0 1 1.99-4.125ZM3.05 13.083A8.585 8.585 0 0 1 2.779 11c0-.72.103-1.417.27-2.084h3.52A17.204 17.204 0 0 0 6.425 11c0 .708.062 1.396.146 2.083H3.049Zm.854 2.083h3.073a16.303 16.303 0 0 0 1.438 3.709 8.32 8.32 0 0 1-4.51-3.709Zm3.073-8.333H3.903a8.32 8.32 0 0 1 4.51-3.708 16.301 16.301 0 0 0-1.437 3.708Zm4.135 12.458a14.673 14.673 0 0 1-1.99-4.125h3.98a14.673 14.673 0 0 1-1.99 4.125Zm2.438-6.208H8.674A15.326 15.326 0 0 1 8.507 11c0-.709.073-1.407.167-2.084h4.875A15.2 15.2 0 0 1 13.716 11c0 .708-.073 1.396-.167 2.083Zm.26 5.792a16.3 16.3 0 0 0 1.438-3.709h3.073a8.364 8.364 0 0 1-4.51 3.709Zm1.844-5.792A17.21 17.21 0 0 0 15.8 11c0-.709-.063-1.396-.146-2.084h3.521c.167.667.27 1.365.27 2.084 0 .718-.103 1.416-.27 2.083h-3.52Z"
        fill={color}
      />
    </svg>
  );
};
