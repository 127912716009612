import React from 'react';
import styled from 'styled-components';
import { MachineStatus } from '../../../hooks/graphqlQueries';
import { colorOfState, StatesWithColor } from '../../../scripts/colorOfState';
import { Icon, IconNames } from '../Icon';

const iconOfState: Record<StatesWithColor, IconNames> = {
  NORMAL: 'Check',
  SLOW: 'Minus',
  SMALL_STOP: 'Cross',
  STOP: 'Cross',
  PAUSED: 'Forbidden',
  NOT_MONITORED: 'Forbidden',
  STARTUP: 'Check',
};

const Container = styled.div`
  position: relative;
  overflow-x: scroll;
  scrollbar-width: none;
  &&::-webkit-scrollbar {
    display: none;
  }
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  color: ${(props) => props.theme.colors.table.colorBody};
  text-align: center;
`;

const StyledTable = styled.table`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-size: 16px;
  text-align: center;
  min-width: 400px;
  border: 1px solid ${(props) => props.theme.colors.table.colorBody};
  border-collapse: collapse;
  border-spacing: 0px;
`;

const StyledHeader = styled.tr`
  line-height: 29px;
  color: ${(props) => props.theme.colors.table.colorTitle};
  background-color: ${(props) => props.theme.colors.table.border};
  th {
    padding: 0px 8px 0px 8px;
    font-weight: normal;
    min-width: 50px;
  }
`;

const StyledRow = styled.tr`
  height: 25px;
  word-break: break-all;
  border-top: 1px solid ${(props) => props.theme.colors.table.colorBody};
  border-bottom: 1px solid ${(props) => props.theme.colors.table.colorBody};
  th {
    font-weight: normal;
    color: ${(props) => props.theme.colors.table.colorTitle};
    background-color: ${(props) => props.theme.colors.table.border};
    border-right: 1px solid ${(props) => props.theme.colors.table.colorBody};
  }
  td {
    padding: 0px 8px 0px 8px;
  }
`;

const StyledTitle = styled.caption`
  line-height: 29px;
  color: ${(props) => props.theme.colors.table.border};
  background-color: ${(props) => props.theme.colors.table.colorTitle};
  padding: 0px 8px 0px 8px;
  font-weight: normal;
`;

const IconFrame = styled.div<{ status: MachineStatus }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 2;
  top: 20px;
  left: 335px;
  width: 38px;
  height: 38px;
  background-color: ${(props) => colorOfState(props.theme)[props.status]};
  border: 1px solid ${(props) => props.theme.colors.icon.foreground};
  border-radius: 30px;
  filter: drop-shadow(0px 7px 20px rgba(50, 50, 0, 0.5));
`;

type ShiftData = {
  oee: number;
  production: number;
  projection: number;
};

type OverviewData = {
  lineCode: string;
  status?: MachineStatus;
  currentShift?: ShiftData;
  previousShift?: ShiftData;
  lastShift?: ShiftData;
  day?: ShiftData;
  month?: ShiftData;
};

const emptyShift = { oee: 0, production: 0, projection: 0 };

export const OverviewTable: React.FC<OverviewData> = ({
  lineCode,
  status = MachineStatus.NotMonitored,
  currentShift = emptyShift,
  previousShift = emptyShift,
  lastShift = emptyShift,
  day = emptyShift,
  month = emptyShift,
}) => {
  return (
    <Container>
      <IconFrame status={status}>
        <Icon name={iconOfState[status]} size="small" />
      </IconFrame>
      <StyledTable>
        <StyledTitle>{lineCode}</StyledTitle>
        <thead>
          <StyledHeader>
            <th>Período</th>
            <th>OEE</th>
            <th>Produção / Projeção</th>
          </StyledHeader>
        </thead>
        <tbody>
          <StyledRow>
            <th>Turno Atual</th>
            <td>{`${(currentShift.oee * 100).toLocaleString(window.navigator.language, {
              maximumFractionDigits: 2,
              minimumIntegerDigits: 1,
              useGrouping: false,
            })}%`}</td>
            <td>{`${currentShift.production} / ${currentShift.projection.toLocaleString(window.navigator.language, {
              maximumFractionDigits: 2,
              minimumIntegerDigits: 1,
              useGrouping: false,
            })}`}</td>
          </StyledRow>
          <StyledRow>
            <th>Turno Anterior</th>
            <td>{`${(previousShift.oee * 100).toLocaleString(window.navigator.language, {
              maximumFractionDigits: 2,
              minimumIntegerDigits: 1,
              useGrouping: false,
            })}%`}</td>
            <td>{`${previousShift.production} / ${previousShift.projection.toLocaleString(window.navigator.language, {
              maximumFractionDigits: 2,
              minimumIntegerDigits: 1,
              useGrouping: false,
            })}`}</td>
          </StyledRow>
          <StyledRow>
            <th>Último Turno</th>
            <td>{`${(lastShift.oee * 100).toLocaleString(window.navigator.language, {
              maximumFractionDigits: 2,
              minimumIntegerDigits: 1,
              useGrouping: false,
            })}%`}</td>
            <td>{`${lastShift.production} / ${lastShift.projection.toLocaleString(window.navigator.language, {
              maximumFractionDigits: 2,
              minimumIntegerDigits: 1,
              useGrouping: false,
            })}`}</td>
          </StyledRow>
          <StyledRow>
            <th>Dia</th>
            <td>{`${(day.oee * 100).toLocaleString(window.navigator.language, {
              maximumFractionDigits: 2,
              minimumIntegerDigits: 1,
              useGrouping: false,
            })}%`}</td>
            <td>{`${day.production} / ${day.projection.toLocaleString(window.navigator.language, {
              maximumFractionDigits: 2,
              minimumIntegerDigits: 1,
              useGrouping: false,
            })}`}</td>
          </StyledRow>
          <StyledRow>
            <th>Mês</th>
            <td>{`${(month.oee * 100).toLocaleString(window.navigator.language, {
              maximumFractionDigits: 2,
              minimumIntegerDigits: 1,
              useGrouping: false,
            })}%`}</td>
            <td>{`${month.production} / ${month.projection.toLocaleString(window.navigator.language, {
              maximumFractionDigits: 2,
              minimumIntegerDigits: 1,
              useGrouping: false,
            })}`}</td>
          </StyledRow>
        </tbody>
      </StyledTable>
    </Container>
  );
};
