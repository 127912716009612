import { DefaultTheme } from 'styled-components';

/**
 * Generates a mapping from the theme to colors.
 * @param theme Current theme being used, from styled-components
 * @returns A mapping from the speed value to the correspondent color from
 * the theme.
 */
export function colorOfState(theme: DefaultTheme) {
  return {
    SMALL_STOP: theme.colors.diagram.status.slow,
    STOP: theme.colors.diagram.status.stopped,
    NOT_MONITORED: theme.colors.diagram.status.disabled,
    PAUSED: theme.colors.diagram.status.disabled,
    NORMAL: theme.colors.diagram.status.normal,
    STARTUP: theme.colors.diagram.status.normal,
    SLOW: theme.colors.diagram.status.slow,
  };
}

/**
 * All states of machines that have a color in the diagram.
 */
export type StatesWithColor = keyof ReturnType<typeof colorOfState>;
