import React, { useEffect, useMemo, useState } from 'react';
import { format, isWithinInterval, parseISO } from 'date-fns';
import { Title } from '../components/ui/Title';
import styled from 'styled-components';
import { Button } from '../components/ui/Button';
import { InputMultiline } from '../components/ui/InputMultiline/InputMultiline';
import { Drop } from '../components/ui/Drop';
import {
  useScrapUserNameQuery,
  useMachinesQuery,
  useJustifyStopMutation,
  JustificationState,
  useSpecificNotificationStopQuery,
  useWorkshiftAtQuery,
  useLastMachineIdQuery,
} from '../hooks/graphqlQueries';
import { useFeedback } from '../context/feedback';
import { useLocation, useNavigate } from 'react-router-dom';
import { StopJustification } from '../components/layout/StopJustification';
import { Checkbox } from '../components/ui/Checkbox';
import { formatDurationFromInterval, formatDurationToNow } from '../scripts/calendar';
import { MouraLogo } from '../components/layout/Loading/Loading';
import { Logo } from '../components/ui/Logo';
import { Footer } from '../components/layout/Footer';
import { PageOptions } from '../components/layout/PageOptions';

const InfoContainer = styled.div`
  grid-area: Info;
`;

const DropContainer = styled.div`
  position: relative;
  display: grid;
  grid-area: Drop;
  grid-template-columns: repeat(auto-fit, minmax(179px, max-content));
  column-gap: 15px;
  row-gap: 15px;
  padding-top: 3%;
  justify-content: space-evenly;
`;

const Name = styled.div`
  display: flex;
  grid-area: Name;
  align-items: center;
  justify-content: left;

  @media (max-width: 660px) {
    justify-content: center;
    align-items: center;
  }
`;

const StopInfo = styled.span`
  display: flex;
  word-break: break-all;
  width: 100%;
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  color: ${(props) => props.theme.colors.title.color};
  gap: 5px;

  span {
    opacity: 0.8;
  }
`;

const Tag = styled.span`
  display: flex;
  width: 200px;
  padding: 5px;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: ${(props) => props.theme.font};
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.button.primary.background};
  color: ${(props) => props.theme.colors.button.primary.text};
`;

export const Loader = styled.div`
  display: flex;
  height: 70vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

type Stop = {
  id: string;
  lineId: string;
  lineCode: string;
  machine?: string;
  machineCode?: string;
  stopTag?: string;
  reasonTag?: string;
  lossTag?: string;
  user?: string;
  description?: string;
  affectAvailability?: boolean;
  startedAt: string;
  endedAt?: string;
  state: JustificationState;
};

const emptyParameters = {
  machine: '',
  machineCode: '',
  stopTag: '',
  reasonTag: '',
  lossTag: '',
  user: localStorage.getItem('xxxx_user') ?? '',
  description: '',
  affectAvailability: true,
};

export const StopJustificationPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const feedback = useFeedback();

  const [notificationStopQuery] = useSpecificNotificationStopQuery({
    requestPolicy: 'network-only',
    variables: { id: (location.state as { id: string }).id },
  });

  const notificationStop = useMemo(() => notificationStopQuery.data?.justifiableStop, [notificationStopQuery.data]);

  const [lastMachineQuery] = useLastMachineIdQuery({
    requestPolicy: 'network-only',
    variables: { id: notificationStop?.lineId ?? '' },
  });

  const lastMachine = useMemo(() => lastMachineQuery.data?.line, [lastMachineQuery.data]);

  const [stopToJustify, setStopToJustify] = useState({
    ...emptyParameters,
    ...(notificationStop as Stop),
    machine: notificationStop?.machine?.id ?? '',
    machineCode: notificationStop?.machine?.code,
  });

  useEffect(() => {
    setStopToJustify({
      ...emptyParameters,
      ...(notificationStop as Stop),
      machine: notificationStop?.machine?.id ?? '',
      machineCode: notificationStop?.machine?.code,
    });
  }, [notificationStop]);

  const [disableRegister, setDisableRegister] = useState(true);

  const [machineName] = useMachinesQuery({
    variables: { lineId: stopToJustify.lineId },
    requestPolicy: 'network-only',
  });

  const machineProblems = useMemo(
    () => machineName.data?.machines?.value?.find((e) => e.id === stopToJustify.machine)?.possibleProblems,
    [machineName, stopToJustify.machine]
  );

  const stopName = useMemo(() => {
    const stops: { id: string; name: string }[] = [];

    machineProblems?.forEach((value) => {
      const stop = { id: value.stop, name: value.stop };
      if (stops.findIndex((e) => e.id === stop.id) === -1) stops.push(stop);
    });

    return stops;
  }, [machineProblems]);

  const lossName = useMemo(() => {
    const losses: { id: string; name: string }[] = [];

    machineProblems?.forEach((value) => {
      if (value.stop === stopToJustify.stopTag) {
        const loss = { id: value.loss, name: value.loss };
        if (losses.findIndex((e) => e.id === loss.id) === -1) losses.push(loss);
      }
    });

    return losses;
  }, [stopToJustify.stopTag]);

  const reasonName = useMemo(() => {
    const reasons: { id: string; name: string }[] = [];

    machineProblems?.forEach((value) => {
      if (value.stop === stopToJustify.stopTag && value.loss === stopToJustify.lossTag) {
        const reason = { id: value.reason, name: value.reason };
        if (reasons.findIndex((e) => e.id === reason.id) === -1) reasons.push(reason);
      }
    });

    return reasons;
  }, [stopToJustify.lossTag]);

  const [userName] = useScrapUserNameQuery({
    requestPolicy: 'network-only',
    variables: { idLine: stopToJustify.lineId ?? '' },
  });
  const [, executeMutationJustifyStop] = useJustifyStopMutation();

  const handleSave = () => {
    executeMutationJustifyStop(stopToJustify)
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.justifyStop === null) {
          feedback.message({
            animation: 'Error',
            title: 'Erro ao justificar parada',
          });
        } else {
          feedback.message({
            animation: 'Confetti',
            title: 'Parada justificada com sucesso',
          });

          navigate('..');
        }
      })
      .catch(() => {
        feedback.message({
          animation: 'Error',
          title: 'Erro ao justificar parada',
        });
      });
  };

  useEffect(() => {
    setDisableRegister(() => {
      if (JustificationState.Expired === stopToJustify.state) return true;
      return Object.values(stopToJustify).some((parameter) => {
        if (typeof parameter === 'string') return parameter.replace(/\s/g, '') === '';
        else return false;
      });
    });
  }, [stopToJustify]);

  const startDateTime = useMemo(
    () => (stopToJustify.startedAt ? format(parseISO(stopToJustify.startedAt), 'dd/MM/yyyy HH:mm:ss') : ''),
    [stopToJustify.startedAt]
  );

  const endDateTime = useMemo(
    () => (stopToJustify.endedAt ? format(parseISO(stopToJustify.endedAt), 'dd/MM/yyyy HH:mm:ss') : 'Em Andamento'),
    [stopToJustify.endedAt]
  );

  const [workshiftAtQuery] = useWorkshiftAtQuery({
    requestPolicy: 'network-only',
  });

  const workshifitAt = useMemo(() => workshiftAtQuery.data?.workshiftAt, [workshiftAtQuery.data]);

  const affectAvailabilityIsBlocked = useMemo(() => {
    if (workshifitAt && stopToJustify.startedAt)
      return isWithinInterval(parseISO(stopToJustify.startedAt), {
        start: parseISO(workshifitAt?.startingAt),
        end: parseISO(workshifitAt?.endingAt),
      });
    else return true;
  }, [workshifitAt, stopToJustify.startedAt]);

  return (
    <StopJustification>
      {notificationStop ? (
        <>
          <Title data-cy="justification-page">Justificar Parada</Title>
          {JustificationState.Expired === stopToJustify.state ? (
            <InfoContainer>
              <StopInfo data-cy="line-info">A parada selecionada foi expirada, não é possível justificá-la.</StopInfo>
            </InfoContainer>
          ) : (
            <InfoContainer>
              <Tag>
                {notificationStop.machine?.id === lastMachine?.lastMachineId ? 'Parada de Linha' : 'Parada de Máquina'}
              </Tag>
              <StopInfo data-cy="line-info">
                <strong>Máquina:</strong>
                <span>{notificationStop.machine?.code}</span>
              </StopInfo>
              <StopInfo data-cy="start-info">
                <strong>Início:</strong>
                <span>{startDateTime}</span>
              </StopInfo>
              <StopInfo data-cy="end-info">
                <strong>Fim:</strong>
                <span>{endDateTime}</span>
              </StopInfo>
              <StopInfo data-cy="duration-info">
                <strong>Duração:</strong>
                <span>
                  {stopToJustify.endedAt
                    ? formatDurationFromInterval(stopToJustify.startedAt, stopToJustify.endedAt)
                    : formatDurationToNow(stopToJustify.startedAt)}
                </span>
              </StopInfo>
            </InfoContainer>
          )}
          <DropContainer>
            <Drop
              dataCy="machine-drop"
              name="Máquina"
              value={stopToJustify.machineCode ?? notificationStop.machine?.code}
              options={
                machineName.data?.machines?.value?.map((machine) => ({
                  id: machine.id,
                  name: machine.code,
                })) ?? []
              }
              onSelect={(machine) => {
                setStopToJustify((previousValue) => ({
                  ...previousValue,
                  machine: machine.id,
                  machineCode: machine.name,
                  stopTag: '',
                  reasonTag: '',
                  lossTag: '',
                }));
              }}
              errorMessage={
                machineName.data?.machines?.value?.length === 0 ? 'Não existe nenhuma máquina cadastrada.' : undefined
              }
            />
            <Drop
              dataCy="stop-drop"
              name="Parada"
              value={stopToJustify.stopTag ?? ''}
              options={stopName ?? []}
              onSelect={(stop) => {
                setStopToJustify((previousValue) => ({
                  ...previousValue,
                  stopTag: stop.name,
                  reasonTag: '',
                  lossTag: '',
                }));
              }}
            />
            <Drop
              dataCy="loss-drop"
              name="Perda"
              value={stopToJustify.lossTag ?? ''}
              options={lossName ?? []}
              onSelect={(loss) => {
                setStopToJustify((previousValue) => ({ ...previousValue, lossTag: loss.name, reasonTag: '' }));
              }}
            />
            <Drop
              dataCy="reason-drop"
              name="Motivo"
              value={stopToJustify.reasonTag ?? ''}
              options={reasonName ?? []}
              onSelect={(reason) => {
                setStopToJustify((previousValue) => ({ ...previousValue, reasonTag: reason.name }));
              }}
            />

            <Checkbox
              label={'Afeta disponibilidade'}
              ondisabledMessage={() => {
                feedback.message({
                  animation: 'Error',
                  title: 'Indisponível',
                  subtitle: 'Está opção só pode ser alterada para paradas aconteceram em turno diferente deste.',
                });
              }}
              disabled={affectAvailabilityIsBlocked}
              value={stopToJustify.affectAvailability}
              onChange={() =>
                setStopToJustify({ ...stopToJustify, affectAvailability: !stopToJustify.affectAvailability })
              }
            />
          </DropContainer>

          <InputMultiline
            dataCy="description-input"
            placeholder="Descrição..."
            area="Desc"
            width="970px"
            height="150px"
            maxLength={150}
            onChange={(e) => setStopToJustify({ ...stopToJustify, description: e.target.value })}
            value={stopToJustify.description}
          />
          <Name>
            <Drop
              dataCy="user-drop"
              name="Usuário"
              size="large"
              color="filled"
              direction="up"
              onSelect={(selected) => setStopToJustify({ ...stopToJustify, user: selected.name })}
              options={
                userName.data?.line?.hasAccess?.map((user) => ({
                  id: user?.id ?? '',
                  name: user?.firstName.concat(' ').concat(user?.lastName) ?? '',
                })) ?? []
              }
              value={stopToJustify.user}
              errorMessage={
                userName.data?.line?.hasAccess?.length === 0 ? 'Não existe nenhum usuário cadastrado.' : undefined
              }
            />
          </Name>
          <Footer>
            <PageOptions>
              <Button title="Voltar" icon="ArrowLeft" color="light" onClick={() => navigate('..')} />
              <Button
                disabled={disableRegister}
                color={'primary'}
                title="Justificar"
                icon="ArrowLeft"
                onClick={() => handleSave()}
                dataCy="justify-button"
              />
            </PageOptions>
          </Footer>
        </>
      ) : (
        <Loader data-cy="loading">
          <MouraLogo>
            <Logo style="onlyIcon" />
          </MouraLogo>
        </Loader>
      )}
    </StopJustification>
  );
};
