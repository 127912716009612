import React from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { Icon, VectorProps } from '../Icon/Icon';

type StyledBadgeProps = {
  color: keyof DefaultTheme['colors']['button'];
};
const StyledBadge = styled.button<StyledBadgeProps>`
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 18px;
  width: max-content;
  height: 30px;
  padding: 0px 20px 0px 10px;
  border-radius: 20px;
  border: ${(props) => props.theme.colors.button[props.color].border};
  background-color: ${(props) => props.theme.colors.button[props.color].background};
  justify-self: center;

  align-items: center;
  justify-content: center;
  text-decoration: none;

  svg:hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    transform: scale(1.5);
    transition: transform 1s ease linear;
  }
`;

type TextProps = {
  color: keyof DefaultTheme['colors']['button'];
};
const Text = styled.span<TextProps>`
  font-family: ${(props) => props.theme.font};
  font-style: Regular;
  font-size: 16px;
  color: ${(props) => props.theme.colors.button[props.color].text};
`;

type Props = {
  title?: string;
  color?: keyof DefaultTheme['colors']['button'];
  iconSize?: VectorProps['size'];
  onClick?: () => void;
  dataCy?: string;
};

export const Badge: React.FC<Props> = ({ title = '', color = 'primary', iconSize = 'smaller', onClick, dataCy }) => {
  const theme = useTheme();
  return (
    <StyledBadge color={color} data-cy={dataCy}>
      {title && <Text color={color}>{title}</Text>}
      <Icon name="Cross" size={iconSize} color={theme.colors.button[color].text} onClick={() => onClick?.call(this)} />
    </StyledBadge>
  );
};
