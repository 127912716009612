import { useState } from 'react';
import { t } from 'i18next';
import { workshiftFrom } from '../scripts/workshiftInterval';

export const FILTER_SHIFT = {
  SHIFT_A: 'SHIFT_A',
  SHIFT_B: 'SHIFT_B',
  SHIFT_C: 'SHIFT_C',
} as const;

export const FILTER_SHIFT_OPTIONS = [
  { id: FILTER_SHIFT.SHIFT_A, name: t(FILTER_SHIFT.SHIFT_A) },
  { id: FILTER_SHIFT.SHIFT_B, name: t(FILTER_SHIFT.SHIFT_B) },
  { id: FILTER_SHIFT.SHIFT_C, name: t(FILTER_SHIFT.SHIFT_C) },
];

interface Filter<T = string> {
  id: string;
  name: T;
}

interface BadgeFilter<T = string> {
  machine: {
    id: string;
    name: string;
  };
  parameters: string[];
  name: T;
}

export interface FilterList {
  loading: boolean;
  multipleMachines: Filter[];
  multipleShifts: Filter[];
  badges: BadgeFilter[];

  factory: Filter;
  machine: Filter;
  parameter: Filter;
  itemType: Filter;
  line: Filter;
  shift: Filter;
  startDate: string;
  endDate: string;
  period: Filter;
  minCount: Filter;
  maxCount: Filter;
  hideUnjustified: boolean;
  date: string;
}

/**
 * A place to store the filter list for a given region of the code.
 * @returns functions to maniuplate the filter list
 */
export function useFilterEngine(context: string) {
  const [filter, setFilter] = useState<Partial<FilterList>>(() => {
    const filterSession = sessionStorage.getItem(context);
    const shift = workshiftFrom(new Date());
    if (filterSession) return JSON.parse(filterSession);
    else
      return {
        date: shift.start.toISOString(),
        startDate: shift.start.toISOString(),
        endDate: shift.end.toISOString(),
      };
  });

  function update(values: Partial<FilterList>, removeKeys?: Array<keyof FilterList>): void {
    const newFilter = { ...filter, ...values };
    if (removeKeys) for (const key of removeKeys) delete newFilter[key];
    sessionStorage.setItem(context, JSON.stringify(newFilter));
    setFilter(newFilter);
  }

  function clear(): void {
    sessionStorage.removeItem(context);
    const shift = workshiftFrom(new Date());
    setFilter({
      date: shift.start.toISOString(),
      startDate: shift.start.toISOString(),
      endDate: shift.end.toISOString(),
      minCount: undefined,
      maxCount: undefined,
      hideUnjustified: false,
      period: undefined,
    });
  }

  return { filter, update, clear };
}

type GenericObject<T> = { id: string } & T;
type Display = { toString(): string };

/**
 * @param data an array of objects with an id and name property
 * @param use which property of the object to use as name
 * @returns the data in the format { id: string, name: string }
 */
export function toOptions<T extends Record<string, Display>>(
  data: GenericObject<T>[] | null | undefined,
  use: keyof T
): Filter[] {
  if (!data) return [];
  return data.map((d) => ({ id: d.id, name: d[use]?.toString() ?? '' }));
}
