import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Footer } from '../../components/layout/Footer';
import { LineRegistration } from '../../components/layout/LineRegistration';
import { PageOptions } from '../../components/layout/PageOptions';
import { Button } from '../../components/ui/Button';
import { Drop } from '../../components/ui/Drop';
import { Input } from '../../components/ui/Input';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import { useFactoriesNameQuery, useRegisterLineMutation } from '../../hooks/graphqlQueries';

const Info = styled.div`
  display: grid;
  grid-area: Info;
  grid-template-columns: 1fr 1fr;
  row-gap: 19px;
  column-gap: 19px;
  align-items: end;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
`;

const DropContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

  @media (max-width: 500px) {
    row-gap: 20px;
    grid-template-columns: 1fr;
  }
`;

export const StyledLabel = styled.label`
  display: inline-block;
  height: 20px;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 5px;
  padding-left: 5px;
  color: ${(props) => props.theme.colors.input.color};
`;

export const CheckDropContainer = styled.div`
  display: grid;
  gap: 5px;
`;

type Line = {
  id: string;
  name: string;
  code: string;
  description: string;
  stopsThreshold: string;
  factory: {
    id: string;
    name: string;
  };
};

const newLine = {
  id: '',
  name: '',
  code: '',
  description: '',
  stopsThreshold: '',
  factory: {
    id: '',
    name: '',
  },
};

export const LineRegistrationPage: React.FC = () => {
  const navigate = useNavigate();
  const feedback = useFeedback();
  const location = useLocation();

  const routeData = location.state as { pagination: { page: number; limit: number } };

  const [info, setInfo] = useState<Line>(newLine);
  const [factoryQuery] = useFactoriesNameQuery();
  const [, registerLine] = useRegisterLineMutation();
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    for (const key in info) {
      if (key !== 'id' && info[key as keyof Line] === '') {
        setDisableSave(true);
        return;
      }
      if (key === 'factory' && info['factory'].id === '') {
        setDisableSave(true);
        return;
      }
    }
    setDisableSave(false);
  }, [info]);

  const handleSave = () => {
    const dialog = () => {
      feedback.confirmation({
        title: 'Deseja cadastrar outra linha?',
        onHandle: (result) => {
          result ? setInfo(newLine as Line) : navigate('..', { state: { pagination: routeData?.pagination } });
        },
      });
    };

    let stopsThresholdSec = parseFloat(info.stopsThreshold);
    if (isNaN(stopsThresholdSec)) stopsThresholdSec = 0;
    else {
      stopsThresholdSec = Math.round(stopsThresholdSec * 60);
    }

    registerLine({
      ...info,
      factoryId: info.factory.id,
      stopsThreshold: stopsThresholdSec,
    })
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.createLine === null)
          feedback.message({ animation: 'Error', title: 'Erro ao cadastrar linha' });
        else
          feedback.message({ animation: 'Confetti', title: 'Linha cadastrada com sucesso', onClose: () => dialog() });
      })
      .catch(() => {
        feedback.message({ animation: 'Error', title: 'Erro ao cadastrar linha' });
      });
  };

  return (
    <LineRegistration>
      <Title>{info.id ? 'Editar Linha' : 'Cadastrar Linha'}</Title>
      <Info>
        <Input
          label="Nome da Linha"
          value={info.name}
          width="406px"
          onChange={(e) => setInfo({ ...info, name: e.target.value })}
        />
        <Input
          label="Código da Linha"
          value={info.code}
          width="406px"
          onChange={(e) => setInfo({ ...info, code: e.target.value })}
        />
        <Input
          label="Descrição"
          maxLength={100}
          value={info.description}
          width="406px"
          onChange={(e) => setInfo({ ...info, description: e.target.value })}
        />
        <Input
          label="Tempo para notificar paradas (minutos)"
          value={info.stopsThreshold}
          width="406px"
          onChange={(e) => {
            const valueNumbers = e.target.value.replace(/[^\d,.]+/g, '').replace(',', '.');
            setInfo({ ...info, stopsThreshold: valueNumbers });
          }}
        />
        <DropContainer>
          <Drop
            label={info.id && 'Planta'}
            name="Planta"
            value={info.factory.name}
            options={factoryQuery.data?.factories?.value?.map((fact) => ({ id: fact.id, name: fact.name })) ?? []}
            onSelect={(factory) => setInfo({ ...info, factory: { id: factory.id, name: factory.name } })}
          />
        </DropContainer>
      </Info>
      <Footer>
        <PageOptions>
          <Button
            dataCy="back-button"
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button
            dataCy="save-button"
            title="Salvar"
            color="primary"
            disabled={disableSave}
            onClick={() => handleSave()}
          />
        </PageOptions>
      </Footer>
    </LineRegistration>
  );
};
