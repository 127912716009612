import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Icon } from '../Icon';

const sizes = {
  small: { text: '16px', width: '136px', height: '45px' },
  medium: { text: '16px', width: '179px', height: '45px' },
  large: { text: '16px', width: '377px', height: '45px' },
};
type Size = keyof typeof sizes;

const InputContainer = styled.div<{ size: Size; disabled: boolean }>`
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 10px;
  align-content: center;
  max-width: ${(props) => sizes[props.size].width};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export const StyledLabel = styled.div`
  position: relative;
  display: flex;
  word-break: normal;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.colors.checkbox.color};
`;

type Props = {
  label?: string;
  onChange?: (e: boolean) => void;
  ondisabledMessage?: () => void;
  disabled?: boolean;
  value?: boolean;
  size?: Size;
  dataCy?: string;
};
export const Checkbox: React.FC<Props> = ({
  label,
  onChange,
  ondisabledMessage,
  disabled,
  value = false,
  size = 'medium',
  dataCy,
}: Props) => {
  const theme = useTheme();
  const [checked, setChecked] = useState(value);

  return (
    <InputContainer size={size} disabled={disabled ?? false}>
      <Icon
        dataCy={dataCy ?? 'checkbox'}
        name={checked ? 'CircleChecked' : 'Circle'}
        size="small"
        color={theme.colors.checkbox.color}
        onClick={() => {
          if (disabled) {
            if (ondisabledMessage) ondisabledMessage();
          } else {
            onChange?.call(this, checked);
            setChecked(!checked);
          }
        }}
      />
      {label && <StyledLabel>{label}</StyledLabel>}
    </InputContainer>
  );
};
