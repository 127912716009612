import { format, parseISO } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/ui/Button';
import { Calendar } from '../../components/ui/Calendar';
import { Drop } from '../../components/ui/Drop';
import { AttributeType, HistoryTable } from '../../components/ui/HistoryTable';
import { MultiDropdown } from '../../components/ui/MultiDropdown';
import { Pagination } from '../../components/ui/Pagination';
import { Title } from '../../components/ui/Title';
import { History } from '../../components/layout/History';
import { useNavigate } from 'react-router-dom';
import {
  Unit,
  useLinesFromFactoryQuery,
  useMachinesFromLineQuery,
  useParameterNamesQuery,
  useParameterValueListQuery,
  useScrapFactoriesNameQuery,
  useScrapItemTypeQuery,
  WorkShift,
} from '../../hooks/graphqlQueries';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../components/ui/Checkbox';
import { downloadFile, makeCSVFromTable } from '../../scripts/exportFiles';
import { toEndShiftC, toStartShiftA } from '../../scripts/times';
import { ClearFilter } from '../../components/layout/History/History';
import { Icon } from '../../components/ui/Icon';
import i18n from '../../i18n';
import { LoaderLogo, MouraLogo } from '../../components/layout/Loading/Loading';
import { Logo } from '../../components/ui/Logo';
import { useFeedback } from '../../context/feedback';
import { asString } from 'ts-runtime-typecheck';
import { getPagination } from '../../scripts/pagination';
import { Footer } from '../../components/layout/Footer';
import { PageOptions } from '../../components/layout/PageOptions';
import { parsePageFilters } from '../../scripts/parses';
import { isEnabledAction, countValidAttributes } from '../../scripts/objectHelper';
import { StyledErrorMessage } from '../../components/ui/Input/Input';
import { periodFilterValidator, ErrorType } from '../../scripts/periodFilterValidator';

const attributes: Array<AttributeType> = [
  { name: 'Nome', attr: 'name', dataCy: 'table-col-name' },
  { name: 'Tipo', attr: 'type', dataCy: 'table-col-type' },
  { name: 'Valor', attr: 'value', dataCy: 'table-col-value' },
  { name: 'Min / Max', attr: 'limits', dataCy: 'table-col-limits' },
  { name: 'Unidade', attr: 'unit', dataCy: 'table-col-unit' },
  { name: 'Linha', attr: 'line', dataCy: 'table-col-line' },
  { name: 'Máquina', attr: 'machine', dataCy: 'table-col-machine' },
  { name: 'Item', attr: 'itemType', dataCy: 'table-col-itemType' },
  { name: 'Turno', attr: 'workShift', dataCy: 'table-col-work-shift' },
  { name: 'Data', attr: 'date', dataCy: 'table-col-date' },
  { name: 'Tempo', attr: 'time', dataCy: 'table-col-time' },
];

const shiftTimes = [
  { id: WorkShift.ShiftA, name: 'Turno A' },
  { id: WorkShift.ShiftB, name: 'Turno B' },
  { id: WorkShift.ShiftC, name: 'Turno C' },
];

const Filters = styled.div`
  display: grid;
  grid-area: Filters;
  grid-template-columns: repeat(auto-fit, minmax(179px, max-content));
  column-gap: 20px;
  row-gap: 10px;
  width: 1fr;
  justify-content: right;

  @media (max-width: 660px) {
    justify-content: center;
  }
`;

const Table = styled.div`
  display: grid;
  grid-area: Table;
  justify-content: space-around;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 1168px) {
    &&:after {
      font-size: 12px;
      padding-top: 5px;
      content: 'Deslize a tabela para os lados';
    }
  }
`;

const fmt: Intl.NumberFormatOptions = {
  maximumFractionDigits: 2,
  minimumIntegerDigits: 1,
  useGrouping: false,
};

type FilterObject = { id: string; name: string };

type PageFilters = {
  page: number;
  limit: number;
  filters: {
    name?: string;
    unit?: Unit;
    factory?: FilterObject;
    line?: FilterObject;
    machine?: FilterObject;
    itemType?: FilterObject;
    workShifts: WorkShift[];
    aboveSuperiorLimit: boolean;
    belowInferiorLimit: boolean;
    startDate: Date;
    endDate: Date;
  };
};

const initialParameters: PageFilters = {
  page: 1,
  limit: 10,
  filters: {
    name: undefined,
    unit: undefined,
    factory: undefined,
    line: undefined,
    machine: undefined,
    itemType: undefined,
    workShifts: [],
    aboveSuperiorLimit: false,
    belowInferiorLimit: false,
    startDate: toStartShiftA(new Date()),
    endDate: toEndShiftC(new Date()),
  },
};

export const ParameterHistoryPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const feedback = useFeedback();

  const rescuedFilters = sessionStorage.getItem('GlobalHistoryFilter');
  const storagedFilters: PageFilters = parsePageFilters(rescuedFilters, initialParameters) as PageFilters;
  const [parameters, setParameters] = useState(
    storagedFilters
      ? {
          ...storagedFilters,
          limit: 10,
          page: 1,
          filters: {
            ...storagedFilters.filters,
            startDate: new Date(storagedFilters.filters.startDate),
            endDate: new Date(storagedFilters.filters.endDate),
          },
        }
      : initialParameters
  );

  const [factoryName] = useScrapFactoriesNameQuery({ requestPolicy: 'network-only' });
  const [lineName] = useLinesFromFactoryQuery({
    pause: parameters.filters.factory === undefined,
    requestPolicy: 'network-only',
    variables: { factoryId: parameters.filters.factory?.id ?? '' },
  });
  const [machineName] = useMachinesFromLineQuery({
    pause: parameters.filters.line?.id === undefined,
    requestPolicy: 'network-only',
    variables: { lineId: parameters.filters.line?.id ?? '' },
  });
  const [parameterName] = useParameterNamesQuery({
    pause: parameters.filters.machine?.id === undefined,
    requestPolicy: 'network-only',
    variables: { machineId: parameters.filters.machine?.id ?? '' },
  });
  const [itemName] = useScrapItemTypeQuery({ requestPolicy: 'network-only' });

  const [isExportData, setIsExportData] = useState(false);
  const dataExportLimit = parseInt(asString(process.env['DATA_EXPORT_LIMIT']));
  const [isFiltersValid, setIsFiltersValid] = useState({ general: false, period: false });
  const maxPeriodFilterLimit = parseInt(asString(process.env['PARAMETER_PERIOD_FILTER_LIMIT'] || '7'));
  const periodStartFilterLimit = parseInt(asString(process.env['PARAMETER_PERIOD_FILTER_START_LIMIT'] || '3'));
  const [errors, setErrors] = useState<ErrorType>();

  const [parametersQuery] = useParameterValueListQuery({
    pause: !isFiltersValid.general,
    variables: {
      ...getPagination(parameters.page, parameters.limit, { dataExportLimit, isExportData }),
      ...parameters.filters,
      name: parameters.filters.name,
      lineId: parameters.filters.line?.id,
      machineId: parameters.filters.machine?.id,
      itemTypeId: parameters.filters.itemType?.id
    },
    requestPolicy: 'network-only',
  });

  const parametersHistory = useMemo(() => parametersQuery.data?.parameterValues?.value ?? [], [parametersQuery.data]);
  const count = useMemo(() => parametersQuery.data?.parameterValues?.count ?? 0, [parametersQuery.data]);

  useEffect(() => {
    const countFilter = countValidAttributes(parameters.filters, ['factory', 'line', 'machine', 'itemType', 'workShifts', 'name']);
    const calculatedPeriod = countFilter * periodStartFilterLimit;
    const newPeriod = calculatedPeriod < maxPeriodFilterLimit ? calculatedPeriod : maxPeriodFilterLimit;
    const validation = { general: false, period: false };
    const error_text = t('ERROR.PERIOD_BIGGER_THAN').split('|') || " | ";

    const result = periodFilterValidator(
      {
        startDate: parameters.filters.startDate,
        endDate: parameters.filters.endDate,
        maxPeriodLimit: newPeriod
      },
      {
        limitGreaterThenSuperior:  `${error_text[0]} ${newPeriod} ${error_text[1]}`
      }
    )

    setErrors(result);

    if (Object.values(result).length == 0) {
      validation.period = true;
    }

    isEnabledAction(
      parameters.filters,
      (result) => {
        validation.general = !validation.period ? false : result;
      },
      { ignoreFields: ['machine', 'itemType', 'aboveSuperiorLimit', 'belowInferiorLimit', 'workShifts', 'name', 'unit'] }
    );

    if(!validation.general){
      setTableData([])
    }

    setIsFiltersValid(validation);
  }, [parameters.filters]);

  useEffect(() => {
    sessionStorage.setItem('GlobalHistoryFilter', JSON.stringify(parameters));
  }, [parameters]);

  const data = useMemo(
    () =>
      parametersHistory.map((value) => {
        const language = i18n.language;
        const inferiorLimit = (value.inferiorLimit ?? 0).toLocaleString(language, fmt);
        const superiorLimit = (value.superiorLimit ?? 0).toLocaleString(language, fmt);

        return {
          name: value.parameterName ?? '',
          type: t(value.parameterType ?? 'NO_TYPE') ?? '',
          machine: value.machineName ?? '',
          line: value.lineCode ?? '',
          itemType: value.itemTypeName ?? '',
          workShift: t(value.workShift ?? 'NO_SHIFT'),
          value: value.value.toLocaleString(language, fmt),
          limits: `${inferiorLimit} / ${superiorLimit}`,
          unit: t(value.unit ?? 'NO_UNIT'),
          date: format(parseISO(value.time), 'dd.MM.yyyy'),
          time: format(parseISO(value.time), 'HH:mm:ss.S'),
        };
      }),
    [parametersHistory]
  );
  
  const exportData = useMemo(
    () =>
      parametersHistory.map((value) => {
        const language = i18n.language;
        const inferiorLimit = (value.inferiorLimit ?? 0).toLocaleString(language, fmt);
        const superiorLimit = (value.superiorLimit ?? 0).toLocaleString(language, fmt);

        return {
          name: value.parameterName ?? '',
          type: t(value.parameterType ?? 'NO_TYPE') ?? '',
          machine: value.machineName ?? '',
          line: value.lineCode ?? '',
          itemType: value.itemTypeName ?? '',
          workShift: t(value.workShift ?? 'NO_SHIFT'),
          value: value.value.toLocaleString(language, fmt),
          limits: `${inferiorLimit} / ${superiorLimit}`,
          unit: t(value.unit ?? 'NO_UNIT'),
          date: format(parseISO(value.time), 'dd/MM/yyyy'),
          time: format(parseISO(value.time), 'HH:mm:ss.S'),
        };
      }),
    [parametersHistory]
  );
  /*const exportData = useMemo(() => {
    // Agrupa os dados pelo date, time e name usando reduce
    const pivotData = parametersHistory.reduce((result: Record<string, any>, value) => {
      const language = i18n.language;
      const inferiorLimit = (value.parameter?.inferiorLimit ?? 0).toLocaleString(language, fmt);
      const superiorLimit = (value.parameter?.superiorLimit ?? 0).toLocaleString(language, fmt);
      const date = format(parseISO(value.time), 'dd/MM/yyyy');
      const time = format(parseISO(value.time), 'HH:mm:ss.S');
      const datetime = format(parseISO(value.time), 'dd/MM/yyyy HH:mm:ss.S');
      const name = value.parameter?.name ?? '';

      if (!result[datetime]) {
        result[datetime] = {};
      }
      
      //result['date'] = value.parameter?.machine?.line?.code ?? '';
      //result['time'] = value.parameter?.machine?.line?.code ?? '';
      //result['datetime'] = value.time;
      result[datetime]['line'] = value.parameter?.machine?.line?.code ?? '';
      result[datetime]['machine'] = value.parameter?.machine?.name ?? '';
      result[datetime]['workShift'] = t(value.workShift ?? 'NO_SHIFT');

  
      //result[name] = {
      //result[date][time][name] = {
      result[datetime][name] = {
        type: t(value.parameter?.type ?? 'NO_TYPE') ?? '',
        //machine: value.parameter?.machine?.name ?? '',
        //line: value.parameter?.machine?.line?.code ?? '',
        //workShift: t(value.workShift ?? 'NO_SHIFT'),
        value: value.value.toLocaleString(language, fmt),
        limits: `${inferiorLimit} / ${superiorLimit}`,
        //unit: t(value.parameter?.unit ?? 'NO_UNIT'),
      };
  
      return result;
    }, {});
    console.log(pivotData)
  
    // Converte os dados pivotados de volta em uma matriz
    const result = [];
    for (const datetime in pivotData) {
      const obj = pivotData[datetime];
      //const subObj = { datetime, x: 'xx', line: "obj['line']", machine: obj['machine'], workShift: obj['workShift'] };
      let subObj = { datetime };
      for (const name in obj) {
        //const data = { date, time, line: pivotData[date][time][name]['line'], machine: pivotData[date][time][name]['machine'] };
        if(typeof  obj[name] === "object"){
          Object.assign(subObj, { [name]: obj[name]['value'] });
        }else{
          Object.assign(subObj, { [name]: obj[name]});
        }
      }
      result.push(subObj);
    }
    console.log(result)
    return result;
  }, [parametersHistory]);*/
  

  const [tableData, setTableData] = useState<typeof data>(data);
  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');

  useEffect(() => {
    if (isExportData) {
      downloadFile(`historico-parametro-${thisDate}`, makeCSVFromTable(attributes, exportData));
      //downloadFile(`historico-parametro-${thisDate}`, makeCSVFromTable(Object.keys(exportData[0]), exportData));
      setIsExportData(false);

      if (data.length === dataExportLimit)
        feedback.message({
          animation: 'Error',
          title: 'Limite de dados atingido!',
          subtitle: `Não é possível exportar mais que ${dataExportLimit} linhas de dados.`,
        });
    } else setTableData(data);
  }, [data]);

  return (
    <History>
      <Title data-cy="param-history">Histórico Parâmetros</Title>
      <Filters>
        <Drop
          label="Unidade*"
          name="Unidade"
          value={parameters.filters.factory?.name}
          options={factoryName.data?.factories?.value ?? []}
          onSelect={(factory) =>
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, factory, line: undefined, machine: undefined },
            })
          }
          errorMessage={
            factoryName.data?.factories?.value?.length === 0 ? 'Não existe nenhuma unidade cadastrada.' : undefined
          }
        />
        <Drop
          label='Linha*'
          name="Linha"
          value={parameters.filters.line?.name}
          options={lineName.data?.lines?.value?.map((line) => ({ id: line.id, name: line.code })) ?? []}
          onSelect={(line) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: {
                ...parameters.filters,
                line: line,
                machine: undefined,
              },
            });
          }}
          disabled={!parameters.filters.factory?.id}
          errorMessage={lineName.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined}
        />
        <Drop
          label="Máquina"
          name="Máquina"
          value={parameters.filters.machine?.name}
          options={machineName.data?.machines?.value?.map((machine) => ({ id: machine.id, name: machine.code })) ?? []}
          onSelect={(machine) => {
            setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, machine } });
          }}
          disabled={!parameters.filters.line?.id}
          errorMessage={
            machineName.data?.machines?.value?.length === 0 ? 'Não existe nenhuma máquina cadastrada.' : undefined
          }
        />
        <Drop
          dataCy="parameter-item-drop"
          label="Tipo de Item"
          name="Tipo de Item"
          options={
            itemName.data?.itemTypes?.value?.map((itemType) => ({
              id: itemType.id,
              name: itemType.name,
            })) ?? []
          }
          onSelect={(selected) => {
            setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, itemType: selected } });
          }}
          value={parameters.filters.itemType?.id ?? ''}
          disabled={!parameters.filters.machine}
          errorMessage={
            itemName.data?.itemTypes?.value?.length === 0 ? 'Não existe nenhum tipo de item cadastrado.' : undefined
          }
        />
        <MultiDropdown
          label="Turno"
          name="Turno"
          value={parameters.filters.workShifts ?? []}
          options={shiftTimes}
          onBlur={(shifts) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, workShifts: shifts.map((el) => el.id as WorkShift) },
            });
          }}
          errorMessage={shiftTimes.length === 0 ? 'Não existe nenhum turno cadastrado.' : undefined}
        />
        <Drop
          label="Parâmetro"
          name="Parâmetro"
          value={parameters.filters.name ?? ''}
          options={parameterName.data?.parameters?.value?.map((param) => ({ id: param.name, name: param.name })) ?? []}
          onSelect={(name) => {
            setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, name: name.name } });
          }}
          disabled={!parameters.filters.machine?.id}
          errorMessage={
            parameterName.data?.parameters?.value?.length === 0 ? 'Não existe nenhum nome cadastrado.' : undefined
          }
        />
        <Calendar
          label="Data inicial*"
          placeholder="Data inicial"
          value={format(parameters.filters.startDate, 'yyyy-MM-dd')}
          onChange={(e) => {
            const startDate = parseISO(e.target.value);
            if (e.target.value.length == 10)
              setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, startDate } });
            }
          }
          onBlur={(date) => {
            const startDate = parseISO(date.target.value);
            if (date.target.value.length == 10)
              setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, startDate } });
          }
          }
        />
        <Calendar
          label="Data final*"
          placeholder="Data final"
          value={format(parameters.filters.endDate, 'yyyy-MM-dd')}
          onChange={(e) => {
            const endDate = parseISO(e.target.value);
            if (e.target.value.length == 10)
              setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, endDate } });
            }
          }
          onBlur={(date) => {
            const endDate = parseISO(date.target.value);
            if (date.target.value.length == 10)
              setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, endDate } });
          }}
        />
        <Drop
          label="Unidade medida"
          name="Unidade medida"
          value={t(parameters.filters.unit ?? 'UNIT')}
          options={Object.keys(Unit).map((value) => ({ id: value, name: t(Unit[value as keyof typeof Unit]) }))}
          onSelect={(unit) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, unit: Unit[unit.id as keyof typeof Unit] },
            });
          }}
          errorMessage={Object.keys(Unit).length === 0 ? 'Não existe nenhuma unidade de medida cadastrada.' : undefined}
        />
        <Checkbox
          label="Acima do limite superior"
          value={parameters.filters.aboveSuperiorLimit}
          onChange={(check) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, aboveSuperiorLimit: !check },
            });
          }}
        />
        <Checkbox
          label="Abaixo do limite inferior"
          value={parameters.filters.belowInferiorLimit}
          onChange={(check) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, belowInferiorLimit: !check },
            });
          }}
        />
      </Filters>
      {parameters.filters.line && (
        <ClearFilter
          onClick={() => {
            setParameters(initialParameters);
            sessionStorage.removeItem('GlobalHistoryFilter');
          }}
        >
          <Icon name="Cross" size="smaller" color="white" />
          <span>Limpar Filtros</span>
        </ClearFilter>
      )}
      {errors?.limitGreaterThenSuperior && (
          <StyledErrorMessage data-cy="field-message" maxWidth='parent'>
            { errors?.limitGreaterThenSuperior}
          </StyledErrorMessage>
      )}
      <Table>
          <HistoryTable attributes={attributes} content={tableData} size={'large'} loading={parametersQuery.fetching && (!isExportData)}/>
      </Table>
      <Footer>
        <PageOptions>
          <Button title="Menu históricos" color="light" icon="ArrowLeft" onClick={() => navigate('..')} />
          <Button title="Exportar" icon="Export" disabled={data.length == 0} onClick={() => setIsExportData(true)} />
        </PageOptions>
        <Pagination
          numberOfItems={parameters.limit}
          onSelectNumber={(number) => {
            setParameters({ ...parameters, page: 1, limit: number });
          }}
          page={parameters.page}
          totalPages={Math.ceil(count / parameters.limit)}
          onPrevious={() => setParameters({ ...parameters, page: parameters.page - 1 })}
          onNext={() => setParameters({ ...parameters, page: parameters.page + 1 })}
        />
      </Footer>

      {isExportData && (
        <LoaderLogo data-cy="loading">
          <MouraLogo>
            <Logo style="onlyIcon" />
          </MouraLogo>
        </LoaderLogo>
      )}
    </History>
  );
};
