import React from 'react';
import { sizes, VectorProps } from './Icon';

export const BatteryBrokenColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 48 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.238 2.009a1.394 1.394 0 00-1.954-.32 1.417 1.417 0 00-.317 1.968l2.405 3.363a1.394 1.394 0 001.954.32c.628-.455.77-1.336.318-1.968l-2.405-3.363z"
        fill="#FFBF31"
      />
      <path
        d="M31.53 2.012l-2.405 3.363a1.417 1.417 0 00.317 1.968 1.394 1.394 0 001.955-.32l2.405-3.363a1.417 1.417 0 00-.317-1.968 1.394 1.394 0 00-1.954.32zM24.383.334v7.049c.371 0 .727-.149.99-.413.262-.265.41-.623.41-.997V1.75A1.417 1.417 0 0024.92.442a1.391 1.391 0 00-.537-.108z"
        fill="#EAA00C"
      />
      <path
        d="M22.984 1.75v4.223c0 .374.148.732.41.997.263.264.619.413.99.413V.334a1.391 1.391 0 00-.992.415 1.41 1.41 0 00-.408 1.001zM14.43 12.136c-.338 0-.423-.14-.415-.446a25.22 25.22 0 000-1.534c-.026-.653-.306-.918-.955-.92H8.49c-.578 0-.838.218-.889.793-.044.5-.027.992-.03 1.489-.007.612 0 .613-.595.613H.97c-.641 0-.912.267-.914.897-.009 1.448-.01 2.897-.003 4.347 0 .58.28.848.86.866h21.379l2.67-6.106c-2.62-.004-7.906-.002-10.532.001z"
        fill="#FFBF31"
      />
      <path
        d="M33.58 12.116c.339 0 .424-.141.416-.447a25.22 25.22 0 010-1.534c.026-.653.306-.918.955-.919 1.523-.005 3.046-.005 4.57 0 .578 0 .837.218.888.793.045.5.028.992.031 1.488.007.613 0 .614.595.614h6.005c.642 0 .913.266.915.896a468.29 468.29 0 010 4.34c0 .58-.28.845-.86.865H25.719l2.803-6.106c2.622.005 2.433.006 5.059.01z"
        fill="#EAA00C"
      />
      <path
        d="M20.064 23.285l1.335-2.71H3.744c-.699 0-.703 0-.704.705v20.904c-.001.219.017.437.055.652a.94.94 0 00.31.591.927.927 0 00.62.233c.144.009.29.006.435.006h16.94l1.336-2.777-2.67-17.604zm-4.678 7.975l-2.49-.019-.019 2.53a1.265 1.265 0 01-.38.885 1.247 1.247 0 01-1.769-.027 1.265 1.265 0 01-.353-.896l.019-2.51-2.51-.02a1.243 1.243 0 01-.901-.369 1.26 1.26 0 01-.26-1.397 1.26 1.26 0 01.708-.674c.157-.058.323-.085.49-.078l2.491.019.019-2.529c.005-.334.142-.652.38-.885a1.247 1.247 0 011.77.027c.23.24.357.563.352.897l-.019 2.509 2.51.018a1.244 1.244 0 01.901.37 1.26 1.26 0 01.366.91 1.267 1.267 0 01-.392.897 1.25 1.25 0 01-.912.342z"
        fill="#006EC7"
      />
      <path
        d="M44.379 20.575H24.834L23.5 23.261l2.67 17.558-1.336 2.846h18.864c.16 0 .32 0 .48-.007a.916.916 0 00.877-.773c.044-.259.065-.52.063-.784v-20.77c.001-.755.001-.756-.74-.756zm-5.4 10.685l-7.503-.056a1.24 1.24 0 01-.9-.37 1.261 1.261 0 01-.26-1.398 1.26 1.26 0 01.708-.673c.157-.058.324-.085.49-.078l7.503.056a1.245 1.245 0 011.176.787 1.267 1.267 0 01-.301 1.39 1.251 1.251 0 01-.912.342z"
        fill="#0033AB"
      />
    </svg>
  );
};
