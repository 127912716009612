export function getUserTheme() {
  const storagedToken = localStorage.getItem('theme');
  if (storagedToken) {
    return storagedToken as 'dark' | 'light';
  }
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return 'dark';
  }
  return 'light';
}
