import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useMedia } from '../../../hooks';
import { Icon } from '../Icon';

const StyledScrollView = styled.div<{ area: string }>`
  grid-area: ${(props) => props.area};
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  align-items: center;
  scroll-behavior: smooth;

  &&::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: 1290px) {
    justify-content: center;
    overflow-x: auto;
    overflow-y: auto;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 20px;
  align-items: center;

  @media (min-width: 1290px) {
    display: flex;
    grid-area: Card;
    align-items: center;
    justify-content: space-between;
  }
`;

const ArrowContainer = styled.div<{ orientation: 'left' | 'right' }>`
  cursor: pointer;
  display: flex;
  position: absolute;
  width: 40px;
  height: 60px;

  justify-content: center;
  align-items: center;

  ${(props) =>
    props.orientation === 'left' &&
    css`
      left: 15px;
    `}

  ${(props) =>
    props.orientation === 'right' &&
    css`
      right: 15px;
    `}

    &&:hover {
    transform: scale(1.2);
  }

  transition: all 0.3s;
`;

type Props = {
  area?: string;
  children: React.ReactNode;
};
export const ScrollView: React.FC<Props> = ({ area = 'Card', children }: Props) => {
  const tooMobile = useMedia('(min-width: 570px)');
  const tooLarge = useMedia('(min-width: 1290px)');
  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    console.log(scrollPosition);
  }, [scrollPosition]);

  const handleScrollPosition = (action: 'prev' | 'next') => {
    if (scrollRef && scrollRef.current) {
      if (action === 'prev') {
        scrollRef.current.scrollLeft -= 200;
      } else {
        scrollRef.current.scrollLeft += 200;
      }
      setScrollPosition(scrollRef.current.scrollLeft);
    }
  };

  if (tooLarge) return <Content>{children}</Content>;
  return (
    <StyledScrollView area={area} ref={scrollRef}>
      {tooMobile && (
        <>
          <ArrowContainer orientation="left" onClick={() => handleScrollPosition('prev')}>
            <Icon name="ArrowLeft" />
          </ArrowContainer>
          <ArrowContainer orientation="right" onClick={() => handleScrollPosition('next')}>
            <Icon name="ArrowRight" />
          </ArrowContainer>
        </>
      )}
      <Content>{children}</Content>
    </StyledScrollView>
  );
};
