export const getPagination = (
  page: number,
  limit: number,
  exportOptions?: {
    dataExportLimit: number;
    isExportData: boolean;
  }
): { offset: number; limit: number } => {
  if (exportOptions && exportOptions.isExportData)
    return {
      offset: 0,
      limit: exportOptions.dataExportLimit,
    };
  else
    return {
      offset: (page - 1) * limit,
      limit: limit,
    };
};
