import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Eye: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0.00195312C6 0.00195312 1.73 3.11195 0 7.50195C1.73 11.892 6 15.002 11 15.002C16 15.002 20.27 11.892 22 7.50195C20.27 3.11195 16 0.00195312 11 0.00195312ZM11 12.502C8.24 12.502 6 10.262 6 7.50195C6 4.74195 8.24 2.50195 11 2.50195C13.76 2.50195 16 4.74195 16 7.50195C16 10.262 13.76 12.502 11 12.502ZM11 4.50195C9.34 4.50195 8 5.84195 8 7.50195C8 9.16195 9.34 10.502 11 10.502C12.66 10.502 14 9.16195 14 7.50195C14 5.84195 12.66 4.50195 11 4.50195Z"
        fill={color}
      />
    </svg>
  );
};
