import { RefObject, useCallback, useEffect, useRef, useState } from 'react';

/**
 * Tracks the width of a html element and triggers an effect if it changes.
 * @param ref Reference to object which should have its width checked after every resize
 * @returns The new width of the object
 */

export function useDynamicWidthOf<T extends HTMLElement>(ref: RefObject<T>): number {
  const [elementWidth, setElementWidth] = useState(0);

  const resize = useCallback(() => {
    if (ref.current) setElementWidth(ref.current.offsetWidth);
  }, [ref.current]);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(resize, 500);
      resize();
    };

    resize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return elementWidth;
}

/**
 * Tracks the width of a html element and triggers an effect if it changes.
 * @returns The new width of the object and a reference to attach to the object.
 */

export function captureWidth<T extends HTMLElement>(): { width: number; ref: RefObject<T> } {
  const [width, setWidth] = useState(0);
  const ref = useRef<T>(null);

  const resize = useCallback(() => {
    if (ref.current) setWidth(ref.current.clientWidth);
  }, [ref.current]);

  useEffect(() => {
    const handleResize = () => {
      setTimeout(resize, 500);
      resize();
    };

    resize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return { width, ref };
}
