import React from 'react';
import { sizes, VectorProps } from './Icon';

export const SettingsColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.8598 16.17H10.4464C9.62355 16.17 8.83437 15.8407 8.2525 15.2547C7.67064 14.6686 7.34375 13.8738 7.34375 13.045C7.34375 12.2162 7.67064 11.4213 8.2525 10.8353C8.83437 10.2492 9.62355 9.91998 10.4464 9.91998H39.8598C40.6827 9.91998 41.4719 10.2492 42.0537 10.8353C42.6356 11.4213 42.9625 12.2162 42.9625 13.045C42.9625 13.8738 42.6356 14.6686 42.0537 15.2547C41.4719 15.8407 40.6827 16.17 39.8598 16.17Z"
        fill="#FFBF31"
      />
      <path
        d="M39.8598 28.67H10.4464C9.62355 28.67 8.83437 28.3407 8.2525 27.7547C7.67064 27.1686 7.34375 26.3738 7.34375 25.545C7.34375 24.7162 7.67064 23.9213 8.2525 23.3353C8.83437 22.7492 9.62355 22.42 10.4464 22.42H39.8598C40.6827 22.42 41.4719 22.7492 42.0537 23.3353C42.6356 23.9213 42.9625 24.7162 42.9625 25.545C42.9625 26.3738 42.6356 27.1686 42.0537 27.7547C41.4719 28.3407 40.6827 28.67 39.8598 28.67V28.67Z"
        fill="#EAA00C"
      />
      <path
        d="M39.8598 41.17H10.4464C9.62355 41.17 8.83437 40.8407 8.2525 40.2547C7.67064 39.6686 7.34375 38.8738 7.34375 38.045C7.34375 37.2162 7.67064 36.4213 8.2525 35.8353C8.83437 35.2492 9.62355 34.92 10.4464 34.92H39.8598C40.6827 34.92 41.4719 35.2492 42.0537 35.8353C42.6356 36.4213 42.9625 37.2162 42.9625 38.045C42.9625 38.8738 42.6356 39.6686 42.0537 40.2547C41.4719 40.8407 40.6827 41.17 39.8598 41.17Z"
        fill="#006EC7"
      />
      <path
        d="M19.6304 19.67C23.2632 19.67 26.2081 16.7039 26.2081 13.045C26.2081 9.3861 23.2632 6.41998 19.6304 6.41998C15.9977 6.41998 13.0527 9.3861 13.0527 13.045C13.0527 16.7039 15.9977 19.67 19.6304 19.67Z"
        fill="#FFBF31"
      />
      <path
        d="M19.6304 44.67C23.2632 44.67 26.2081 41.7039 26.2081 38.045C26.2081 34.3861 23.2632 31.42 19.6304 31.42C15.9977 31.42 13.0527 34.3861 13.0527 38.045C13.0527 41.7039 15.9977 44.67 19.6304 44.67Z"
        fill="#006EC7"
      />
      <path
        d="M31.9175 32.17C35.5503 32.17 38.4952 29.2039 38.4952 25.545C38.4952 21.8861 35.5503 18.92 31.9175 18.92C28.2848 18.92 25.3398 21.8861 25.3398 25.545C25.3398 29.2039 28.2848 32.17 31.9175 32.17Z"
        fill="#EAA00C"
      />
    </svg>
  );
};
