import produce from 'immer';
import { FilterList } from '../hooks/filterEngine';

export function addBadgeToFilter(
  update: (values: Partial<FilterList>, removeKeys?: Array<keyof FilterList>) => void,
  { machine, parameter, badges = [] }: Partial<FilterList>
): void {
  if (!machine || !parameter) return;

  const newBadges = produce(badges, (draft) => {
    const existingBadge = draft.find((badge) => badge.machine.id === machine.id);
    if (existingBadge && !existingBadge.parameters.includes(parameter.id)) {
      existingBadge.parameters.push(parameter.id);
      existingBadge.name += `/${parameter.name}`;
    } else if (!existingBadge) {
      draft.push({
        machine,
        parameters: [parameter.id],
        name: `${machine.name}/${parameter.name}`,
      });
    }
  });

  update({ badges: newBadges });
}

export function removeBadgeByMachine(
  update: (values: Partial<FilterList>, removeKeys?: Array<keyof FilterList>) => void,
  machineId: string,
  { badges }: Partial<FilterList>
): void {
  if (!badges || badges.length === 0) return;

  const remainingBadges = produce(badges, (draft) => {
    const index = draft.findIndex((badge) => badge.machine.id === machineId);
    if (index !== -1) draft.splice(index, 1);
  });

  if (remainingBadges.length > 0) update({ badges: remainingBadges });
  else update({ badges: undefined });
}
