import React from 'react';
import { sizes, VectorProps } from './Icon';

export const ArrowRight: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.189453 10.59L4.76945 6L0.189453 1.41L1.59945 0L7.59945 6L1.59945 12L0.189453 10.59Z" fill={color} />
    </svg>
  );
};
