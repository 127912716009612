import React from 'react';
import { sizes, VectorProps } from './Icon';

export const ArrowLeft: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.59945 10.59L3.01945 6L7.59945 1.41L6.18945 0L0.189453 6L6.18945 12L7.59945 10.59Z" fill={color} />
    </svg>
  );
};
