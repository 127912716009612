import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISODate: any;
};

export type Conveyor = {
  __typename?: 'Conveyor';
  handleFrom: Scalars['String'];
  handleTo: Scalars['String'];
  id: Scalars['ID'];
  origin?: Maybe<Scalars['String']>;
  speed: Scalars['Float'];
  status: MachineStatus;
  target?: Maybe<Scalars['String']>;
};

export type CreateAlarmInput = {
  alarmCode: Scalars['String'];
  machineCode: Scalars['ID'];
  name: Scalars['String'];
  time: Scalars['String'];
  uucode: Scalars['ID'];
};

export type CreateEventSignalInput = {
  amount: Scalars['Float'];
  itemTypeCode?: InputMaybe<Scalars['String']>;
  time: Scalars['String'];
  uucode: Scalars['ID'];
};

export type CreateParameterValueInput = {
  itemCode?: InputMaybe<Scalars['String']>;
  itemTypeCode?: InputMaybe<Scalars['String']>;
  machineCode: Scalars['String'];
  time: Scalars['String'];
  uucode: Scalars['ID'];
  value: Scalars['Float'];
};

export type Dashboard = {
  __typename?: 'Dashboard';
  bottleneckIdealCycle?: Maybe<Scalars['Float']>;
  code: Scalars['String'];
  currentStateOfLine: StateOfLine;
  lineId: Scalars['ID'];
  reactFlow: ReactFlow;
  workshift: Workshift;
};

export type Error = {
  __typename?: 'Error';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Event = {
  __typename?: 'Event';
  delta: Scalars['Float'];
  previousRunDelta: Scalars['Float'];
  previousStatus: MachineStatus;
  runDelta: Scalars['Float'];
  status: MachineStatus;
  time: Scalars['ISODate'];
};

export type EventThresholds = {
  __typename?: 'EventThresholds';
  normalCycle: Scalars['Float'];
  smallStopCycle: Scalars['Float'];
  stopCycle: Scalars['Float'];
};

export type LastItemTime = {
  __typename?: 'LastItemTime';
  id: Scalars['ID'];
  time: Scalars['String'];
};

export type Machine = {
  __typename?: 'Machine';
  bottleneckId?: Maybe<Scalars['String']>;
  bottleneckIdealCycle?: Maybe<Scalars['Float']>;
  bottleneckMaxSpeed: Scalars['Float'];
  bottleneckProduction?: Maybe<Scalars['Float']>;
  disabled?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  idealCycleTime?: Maybe<Scalars['Float']>;
  itemTypeCode?: Maybe<Scalars['String']>;
  itemTypeName?: Maybe<Scalars['String']>;
  maxSpeed: Scalars['Float'];
  meanSpeed?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  normalCycle?: Maybe<Scalars['Int']>;
  oee?: Maybe<Scalars['Float']>;
  positionX?: Maybe<Scalars['Int']>;
  positionY?: Maybe<Scalars['Int']>;
  production?: Maybe<Scalars['Float']>;
  smallStopCycle?: Maybe<Scalars['Int']>;
  status: MachineStatus;
  stopCount?: Maybe<Scalars['Int']>;
  stopCycle?: Maybe<Scalars['Int']>;
  stopTime?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['String']>;
};

export enum MachineStatus {
  Normal = 'NORMAL',
  NotMonitored = 'NOT_MONITORED',
  Paused = 'PAUSED',
  Slow = 'SLOW',
  SmallStop = 'SMALL_STOP',
  Startup = 'STARTUP',
  Stop = 'STOP'
}

export type Mutation = {
  __typename?: 'Mutation';
  createAlarm?: Maybe<Scalars['Boolean']>;
  createEventSignal?: Maybe<Scalars['Boolean']>;
  createParameterValue?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateAlarmArgs = {
  alarm: CreateAlarmInput;
};


export type MutationCreateEventSignalArgs = {
  signal: CreateEventSignalInput;
};


export type MutationCreateParameterValueArgs = {
  parameter: CreateParameterValueInput;
};

export type Query = {
  __typename?: 'Query';
  dashboard: Dashboard;
};

export type ReactFlow = {
  __typename?: 'ReactFlow';
  conveyors: Array<Conveyor>;
  lastItemTime: Array<LastItemTime>;
  machines: Array<Machine>;
};

export enum Shift {
  NoShift = 'NO_SHIFT',
  ShiftA = 'SHIFT_A',
  ShiftB = 'SHIFT_B',
  ShiftC = 'SHIFT_C'
}

export type StateOfLine = {
  __typename?: 'StateOfLine';
  availability?: Maybe<Scalars['Float']>;
  delta?: Maybe<Scalars['Float']>;
  idealCycleTime?: Maybe<Scalars['Float']>;
  itemTypeCode?: Maybe<Scalars['String']>;
  itemTypeName?: Maybe<Scalars['String']>;
  meanSpeed?: Maybe<Scalars['Float']>;
  normalCycle?: Maybe<Scalars['Int']>;
  oee?: Maybe<Scalars['Float']>;
  performance?: Maybe<Scalars['Float']>;
  production?: Maybe<Scalars['Float']>;
  projection?: Maybe<Scalars['Float']>;
  smallStopCount?: Maybe<Scalars['Int']>;
  smallStopCycle?: Maybe<Scalars['Int']>;
  smallStopTime?: Maybe<Scalars['Float']>;
  speed?: Maybe<Scalars['Float']>;
  stopCount?: Maybe<Scalars['Int']>;
  stopCycle?: Maybe<Scalars['Int']>;
  stopTime?: Maybe<Scalars['Float']>;
  time?: Maybe<Scalars['ISODate']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  localApiError?: Maybe<Error>;
  newTimelineEvent?: Maybe<Array<Event>>;
};

export type Workshift = {
  __typename?: 'Workshift';
  duration: Scalars['Int'];
  endingAt: Scalars['ISODate'];
  name: Shift;
  startingAt: Scalars['ISODate'];
};

export type DashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardQuery = { __typename?: 'Query', dashboard: { __typename?: 'Dashboard', lineId: string, code: string, bottleneckIdealCycle?: number | null, currentStateOfLine: { __typename?: 'StateOfLine', time?: any | null, oee?: number | null, availability?: number | null, performance?: number | null, production?: number | null, projection?: number | null, stopCount?: number | null, smallStopCount?: number | null, stopTime?: number | null, smallStopTime?: number | null, itemTypeCode?: string | null, itemTypeName?: string | null, speed?: number | null, meanSpeed?: number | null, delta?: number | null, normalCycle?: number | null, smallStopCycle?: number | null, stopCycle?: number | null, idealCycleTime?: number | null }, reactFlow: { __typename?: 'ReactFlow', machines: Array<{ __typename?: 'Machine', id: string, name: string, time?: string | null, status: MachineStatus, meanSpeed?: number | null, maxSpeed: number, disabled?: boolean | null, bottleneckMaxSpeed: number, bottleneckId?: string | null, production?: number | null, oee?: number | null, stopCount?: number | null, stopTime?: number | null, positionX?: number | null, positionY?: number | null, normalCycle?: number | null, smallStopCycle?: number | null, itemTypeCode?: string | null, itemTypeName?: string | null, stopCycle?: number | null, idealCycleTime?: number | null, bottleneckIdealCycle?: number | null, bottleneckProduction?: number | null }>, lastItemTime: Array<{ __typename?: 'LastItemTime', id: string, time: string }>, conveyors: Array<{ __typename?: 'Conveyor', id: string, origin?: string | null, target?: string | null, speed: number, status: MachineStatus, handleFrom: string, handleTo: string }> }, workshift: { __typename?: 'Workshift', name: Shift, duration: number, startingAt: any, endingAt: any } } };

export type TimelineEventSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TimelineEventSubscription = { __typename?: 'Subscription', newTimelineEvent?: Array<{ __typename?: 'Event', previousRunDelta: number, previousStatus: MachineStatus, delta: number, runDelta: number, status: MachineStatus, time: any }> | null };

export type LocalApiErrorSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type LocalApiErrorSubscription = { __typename?: 'Subscription', localApiError?: { __typename?: 'Error', code?: string | null, message?: string | null } | null };


export const DashboardDocument = gql`
    query Dashboard {
  dashboard {
    lineId
    code
    bottleneckIdealCycle
    currentStateOfLine {
      time
      oee
      availability
      performance
      production
      projection
      stopCount
      smallStopCount
      stopTime
      smallStopTime
      itemTypeCode
      itemTypeName
      speed
      meanSpeed
      delta
      normalCycle
      smallStopCycle
      stopCycle
      idealCycleTime
    }
    reactFlow {
      machines {
        id
        name
        time
        status
        meanSpeed
        maxSpeed
        disabled
        bottleneckMaxSpeed
        bottleneckId
        production
        oee
        stopCount
        stopTime
        positionX
        positionY
        normalCycle
        smallStopCycle
        itemTypeCode
        itemTypeName
        stopCycle
        idealCycleTime
        bottleneckIdealCycle
        bottleneckProduction
      }
      lastItemTime {
        id
        time
      }
      conveyors {
        id
        origin
        target
        speed
        status
        handleFrom
        handleTo
      }
    }
    workshift {
      name
      duration
      startingAt
      endingAt
    }
  }
}
    `;

export function useDashboardQuery(options?: Omit<Urql.UseQueryArgs<DashboardQueryVariables>, 'query'>) {
  return Urql.useQuery<DashboardQuery>({ query: DashboardDocument, ...options });
};
export const TimelineEventDocument = gql`
    subscription TimelineEvent {
  newTimelineEvent {
    previousRunDelta
    previousStatus
    delta
    runDelta
    status
    time
  }
}
    `;

export function useTimelineEventSubscription<TData = TimelineEventSubscription>(options: Omit<Urql.UseSubscriptionArgs<TimelineEventSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<TimelineEventSubscription, TData>) {
  return Urql.useSubscription<TimelineEventSubscription, TData, TimelineEventSubscriptionVariables>({ query: TimelineEventDocument, ...options }, handler);
};
export const LocalApiErrorDocument = gql`
    subscription LocalApiError {
  localApiError {
    code
    message
  }
}
    `;

export function useLocalApiErrorSubscription<TData = LocalApiErrorSubscription>(options: Omit<Urql.UseSubscriptionArgs<LocalApiErrorSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<LocalApiErrorSubscription, TData>) {
  return Urql.useSubscription<LocalApiErrorSubscription, TData, LocalApiErrorSubscriptionVariables>({ query: LocalApiErrorDocument, ...options }, handler);
};