import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { StopRegistration } from '../../components/layout/StopRegistration/StopRegistration';
import { useFeedback } from '../../context/feedback';
import { StopTagType, useRegisterStopMutation, useUpdateStopMutation } from '../../hooks/graphqlQueries';
import { Button } from '../../components/ui/Button';
import { Input } from '../../components/ui/Input';
import { Title } from '../../components/ui/Title';
import { PageOptions } from '../../components/layout/PageOptions';
import { Footer } from '../../components/layout/Footer';

type Stop = {
  id: string;
  name: string;
  description: string;
  tagType: StopTagType;
};

export const StopRegistrationPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const feedback = useFeedback();

  const [disableSave, setDisableSave] = useState(true);
  const routeData = location.state as { data: Stop; pagination: { page: number; limit: number } };
  const [info, setInfo] = useState<Stop>(
    (routeData?.data as Stop) ?? { id: '', name: '', description: '', tagType: StopTagType.Category }
  );

  const [, registerStop] = useRegisterStopMutation();
  const [, updateStop] = useUpdateStopMutation();

  useEffect(() => {
    if (info.name.length > 0) setDisableSave(false);
    else setDisableSave(true);
  }, [info]);

  const handleSave = () => {
    const dialog = () => {
      feedback.confirmation({
        title: 'Deseja cadastrar outra parada?',
        onHandle: (result) => {
          result
            ? setInfo({ id: '', name: '', description: '', tagType: StopTagType.Category })
            : navigate('..', { state: { pagination: routeData?.pagination } });
        },
      });
    };

    if (info.id) {
      updateStop(info)
        .then((result) => {
          if (result.error) {
            feedback.apiError(result.error);
          } else if (result.data?.updateStopTag === null)
            feedback.message({ animation: 'Error', title: 'Erro ao editar parada' });
          else {
            feedback.message({ animation: 'Confetti', title: 'Parada editada com sucesso' });
            navigate('..', { state: { pagination: routeData?.pagination } });
          }
        })
        .catch(() => {
          feedback.message({ animation: 'Error', title: 'Erro ao editar parada' });
        });
    } else {
      registerStop(info)
        .then((result) => {
          if (result.error) {
            feedback.apiError(result.error);
          } else if (result.data?.createStopTag === null) {
            feedback.message({ animation: 'Error', title: 'Erro ao cadastrar parada' });
          } else
            feedback.message({
              animation: 'Confetti',
              title: 'Parada cadastrada com sucesso',
              onClose: () => dialog(),
            });
        })
        .catch(() => {
          feedback.message({ animation: 'Error', title: 'Erro ao cadastrar parada' });
        });
    }
  };

  return (
    <StopRegistration>
      <Title>{info.id ? 'Editar Parada' : 'Cadastrar Parada'}</Title>
      <Input
        label="Nome"
        value={info.name}
        area="Name"
        width="376px"
        onChange={(e) => setInfo({ ...info, name: e.target.value })}
      />
      <Input
        label="Descrição"
        maxLength={100}
        value={info.description}
        area="Desc"
        width="376px"
        onChange={(e) => setInfo({ ...info, description: e.target.value })}
      />
      <Footer>
        <PageOptions>
          <Button
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button title="Salvar" color="primary" disabled={disableSave} onClick={() => handleSave()} />
        </PageOptions>
      </Footer>
    </StopRegistration>
  );
};
