import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/ui/Button';
import { Drop } from '../../components/ui/Drop';
import { StopTagType, useAllStopTagsQuery } from '../../hooks/graphqlQueries';

const InfoTag = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, max-content));
  row-gap: 19px;
  column-gap: 10px;
  align-items: end;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

type Props = {
  onAddItem: (data: { stop: string; reason: string; loss: string }) => void;
};
export const TagTemplate: React.FC<Props> = ({ onAddItem }) => {
  const [problem, setProblem] = useState({ stop: '', reason: '', loss: '' });
  const [stopName] = useAllStopTagsQuery({
    requestPolicy: 'network-only',
    variables: { tagType: StopTagType.Category },
  });
  const [reasonName] = useAllStopTagsQuery({
    requestPolicy: 'network-only',
    variables: { tagType: StopTagType.Reason },
  });
  const [lossName] = useAllStopTagsQuery({
    requestPolicy: 'network-only',
    variables: { tagType: StopTagType.Loss },
  });

  return (
    <InfoTag>
      <Drop
        label="Parada"
        name="Parada"
        value={problem.stop}
        options={stopName.data?.stopTags?.value ?? []}
        onSelect={(stop) => setProblem({ ...problem, stop: stop.name })}
        textOverflowLeftToRight
      />
      <Drop
        label="Perda"
        name="Perda"
        value={problem.loss}
        options={lossName.data?.stopTags?.value ?? []}
        onSelect={(loss) => setProblem({ ...problem, loss: loss.name })}
        textOverflowLeftToRight
      />
      <Drop
        label="Motivo"
        name="Motivo"
        value={problem.reason}
        options={reasonName.data?.stopTags?.value ?? []}
        onSelect={(reason) => setProblem({ ...problem, reason: reason.name })}
        textOverflowLeftToRight
      />
      <Button
        title="Adicionar"
        icon={'CirclePlus'}
        iconSize={'small'}
        color="green"
        onClick={() => {
          onAddItem(problem);
          setProblem({ stop: '', reason: '', loss: '' });
        }}
        disabled={problem.stop === '' || problem.reason === '' || problem.loss === ''}
      />
    </InfoTag>
  );
};
