import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import { Footer } from '../../components/layout/Footer';
import { PageOptions } from '../../components/layout/PageOptions';
import { ParameterRegistration } from '../../components/layout/ParameterRegistration';
import { Button } from '../../components/ui/Button';
import { Drop } from '../../components/ui/Drop';
import { Input } from '../../components/ui/Input';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import {
  ParameterType,
  Unit,
  useLineTitleListQuery,
  useMachinesQuery,
  useRegisterParameterMutation,
  useUpdateParameterMutation,
} from '../../hooks/graphqlQueries';
import { ErrorType, limitsValidator } from '../../scripts/limitsValidator';

const Info = styled.div`
  display: grid;
  grid-area: Info;
  grid-template-columns: 1fr 1fr;
  row-gap: 19px;
  column-gap: 19px;
  align-items: start;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
`;

const DropContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;

  @media (max-width: 500px) {
    row-gap: 20px;
    grid-template-columns: 1fr;
  }
`;

type Parameter = {
  code: string;
  name: string;
  id: string;
  machineId: string;
  machineName: string;
  unit: Unit;
  type: ParameterType;
  superiorLimit: string;
  inferiorLimit: string;
  lineId: string;
  lineCode: string;
};

const newParameter: Parameter = {
  id: '',
  name: '',
  code: '',
  type: ParameterType.Measurement,
  unit: Unit.NoUnit,
  machineId: '',
  machineName: '',
  superiorLimit: '',
  inferiorLimit: '',
  lineId: '',
  lineCode: '',
};

export const ParameterRegistrationPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const feedback = useFeedback();
  const location = useLocation();

  const [disableSave, setDisableSave] = useState(true);
  const [errors, setErrors] = useState<ErrorType>();
  const routeData = location.state as { data: Parameter; pagination: { page: number; limit: number } };
  const [info, setInfo] = useState<Parameter>((routeData?.data as Parameter) ?? newParameter);

  const [lineQuery] = useLineTitleListQuery({
    requestPolicy: 'network-only',
  });
  const lineOptions = useMemo(() => {
    return (
      lineQuery.data?.lines?.value?.map((line) => ({
        id: line.id,
        name: line.code,
      })) ?? []
    );
  }, [lineQuery.data]);
  const [machinesQuery] = useMachinesQuery({ variables: { lineId: info.lineId } });
  const machineOptions = useMemo(() => {
    return (
      machinesQuery.data?.machines?.value?.map((machine) => ({
        id: machine.id,
        name: machine.name,
      })) ?? []
    );
  }, [machinesQuery.data]);

  const [, registerParameter] = useRegisterParameterMutation();
  const [, updateParameter] = useUpdateParameterMutation();

  useEffect(() => {
    const inferiorLimitNumber = parseFloat(info.inferiorLimit);
    const superiorLimitNumber = parseFloat(info.superiorLimit);

    const result = limitsValidator(
      {
        inferiorLimit: inferiorLimitNumber,
        superiorLimit: superiorLimitNumber,
      },
      {
        inferiorGreaterThenSuperior: t('ERROR.INFERIOR_GREATER_THEN_SUPERIOR'),
      }
    );

    setErrors(result);

    if (Object.values(result).length > 0) {
      setDisableSave(true);
    } else {
      for (const key in info) {
        const value = info[key as keyof Parameter];
        if (key !== 'id' && value === '') {
          setDisableSave(true);
          return;
        }
      }
      setDisableSave(false);
    }
  }, [info]);

  const handleSave = () => {
    let inferiorLimitNumber = parseFloat(info.inferiorLimit);
    isNaN(inferiorLimitNumber) && (inferiorLimitNumber = 0);
    let superiorLimitNumber = parseFloat(info.superiorLimit);
    isNaN(superiorLimitNumber) && (superiorLimitNumber = 0);

    const dialog = () =>
      feedback.confirmation({
        title: 'Deseja cadastrar outro parâmetro?',
        onHandle: (result) => {
          result
            ? setInfo(newParameter as Parameter)
            : navigate('..', { state: { pagination: routeData?.pagination } });
        },
      });

    if (info.id) {
      updateParameter({ ...info, inferiorLimit: inferiorLimitNumber, superiorLimit: superiorLimitNumber })
        .then((result) => {
          if (result.error) {
            feedback.apiError(result.error);
          } else if (result.data?.updateParameter === null)
            feedback.message({ animation: 'Error', title: 'Erro ao editar parâmetro' });
          else {
            feedback.message({ animation: 'Confetti', title: 'Parâmetro editado com sucesso' });
            navigate('..', { state: { pagination: routeData?.pagination } });
          }
        })
        .catch(() => {
          feedback.message({ animation: 'Error', title: 'Erro ao editar parâmetro' });
        });
    } else {
      registerParameter({ ...info, inferiorLimit: inferiorLimitNumber, superiorLimit: superiorLimitNumber })
        .then((result) => {
          if (result.error) {
            feedback.apiError(result.error);
          } else if (result.data?.createParameter === null)
            feedback.message({ animation: 'Error', title: 'Erro ao cadastrar parâmetro' });
          else
            feedback.message({
              animation: 'Confetti',
              title: 'Parâmetro cadastrado com sucesso',
              onClose: () => dialog(),
            });
        })
        .catch(() => {
          feedback.message({ animation: 'Error', title: 'Erro ao cadastrar parâmetro' });
        });
    }
  };

  return (
    <ParameterRegistration>
      <Title>{info.id ? 'Editar Parâmetro' : 'Cadastrar Parâmetro'}</Title>
      <Info>
        <Input
          dataCy="name"
          label="Nome"
          value={info.name}
          width="376px"
          onChange={(e) => setInfo({ ...info, name: e.target.value })}
        />
        <Input
          dataCy="plc-address"
          label="Código"
          value={info.code}
          width="376px"
          onChange={(e) => setInfo({ ...info, code: e.target.value })}
        />
        <Input
          dataCy="inferior-limit"
          type="number"
          label="Limite Inferior"
          value={info.inferiorLimit}
          width="376px"
          onChange={(e) => {
            const valueNumbers = e.target.value;
            setInfo({ ...info, inferiorLimit: valueNumbers });
          }}
          errorMessage={errors?.inferiorGreaterThenSuperior ? true : false}
          message={errors?.inferiorGreaterThenSuperior}
        />
        <Input
          dataCy="superior-limit"
          type="number"
          label="Limite Superior"
          value={info.superiorLimit}
          width="376px"
          onChange={(e) => {
            const valueNumbers = e.target.value;
            setInfo({ ...info, superiorLimit: valueNumbers });
          }}
        />
        <DropContainer>
          <Drop
            dataCy="line"
            name="Linha"
            label="Linha"
            options={lineOptions}
            onSelect={(line) =>
              setInfo({ ...info, lineCode: line.name, lineId: line.id, machineName: '', machineId: '' })
            }
            value={info.lineCode}
            errorMessage={lineOptions.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined}
          />
          <Drop
            dataCy="machine"
            name="Máquina"
            label="Máquina"
            options={machineOptions}
            onSelect={(machine) => setInfo({ ...info, machineName: machine.name, machineId: machine.id })}
            value={info.machineName}
            disabled={!info.lineId}
            errorMessage={machinesQuery.data === 0 ? 'Não existe nenhuma máquina cadastrada.' : undefined}
          />
        </DropContainer>
        <Drop
          dataCy="type"
          name="Tipo do parâmetro"
          label="Tipo do parâmetro"
          size={'large'}
          required
          value={t(info.type)}
          options={Object.keys(ParameterType).map((value) => ({
            id: value,
            name: t(ParameterType[value as keyof typeof ParameterType]),
          }))}
          onSelect={(type) => setInfo({ ...info, type: ParameterType[type.id as keyof typeof ParameterType] })}
          errorMessage={Object.keys(ParameterType).length === 0 ? 'Não existe nenhum parâmetro cadastrado.' : undefined}
        />
        <Drop
          dataCy="unit"
          name="Unidade"
          label="Unidade"
          size="large"
          required
          value={t(info.unit)}
          options={Object.keys(Unit).map((value) => ({ id: value, name: t(Unit[value as keyof typeof Unit]) }))}
          onSelect={(unit) => setInfo({ ...info, unit: Unit[unit.id as keyof typeof Unit] })}
          direction="up"
          errorMessage={Object.keys(Unit).length === 0 ? 'Não existe nenhuma unidade de medida cadastrada.' : undefined}
        />
      </Info>
      <Footer>
        <PageOptions>
          <Button
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button
            dataCy="save-button"
            title="Salvar"
            color="primary"
            disabled={disableSave}
            onClick={() => handleSave()}
          />
        </PageOptions>
      </Footer>
    </ParameterRegistration>
  );
};
