import { addDays, format, parseISO } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/ui/Button';
import { Calendar } from '../../components/ui/Calendar';
import { Drop } from '../../components/ui/Drop';
import { AttributeType, HistoryTable } from '../../components/ui/HistoryTable';
import { MultiDropdown } from '../../components/ui/MultiDropdown';
import { Pagination } from '../../components/ui/Pagination';
import { Title } from '../../components/ui/Title';
import { History } from '../../components/layout/History';
import { useNavigate } from 'react-router-dom';
import {
  useLinesFromFactoryQuery,
  useMachinesFromLineQuery,
  useProductionCycleHistoryQuery,
  useScrapFactoriesNameQuery,
  WorkShift,
} from '../../hooks/graphqlQueries';
import { useTranslation } from 'react-i18next';
import { downloadFile, makeCSVFromTable } from '../../scripts/exportFiles';
import { toEndShiftC, toStartShiftA } from '../../scripts/times';
import { ClearFilter } from '../../components/layout/History/History';
import { Icon } from '../../components/ui/Icon';
import { LoaderLogo, MouraLogo } from '../../components/layout/Loading/Loading';
import { Logo } from '../../components/ui/Logo';
import { useFeedback } from '../../context/feedback';
import { asString } from 'ts-runtime-typecheck';
import { getPagination } from '../../scripts/pagination';
import { Footer } from '../../components/layout/Footer';
import { PageOptions } from '../../components/layout/PageOptions';
import { parsePageFilters } from '../../scripts/parses';
import { isEnabledAction, countValidAttributes } from '../../scripts/objectHelper';
import { StyledErrorMessage } from '../../components/ui/Input/Input';
import { periodFilterValidator, ErrorType } from '../../scripts/periodFilterValidator';

const attributes: Array<AttributeType> = [
  { name: 'Linha', attr: 'line', dataCy: 'table-col-line' },
  { name: 'Máquina', attr: 'machine', dataCy: 'table-col-machine' },
  { name: 'Turno', attr: 'workShift', dataCy: 'table-col-work-shift' },
  { name: 'Produção', attr: 'amount', dataCy: 'table-col-amount' },
  { name: 'Projeção', attr: 'projection', dataCy: 'table-col-projection' },
  { name: 'Produção Acumulada', attr: 'production', dataCy: 'table-col-production' },
  { name: 'Hora', attr: 'time', dataCy: 'table-col-time' },
];

const shiftTimes = [
  { id: WorkShift.ShiftA, name: 'Turno A' },
  { id: WorkShift.ShiftB, name: 'Turno B' },
  { id: WorkShift.ShiftC, name: 'Turno C' },
];

const Filters = styled.div`
  display: grid;
  grid-area: Filters;
  grid-template-columns: repeat(auto-fit, minmax(179px, max-content));
  column-gap: 20px;
  row-gap: 10px;
  width: 1fr;
  justify-content: right;

  @media (max-width: 660px) {
    justify-content: center;
  }
`;

const Table = styled.div`
  display: grid;
  grid-area: Table;
  justify-content: space-around;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 1168px) {
    &&:after {
      font-size: 12px;
      padding-top: 5px;
      content: 'Deslize a tabela para os lados';
    }
  }
`;

const fmt: Intl.NumberFormatOptions = {
  maximumFractionDigits: 2,
  useGrouping: false,
};

type PageFilters = {
  page: number;
  limit: number;
  filters: {
    factory?: { id: string; name: string };
    line?: { id: string; name: string };
    machine?: { id: string; name: string };
    workShift: WorkShift[];
    startDate: Date;
    endDate: Date;
  };
};

const initialParameters: PageFilters = {
  page: 1,
  limit: 10,
  filters: {
    factory: undefined,
    line: undefined,
    machine: undefined,
    workShift: [],
    startDate: toStartShiftA(new Date()),
    endDate: toEndShiftC(new Date()),
  },
};

export const ProductionCycleHistoryPage: React.FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const feedback = useFeedback();

  const rescuedFilters = sessionStorage.getItem('GlobalHistoryFilter');
  const storagedFilters: PageFilters = parsePageFilters(rescuedFilters, initialParameters) as PageFilters;
  const [parameters, setParameters] = useState(
    storagedFilters
      ? {
          ...storagedFilters,
          limit: 10,
          page: 1,
          filters: {
            ...storagedFilters.filters,
            startDate: toStartShiftA(new Date(storagedFilters.filters.startDate)),
            endDate: toEndShiftC(new Date(storagedFilters.filters.startDate)),
          },
        }
      : initialParameters
  );

  const [factoryName] = useScrapFactoriesNameQuery({ requestPolicy: 'network-only' });
  const [lineName] = useLinesFromFactoryQuery({
    pause: parameters.filters.factory === undefined,
    requestPolicy: 'network-only',
    variables: { factoryId: parameters.filters.factory?.id ?? '' },
  });
  const [machineName] = useMachinesFromLineQuery({
    pause: parameters.filters.line?.id === undefined,
    requestPolicy: 'network-only',
    variables: { lineId: parameters.filters.line?.id ?? '' },
  });

  const [isExportData, setIsExportData] = useState(false);
  const dataExportLimit = parseInt(asString(process.env['DATA_EXPORT_LIMIT']));
  const [isFiltersValid, setIsFiltersValid] = useState({ general: false, period: false });
  const maxPeriodFilterLimit = parseInt(asString(process.env['CYCLES_PERIOD_FILTER_LIMIT'] || '30'));
  const periodStartFilterLimit = parseInt(asString(process.env['CYCLES_PERIOD_FILTER_START_LIMIT'] || '7'));
  const [errors, setErrors] = useState<ErrorType>();

  const [productionCyclesHistoryQuery] = useProductionCycleHistoryQuery({
    pause: !isFiltersValid.general,
    variables: {
      ...getPagination(parameters.page, parameters.limit, { dataExportLimit, isExportData }),
      ...parameters.filters,
      lineId: parameters.filters.line?.id,
      machineId: parameters.filters.machine?.id,
    },
    requestPolicy: 'network-only',
  });
  const productionCyclesHistory = useMemo(
    () => productionCyclesHistoryQuery.data?.machineStates?.value ?? [],
    [productionCyclesHistoryQuery.data]
  );

  const count = useMemo(
    () => productionCyclesHistoryQuery.data?.machineStates?.count ?? 0,
    [productionCyclesHistoryQuery.data]
  );

  useEffect(() => {
    //refetch();
    const countFilter = countValidAttributes(parameters.filters, ['factory', 'line', 'machine', 'workShift']);
    const calculatedPeriod = countFilter * periodStartFilterLimit;
    const newPeriod = calculatedPeriod < maxPeriodFilterLimit ? calculatedPeriod : maxPeriodFilterLimit;
    const validation = { general: false, period: false };
    const error_text = t('ERROR.PERIOD_BIGGER_THAN').split('|') || " | ";

    const result = periodFilterValidator(
      {
        startDate: parameters.filters.startDate,
        endDate: parameters.filters.endDate,
        maxPeriodLimit: newPeriod
      },
      {
        limitGreaterThenSuperior:  `${error_text[0]} ${newPeriod} ${error_text[1]}`
      }
    )

    setErrors(result);

    if (Object.values(result).length == 0) {
      validation.period = true;
    }

    isEnabledAction(
      parameters.filters,
      (result) => {
        validation.general = !validation.period ? false : result;
      },
      { ignoreFields: ['workShift'] }
    );

    if(!validation.general){
      setTableData([])
    }

    setIsFiltersValid(validation);
  }, [parameters.filters]);

  useEffect(() => {
    sessionStorage.setItem('GlobalHistoryFilter', JSON.stringify(parameters));
  }, [parameters]);

  const data = useMemo(
    () =>
      productionCyclesHistory.map((value) => {
        return {
          id: value.machineId,
          line: value.machine?.line?.code ?? '',
          machine: value.machine?.code.split('.')[4] ?? '',
          workShift: t(value.workShift),
          amount: value.amount.toLocaleString(i18n.language, fmt),
          projection: value.projection.toLocaleString(i18n.language, fmt),
          production: value.production.toLocaleString(i18n.language, fmt),
          time: format(parseISO(value.time), 'HH:mm:ss.S'),
        };
      }),
    [productionCyclesHistory]
  );

  const [tableData, setTableData] = useState<typeof data>(data);
  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');

  useEffect(() => {
    if (isExportData) {
      downloadFile(`historico-ciclos-de-produção-${thisDate}`, makeCSVFromTable(attributes, data));
      setIsExportData(false);

      if (data.length === dataExportLimit)
        feedback.message({
          animation: 'Error',
          title: 'Limite de dados atingido!',
          subtitle: `Não é possível exportar mais que ${dataExportLimit} linhas de dados.`,
        });
    } else setTableData(data);
  }, [data]);

  return (
    <History>
      <Title>Ciclos de Produção</Title>
      <Filters>
        <Drop
          label="Unidade*"
          name="Unidade"
          value={parameters.filters.factory?.name}
          options={factoryName.data?.factories?.value ?? []}
          onSelect={(factory) =>
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, factory, line: undefined, machine: undefined },
            })
          }
          errorMessage={
            factoryName.data?.factories?.value?.length === 0 ? 'Não existe nenhuma unidade cadastrada.' : undefined
          }
        />
        <Drop
          label="Linha*"
          name="Linha"
          value={parameters.filters.line?.name}
          options={lineName.data?.lines?.value?.map((line) => ({ id: line.id, name: line.code })) ?? []}
          onSelect={(line) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, line, machine: undefined },
            });
          }}
          disabled={!parameters.filters.factory?.id}
          errorMessage={lineName.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined}
        />
        <Drop
          label="Máquina*"
          name="Máquina"
          value={parameters.filters.machine?.name}
          options={machineName.data?.machines?.value?.map((machine) => ({ id: machine.id, name: machine.code })) ?? []}
          onSelect={(machine) =>
            setParameters({ limit: parameters.limit, page: 1, filters: { ...parameters.filters, machine } })
          }
          disabled={!parameters.filters.line?.id}
          errorMessage={
            machineName.data?.machines?.value?.length === 0 ? 'Não existe nenhuma máquina cadastrada.' : undefined
          }
        />
        <MultiDropdown
          label="Turno"
          name="Turno"
          value={parameters.filters.workShift ?? []}
          options={shiftTimes}
          onBlur={(shifts) => {
            setParameters({
              limit: parameters.limit,
              page: 1,
              filters: { ...parameters.filters, workShift: shifts.map((el) => el.id as WorkShift) },
            });
          }}
          errorMessage={shiftTimes.length === 0 ? 'Não existe nenhum turno cadastrado.' : undefined}
        />
        <Calendar
          label="Data*"
          placeholder="Data"
          value={format(parameters.filters.startDate, 'yyyy-MM-dd')}
          onChange={(e) => {
            const startDate = parseISO(e.target.value);
            const endDate = addDays(parseISO(e.target.value), 1);
            if (e.target.value.length == 10)
              setParameters({
                limit: parameters.limit,
                page: 1,
                filters: {
                  ...parameters.filters,
                  startDate,
                  endDate,
                },
              });
            }
          }
          onBlur={(date) => {
            const startDate = parseISO(date.target.value);
            const endDate = addDays(parseISO(date.target.value), 1);
            if (date.target.value.length == 10)
              setParameters({
                limit: parameters.limit,
                page: 1,
                filters: {
                  ...parameters.filters,
                  startDate,
                  endDate,
                },
              });
          }}
        />
      </Filters>
      {parameters.filters.line && (
        <ClearFilter
          onClick={() => {
            setParameters(initialParameters);
            sessionStorage.removeItem('GlobalHistoryFilter');
          }}
        >
          <Icon name="Cross" size="smaller" color="white" />
          <span>Limpar Filtros</span>
        </ClearFilter>
      )}
      {errors?.limitGreaterThenSuperior && (
          <StyledErrorMessage data-cy="field-message" maxWidth='parent'>
            { errors?.limitGreaterThenSuperior}
          </StyledErrorMessage>
      )}
      <Table>
        <HistoryTable attributes={attributes} content={tableData} size={'large'} loading={productionCyclesHistoryQuery.fetching && (!isExportData)}/>
      </Table>
      <Footer>
        <PageOptions>
          <Button title="Menu históricos" color="light" icon="ArrowLeft" onClick={() => navigate('..')} />
          <Button title="Exportar" icon="Export" disabled={data.length == 0} onClick={() => setIsExportData(true)} />
        </PageOptions>
        <Pagination
          numberOfItems={parameters.limit}
          onSelectNumber={(number) => {
            setParameters({ ...parameters, page: 1, limit: number });
          }}
          page={parameters.page}
          totalPages={Math.ceil(count / parameters.limit)}
          onPrevious={() => setParameters({ ...parameters, page: parameters.page - 1 })}
          onNext={() => setParameters({ ...parameters, page: parameters.page + 1 })}
        />
      </Footer>

      {isExportData && (
        <LoaderLogo data-cy="loading">
          <MouraLogo>
            <Logo style="onlyIcon" />
          </MouraLogo>
        </LoaderLogo>
      )}
    </History>
  );
};
