import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Footer } from '../../components/layout/Footer';
import { ItemRegistration } from '../../components/layout/ItemRegistration';
import { PageOptions } from '../../components/layout/PageOptions';
import { Button } from '../../components/ui/Button';
import { Input } from '../../components/ui/Input';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import { RuntimeOptions, useUpdateItemMutation } from '../../hooks/graphqlQueries';

const InfoDetails = styled.div`
  display: grid;
  grid-area: InfoDetails;
  grid-template-columns: 1fr 1fr;
  row-gap: 19px;
  column-gap: 19px;
  align-items: end;

  @media (max-width: 660px) {
    justify-content: center;
    align-items: center;
  }
`;

type Item = {
  id: string;
  name: string;
  code: string;
  description: string;
  possibleProblems: { stop: string; reason: string; loss: string }[];
  runtimeOptions: RuntimeOptions[];
};

function createItemInfo() {
  return {
    name: '',
    code: '',
    description: '',
  };
}

export const ItemTypeEditPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const feedback = useFeedback();

  const [, updateItem] = useUpdateItemMutation();

  const routeData = location.state as { data: Item; pagination: { page: number; limit: number } };
  const itemBefore = { ...(routeData?.data as Item) };

  const [info, setInfo] = useState<Item>(itemBefore ?? createItemInfo());
  const [disableSave, setDisableSave] = useState(true);

  useEffect(() => {
    if (info.name.length > 0 && info.code.length > 0) setDisableSave(false);
    else setDisableSave(true);
  }, [info]);

  const handleSave = () => {
    updateItem(info)
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.updateItemType === null)
          feedback.message({ animation: 'Error', title: 'Erro ao editar tipo de item' });
        else {
          feedback.message({ animation: 'Confetti', title: 'Tipo de item editado com sucesso' });
          navigate('..', { state: { pagination: routeData?.pagination } });
        }
      })
      .catch(() => {
        feedback.message({ animation: 'Error', title: 'Erro ao editar tipo de item' });
      });
  };

  return (
    <ItemRegistration>
      <Title>Editar Tipo de Item</Title>
      <InfoDetails>
        <Input
          dataCy="name-field"
          label="Nome do Tipo"
          value={info.name}
          width="376px"
          onChange={(e) => setInfo({ ...info, name: e.target.value })}
        />
        <Input
          dataCy="code-field"
          label="Código do Tipo"
          value={info.code}
          width="376px"
          onChange={(e) => setInfo({ ...info, code: e.target.value })}
        />
        <Input
          dataCy="description-field"
          label="Descrição"
          maxLength={100}
          value={info.description}
          width="376px"
          onChange={(e) => setInfo({ ...info, description: e.target.value })}
        />
        <div></div>
      </InfoDetails>
      <Footer>
        <PageOptions>
          <Button
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button
            dataCy="register-button"
            title="Salvar"
            color="primary"
            disabled={disableSave}
            onClick={() => handleSave()}
          />
        </PageOptions>
      </Footer>
    </ItemRegistration>
  );
};
