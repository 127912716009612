import React from 'react';
import styled from 'styled-components';

const StyledStops = styled.div`
  display: grid;
  grid-area: 'Content';
  grid-template:
    'Title Title .' max-content
    'Filters Filters Filters' max-content
    'Table Table Table' max-content
    '. . Pagination' 45px;
  grid-row-gap: 25px;
  width: max-content;
  max-width: 90vw;
  margin: 55px auto;

  @media (max-width: 660px) {
    grid-template-columns: 1fr;
    grid-template:
      'Icon' 25px
      'Title' max-content
      'Filters' max-content
      'Drop' max-content
      'Table' max-content
      'Pagination' 45px;
    max-width: 90vw;
    margin: 55px auto;
  }
`;
type Props = {
  children: React.ReactNode;
};
export const Stops: React.FC<Props> = ({ children }) => <StyledStops>{children}</StyledStops>;
