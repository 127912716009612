import React, { useEffect, useMemo, useState } from 'react';
import { Chart } from '../../components/ui/Chart';
import { useTheme } from 'styled-components';
import { useAggregatedScrapByQuery, ScrapBy, ScrapByObject, WorkShift } from '../../hooks/graphqlQueries';
import { ChartContextType } from '../ChartFilterInterval';
import { useOutletContext } from 'react-router-dom';

type ChartType = 'LINE' | 'BAR' | 'STACKBAR';

const scrapChartTemplate = (scrap: ScrapByObject[]) => {
  return scrap?.length > 0
    ? scrap.map((value) => ({
        x: value.name,
        y: value.amount,
        hover: `(${value.amount})
          ${value.details
            ?.map((value) => {
              return `<br><b>${value?.name ?? 'Scrap'}</b>: ${value?.amount}`;
            })
            .join('')}`,
      }))
    : undefined;
};

const mountParetoChart = (color: { barColor: string; lineColor: string }, scrapComponent: ScrapByObject[]) => {
  // formats all queries to x, y and hover format
  const scrapComponentMount = scrapChartTemplate(scrapComponent);

  // groups the data
  const components = scrapComponentMount ?? [];

  // makes all data lists one for the chart model and calculates the accumulation
  const chartDataTemplate = [...components]
    .filter((v) => v)
    .reduce(
      function (prev, curr, index) {
        return {
          x: [...prev.x, curr?.x ?? ''],
          y: [...prev.y, curr?.y ?? 0],
          acc: [...prev.acc, (prev.acc[index - 1] ?? 0) + (curr?.y ?? 0)],
          hover: [...prev.hover, curr?.hover ?? ''],
        };
      },
      {
        x: new Array<string>(),
        y: new Array<number>(),
        hover: new Array<string>(),
        acc: new Array<number>(),
      }
    );

  // calculates pareto accumulation and assembles the data into the chart model
  const totalScrap = chartDataTemplate.y.reduce((prev, curr) => prev + curr, 0);

  const accumulationLine = chartDataTemplate.acc.map((value) => parseFloat(((value / totalScrap) * 100).toFixed(2)));

  const paretoChartComposition = [
    {
      x: chartDataTemplate.x,
      y: chartDataTemplate.y,
      name: 'Scrap (unidades)',
      hoverData: chartDataTemplate.hover,
      text: chartDataTemplate.hover,
      textposition: 'auto',
      chartType: 'BAR' as ChartType,
      marker: {
        color: color.barColor,
      },
      color: color.barColor,
    },
    {
      x: chartDataTemplate.x,
      y: accumulationLine,
      name: 'Acumulado (porcentagem)',
      yaxis: 'y2',
      chartType: 'LINE' as ChartType,
      marker: {
        color: color.lineColor,
      },
      color: color.lineColor,
    },
  ];

  return {
    exportData: [
      ['origem', ...chartDataTemplate.x],
      ['scrap', ...chartDataTemplate.y],
      ['acumulado', ...accumulationLine],
    ],
    paretoChart: paretoChartComposition,
  };
};

const filterBySelect: { [key in string]: ScrapBy } = {
  '1': ScrapBy.Defect,
  '2': ScrapBy.Item,
  '3': ScrapBy.Machine,
};

type OptionsFilterByType = { id: string; name: string };

const options: OptionsFilterByType[] = [
  {
    id: '1',
    name: 'Falha',
  },
  {
    id: '2',
    name: 'Item',
  },
  {
    id: '3',
    name: 'Máquina',
  },
];

export const ScrapChart: React.FC = () => {
  const { filter, setExportData, isFiltersValid } = useOutletContext<ChartContextType>();
  const [filterBy, setFilterBy] = useState<OptionsFilterByType>(options[1] as OptionsFilterByType);
  const theme = useTheme();

  const paretoChartColor = useMemo(() => {
    return {
      barColor: theme.colors.chart.customChart.scrapChart.barColor,
      lineColor: theme.colors.chart.customChart.scrapChart.lineColor,
    };
  }, [theme]);

  const [aggregatedScrapByQuery] = useAggregatedScrapByQuery({
    //pause: filter.factory === undefined || filter.line === undefined,
    pause: !isFiltersValid.general,
    requestPolicy: 'network-only',
    variables: {
      filter: {
        by: filterBySelect[filterBy.id],
        lineId: filter?.line?.id ?? '',
        machineId: filter?.machine?.id ?? '',
        workShift: (filter?.multipleShifts?.map((s) => s.id) ?? []) as WorkShift[],
        startDate: filter?.startDate,
        endDate: filter?.endDate,
      },
    },
  });

  const showFetching = aggregatedScrapByQuery.fetching || filter?.loading === true;
  const aggregatedScrapBy = useMemo(
    () => aggregatedScrapByQuery.data?.aggregatedScrapBy?.data,
    [aggregatedScrapByQuery]
  );
  const data = useMemo(
    () => mountParetoChart(paretoChartColor, aggregatedScrapBy as ScrapByObject[]),
    [aggregatedScrapBy, paretoChartColor]
  );

  useEffect(() => {
    setExportData(data.exportData);
  }, [data]);

  return (
    <Chart
      chartType={'BAR'}
      data={data.paretoChart}
      optionFilterByInitial={options[1]}
      optionsFilterBy={options}
      selectFilterBy={(filterBy) => setFilterBy(filterBy)}
      loading={showFetching}
    />
  );
};
