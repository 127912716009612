import React, { useEffect, useMemo } from 'react';
import { Chart } from '../../components/ui/Chart';
import { useTheme } from 'styled-components';
import { Period, ProductionResponse, useProductionChartQuery, WorkShift } from '../../hooks/graphqlQueries';
import { useOutletContext } from 'react-router-dom';
import { ChartContextType } from '../ChartFilterInterval';
import { format, parseISO } from 'date-fns';

type ChartType = 'LINE' | 'BAR' | 'STACKBAR';

type BarTemplate = { x: Array<Date>; y: Array<number>; hover: Array<string> };
function newBar() {
  return { x: new Array<Date>(), y: new Array<number>(), hover: new Array<string>() } as BarTemplate;
}

function addPoint(bar: BarTemplate, x: Date | undefined | null, y: number, hover: string) {
  if (!x) return;
  const capacity = Math.round(y * 100) / 100;
  bar.x.push(x);
  bar.y.push(capacity);
  bar.hover.push(`<b>${hover}</b>: ${capacity}<br>`);
}

const mountStackBarChart = (color: string, intervalMinutes: number, stateData: ProductionResponse[]) => {
  const capacity: BarTemplate = newBar();
  if (stateData ?? 0 > 0) {
    for (const state of stateData) {
      const capacityValue = (state.production ?? 0) / intervalMinutes;
      const date = parseISO(state?.day ?? '');
      addPoint(capacity, date, capacityValue, 'Capacidade');
    }
  }

  const chartComposition = [
    {
      x: capacity.x,
      y: capacity.y,
      textposition: 'auto',
      name: 'Capacidade',
      width: 0.2,
      hoverData: capacity.hover,
      hoverLabel: {
        bgcolor: '',
        bordercolor: '',
        font: {
          color: '',
        },
      },
      chartType: 'LINE' as ChartType,
      marker: { color: color },
      color: color,
    },
  ];

  return {
    exportData: [
      ['hora', ...capacity.x.map((time) => format(parseISO(time.toISOString()), 'dd/MM/yyyy HH:mm:ss'))],
      ['capacidade', ...capacity.y],
    ],
    chartData: chartComposition,
  };
};

const periodToMinutes = {
  P1_MIN: 1,
  P10_MIN: 10,
  P15_MIN: 15,
  P30_MIN: 30,
  P1_HOUR: 60,
  P8_HOUR: 480,
  P24_HOUR: 1440,
};

export const CapacityChart: React.FC = () => {
  const { filter, setExportData, isFiltersValid } = useOutletContext<ChartContextType>();
  const theme = useTheme();

  const capacityChartColor = useMemo(() => {
    return theme.colors.chart.customChart.capacityChart.value;
  }, [theme]);

  const intervalMinutes = useMemo(() => {
    if (!filter.period) return 1;
    return periodToMinutes[filter.period?.id as Period] ?? 1;
  }, [filter.period]);

  const [stateQuery] = useProductionChartQuery({
    //pause: filter?.line === undefined || filter?.multipleShifts === undefined || filter.period === undefined,
    pause: !isFiltersValid.general,
    requestPolicy: 'network-only',
    variables: {
      lineId: filter?.line?.id,
      machineId: filter?.machine?.id,
      workShift: (filter?.multipleShifts?.map((s) => s.id) as WorkShift[]) ?? [],
      period: filter.period?.id as Period,
      startDate: filter.startDate,
      endDate: filter.endDate,
    },
  });
  const state = useMemo(() => stateQuery.data?.production, [stateQuery]);
  const data = useMemo(
    () => mountStackBarChart(capacityChartColor, intervalMinutes, state as ProductionResponse[]),
    [state, capacityChartColor]
  );

  useEffect(() => {
    setExportData(data.exportData);
  }, [data]);

  return (
    <Chart
      chartType={'LINE'}
      capacity={true}
      data={data.chartData}
      loading={stateQuery.fetching || filter?.loading === true}
    />
  );
};
