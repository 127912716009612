import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Selection } from '../../components/layout/Selection';
import { Title } from '../../components/ui/Title';
import { routes } from '../../Routes';
import { SelectionCard, SelectionCardLayout } from '../../components/ui/Cards';
import { usePermission } from '../../context/permission';
import { UserProfile } from '../../hooks/graphqlQueries';

export const SelectRegistration: React.FC = () => {
  const navigate = useNavigate();
  const profile = usePermission();

  return (
    <Selection>
      <Title data-cy="select-registration">Cadastro</Title>
      <SelectionCardLayout>
        {[UserProfile.Admin].includes(profile.permission as UserProfile) && (
          <SelectionCard
            title="Fábrica"
            dataCy="factory-list"
            icon="OeeColored"
            onClick={() => navigate(routes.registration.factories)}
          />
        )}
        <SelectionCard title="Linha" icon="OeeColored" onClick={() => navigate(routes.registration.lines)} />
        <SelectionCard
          dataCy="machines-list"
          title="Máquina"
          icon="MachineColored"
          onClick={() => navigate(routes.registration.machines)}
        />
        {[UserProfile.Supervisor, UserProfile.Admin].includes(profile.permission as UserProfile) && (
          <SelectionCard title="Usuário" icon="UserColored" onClick={() => navigate(routes.registration.users)} />
        )}
        <SelectionCard title="Defeito" icon="DefectColored" onClick={() => navigate(routes.registration.defects)} />
        <SelectionCard title="Parada" icon="StopColored" onClick={() => navigate(routes.registration.stops)} />
        <SelectionCard title="Motivo" icon="ReasonColored" onClick={() => navigate(routes.registration.reasonLabels)} />
        <SelectionCard title="Perda" icon="LossColored" onClick={() => navigate(routes.registration.lossLabels)} />
        <SelectionCard
          title="Parâmetro"
          icon="ParamsColored"
          onClick={() => navigate(routes.registration.parameters)}
        />
        <SelectionCard
          title="Tipo de Item"
          dataCy="item-list"
          icon="BatteryColored"
          onClick={() => navigate(routes.registration.types)}
        />
        {[UserProfile.Admin].includes(profile.permission as UserProfile) && (
          <SelectionCard
            title="Aplicação"
            dataCy="application-list"
            icon="OeeColored"
            onClick={() => navigate(routes.registration.applications)}
          />
        )}
        <SelectionCard title="Grupos" icon="UserColored" onClick={() => navigate(routes.registration.groups)} />
        <SelectionCard
          title="Scraps"
          dataCy="scrap-list"
          icon="OeeColored"
          onClick={() => navigate(routes.registration.scraps)}
        />
      </SelectionCardLayout>
    </Selection>
  );
};
