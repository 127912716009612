import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Bell: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0563 7.7143C18.0563 6.00867 17.3787 4.37289 16.1726 3.16683C14.9666 1.96076 13.3308 1.2832 11.6252 1.2832C9.91953 1.2832 8.28375 1.96076 7.07769 3.16683C5.87162 4.37289 5.19406 6.00867 5.19406 7.7143C5.19406 15.2172 1.97852 17.3609 1.97852 17.3609H21.2718C21.2718 17.3609 18.0563 15.2172 18.0563 7.7143Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4791 21.6484C13.2907 21.9733 13.0202 22.2429 12.6948 22.4304C12.3693 22.6178 12.0004 22.7165 11.6248 22.7165C11.2493 22.7165 10.8803 22.6178 10.5549 22.4304C10.2294 22.2429 9.95895 21.9733 9.77051 21.6484"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
