import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Moon: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3198 13.8217C15.0482 14.1748 16.8423 14.0122 18.4791 13.354C17.8091 15.0213 16.6554 16.45 15.1667 17.4562C13.6779 18.4624 11.922 19.0001 10.1251 19C8.0355 18.9977 6.01174 18.2687 4.40077 16.9378C2.7898 15.6069 1.69195 13.757 1.29543 11.7054C0.898906 9.65374 1.2284 7.52805 2.22744 5.69272C3.22648 3.85739 4.83282 2.42673 6.77111 1.646C6.11291 3.28277 5.95027 5.0769 6.30339 6.80535C6.65651 8.53381 7.50984 10.1204 8.75729 11.3678C10.0047 12.6153 11.5913 13.4686 13.3198 13.8217Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
