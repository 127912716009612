import { format, parseISO } from 'date-fns';

export function formatDateForChart(dates: string[], startDate: string, endDate: string): string[] {
  if (dates.length > 0) {
    const startDateISO = parseISO(startDate);
    const endDateISO = parseISO(endDate);

    if (startDateISO.getFullYear() !== endDateISO.getFullYear())
      return dates.map((date) => format(parseISO(date), "dd/MM/yy HH:mm"));
    else if (startDateISO.getMonth() !== endDateISO.getMonth())
      return dates.map((date) => format(parseISO(date), "dd/MM HH:mm"));
    else if (startDateISO.getDate() !== endDateISO.getDate())//[BUGFIX]
      return dates.map((date) => format(parseISO(date), "dd HH:mm"));
    else return dates.map((date) => format(parseISO(date), 'HH:mm'));
  }
  return dates;
}
