import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Minus: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 17 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.70264 1.91846H15.7026" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
