export interface Timechoice {
  hours: number[] | null;
  minutes: number[] | null;
}

export interface Duration {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

// The amounts of time below are the convertion of them to milliseconds.
export const SECONDS = 1000;
export const MINUTES = 60 * SECONDS;
export const HOURS = 60 * MINUTES;
export const DAYS = 24 * HOURS;

export const DaysOfWeekShort = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
export const Months = [
  'janeiro',
  'fevereiro',
  'março',
  'abril',
  'maio',
  'junho',
  'julho',
  'agosto',
  'setembro',
  'outubro',
  'novembro',
  'dezembro',
];

export const HoursOfDay = new Array(24).fill(0).map((_, i) => i);
export const MinutesOfHour = new Array(60).fill(0).map((_, i) => i);
export const HoursOfDayMs = HoursOfDay.map((h) => h * HOURS);
export const MinutesOfHourMs = MinutesOfHour.map((m) => m * MINUTES);

/**
 * @param date The date to be formatted into the calendar format.
 * @param placeholder If the date is not set, the placeholder will be used. If
 * the placeholder is not set, the date will be formatted with a standard
 * blank date format.
 * @returns A string with the date formatted as "DD/MM/yyyy HH:mm".
 */
export function formatForCalendar(date?: Date, placeholder?: string) {
  if (!date && !placeholder) return '--/--/---- --:--';
  if (!date) return placeholder;
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().padStart(4, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const minute = date.getMinutes().toString().padStart(2, '0');
  return `${day}/${month}/${year} ${hour}:${minute}`;
}

/**
 * Returns an array with all days present in the given interval, from start to
 * end.
 */
export function eachDayOfInterval(start: Date, end: Date) {
  const days: Date[] = [];
  for (let i = start.getTime(); i <= end.getTime(); i += DAYS) days.push(new Date(i));
  return days;
}

/**
 * Returns an array of groups of the specified size. The groups cover all
 * elements of the original array, without gaps or overlaps and in order.
 */
export function chunk<T>(array: T[], size: number) {
  const chunked: T[][] = [];
  for (let i = 0; i < array.length; i += size) chunked.push(array.slice(i, i + size));
  return chunked;
}

/**
 * Returns true if the first date is of the same month and year as the second
 * date.
 */
export function isSameMonth(first: Date, second: Date): boolean {
  return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth();
}

/**
 * Returns true if the first date is of the same day, month and year as the
 * second date.
 */
export function isSameDay(first?: Date, second?: Date): boolean {
  if (first === undefined && second === undefined) return true;
  if (first === undefined || second === undefined) return false;
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  );
}

/**
 * Returns true if the specified date is in the current day.
 */
export function isToday(date: Date): boolean {
  const today = new Date();
  return isSameDay(date, today);
}

/**
 * @param first The first date to be compared.
 * @param second The second date to be compared.
 * @returns True if the first date is before the second date.
 */
export function isBefore(first: Date | string, second: Date | string): boolean {
  return new Date(first).getTime() < new Date(second).getTime();
}

/**
 * Returns the title of the calendar in the format "julho de 2022", which is
 * "{monthName} de {fullYear}".
 */
export function titleOfCalendar(date: Date) {
  const month = Months[date.getMonth()];
  const year = date.getFullYear();
  return `${month} de ${year}`;
}

/**
 * Adds the specified amount of days to the date.
 */
export function addDays(from: Date, days: number) {
  return new Date(from.getTime() + days * DAYS);
}

/**
 * Adds the specified amount of months to the date.
 */
export function addMonths(from: Date, months: number) {
  const date = new Date(from.getTime());
  date.setMonth(date.getMonth() + months);
  return date;
}

/**
 * Subtracts the specified amount of months from the date.
 */
export function subMonths(from: Date, months: number) {
  const date = new Date(from.getTime());
  date.setMonth(date.getMonth() - months);
  return date;
}

/**
 * Subtracts the specified amount of days from the date.
 */
export function subDays(from: Date, days: number) {
  return new Date(from.getTime() - days * DAYS);
}

/**
 * Modifies only the hours of the date.
 */
export function setHours(from: Date | undefined, hours: number) {
  const date = new Date(from?.getTime() ?? Date.now());
  date.setHours(hours);
  return date;
}

/**
 * Modifies only the minutes of the date.
 */
export function setMinutes(from: Date | undefined, minutes: number) {
  const date = new Date(from?.getTime() ?? Date.now());
  date.setMinutes(minutes);
  return date;
}

/**
 * Modifies the full yearn, month and day of the date.
 */
export function setDate(from: Date, date: Date) {
  const newDate = new Date(from?.getTime() ?? Date.now());
  newDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
  return newDate;
}

/**
 * Modifies the full yearn, month and day of the date. If from is not defined,
 * the current date will be used.
 */
export function setDateOrNow(from: Date | undefined, date: Date) {
  return from ? setDate(from, date) : new Date();
}

/**
 * Modifies the full yearn, month and day of the date. If from is not defined,
 * the date passed as parameter will be used.
 */
export function upsertDate(from: Date | undefined, date: Date) {
  return from ? setDate(from, date) : date;
}

/**
 * Returns an array with each day of the month of the given date, from the first
 * day the first week of the month to the last day of the last week of the month.
 */
export function daysOfCalendar(date: Date) {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const firstDayOfFirstWeek = subDays(firstDayOfMonth, firstDayOfMonth.getDay());
  const lastDayOfLastWeek = addDays(firstDayOfFirstWeek, 48);
  return eachDayOfInterval(firstDayOfFirstWeek, lastDayOfLastWeek);
}

/**
 * @param step The time step of the time period to choose from.
 * @returns An array with the time periods for the given step. It will return
 * null if there is no choice to make.
 */
export function limitTimeChoice(step: number | undefined): Timechoice {
  if (!step || step >= DAYS) return { hours: null, minutes: null };
  if (step <= MINUTES) return { hours: HoursOfDay, minutes: MinutesOfHour };
  const hours = HoursOfDayMs.filter((m) => m % step === 0).map((h) => h / HOURS);
  const minutes = MinutesOfHourMs.filter((minute) => minute % step === 0).map((m) => m / MINUTES);
  if (minutes.length === 1) return { hours, minutes: null };
  return { hours, minutes };
}

/**
 * @param step The time step of the time period to choose from.
 * @param condition If true, it will return the time periods for the given step.
 * @returns An array with the time periods for the given step. It will return
 * null if there is no choice to make or if the condition is false.
 */
export function limitTimeChoiceIfTrue(step: number | undefined, condition: boolean): Timechoice {
  if (!condition) return { hours: null, minutes: null };
  return limitTimeChoice(step);
}

/**
 * @param duration A duration in milliseconds.
 * @returns The amount of days, hours, minutes and seconds in the given duration.
 */
export function durationFromMilliseconds(duration: number): Duration {
  const days = Math.floor(duration / DAYS);
  const hours = Math.floor((duration % DAYS) / HOURS);
  const minutes = Math.floor((duration % HOURS) / MINUTES);
  const seconds = Math.floor((duration % MINUTES) / SECONDS);
  return { days, hours, minutes, seconds };
}

/**
 * @param duration A duration in milliseconds.
 * @returns A string representing the given duration in long form.
 */
export function formatDuration({ days, hours, minutes, seconds }: Duration): string {
  let display = '';
  if (days) display += `${days} ${days === 1 ? 'dia' : 'dias'} `;
  if (hours) display += `${hours} ${hours === 1 ? 'hora' : 'horas'} `;
  if (minutes) display += `${minutes} ${minutes === 1 ? 'minuto' : 'minutos'} `;
  if (seconds) display += `${seconds} ${seconds === 1 ? 'segundo' : 'segundos'} `;
  return display;
}

/**
 * @param from The start date of the period.
 * @param to The end date of the period.
 * @returns A string representing the given period in long form.
 */
export function formatDurationFromInterval(from: Date | string, to: Date | string): string {
  const time = new Date(to).getTime() - new Date(from).getTime();
  const duration = durationFromMilliseconds(time);
  return formatDuration(duration);
}

/**
 * @param from The start date of the period.
 * @returns A string representing the given period in long form.
 */
export function formatDurationToNow(from: Date | string): string {
  return formatDurationFromInterval(from, new Date());
}

/**
 * @param duration A duration in milliseconds.
 * @returns A string representing the given duration in long form.
 */
export function formatStopDurationNotification(duration: number): string {
  const timeInfo = durationFromMilliseconds(duration * 1000);

  let display = '';
  if (timeInfo.days) display += `${timeInfo.days} ${timeInfo.days === 1 ? 'dia' : 'dias'} `;
  if (timeInfo.hours) display += `${timeInfo.hours} ${timeInfo.hours === 1 ? 'hora' : 'horas'} `;
  if (timeInfo.minutes && timeInfo.hours) {
    display += `e ${timeInfo.minutes} ${timeInfo.minutes === 1 ? 'minuto' : 'minutos'} `;
  } else {
    if (timeInfo.seconds > 30) {
      display += `${timeInfo.minutes + 1} ${timeInfo.minutes + 1 === 1 ? 'minuto' : 'minutos'} `;
    } else {
      display += `${timeInfo.minutes} ${timeInfo.minutes === 1 ? 'minuto' : 'minutos'} `;
    }
  }
  return display;
}

export function formatDurationInTable(duration: number): string {
  const timeInfo = durationFromMilliseconds(duration * 1000);

  let display = '';

  timeInfo.hours < 10 && timeInfo.hours >= 0 ? (display += `0${timeInfo.hours}`) : (display += `${timeInfo.hours}`);

  timeInfo.minutes < 10 && timeInfo.minutes >= 0
    ? (display += `:0${timeInfo.minutes}`)
    : (display += `:${timeInfo.minutes}`);

  timeInfo.seconds < 10 && timeInfo.seconds >= 0
    ? (display += `:0${timeInfo.seconds}`)
    : (display += `:${timeInfo.seconds}`);

  return display;
}
