import React from 'react';
import { sizes, VectorProps } from './Icon';

export const CircleFilled: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.56738" cy="6" r="6" fill={color} />
    </svg>
  );
};
