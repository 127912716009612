import { t } from 'i18next';
import React, { useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Footer } from '../../components/layout/Footer';
import { GroupRegistration } from '../../components/layout/GroupRegistration';
import { PageOptions } from '../../components/layout/PageOptions';
import { Button } from '../../components/ui/Button';
import { Drop } from '../../components/ui/Drop';
import { Input } from '../../components/ui/Input';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import {
  Sector,
  useFactoriesNameQuery,
  useLinesFromFactoryQuery,
  useRegisterGroupMutation,
  useUpdateGroupMutation,
  WorkShift,
} from '../../hooks/graphqlQueries';

const Info = styled.div`
  display: grid;
  grid-area: Info;
  grid-template-columns: 1fr 1fr;
  row-gap: 19px;
  column-gap: 19px;
  align-items: end;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
`;

const newGroup: Group = {
  name: '',
  sector: Sector.NoSector,
  workShift: WorkShift.ShiftA,
  factoryId: '',
  factory: '',
};

type Group = {
  id?: string;
  name: string;
  sector: Sector;
  workShift: WorkShift;
  lineId?: string;
  line?: string;
  factoryId: string;
  factory: string;
};
export const GroupRegistrationPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const feedback = useFeedback();

  const routeData = location.state as { data: Group; pagination: { page: number; limit: number } };
  const [info, setInfo] = useState<Group>((routeData?.data as Group) ?? newGroup);

  const canRegister = useMemo(() => {
    for (const key in info) {
      const value = info[key as keyof Group];
      if ((value === undefined || value === '') && key !== 'id' && key !== 'lineId' && key !== 'line') return false;
    }

    return true;
  }, [info]);

  const [, registerGroup] = useRegisterGroupMutation();
  const [, updateGroup] = useUpdateGroupMutation();

  const [factoriesQuery] = useFactoriesNameQuery({ requestPolicy: 'network-only' });
  const factories = useMemo(() => factoriesQuery.data?.factories?.value ?? [], [factoriesQuery.data]);

  const [linesQuery] = useLinesFromFactoryQuery({
    requestPolicy: 'network-only',
    variables: { factoryId: info.factoryId },
  });
  const lines = useMemo(() => linesQuery.data?.lines?.value ?? [], [linesQuery.data]);

  const handleSave = () => {
    const dialog = () => {
      feedback.confirmation({
        title: 'Deseja cadastrar outro Grupo?',
        onHandle: (result) => {
          result ? setInfo(newGroup) : navigate('..', { state: { pagination: routeData?.pagination } });
        },
      });
    };

    if (info.id) {
      updateGroup({ ...info, id: info.id })
        .then((result) => {
          if (result.error) feedback.apiError(result.error);
          else if (result.data?.updateGroup === null)
            feedback.message({ animation: 'Error', title: 'Erro ao editar grupo' });
          else {
            feedback.message({ animation: 'Confetti', title: 'Grupo editado com sucesso' });
            navigate('..', { state: { pagination: routeData?.pagination } });
          }
        })
        .catch(() => {
          feedback.message({ animation: 'Error', title: 'Erro ao editar grupo' });
        });
    } else {
      registerGroup(info)
        .then((result) => {
          if (result.error) feedback.apiError(result.error);
          else if (result.data?.createGroup === null)
            feedback.message({ animation: 'Error', title: 'Erro ao cadastrar grupo' });
          else
            feedback.message({
              animation: 'Confetti',
              title: 'Grupo cadastrado com sucesso',
              onClose: () => dialog(),
            });
        })
        .catch(() => {
          feedback.message({ animation: 'Error', title: 'Erro ao cadastrar grupo' });
        });
    }
  };

  return (
    <GroupRegistration>
      <Title>{info.id ? 'Editar Grupo' : 'Cadastrar Grupo'}</Title>
      <Info>
        <Input
          label="Nome"
          value={info.name}
          width="376px"
          onChange={(e) => setInfo({ ...info, name: e.target.value })}
        />
        <Drop
          name="Setor"
          size="large"
          value={t(info.sector)}
          options={(Object.keys(Sector) as Array<keyof typeof Sector>).map((key) => {
            return { id: Sector[key], name: t(Sector[key]) };
          })}
          onSelect={(value) => setInfo({ ...info, sector: value.id as Sector })}
        />
        <Drop
          dataCy="factory-drop"
          size="large"
          name="Unidade"
          value={info.factory}
          options={factories.map((factory) => ({ id: factory.id, name: factory.name })) ?? []}
          onSelect={(factory) =>
            setInfo({ ...info, factoryId: factory.id, factory: factory.name, lineId: '', line: '' })
          }
          errorMessage={factories.length === 0 ? 'Não existe nenhuma fábrica cadastrada.' : undefined}
        />
        <Drop
          dataCy="line-drop"
          size="large"
          name="Linha"
          value={info.line}
          options={lines.map((line) => ({ id: line.id, name: line.code })) ?? []}
          onSelect={(line) => setInfo({ ...info, lineId: line.id, line: line.name })}
          errorMessage={lines.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined}
        />
        <Drop
          name="Turno"
          value={t(info.workShift)}
          options={(Object.keys(WorkShift) as Array<keyof typeof WorkShift>).map((key) => {
            return { id: WorkShift[key], name: t(WorkShift[key]) };
          })}
          onSelect={(value) => setInfo({ ...info, workShift: value.id as WorkShift })}
        />
      </Info>
      <Footer>
        <PageOptions>
          <Button
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button title="Salvar" color="primary" disabled={!canRegister} onClick={() => handleSave()} />
        </PageOptions>
      </Footer>
    </GroupRegistration>
  );
};
