import React from 'react';
import styled from 'styled-components';
import { Button } from '../../components/ui/Button';

const InfoTag = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, max-content));
  row-gap: 19px;
  column-gap: 10px;
  align-items: end;

  @media (max-width: 800px) {
    justify-content: center;
  }
`;

const TagText = styled.div`
  display: flex;
  width: 159px;
  height: 45px;
  padding: 0px 10px 0px 10px;

  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-weight: 500;
  line-height: 45px;
  color: ${(props) => props.theme.colors.input.color};

  border: 1.5px solid ${(props) => props.theme.colors.input.color};
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

type Props = {
  id: string;
  stop: string;
  reason: string;
  loss: string;
  onDeleteItem: (id: string) => void;
};
export const TagItem: React.FC<Props> = ({ id, stop, reason, loss, onDeleteItem }) => {
  return (
    <InfoTag>
      <TagText title={stop}>{stop}</TagText>
      <TagText title={loss}>{loss}</TagText>
      <TagText title={reason}>{reason}</TagText>
      <Button title="Remover" icon={'Stop'} iconSize={'small'} color="red" onClick={() => onDeleteItem(id)} />
    </InfoTag>
  );
};
