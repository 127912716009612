export type LimitsType = {
  inferiorLimit?: number;
  superiorLimit?: number;
};

export type ErrorType = {
  inferiorGreaterThenSuperior: string;
};

export const limitsValidator = ({ inferiorLimit, superiorLimit }: LimitsType, errorMessages: ErrorType) => {
  const errors = <ErrorType>{};
  const higherValue = Math.max(inferiorLimit ?? 0, superiorLimit ?? 0);
  const isInferiorLimit = higherValue === inferiorLimit;

  if (isInferiorLimit) {
    errors.inferiorGreaterThenSuperior = errorMessages.inferiorGreaterThenSuperior;
  }
  return errors;
};
