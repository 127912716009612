import React from 'react';
import styled from 'styled-components';

const StyledSelection = styled.div`
  display: grid;
  grid-area: 'Content';
  grid-template:
    'Title .' 45px
    'Card Card' max-content
    / max-content;

  margin: 55px;
  justify-content: center;

  @media (max-width: 965px) {
    margin: 20px 25px 20px 25px;
  }
`;

type Props = {
  children: React.ReactNode;
};
export const Selection: React.FC<Props> = ({ children }) => <StyledSelection>{children}</StyledSelection>;
