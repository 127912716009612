import React from 'react';
import { sizes, VectorProps } from './Icon';

export const ParamsColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 36 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.86 10.17H3.446c-.822 0-1.612-.33-2.194-.915a3.136 3.136 0 0 1-.908-2.21c0-.829.327-1.624.908-2.21a3.092 3.092 0 0 1 2.194-.915H32.86c.823 0 1.612.33 2.194.915.582.586.908 1.381.908 2.21s-.326 1.624-.908 2.21a3.091 3.091 0 0 1-2.194.915Z"
        fill="#FFBF31"
      />
      <path
        d="M32.86 22.67H3.446c-.822 0-1.612-.33-2.194-.915a3.137 3.137 0 0 1-.908-2.21c0-.829.327-1.624.908-2.21a3.091 3.091 0 0 1 2.194-.915H32.86c.823 0 1.612.33 2.194.915.582.586.908 1.381.908 2.21s-.326 1.624-.908 2.21a3.092 3.092 0 0 1-2.194.915Z"
        fill="#EAA00C"
      />
      <path
        d="M32.86 35.17H3.446c-.822 0-1.612-.33-2.194-.915a3.137 3.137 0 0 1-.908-2.21c0-.829.327-1.624.908-2.21a3.091 3.091 0 0 1 2.194-.915H32.86c.823 0 1.612.33 2.194.915.582.586.908 1.381.908 2.21s-.326 1.624-.908 2.21a3.092 3.092 0 0 1-2.194.915Z"
        fill="#006EC7"
      />
      <path
        d="M12.63 13.67c3.633 0 6.578-2.966 6.578-6.625S16.263.42 12.63.42c-3.632 0-6.577 2.966-6.577 6.625s2.945 6.625 6.577 6.625Z"
        fill="#FFBF31"
      />
      <path
        d="M12.63 38.67c3.633 0 6.578-2.966 6.578-6.625s-2.945-6.625-6.578-6.625c-3.632 0-6.577 2.966-6.577 6.625s2.945 6.625 6.577 6.625Z"
        fill="#006EC7"
      />
      <path
        d="M24.918 26.17c3.632 0 6.577-2.966 6.577-6.625s-2.945-6.625-6.578-6.625c-3.632 0-6.577 2.966-6.577 6.625s2.945 6.625 6.578 6.625Z"
        fill="#EAA00C"
      />
    </svg>
  );
};
