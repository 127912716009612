import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div<{ area: string; width: string }>`
  display: grid;
  grid-area: ${(props) => props.area};
  width: ${(props) => props.width};
  max-width: 80vw;
`;

export const StyledLabel = styled.label`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 5px;
  padding-left: 5px;
  color: ${(props) => props.theme.colors.input.color};
`;

export const MessageContainer = styled.div<{ notDisplay?: boolean; area: string; width: string }>`
  display: flex;
  visibility: ${(props) => (props.notDisplay ? 'hidden' : 'visible')};
  grid-area: ${(props) => props.area};
  width: ${(props) => props.width};
  max-width: 80vw;
  height: 45px;
`;

export const StyledMessage = styled.span<{ maxWidth: number | string }>`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.input.bottomMessage.tip};
  max-width: ${(props) => props.maxWidth};
  padding-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StyledErrorMessage = styled(StyledMessage)`
  color: ${(props) => props.theme.colors.input.bottomMessage.error};
`;

export const StyledInput = styled.input<{ hasError?: boolean }>`
  width: calc(100% - 25px);
  height: 44px;

  margin: 0;
  padding: 0 10px;

  background: ${(props) => props.theme.colors.input.background};
  border-radius: ${(props) => props.theme.colors.input.borderRadius};
  border: 2px solid ${(props) => props.theme.colors.input.background};

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-size: 16px;

  border-color: ${(props) => (props.hasError ? props.theme.colors.input.bottomMessage.error : '')};

  /* Chrome, Safari, Edge, Opera */
  &&::-webkit-outer-spin-button,
  &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;

  &:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.input.border};
    border-color: ${(props) => (props.hasError ? props.theme.colors.input.bottomMessage.error : '')};
  }
`;

type Props = {
  type?: 'text' | 'password' | 'email' | 'tel' | 'number';
  placeholder?: string;
  label?: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  area?: string;
  width?: string;
  height?: string;
  maxLength?: number;
  renderMessage?: boolean;
  errorMessage?: boolean;
  message?: string;
  dataCy?: string;
  disabled?: boolean;
};

export const Input: React.FC<Props> = ({
  type = 'text',
  label,
  placeholder,
  value,
  area = 'unset',
  width = '270px',
  onChange,
  maxLength = 500,
  renderMessage,
  errorMessage,
  message,
  dataCy,
  disabled = false,
}: Props) => {
  const chooseMessage = () => {
    if (renderMessage) {
      return (
        <MessageContainer notDisplay={!message ? true : false} area={area} width={width}>
          {errorMessage ? (
            <StyledErrorMessage data-cy="field-message" maxWidth={width}>
              {message}
            </StyledErrorMessage>
          ) : (
            <StyledMessage data-cy="field-message" maxWidth={width}>
              {message}
            </StyledMessage>
          )}
        </MessageContainer>
      );
    } else {
      if (message) {
        return (
          <MessageContainer area={area} width={width}>
            {errorMessage ? (
              <StyledErrorMessage data-cy="field-message" maxWidth={width}>
                {message}
              </StyledErrorMessage>
            ) : (
              <StyledMessage data-cy="field-message" maxWidth={width}>
                {message}
              </StyledMessage>
            )}
          </MessageContainer>
        );
      } else {
        return;
      }
    }
  };

  return (
    <InputContainer data-cy={dataCy} area={area} width={width}>
      {label && <StyledLabel data-cy="field-label">{label}</StyledLabel>}
      <StyledInput
        data-cy="field-input"
        placeholder={placeholder}
        type={type}
        value={value}
        maxLength={maxLength}
        onChange={onChange}
        hasError={errorMessage}
        disabled={disabled}
      />
      {chooseMessage()}
    </InputContainer>
  );
};
