import React from 'react';
import styled from 'styled-components';

const StyledStopJustification = styled.div`
  display: grid;
  grid-area: 'Content';
  grid-template:
    'Title .'
    'Info Info'
    'Drop Drop'
    'Desc Desc'
    'Name .'
    'Footer Footer';
  grid-row-gap: 25px;
  width: max-content;
  max-width: 95vw;
  margin: 55px auto;

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
    grid-template:
      'Title'
      'Info'
      'Drop'
      'Desc' 150px
      'Name'
      'Footer';
    max-width: 90vw;
    margin: 55px auto;
    align-items: center;
  }
`;

type Props = {
  children: React.ReactNode;
};
export const StopJustification: React.FC<Props> = ({ children }) => (
  <StyledStopJustification>{children}</StyledStopJustification>
);
