import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';

const Container = styled.div`
  position: relative;

  overflow-x: scroll;
  scrollbar-width: none;
  &&::-webkit-scrollbar {
    display: none;
  }

  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  color: ${(props) => props.theme.colors.table.colorBody};
  text-align: center;
`;

const StyledTable = styled.table`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-size: 16px;
  text-align: center;
  min-width: 770px;

  border: 1px solid ${(props) => props.theme.colors.table.border};
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0px;
`;

const StyledHeader = styled.tr`
  line-height: 29px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.table.colorTitle};
  background-color: ${(props) => props.theme.colors.table.border};

  th {
    padding: 0px 15px 0px 15px;
    font-weight: normal;
  }
`;

const StyledRow = styled.tr`
  height: 55px;
  word-break: keep-all;

  td {
    padding: 0px 15px 0px 15px;
    text-align: center;
    max-width: 350px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 10px;
  justify-content: center;
`;

const StyledMessage = styled.td.attrs({
  colSpan: 100,
})`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-size: 16px;
  color: ${(props) => props.theme.colors.table.colorBody};
  height: 200px;
  text-align: center;
  justify-content: center;
`;

export type AttributeType = {
  name: string;
  attr: string;
  dataCy: string;
};

type Props = {
  attributes: AttributeType[];
  content: { [key: string]: string | number | boolean | string[] | null }[];
  onRegister: (value: { [key: string]: string | number | boolean | string[] | null }) => void;
};

export const RegisterTableStop: React.FC<Props> = ({ attributes, content, onRegister }) => {
  return (
    <Container>
      <StyledTable>
        <thead>
          <StyledHeader>
            {attributes.map((value) => {
              return (
                <th key={value.attr} data-cy={value.dataCy}>
                  {value.name}
                </th>
              );
            })}

            <th data-cy={'table-col-register'}></th>
          </StyledHeader>
        </thead>

        <tbody>
          {(content === undefined || content?.length === 0) && (
            <tr>
              <StyledMessage>
                Você ainda não tem nenhum dado cadastrado. Em breve estará disponível aqui :D
              </StyledMessage>
            </tr>
          )}

          {content !== undefined &&
            content?.map((el, index) => {
              return (
                <StyledRow key={index}>
                  {attributes.map((value, index) => {
                    return (
                      <td key={index} data-cy={value.dataCy}>
                        {el[value.attr]}
                      </td>
                    );
                  })}

                  <td key={index + 'options'} data-cy={'table-col-register'}>
                    <ButtonContainer>
                      <Button
                        title="Registrar"
                        size="small"
                        color="green"
                        onClick={() => onRegister(el)}
                        dataCy={`register-${index}`}
                      />
                    </ButtonContainer>
                  </td>
                </StyledRow>
              );
            })}
        </tbody>
      </StyledTable>
    </Container>
  );
};
