import React, { useState } from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { IconNames } from '../Icon';
import { Icon, VectorProps } from '../Icon/Icon';

const sizes = {
  square: { text: '16px', width: '45px', height: '45px' },
  small: { text: '16px', width: '136px', height: '45px' },
  medium: { text: '16px', width: '179px', height: '45px' },
};
type Size = keyof typeof sizes;

type StyledButtonProps = {
  area: string;
  color: keyof DefaultTheme['colors']['button'];
  size: Size;
  icon: boolean;
  disabled: boolean;
};
const StyledButton = styled.button<StyledButtonProps>`
  display: grid;
  grid-area: ${(props) => props.area};
  grid-template-columns: ${(props) =>
    props.icon && props.size !== 'square' ? 'max-content max-content' : 'max-content'};
  column-gap: 18px;
  width: ${(props) => sizes[props.size].width};
  height: ${(props) => sizes[props.size].height};
  border-radius: 5px;
  border: ${(props) => props.theme.colors.button[props.color].border};
  background-color: ${(props) => props.theme.colors.button[props.color].background};
  justify-self: center;

  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
    box-shadow: ${(props) => (props.disabled ? '0px' : props.theme.colors.button[props.color].boxShadow)};
  }
`;

type TextProps = {
  color: keyof DefaultTheme['colors']['button'];
  size: Size;
};
const Text = styled.span<TextProps>`
  font-family: ${(props) => props.theme.font};
  font-style: Regular;
  font-size: ${(props) => sizes[props.size].text};
  color: ${(props) => props.theme.colors.button[props.color].text};
`;

type BoxProps = {
  visible: boolean;
};
const Box = styled.div<BoxProps>`
  display: ${(props) => (props.visible ? 'grid' : 'none')};
  position: relative;
  grid-template-rows: repeat(6, max-content);
  row-gap: 5px;
  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #001738;
  z-index: 5;

  width: 200px;
  height: 50px;
  padding: 10px;
  top: -15px;
  border-radius: 5px;
  background-color: #ffffff;

  align-items: center;
  justify-content: space-between;
`;

const BoxTitle = styled.span`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  color: black;
`;

type Props = {
  area?: string;
  title?: string;
  color?: keyof DefaultTheme['colors']['button'];
  size?: Size;
  icon?: IconNames;
  iconSize?: VectorProps['size'];
  onClick?: () => void;
  disabled?: boolean;
  dataCy?: string;
  errorMessage?: string;
};

export const Button: React.FC<Props> = ({
  area = 'Initial',
  title = '',
  color = 'primary',
  size = 'medium',
  icon,
  iconSize = 'smaller',
  onClick,
  disabled = false,
  errorMessage,
  dataCy,
}) => {
  const theme = useTheme();
  const [boxVisibility, setBoxVisibility] = useState(false);
  color = disabled ? 'gray' : color;

  return (
    <StyledButton
      area={area}
      color={color}
      size={size}
      icon={icon !== undefined}
      onClick={() => !disabled && onClick?.call(this)}
      onMouseOver={() => setBoxVisibility(true)}
      onMouseOut={() => setBoxVisibility(false)}
      disabled={disabled}
      data-cy={dataCy}
    >
      {errorMessage && (
        <Box visible={boxVisibility}>
          <BoxTitle data-cy="box-error">{errorMessage}</BoxTitle>
        </Box>
      )}
      {icon && <Icon name={icon} size={iconSize} color={theme.colors.button[color].text} />}
      {title && (
        <Text size={size} color={color}>
          {title}
        </Text>
      )}
    </StyledButton>
  );
};
