import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Selection } from '../../components/layout/Selection';
import { Title } from '../../components/ui/Title';
import { routes } from '../../Routes';
import { SelectionCard, SelectionCardLayout } from '../../components/ui/Cards';

export const SelectChart: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Selection>
      <Title>Gráficos</Title>
      <SelectionCardLayout>
        <SelectionCard title="OEE" icon="OeeColored" onClick={() => navigate(routes.charts.oee)} />
        <SelectionCard title="Produção" icon="ProductionColored" onClick={() => navigate(routes.charts.production)} />
        <SelectionCard
          title="Taxa Produção"
          icon="ProductionTaxColored"
          onClick={() => navigate(routes.charts.productionRate)}
        />
        <SelectionCard title="Capacidade" icon="ProductionColored" onClick={() => navigate(routes.charts.capacity)} />
        <SelectionCard title="Parada" icon="StopColored" onClick={() => navigate(routes.charts.stop)} />
        <SelectionCard title="Scrap" icon="BatteryBrokenColored" onClick={() => navigate(routes.charts.scrap)} />
        <SelectionCard
          title="Tempo de Ciclo"
          icon="CycleTimeColored"
          onClick={() => navigate(routes.charts.cycleTime)}
        />
        <SelectionCard
          title="Análise de Período"
          icon="PeriodAnalysisColored"
          onClick={() => navigate(routes.charts.periodAnalysis)}
        />
        <SelectionCard title="Parâmetro" icon="OeeColored" onClick={() => navigate(routes.charts.parameter)} />
      </SelectionCardLayout>
    </Selection>
  );
};
