import React from 'react';
import styled from 'styled-components';

const StyledChart = styled.div`
  display: grid;
  grid-area: 'Content';
  grid-template:
    'Title .'
    'Filters Filters'
    'BadgeSelection BadgeSelection'
    'ValuesMinMax .'
    'Badges Badges'
    '. ClearFilter'
    'LineChart LineChart'
    'Footer Footer';
  grid-template-rows: max-content auto auto auto 25px max-content minmax(550px, auto) max-content;
  grid-row-gap: 15px;
  width: 90vw;
  margin: 55px auto 55px auto;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    grid-template:
      'Title'
      'Filters'
      'BadgeSelection'
      'ValuesMinMax'
      'Badges'
      'ClearFilter'
      'LineChart'
      'Footer';
    margin: 20px 20px 50px 20px 50px;
    grid-template-rows:
      max-content auto auto auto max-content max-content minmax(550px, auto)
      max-content;
    grid-row-gap: 15px;
  }

  @media (max-width: 620px) {
    grid-template-columns: 1fr;
    grid-template:
      'Title'
      'Filters'
      'BadgeSelection'
      'ValuesMinMax'
      'Badges'
      'ClearFilter'
      'LineChart'
      'Footer';
    margin: 20px 20px 25px 20px 25px;
    grid-template-rows:
      max-content auto auto auto max-content max-content minmax(550px, auto)
      max-content;
    grid-row-gap: 15px;
  }
`;

type Props = {
  children: React.ReactNode;
};
export const Chart: React.FC<Props> = ({ children }) => <StyledChart>{children}</StyledChart>;
