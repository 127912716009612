import React, { useMemo, useState } from 'react';
import { colorOfState, StatesWithColor } from '../../../scripts/colorOfState';
import styled from 'styled-components';
import { Icon, IconNames } from '../Icon';
import { format } from 'date-fns';
import { simplifiedOee } from '../../../core/oee';
import { useTranslation } from 'react-i18next';
import { isBefore } from '../../../scripts/calendar';

const iconOfState: Record<StatesWithColor, IconNames> = {
  NORMAL: 'Check',
  SLOW: 'Minus',
  SMALL_STOP: 'Cross',
  STOP: 'Cross',
  PAUSED: 'Forbidden',
  NOT_MONITORED: 'Forbidden',
  STARTUP: 'Check',
};

type ContainerProps = { state: StatesWithColor };
const Container = styled.div<ContainerProps>`
  display: grid;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: ${(props) => props.theme.colors.diagram.content.text};
  border: 2px solid ${(props) => colorOfState(props.theme)[props.state]};
  background-color: ${(props) => props.theme.colors.diagram.content.background};
  border-radius: 4px;
  width: 135px;
  height: 90px;
  filter: drop-shadow(0px 7px 20px rgba(0, 0, 0, 0.25));
  user-select: none;

  &:hover {
    cursor: pointer;
    filter: drop-shadow(0px 0px 6px ${(props) => colorOfState(props.theme)[props.state]});
  }
`;

type IconFrameProps = { state: StatesWithColor; isBottleneck: boolean };
const IconFrame = styled.div<IconFrameProps>`
  display: grid;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -22px;
  right: -20px;
  width: 38px;
  height: 38px;
  border-radius: 30px;
  background-color: ${(props) => {
    if (props.isBottleneck) {
      return props.theme.colors.diagram.status.bottleneck;
    } else {
      return colorOfState(props.theme)[props.state];
    }
  }};
  border: 1px solid #ffffff;
  filter: drop-shadow(-1px 8px 15px rgba(50, 50, 0, 0.5));
`;

type TitleProps = { state: StatesWithColor };
const Title = styled.div<TitleProps>`
  position: absolute;
  top: -1px;
  left: -2px;
  color: ${(props) => props.theme.colors.diagram.title};
  font-size: 28px;
  line-height: 40px;
  font-weight: 600;
  font-family: ${(props) => props.theme.font};
  text-align: center;
  border-radius: 4px;
  background-color: ${(props) => colorOfState(props.theme)[props.state]};
  width: 139px;
  height: 40px;
`;

const Values = styled.span`
  font-family: ${(props) => props.theme.font};
  line-height: 19.36px;
  font-weight: 500;
  padding-top: 46px;
`;

type BoxProps = {
  visible: boolean;
  state: StatesWithColor;
};
const Box = styled.div<BoxProps>`
  display: ${(props) => (props.visible ? 'grid' : 'none')};
  position: absolute;
  grid-template-rows: repeat(6, max-content);
  row-gap: 5px;
  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #001738;
  z-index: 5;

  width: 115px;
  height: 215px;
  padding: 10px;
  top: -25px;
  border-radius: 5px;
  background-color: #ffffff;

  align-items: center;
  justify-content: space-between;
`;

const BoxTitle = styled.span`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  color: black;
`;

const BoxItem = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  text-align: left;
  color: black;
`;
type ValueProps = { dataCy?: string };
const Value = styled.div<ValueProps>`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #006ec7;
  padding-left: 5px;
`;

const ProgressBg = styled.div`
  display: grid;
  background: ${(props) => props.theme.colors.card.progressBar.background};
  width: 60px;
  height: 12px;
  box-shadow: inset 1px 1px 8px rgba(0, 31, 77, 0.4);
  border-radius: 8px;
  overflow: hidden;
`;

type ProgressProps = { value: number; color: string };
const Progress = styled.div.attrs<ProgressProps>(props =>({
  style: {
    width: props.value,
    backgroundColor: props.color,
  }
}))<ProgressProps>`
  height: 100%;
  border-radius: 8px 0px 0px 8px;
`;
/*
const Progress = styled.div<ProgressProps>`
  width: ${(props) => props.value}%;
  height: 100%;
  background-color: ${(props) => props.color};
  border-radius: 8px 0px 0px 8px;
`;*/

export type Props = {
  id: string;
  name: string;
  time?: string;
  clock: Date;
  disabled?: boolean;
  timeLastItem?: string;
  itemTypeCode?: string;
  itemTypeName?: string;
  status: StatesWithColor;
  production: number;
  stopTime: number;
  bottleneckIdealCycle: number;
  bottleneckProduction: number;
  isBottleneck: boolean;
  shiftStartAt?: Date;
  idealCycle: number;
  stopCycle: number;
};

const fmt: Intl.NumberFormatOptions = {
  maximumFractionDigits: 3,
  minimumIntegerDigits: 1,
  useGrouping: false,
};

const fmtProduction: Intl.NumberFormatOptions = {
  maximumFractionDigits: 2,
  minimumIntegerDigits: 1,
  useGrouping: false,
};

export const MachineCard: React.FC<Props> = ({
  name,
  status,
  production,
  bottleneckIdealCycle,
  bottleneckProduction,
  stopTime,
  disabled,
  isBottleneck,
  idealCycle,
  stopCycle,
  time,
  clock,
  shiftStartAt,
  timeLastItem,
  //itemTypeCode = 'N.A.',
  itemTypeName = 'Desconhecido',
}: Props) => {
  const [boxVisibility, setBoxVisibility] = useState(false);
  const {
    i18n: { language },
  } = useTranslation();

  const future = useMemo(() => {
    let lastItemReceivedAt = 'Sem Dados';
    if (timeLastItem) lastItemReceivedAt = format(new Date(timeLastItem), 'dd/MM HH:mm:ss');

    if (!time || !shiftStartAt || disabled || isBefore(time, shiftStartAt))
      return {
        statusIcon: iconOfState.NOT_MONITORED,
        status: 'NOT_MONITORED' as const,
        idealCapacity: 60 / idealCycle,
        lastItemReceivedAt,
        production: 0,
        stopTime: 0,
        bottleneckCapacity: 0,
        realCapacity: 0,
        maxSpeed: 0,
        oee: 0,
      };

    let futureStatus = status;
    let futureStopTime = stopTime;

    let cycleLength = clock.getTime() - new Date(time).getTime();
    let shiftLength = clock.getTime() - new Date(shiftStartAt).getTime();

    if (cycleLength < 0) cycleLength = 0;
    if (shiftLength < 0) shiftLength = 0;

    if (cycleLength > stopCycle * 1000) futureStatus = 'STOP';
    else if (cycleLength > idealCycle * 1000) futureStatus = 'SLOW';
    else futureStatus = 'NORMAL';

    if (futureStatus === 'STOP') futureStopTime += cycleLength / 1000;
    const oee = simplifiedOee({
      acceptedCount: production,
      idealCycleTime: bottleneckIdealCycle,
      totalTime: shiftLength / 1000,
    });

    return {
      status: futureStatus,
      statusIcon: iconOfState[futureStatus],
      stopTime: Math.round(futureStopTime / 60),
      maxSpeed: 1 / idealCycle,
      realCapacity: shiftLength === 0 ? 0 : (60000 * production) / shiftLength,
      idealCapacity: 60 / idealCycle,
      production: production.toLocaleString(language, fmtProduction),
      bottleneckCapacity: shiftLength === 0 ? 0 : (60000 * bottleneckProduction) / shiftLength,
      lastItemReceivedAt: lastItemReceivedAt,
      oee: Math.round(oee * 100),
    };
  }, [clock, time]);

  return (
    <Container
      state={future.status}
      onMouseOver={() => setBoxVisibility(true)}
      onMouseOut={() => setBoxVisibility(false)}
    >
      <Box visible={boxVisibility} state={future.status} data-cy={`card-box-${name}`}>
        <BoxTitle>{name}</BoxTitle>
        <BoxItem>
          <Label>Último item:</Label>
        </BoxItem>
        <BoxItem>
          <Value>{future.lastItemReceivedAt}</Value>
        </BoxItem>
        <BoxItem>
          <Label>OEE:</Label>
          <Value>{future.oee}%</Value>
        </BoxItem>
        <Label>Cap. gargalo:</Label>
        <BoxItem>
          <ProgressBg>
            <Progress value={(100 * future.bottleneckCapacity) / future.idealCapacity} color="#006EC7" />
          </ProgressBg>
          <Value>{future.bottleneckCapacity.toLocaleString(language, fmt)}</Value>
        </BoxItem>
        <Label>Cap. real:</Label>
        <BoxItem>
          <ProgressBg>
            <Progress value={(100 * future.realCapacity) / future.idealCapacity} color="#EAA00C" />
          </ProgressBg>
          <Value dataCy="real-capacity-value">{future.realCapacity.toLocaleString(language, fmt)}</Value>
        </BoxItem>
        <BoxItem>
          <Label>Produzindo Itens:</Label>
        </BoxItem>
        <BoxItem>
          <Value>
            {itemTypeName}
          </Value>
        </BoxItem>
      </Box>

      <Title state={future.status} data-cy={`card-${name}`}>
        {name}
      </Title>
      <IconFrame state={future.status} isBottleneck={isBottleneck}>
        <Icon name={future.statusIcon} size="small" />
      </IconFrame>
      <Values>
        Prod / Parada
        <br />
        {`${future.production} / ${future.stopTime} min`}
      </Values>
    </Container>
  );
};
