import React from 'react';
import { sizes, VectorProps } from './Icon';

export const CircleChecked: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6035 0C5.97652 0 0.603516 5.373 0.603516 12C0.603516 18.627 5.97652 24 12.6035 24C19.2305 24 24.6035 18.627 24.6035 12C24.6035 5.373 19.2305 0 12.6035 0ZM19.3105 9.707L11.7505 17.267C11.5625 17.455 11.3085 17.56 11.0435 17.56C10.7785 17.56 10.5235 17.455 10.3365 17.267L6.88352 13.814C6.49252 13.423 6.49252 12.791 6.88352 12.4C7.27452 12.009 7.90652 12.009 8.29752 12.4L11.0435 15.146L17.8965 8.293C18.2875 7.902 18.9195 7.902 19.3105 8.293C19.7015 8.684 19.7015 9.316 19.3105 9.707Z"
        fill={color}
      />
    </svg>
  );
};
