import i18n, { Resource } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { format as formatDate } from 'date-fns';
import { enUS, ptBR } from 'date-fns/locale';
import { ln_enUS, ln_ptBR } from '../public/locales';

const timeLocales: Resource = {
  'en-US': enUS,
  'pt-BR': ptBR,
};
const resources: Resource = {
  'en-US': { translation: ln_enUS },
  'pt-BR': { translation: ln_ptBR },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources,
    fallbackLng: 'pt-BR',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (value instanceof Date) {
          const language: string = lng ? lng : 'pt-BR';
          const formattedDate: string = formatDate(value, format ? format : '', { locale: timeLocales[language] });

          return formattedDate;
        }
        return value;
      },
    },
  });

export default i18n;
