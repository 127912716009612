import React from 'react';
import styled from 'styled-components';
import { MessageContainer, StyledErrorMessage } from '../Input/Input'

const Container = styled.div`
  position: relative;
  user-select: none;
`;

export const StyledLabel = styled.label`
  display: inline-block;
  height: 20px;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 5px;
  padding-left: 5px;
  color: ${(props) => props.theme.colors.input.color};
`;

const StyledCalendar = styled.input`
  cursor: pointer;
  display: flex;
  flex: 1;
  width: ${(props) => (props.width ? props.width : props.type == 'datetime-local' ? '170px' : '159px')};
  height: 45px;
  padding: 0px 10px 0px 10px;
  background: transparent;

  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.drop['default'].color};
  font-size: 16px;
  font-weight: 500;

  border: 1.5px solid ${(props) => props.theme.colors.drop['default'].border};
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;

  &&:hover {
    box-shadow: 0px 2px 10px rgba(0, 100, 199, 0.25);
    position: relative;
    top: 1px;
  }

  ::-webkit-calendar-picker-indicator {
    filter: ${(props) => (props.theme.id === 'T_001' ? 'invert(0)' : 'invert(1)')};
  }
`;

type CalendarType = 'DATE' | 'DATETIME';

type Props = {
  label?: string;
  errorMessage?: string;
  placeholder?: string;
  value?: string;
  width?: string;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  area?: string;
  dataCy?: string;
  type?: CalendarType;
};
export const Calendar: React.FC<Props> = ({ 
  label, 
  placeholder, 
  value, 
  width = '170px', 
  onBlur, 
  onChange,
  dataCy, 
  type,
  errorMessage,
  area = 'unset' }) => {
  return (
    <Container>
      {label && <StyledLabel data-cy="drop-label">{label}</StyledLabel>}
      {type && type == 'DATETIME' ? (
        <StyledCalendar
          data-cy={dataCy}
          width={width}
          min={new Date(2022, 7, 19).getTime()}
          step={900}
          type="datetime-local"
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      ) : (
        <StyledCalendar
          data-cy={dataCy}
          width={width}
          min={new Date(2022, 7, 19).getTime()}
          type="date"
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
        
      )}
      {errorMessage && (
        <MessageContainer notDisplay={!errorMessage ? true : false} area={area} width={width}>
          <StyledErrorMessage data-cy="field-message" maxWidth={width}>
            {errorMessage}
          </StyledErrorMessage>
        </MessageContainer>
      )}
    </Container>
  );
};
