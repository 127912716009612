import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Chart as ChartPageContainer } from '../components/layout/Chart';
import { Button } from '../components/ui/Button/Button';
import { Title } from '../components/ui/Title';
import { Outlet, useNavigate, useOutletContext, useMatch } from 'react-router-dom';
import { routes } from '../Routes';
import { Drop } from '../components/ui/Drop';
import { MultiDropdown } from '../components/ui/MultiDropdown';
import {
  useLinesNamesQuery,
  useMachinesFromLineQuery,
  useFactoriesNameQuery,
  Period,
  useScrapItemTypeQuery,
  useParameterNamesQuery,
} from '../hooks/graphqlQueries';
import { makeCSV, downloadFile } from '../scripts/exportFiles';
import { Calendar } from '../components/ui/Calendar';
import { Icon } from '../components/ui/Icon';
import { ClearFilter } from '../components/layout/History/History';
import { format, parseISO } from 'date-fns';
import { FilterList, FILTER_SHIFT_OPTIONS, toOptions, useFilterEngine } from '../hooks/filterEngine';
import { Input } from '../components/ui/Input';
import { Badge } from '../components/ui/Badge/Badge';
import { addBadgeToFilter, removeBadgeByMachine } from '../scripts/badges';
import { PageOptions } from '../components/layout/PageOptions';
import { Footer } from '../components/layout/Footer';
import { countValidAttributes } from '../scripts/objectHelper';
import { StyledErrorMessage } from '../components/ui/Input/Input';
import { periodFilterValidator, ErrorType } from '../scripts/periodFilterValidator';
import { asString } from 'ts-runtime-typecheck';
import { useTranslation } from 'react-i18next';
import { FilterValid } from './ChartFilterInterval';

const Filters = styled.div`
  display: grid;
  grid-area: Filters;
  grid-template-columns: repeat(auto-fit, minmax(179px, max-content));
  row-gap: 10px;
  column-gap: 20px;
  align-items: center;
  padding-bottom: 5px;
`;

const BadgeSelection = styled.div`
  display: grid;
  grid-area: BadgeSelection;
  grid-template-columns: repeat(auto-fit, minmax(179px, max-content));
  row-gap: 10px;
  column-gap: 20px;
  align-items: center;
  padding-bottom: 5px;
`;

const BadgeArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  row-gap: 5px;
  grid-area: Badges;
  align-items: center;
`;

const ChartContainer = styled.div`
  display: grid;
  grid-area: LineChart;
  margin: 65px 0px;
`;

type Charts = Record<
  keyof Omit<typeof routes.charts, 'index'>,
  { name: string; hasPeriod: boolean; hasCount: boolean; hasCheckBox: boolean, mandatoryFields?: string[], periodLimit?: number }
>;
type ChartName = keyof Charts;
const charts: Charts = {
  oee: { name: 'OEE', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: ['factory', 'line', 'startDate', 'endDate'] },
  capacity: { name: 'Capacidade', hasPeriod: true, hasCount: false, hasCheckBox: false, mandatoryFields: ['factory', 'line', 'machine', 'startDate', 'endDate', 'period'] },
  production: { name: 'Produção', hasPeriod: true, hasCount: false, hasCheckBox: false, mandatoryFields: ['factory', 'line', 'startDate', 'endDate', 'period'] },
  productionRate: { name: 'Taxa de Produção', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: [] },
  stop: { name: 'Parada', hasPeriod: false, hasCount: false, hasCheckBox: true, mandatoryFields: ['factory', 'line', 'startDate', 'endDate'] },
  scrap: { name: 'Scrap', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: ['factory', 'startDate', 'endDate'] },
  cycleTime: { name: 'Tempo de Ciclo', hasPeriod: false, hasCount: true, hasCheckBox: false, mandatoryFields: ['factory', 'line', 'machine', 'startDate', 'endDate'] },
  periodAnalysis: { name: 'Análise de Período', hasPeriod: false, hasCount: false, hasCheckBox: false, mandatoryFields: [] },
  parameter: { name: 'Parâmetro', hasPeriod: false, hasCount: true, hasCheckBox: false, mandatoryFields: ['factory', 'line', 'machine', 'startDate', 'endDate', 'parameter'], periodLimit: parseInt(asString(process.env['PARAMETER_PERIOD_FILTER_LIMIT'] || '7')) },
};

const TIME_PERIODS_OPTIONS = [
  { id: Period.P10Min, name: '10 min' },
  { id: Period.P15Min, name: '15 min' },
  { id: Period.P30Min, name: '30 min' },
  { id: Period.P1Hour, name: '1 hora' },
  { id: Period.P8Hour, name: '8 horas' },
  { id: Period.P24Hour, name: '24 horas' },
];

export type ExportData = (string | number)[][];
export type ChartContextType = {
  setExportData: (data: ExportData) => void;
  filter: FilterList;
};

export const ChartFilterMulti: React.FC = () => {
  const { filter, update, clear } = useFilterEngine('ChartFilter');
  const { t } = useTranslation();

  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');
  const [isFiltersValid, setIsFiltersValid] = useState<FilterValid>({ general: false, period: false });
  const [errors, setErrors] = useState<ErrorType>();

  const [exportData, setExportData] = useState<ExportData>();
  const [factoryQuery] = useFactoriesNameQuery({
    requestPolicy: 'network-only',
  });

  const [lineQuery] = useLinesNamesQuery({
    pause: filter.factory === undefined,
    requestPolicy: 'network-only',
    variables: { factoryId: filter.factory?.id ?? '' },
  });

  const [machineQuery] = useMachinesFromLineQuery({
    pause: filter.line === undefined,
    requestPolicy: 'network-only',
    variables: { lineId: filter.line?.id ?? '' },
  });

  const [parameterQuery] = useParameterNamesQuery({
    pause: filter.machine?.name === undefined,
    requestPolicy: 'network-only',
    variables: { machineId: filter.machine?.id ?? '' },
  });

  const [itemName] = useScrapItemTypeQuery({ requestPolicy: 'network-only' });

  const navigate = useNavigate();
  const match = useMatch('/charts/:chartId');
  const chart = useMemo(() => {
    const chartId = match?.params.chartId;
    if (chartId && chartId in charts) return charts[chartId as ChartName];
    else return undefined;
  }, [match]);

  const maxPeriodFilterLimit = chart?.periodLimit || 7;

  const returnMandatoryMark = (fieldMatch:string):string => {
   const fields = chart?.mandatoryFields || [];
   const value = fields.find(key => key == fieldMatch)
  if(value)
      return '*';
    return '';
  }

  useEffect(() => {
    const validation = { general: false, period: false };
    const error_text = t('ERROR.PERIOD_BIGGER_THAN').split('|') || "  ";
    const newPeriod = maxPeriodFilterLimit;
    const mandatoryFields = chart?.mandatoryFields || []

    const result = periodFilterValidator(
      {
        startDate: new Date(filter.startDate || thisDate),
        endDate: new Date(filter.endDate || thisDate),
        maxPeriodLimit: newPeriod
      },
      {
        limitGreaterThenSuperior:  `${error_text[0]} ${newPeriod} ${error_text[1]}`
      }
    )

    setErrors(result);

    if (Object.values(result).length == 0) {
      validation.period = true;
    }

    const validAtributtes:number = countValidAttributes(filter, mandatoryFields)
    if(validAtributtes == mandatoryFields.length){
      validation.general = !validation.period ? false : true;
    }

    setIsFiltersValid(validation);
  }, [filter]);

  const title = useMemo(() => chart?.name ?? 'Gráfico não encontrado', [chart]);

  const factoryValues = useMemo(() => factoryQuery.data?.factories?.value, [factoryQuery]);
  const lineValues = useMemo(() => lineQuery.data?.lines?.value, [lineQuery]);
  const machineValues = useMemo(() => machineQuery.data?.machines?.value, [machineQuery]);
  const parameterValues = useMemo(() => parameterQuery.data?.parameters?.value, [parameterQuery]);

  const thisTitle = title
    .normalize('NFD')
    .replace(/[^a-zA-Z\s]/g, '')
    .replace(/ /g, '-')
    .toLowerCase();

  return (
    <ChartPageContainer>
      <Title>{title}</Title>
      <Filters>
        <MultiDropdown
          dataCy="shift-drop"
          label={`Turno ${returnMandatoryMark('workShift')}`}
          name="Turno"
          value={filter.multipleShifts?.map((shift) => shift.name) ?? []}
          options={FILTER_SHIFT_OPTIONS}
          onBlur={(multipleShifts) => update({ multipleShifts })}
        />
        <Calendar
          dataCy="start-date"
          label={`Data inicial ${returnMandatoryMark('startDate')}`}
          placeholder="Data inicial"
          type="DATETIME"
          onChange={(e) => {
            const startDate = parseISO(e.target.value);
              if (e.target.value.length == 16){
                update({ startDate: startDate.toISOString()})
              }
            }
          }
          onBlur={(e) => update({ startDate: new Date(e.target.value).toISOString() })}
          value={format(new Date(filter.startDate ?? Date.now()), "yyyy-MM-dd'T'HH:mm")}
        />
        <Calendar
          dataCy="end-date"
          label={`Data final ${returnMandatoryMark('endDate')}`}
          placeholder="Data final"
          type="DATETIME"
          onChange={(e) => {
            const endDate = parseISO(e.target.value);
              if (e.target.value.length == 16){
                update({ endDate: endDate.toISOString()})
              }
            }
          }
          onBlur={(e) => update({ endDate: new Date(e.target.value).toISOString() })}
          value={format(new Date(filter.endDate ?? Date.now()), "yyyy-MM-dd'T'HH:mm")}
        />
        {chart?.hasPeriod && (
          <Drop
            dataCy="period-drop"
            label={`Período ${returnMandatoryMark('period')}`}
            name="Período"
            options={TIME_PERIODS_OPTIONS}
            onSelect={(period) => update({ period })}
            value={filter.period?.name}
          />
        )}
        {chart?.hasCount && (
          <>
            <Input
              type="number"
              label={`Valor Mínimo ${returnMandatoryMark('minCount')}`}
              placeholder="Valor Mínimo"
              value={filter.minCount ? filter.minCount?.name : ''}
              width="179px"
              onChange={(e) => update({ minCount: { id: e.target.value, name: e.target.value } })}
            />
            <Input
              type="number"
              label={`Valor Máximo ${returnMandatoryMark('maxCount')}`}
              placeholder="Valor Máximo"
              value={filter.maxCount ? filter.maxCount?.name : ''}
              width="179px"
              onChange={(e) => update({ maxCount: { id: e.target.value, name: e.target.value } })}
            />
          </>
        )}
      </Filters>
      <BadgeSelection>
        <Drop
          dataCy="factory-drop"
          label={`Unidade ${returnMandatoryMark('factory')}`}
          name="Unidade"
          options={toOptions(factoryValues?.map(obj => ({id: obj.id, name: obj.name, code: obj.code})), 'name')}
          onSelect={(factory) => update({ factory }, ['line', 'machine'])}
          value={filter.factory?.name}
          errorMessage={factoryValues?.length === 0 ? 'Não existe nenhuma fábrica cadastrada.' : undefined}
        />
        <Drop
          dataCy="line-drop"
          label={`Linha ${returnMandatoryMark('line')}`}
          name="Linha"
          options={toOptions(lineValues, 'code')}
          onSelect={(line) => update({ line }, ['machine'])}
          value={filter.line?.name}
          disabled={!filter.factory?.id}
          errorMessage={lineValues?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined}
        />
        <Drop
          dataCy="machine-drop"
          label={`Máquina ${returnMandatoryMark('machine')}`}
          name="Máquina"
          options={toOptions(machineValues?.map(obj => ({id: obj.id, name: obj.name, code: obj.code})), 'name')}
          onSelect={(machine) => update({ machine })}
          value={filter.machine?.name}
          disabled={!filter.line?.id}
          errorMessage={machineValues?.length === 0 ? 'Não existe nenhuma máquina cadastrada.' : undefined}
        />
        <Drop
          dataCy="parameter-drop"
          label={`Parâmetro ${returnMandatoryMark('parameter')}`}
          name="Parâmetro"
          options={parameterValues?.map((param) => ({ id: param.id, name: param.name })) ?? []}
          onSelect={(parameter) => update({ parameter })}
          value={filter.parameter?.name}
          disabled={!filter.machine?.id}
          errorMessage={machineValues?.length === 0 ? 'Não existe nenhum parâmetro cadastrado.' : undefined}
        />
        <Drop
          dataCy="parameter-item-drop"
          label="Tipo de Item"
          name="Tipo de Item"
          options={
            itemName.data?.itemTypes?.value?.map((itemType) => ({
              id: itemType.id,
              name: itemType.name,
            })) ?? []
          }
          onSelect={(itemType) => update({ itemType })}
          value={filter.itemType?.id ?? ''}
          disabled={!filter.machine?.id}
          errorMessage={
            itemName.data?.itemTypes?.value?.length === 0 ? 'Não existe nenhum tipo de item cadastrado.' : undefined
          }
        />
        <Button
          onClick={() => addBadgeToFilter(update, filter)}
          title="Adicionar"
          iconSize="small"
          icon="CirclePlus"
          disabled={!filter.parameter?.id ?? ''}
        />
      </BadgeSelection>
      <BadgeArea>
        {filter.badges?.map((value) => (
          <Badge
            key={value.machine.id}
            title={value.name}
            onClick={() => removeBadgeByMachine(update, value.machine.id, filter)}
          />
        ))}
      </BadgeArea>
      {filter.factory && (
        <ClearFilter onClick={clear}>
          <Icon name="Cross" size="smaller" color="white" />
          <span>Limpar Filtros</span>
        </ClearFilter>
      )}
      {errors?.limitGreaterThenSuperior && (
          <StyledErrorMessage data-cy="field-message" maxWidth='parent'>
            { errors?.limitGreaterThenSuperior}
          </StyledErrorMessage>
      )}
      <ChartContainer>
        <Outlet context={{ filter, setExportData, isFiltersValid }} />
      </ChartContainer>
      <Footer>
        <PageOptions>
          <Button title="Menu gráficos" color="light" icon="ArrowLeft" onClick={() => navigate(routes.charts.index)} />
          <Button
            onClick={() => downloadFile(`grafico-${thisTitle}-${thisDate}`, makeCSV(exportData))}
            title="Exportar"
            disabled={exportData?.length == 0}
            icon="Export"
          />
        </PageOptions>
      </Footer>
    </ChartPageContainer>
  );
};

export function useContext() {
  return useOutletContext<ChartContextType>();
}
