import React from 'react';
import styled from 'styled-components';

const StyledRegisterList = styled.div`
  display: grid;
  grid-area: 'Content';
  grid-template:
    'Title Drop' max-content
    'Filters Filters' max-content
    '. ClearFilter'
    'Table Table' max-content
    'Footer Footer' max-content;
  grid-row-gap: 25px;
  width: max-content;
  max-width: 90vw;
  margin: 55px auto;

  @media (max-width: 660px) {
    grid-template-columns: 1fr;
    grid-template:
      'Title' max-content
      'Drop' max-content
      'Table' max-content
      'Footer' max-content;
    max-width: 90vw;
    margin: 55px auto;
  }
`;

type Props = {
  children: React.ReactNode;
};
export const RegisterList: React.FC<Props> = ({ children }) => <StyledRegisterList>{children}</StyledRegisterList>;
