import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { arraySorting } from '../../../scripts/arraySorting';
import { useIsInputOverflow } from '../../../scripts/isOverflow';
import { Icon } from '../Icon';

const sizes = {
  smaller: { text: '14px', width: '95px', height: '44px' },
  small: { text: '16px', width: '136px', height: '45px' },
  medium: { text: '16px', width: '179px', height: '45px' },
  large: { text: '16px', width: '377px', height: '45px' },
};
type Size = keyof typeof sizes;

type Color = keyof DefaultTheme['colors']['drop'];

const IconContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-self: center;
  align-self: center;
  height: 100%;
`;

const Container = styled.div<{ area: string }>`
  grid-area: ${(props) => props.area};
  position: relative;
  user-select: none;
  width: max-content;
`;

type BoxProps = {
  visible: boolean;
  size: Size;
};
const Box = styled.div<BoxProps>`
  display: ${(props) => (props.visible ? 'grid' : 'none')};
  position: absolute;
  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #001738;
  z-index: 5;

  width: ${(props) => sizes[props.size].width};
  max-width: 80vw;
  padding: 10px;
  padding-bottom: 20px;
  top: -15px;
  border-radius: 5px;
  background-color: #ffffff;

  align-items: center;
  justify-content: space-between;
`;

const BoxTitle = styled.span`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  color: black;
`;

export const StyledLabel = styled.label`
  display: inline-block;
  height: 20px;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 5px;
  padding-left: 5px;
  color: ${(props) => props.theme.colors.input.color};
`;

const StyledDropBox = styled.div<{ size: Size; color: Color }>`
  display: flex;
  width: calc(${(props) => sizes[props.size].width} - 20px);
  height: ${(props) => sizes[props.size].height};
  max-width: 80vw;
  padding: 0px 10px 0px 10px;
  background: ${(props) => props.theme.colors.drop[props.color].background};
  border: 1.5px solid ${(props) => props.theme.colors.drop[props.color].border};
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  &&:hover {
    box-shadow: 0px 2px 10px rgba(0, 100, 199, 0.25);
    position: relative;
    top: 1px;
  }
`;

const DropContent = styled.div<{ visible: boolean; size: Size; color: Color; direction: 'up' | 'down' }>`
  position: absolute;
  bottom: ${(props) => (props.direction === 'down' ? 'auto' : '46px')};
  display: ${(props) => (props.visible ? 'grid' : 'none')};
  z-index: 5;
  width: auto; /*Added by charles*/
  min-width: calc(${(props) => sizes[props.size].width});/*Added by charles*/
  max-height: 302px; /*Added by charles*/
  overflow-x: hidden;
  overflow-y: scroll;
  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) =>
    `${props.theme.colors.drop[props.color].scrollbar.color} ${props.theme.colors.drop[props.color].item.background}`};

  /* Edge, Chrome */
  &&::-webkit-scrollbar {
    width: 12px;
  }
  &&::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.drop[props.color].item.background};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.drop[props.color].scrollbar.color};
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid ${(props) => props.theme.colors.drop[props.color].item.background};
  }
  border-radius: 5px;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.25);
  background-color: ${(props) => props.theme.colors.drop[props.color].item.background};
  color: ${(props) => props.theme.colors.drop[props.color].item.color};
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-weight: 500;
  align-items: center;
`;

const Item = styled.span<{ size: Size; color: Color }>`
  position: relative;
  display: flex;
  min-height: 50px;
  padding: 0px 8px 0px 8px;
  word-break: normal; /*Added by charles*/
  align-items: center;
  border-radius: 5px;
  font-family: ${(props) => props.theme.font};
  color: ${(props) => props.theme.colors.drop[props.color].item.color};
  background-color: ${(props) => props.theme.colors.drop[props.color].item.background};
  font-size: 16px;
  font-weight: 500;
  &&:hover {
    color: ${(props) => props.theme.colors.drop[props.color].item.colorHover};
    background-color: ${(props) => props.theme.colors.drop[props.color].item.backgroundHover};
  }
`;

const Selected = styled.input<{ direction: string; color: Color }>`
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: transparent;
  direction: ${(props) => props.direction};
  text-overflow: ellipsis;
  padding-right: 5px;
  white-space: nowrap;
  border: 0px;
  color: #fff;
  margin-right: 10px;
  font-style: normal;
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.drop[props.color].color};

  /* Chrome, Safari, Edge, Opera */
  &&::-webkit-outer-spin-button,
  &&::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  -moz-appearance: textfield;

  &:focus {
    outline: none;
    border: 0px;
  }
`;

const useDisplayValue = (
  initialValue: string,
  isTextOverflowRightToLeft: boolean
): [string, (value: string, options: { isOverflow: boolean; isFocused: boolean }) => void] => {
  const [displayValue, setDisplayValueTemp] = useState<string>(initialValue);

  const setDisplayValue = (value: string, options: { isOverflow: boolean; isFocused: boolean }) => {
    const { isOverflow, isFocused } = options;

    if (isOverflow && !isFocused && isTextOverflowRightToLeft) setDisplayValueTemp('\u00AA' + value);
    else setDisplayValueTemp(value.replace('\u00AA', ''));
  };
  return [displayValue, setDisplayValue];
};

type Props = {
  name: string;
  label?: string;
  errorMessage?: string;
  options: { id: string; name: string }[];
  onSelect: (value: { id: string; name: string }) => void;
  color?: Color;
  size?: Size;
  direction?: 'up' | 'down';
  disabled?: boolean;
  value?: string;
  area?: string;
  dataCy?: string;
  required?: boolean;
  textOverflowLeftToRight?: boolean;
};

export const Drop: React.FC<Props> = ({
  name,
  label,
  errorMessage,
  options,
  onSelect,
  color = 'default',
  size = 'medium',
  direction = 'down',
  disabled = false,
  area = 'unset',
  value = '',
  dataCy,
  required = false,
  textOverflowLeftToRight = false,
}) => {
  const theme = useTheme();
  const [drop, setDrop] = useState(false);
  const [selected, setSelected] = useState(value);
  const [textDirectionValue, setTextDirectionValue] = useState('ltr');
  const [boxVisibility, setBoxVisibility] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const ref = React.createRef<HTMLInputElement>();
  const isOverflow = useIsInputOverflow(ref);

  const [displayValue, setDisplayValue] = useDisplayValue(
    selected?.length > 0 ? selected : name,
    !textOverflowLeftToRight
  );

  const userRemoveSelection = () => {
    setDisplayValue(name, { isOverflow, isFocused: true });
    setSelected('');
    onSelect({ id: '', name: '' });
  };

  const userSelect = (option: { id: string; name: string }) => {
    setDisplayValue(option.name, { isOverflow, isFocused });
    setSelected(option.name);
    onSelect(option);
    setDrop(false);
  };

  const removeSelection = () => {
    setDisplayValue(name, { isOverflow, isFocused });
    setSelected('');
  };

  const select = (option: { id: string; name: string }) => {
    setDisplayValue(option.name, { isOverflow, isFocused });
    setSelected(option.name);
  };

  const makeDisplay = (isFocused: boolean) =>
    setDisplayValue(selected?.length > 0 ? selected : name, { isOverflow, isFocused });

  const clearDisplay = (isFocused: boolean) => setDisplayValue('', { isOverflow, isFocused });

  useEffect(() => {
    if (isOverflow && !isFocused && !textOverflowLeftToRight) {
      makeDisplay(isFocused);
      setTextDirectionValue('rtl');
    } else setTextDirectionValue('ltr');
  }, [isOverflow]);

  useEffect(() => {
    if (!isFocused && !drop) {
      const valueSelected = options.find((option) => option.name === value || option.id === value);
      if (valueSelected !== undefined) select(valueSelected);
      else removeSelection();
    }
  }, [value, options]);

  disabled = disabled || options?.length === 0;
  color = disabled ? 'gray' : color;

  return (
    <Container
      data-cy={dataCy}
      area={area}
      onMouseLeave={() => {
        if (!isFocused) {
          setDrop(false);
          makeDisplay(isFocused);
        }
      }}
      onMouseOver={() => setBoxVisibility(true)}
      onMouseOut={() => setBoxVisibility(false)}
    >
      {errorMessage && (
        <Box size={size} visible={boxVisibility}>
          <BoxTitle data-cy="box-error">{errorMessage}</BoxTitle>
        </Box>
      )}
      {label && <StyledLabel data-cy="drop-label">{label}</StyledLabel>}
      <StyledDropBox size={size} color={color}>
        <Selected
          ref={ref}
          type={'text'}
          color={color}
          value={displayValue}
          data-cy="drop-selection"
          direction={textDirectionValue}
          onChange={(event) => setDisplayValue(event.target.value, { isOverflow, isFocused })}
          disabled={disabled}
          onFocus={() => {
            setDrop(true);
            setIsFocused(true);
            setTextDirectionValue('ltr');
            if (displayValue === name) clearDisplay(true);
            else makeDisplay(true);
          }}
          onBlur={() => {
            setIsFocused(false);
            setDrop(false);
            if (isOverflow && !textOverflowLeftToRight) setTextDirectionValue('rtl');
            if (displayValue?.length == 0 && !required) {
              userRemoveSelection();
            } else {
              makeDisplay(false);
            }
          }}
        />
        <IconContainer
          onMouseDown={() => {
            setDrop(!drop);
            if (!drop) {
              if (displayValue === name) clearDisplay(isFocused);
            } else makeDisplay(isFocused);
          }}
        >
          <Icon name="ArrowDown" size="smaller" color={theme.colors.drop[color].color} />
        </IconContainer>
      </StyledDropBox>

      <DropContent
        data-cy="drop-content"
        onClick={() => setDrop(!drop)}
        color={color}
        size={size}
        direction={direction}
        visible={drop && !disabled}
      >
        {selected !== '' && !required && (
          <Item data-cy="drop-item" color={color} size={size} key={'default'} onMouseDown={() => userRemoveSelection()}>
            Remover seleção
          </Item>
        )}
        {options
          .sort((a, b) => arraySorting(a.name, b.name))
          .map((option, index) => {
            let showOptions = false;
            if (option.name === selected) showOptions = false;
            else if (displayValue.replace('\u00AA', '') === selected) showOptions = true;
            else if (option.name.toLowerCase().includes(displayValue.toLowerCase())) showOptions = true;
            else showOptions = false;
            if (showOptions)
              return (
                <Item data-cy="drop-item" color={color} size={size} key={index} onMouseDown={() => userSelect(option)}>
                  {option.name}
                </Item>
              );
            else return;
          })}
      </DropContent>
    </Container>
  );
};
