import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFeedback } from '../context/feedback';

export const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const feedback = useFeedback();

  useEffect(() => {
    feedback.message({
      animation: 'Tool',
      title: 'Página não disponível',
      subtitle: 'Página estará disponível em breve.',
      onClose: () => navigate(-1),
    });
  }, []);

  return null;
};
