import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';

const Container = styled.div`
  position: relative;

  overflow-x: scroll;
  scrollbar-width: none;
  &&::-webkit-scrollbar {
    display: none;
  }

  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  color: ${(props) => props.theme.colors.table.colorBody};
  text-align: center;
`;

const StyledTable = styled.table`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-size: 16px;
  text-align: center;
  min-width: 770px;
  width: 100%;

  border: 1px solid ${(props) => props.theme.colors.table.border};
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0px;
`;

const StyledHeader = styled.tr`
  line-height: 29px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.table.colorTitle};
  background-color: ${(props) => props.theme.colors.table.border};

  th {
    padding: 0px 15px 0px 15px;
    font-weight: normal;
  }
`;

const StyledRow = styled.tr`
  height: 55px;
  word-break: keep-all;

  td {
    padding: 0px 15px 0px 15px;
    text-align: center;
    max-width: 350px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 10px;
  justify-content: center;
`;

const StyledMessage = styled.td.attrs({
  colSpan: 100,
})`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-size: 16px;
  color: ${(props) => props.theme.colors.table.colorBody};
  height: 200px;
  text-align: center;
  justify-content: center;
`;

export type attributeType = {
  name: string;
  attr: string;
};

interface IProps<T> {
  attributes: attributeType[];
  content: { table: { [key: string]: string }; value: T }[];
  onMonitor?: (value: T) => void;
  onDelete?: (value: T) => void;
  onEdit?: (value: T) => void;
  thisError?: (value: T) => boolean;
}

export const RegisterTable = <T extends object>({
  attributes,
  content,
  onMonitor,
  onDelete,
  onEdit,
  thisError,
}: IProps<T>) => {
  return (
    <Container>
      <StyledTable>
        <thead>
          <StyledHeader>
            {attributes.map((value) => {
              return <th key={value.attr}>{value.name}</th>;
            })}

            <th>{(onDelete || onEdit) && 'Opções'}</th>
          </StyledHeader>
        </thead>

        <tbody>
          {(content === undefined || content?.length === 0) && (
            <tr>
              <StyledMessage>
                Você ainda não tem nenhum dado cadastrado. Em breve estará disponível aqui :)
              </StyledMessage>
            </tr>
          )}

          {content !== undefined &&
            content?.map((el, index) => {
              return (
                <StyledRow key={index}>
                  {attributes.map((value, index) => {
                    if (value.attr === 'active' && onMonitor !== undefined) {
                      const active = el.table[value.attr] === 'true' ? true : false;
                      return (
                        <td key={index}>
                          <ButtonContainer>
                            <Button
                              title={active ? 'Ativo' : 'Inativo'}
                              size="small"
                              color={active ? 'green' : 'gray'}
                              onClick={() => {
                                el.table[value.attr] = String(!active);
                                onMonitor(el.value);
                              }}
                              errorMessage={
                                thisError !== undefined && thisError(el.value)
                                  ? 'Não há máquinas cadastradas para essa linha.'
                                  : undefined
                              }
                            />
                          </ButtonContainer>
                        </td>
                      );
                    }

                    return <td key={index}>{el.table[value.attr]}</td>;
                  })}

                  <td key={index + 'options'}>
                    <ButtonContainer>
                      {onDelete && (
                        <Button
                          dataCy="delete-button"
                          title=""
                          icon="Trashcan"
                          iconSize="small"
                          size="square"
                          color="light"
                          onClick={() => onDelete(el.value)}
                        />
                      )}
                      {onEdit && (
                        <Button
                          dataCy="edit-button"
                          title=""
                          icon="Wrench"
                          iconSize="small"
                          size="square"
                          color="light"
                          onClick={() => onEdit(el.value)}
                        />
                      )}
                    </ButtonContainer>
                  </td>
                </StyledRow>
              );
            })}
        </tbody>
      </StyledTable>
    </Container>
  );
};
