import { addHours, isBefore, isWithinInterval, parseISO, startOfDay, subHours } from 'date-fns';

type Workshift = {
  duration: number;
  startingAt: string;
  endingAt: string;
  name: string;
};

/**
 * Compares a given date with a workshift interval.
 * @param time
 * @param workshift
 * @returns 0 if the date is contained in the workshift,
 * -1 if the date is previous to the workshift,
 * 1 if the date is after the workshift.
 */
export function cmpWorkshift(time: string, workshift: Workshift) {
  const date = parseISO(time);
  const interval = { start: parseISO(workshift.startingAt), end: parseISO(workshift.endingAt) };

  if (isWithinInterval(date, interval)) return 0;
  else if (date < interval.start) return -1;
  else return 1;
}

/**
 * Checks if a given date string is contained within a workshift.
 * @param time
 * @param workshift
 * @returns true or false.
 */
export function isWithinWorkshift(time: string | Date, workshift: Workshift) {
  const date = new Date(time);
  const interval = { start: new Date(workshift.startingAt), end: new Date(workshift.endingAt) };
  return isWithinInterval(date, interval);
}

export function workshiftFrom(date: Date): { name: string; start: Date; end: Date } {
  const base = startOfDay(date);

  const startPrevShiftC = subHours(base, 2);
  const startShiftA = addHours(base, 6);
  const startShiftB = addHours(base, 14);
  const startShiftC = addHours(base, 22);
  const startNextShiftA = addHours(base, 28);

  if (isBefore(date, startShiftA)) {
    return {
      name: 'SHIFT_C',
      start: startPrevShiftC,
      end: startShiftA,
    };
  } else if (isBefore(date, startShiftB)) {
    return {
      name: 'SHIFT_A',
      start: startShiftA,
      end: startShiftB,
    };
  } else if (isBefore(date, startShiftC)) {
    return {
      name: 'SHIFT_B',
      start: startShiftB,
      end: startShiftC,
    };
  } else {
    return {
      name: 'SHIFT_C',
      start: startShiftC,
      end: startNextShiftA,
    };
  }
}
