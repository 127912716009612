import React, { useEffect, useMemo, useState } from 'react';
import { Title } from '../../components/ui/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import { RegisterList } from '../../components/layout/RegisterList';
import { RegisterTable } from '../../components/ui/RegisterTable';
import {
  useUpdateUserMutation,
  useUserListQuery,
  useLineTitleListQuery,
  useDeleteUserMutation,
  MutationUpdateUserArgs,
} from '../../hooks/graphqlQueries';
import { useFeedback } from '../../context/feedback';

import styled from 'styled-components';
import { Drop } from '../../components/ui/Drop';
import { useTranslation } from 'react-i18next';
import { PageOptions } from '../../components/layout/PageOptions';
import { Footer } from '../../components/layout/Footer';
import { downloadFile, makeCSVFromTable } from '../../scripts/exportFiles';
import { format, parseISO } from 'date-fns';
import { getPagination } from '../../scripts/pagination';
import { asString } from 'ts-runtime-typecheck';

type attributeType = {
  name: string;
  attr: string;
};

const attributes: attributeType[] = [
  { name: 'Tipo de Usuário', attr: 'profile' },
  { name: 'Nome', attr: 'name' },
  { name: 'E-Mail', attr: 'email' },
  { name: 'Situação', attr: 'active' },
];

const Filters = styled.div`
  display: grid;
  grid-area: Drop;
  grid-template-columns: repeat(auto-fit, minmax(179px, max-content));
  column-gap: 20px;
  justify-content: right;
`;

const Table = styled.div`
  display: grid;
  grid-area: Table;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 1168px) {
    &&:after {
      font-size: 12px;
      padding-top: 5px;
      content: 'Deslize a tabela para os lados';
    }
  }
`;

type PageParameters = { page: number; limit: number };
const pageParameters = { page: 1, limit: 10 };

type Filter = {
  lineId?: string;
  lineName?: string;
};

export const UserListPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const feedback = useFeedback();
  const location = useLocation();
  const routeData = location.state as { pagination: { page: number; limit: number } };

  const [parameters, setParameters] = useState<PageParameters>(routeData?.pagination ?? pageParameters);
  const [linesQuery] = useLineTitleListQuery();

  const [, deleteUser] = useDeleteUserMutation();

  const rescuedFilters = sessionStorage.getItem('GlobalRegistrationFilter');
  const storagedFilters: Filter = rescuedFilters ? JSON.parse(rescuedFilters) : pageParameters;
  const [filters, setFilters] = useState<Filter>(storagedFilters);
  const [isExportData, setIsExportData] = useState(false);
  const dataExportLimit = parseInt(asString(process.env['DATA_EXPORT_LIMIT']));
  const [usersQuery, refetch] = useUserListQuery({
    pause: linesQuery.data?.lines?.value?.length === 0,
    requestPolicy: 'network-only',
    variables: {
      ...getPagination(parameters.page, parameters.limit, { dataExportLimit, isExportData }),
      hasAcessTo: filters.lineId ?? '',
    },
  });

  const users = useMemo(() => usersQuery.data?.users?.value ?? [], [usersQuery.data]);

  const [, updateUser] = useUpdateUserMutation();

  useEffect(() => {
    sessionStorage.setItem('GlobalRegistrationFilter', JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    refetch();
  }, [parameters]);

  const data = useMemo(
    () =>
      users.map((user) => {
        return {
          table: {
            profile: t(user.profile),
            name: `${user.firstName} ${user.lastName} `,
            email: user.email,
            active: String(user.active),
          },
          value: user,
        };
      }),
    [users]
  );

  const [tableData, setTableData] = useState<typeof data>(data);
  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');

  useEffect(() => {
    if (isExportData) {
      downloadFile(
        `usuarios-${thisDate}`,
        makeCSVFromTable(
          attributes,
          data.map((v) => v.table)
        )
      );
      setIsExportData(false);

      if (users.length === dataExportLimit)
        feedback.message({
          animation: 'Error',
          title: 'Limite de dados atingido!',
          subtitle: `Não é possível exportar mais que ${dataExportLimit} linhas de dados.`,
        });
    } else setTableData(data);
  }, [data]);

  return (
    <RegisterList>
      <Title>Usuários</Title>
      <Filters>
        <Drop
          name="Linha"
          area="Drop"
          value={filters.lineName}
          options={linesQuery.data?.lines?.value?.map((line) => ({ id: line.id, name: line.code })) ?? []}
          onSelect={(line) => {
            setParameters({ ...parameters, page: 1 });
            setFilters({ lineId: line.id, lineName: line.name });
          }}
          errorMessage={
            linesQuery.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined
          }
        />
      </Filters>
      <Table>
        <RegisterTable
          attributes={attributes}
          content={tableData}
          onMonitor={(user) => {
            updateUser({
              id: user['id']?.toString() ?? '',
              active: !(user['active'] as boolean) ?? false,
            })
              .then((result) => {
                if (result.data?.updateUser === null) {
                  feedback.message({
                    animation: 'Error',
                    title: 'Erro ao ativar/desativar usuário',
                  });
                } else {
                  refetch();
                }
              })
              .catch(() => {
                feedback.message({
                  animation: 'Error',
                  title: user['active'] ? 'Erro ao ativar usuário' : 'Erro ao desativar usuário',
                });
              });
          }}
          onDelete={(user) => {
            feedback.confirmation({
              title:
                'Deseja mesmo excluir esse usuário? Essa operação é irreversível e irá exluir também todos os dados associados ao usuário.',
              onHandle: (result) => {
                if (result) {
                  deleteUser({ id: user['id']?.toString() ?? '' })
                    .then(() => {
                      feedback.message({
                        animation: 'Confetti',
                        title: 'Usúario deletado com sucesso',
                      });

                      parameters.page === 1 ? refetch() : setParameters({ ...parameters, page: 1 });
                    })
                    .catch(() => {
                      feedback.message({
                        animation: 'Error',
                        title: 'Erro ao deletar usúario',
                      });
                    });
                }
              },
            });
          }}
          onEdit={(user) => {
            navigate('edit', {
              state: { data: { id: user['id'], user: user } as MutationUpdateUserArgs, pagination: { ...parameters } },
            });
          }}
        />
      </Table>
      <Footer>
        <PageOptions>
          <Button color="light" title="Menu cadastros" icon="ArrowLeft" onClick={() => navigate('..')} />
          <Button
            color="primary"
            title="Criar Usuário"
            icon="CirclePlus"
            iconSize="small"
            onClick={() =>
              navigate('add', {
                state: { pagination: { ...parameters } },
              })
            }
          />
          <Button title="Exportar" icon="Export" disabled={data.length == 0} onClick={() => setIsExportData(true)} />
        </PageOptions>
        <Pagination
          numberOfItems={parameters.limit}
          onSelectNumber={(number) => setParameters({ limit: number, page: 1 })}
          page={parameters.page}
          totalPages={Math.ceil((usersQuery.data?.users?.count ?? parameters.limit) / parameters.limit)}
          onPrevious={() => {
            setParameters({ ...parameters, page: parameters.page - 1 });
          }}
          onNext={() => {
            setParameters({ ...parameters, page: parameters.page + 1 });
          }}
        />
      </Footer>
    </RegisterList>
  );
};
