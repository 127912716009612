import React, { useState, useEffect, useContext, createContext } from 'react';
import { CombinedError } from 'urql';
import { Confirmation, ConfirmationProps } from '../components/layout/Confirmation';
import { Feedback, FeedbackProps } from '../components/layout/Feedback';
import { errorFromGraphql } from './errors';

type Context = {
  apiError(data: CombinedError): void;
  message(data: FeedbackProps): void;
  confirmation(data: ConfirmationProps): void;
};

export const FeedbackContext = createContext<Context>({} as never);

export const FeedbackProvider: React.FC = ({ children }) => {
  const [feedbackData, setFeedbackData] = useState<FeedbackProps>();
  const [confirmationData, setConfirmationData] = useState<ConfirmationProps>();

  useEffect(() => {
    if (feedbackData?.visible === true) {
      setTimeout(() => {
        setFeedbackData({ ...feedbackData, visible: false });
      }, 2000);
    }
  }, [feedbackData]);

  const message = (data: FeedbackProps) => {
    setFeedbackData(undefined);
    setFeedbackData({ ...data, visible: true });
  };

  const confirmation = (data: ConfirmationProps) => {
    setConfirmationData(undefined);
    setConfirmationData({ ...data, visible: true });
  };

  const apiError = (data: CombinedError) => {
    setFeedbackData(undefined);
    setFeedbackData({ ...errorFromGraphql(data), visible: true });
  };

  return (
    <FeedbackContext.Provider value={{ message, confirmation, apiError }}>
      {feedbackData && (
        <Feedback
          animation={feedbackData.animation}
          title={feedbackData.title}
          subtitle={feedbackData.subtitle}
          onClose={() => feedbackData.onClose?.call(this)}
          visible={feedbackData.visible}
        />
      )}
      {confirmationData && (
        <Confirmation
          title={confirmationData.title}
          onHandle={(result) => {
            if (confirmationData) {
              setConfirmationData({ ...confirmationData, visible: false });
              confirmationData?.onHandle(result);
            }
          }}
          visible={confirmationData.visible}
        />
      )}
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = () => {
  const context = useContext(FeedbackContext);
  return context;
};
