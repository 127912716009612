import React from 'react';
import { sizes, VectorProps } from './Icon';

export const CirclePlus: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.957 0.25C6.3063 0.25 0.914062 5.64224 0.914062 12.293C0.914062 18.9437 6.3063 24.3359 12.957 24.3359C19.6078 24.3359 25 18.9437 25 12.293C25 5.64224 19.6078 0.25 12.957 0.25ZM18.9785 13.2965H13.9606V18.3145C13.9606 18.8694 13.511 19.318 12.957 19.318C12.4031 19.318 11.9535 18.8694 11.9535 18.3145V13.2965H6.93555C6.38157 13.2965 5.93197 12.8479 5.93197 12.293C5.93197 11.738 6.38157 11.2894 6.93555 11.2894H11.9535V6.27148C11.9535 5.7165 12.4031 5.2679 12.957 5.2679C13.511 5.2679 13.9606 5.7165 13.9606 6.27148V11.2894H18.9785C19.5325 11.2894 19.9821 11.738 19.9821 12.293C19.9821 12.8479 19.5325 13.2965 18.9785 13.2965Z"
        fill={color}
      />
    </svg>
  );
};
