import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Selection } from '../../components/layout/Selection';
import { Title } from '../../components/ui/Title';
import { routes } from '../../Routes';
import { SelectionCard, SelectionCardLayout } from '../../components/ui/Cards';

export const SelectHistory: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Selection>
      <Title>Históricos</Title>
      <SelectionCardLayout>
        <SelectionCard
          dataCy="line-history-card"
          title="Linha"
          icon="OeeColored"
          onClick={() => navigate(routes.history.line)}
        />
        <SelectionCard
          dataCy="machine-history-card"
          title="Máquina"
          icon="MachineColored"
          onClick={() => navigate(routes.history.machine)}
        />
        <SelectionCard
          dataCy="stop-history-card"
          title="Parada"
          icon="StopColored"
          onClick={() => navigate(routes.history.stop)}
        />
        <SelectionCard
          dataCy="small-history-card"
          title="Microparada"
          icon="StopColored"
          onClick={() => navigate(routes.history.microStop)}
        />
        <SelectionCard
          dataCy="scrap-history-card"
          title="Scrap"
          icon="BatteryBrokenColored"
          onClick={() => navigate(routes.history.scrap)}
        />
        <SelectionCard
          dataCy="prod-history-card"
          title="Ciclos de Produção"
          icon="PeriodAnalysisColored"
          onClick={() => navigate(routes.history.productionCycles)}
        />
        <SelectionCard
          dataCy="prod-history-card"
          title="Produção"
          icon="ProductionColored"
          onClick={() => navigate(routes.history.production)}
        />
        <SelectionCard
          dataCy="param-history-card"
          title="Parâmetro"
          icon="ParamsColored"
          onClick={() => navigate(routes.history.parameters)}
        />
      </SelectionCardLayout>
    </Selection>
  );
};
