import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Cross: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.33398 1L6.70241 7L12.0708 13" stroke={color} strokeWidth="2" strokeLinecap="round" />
      <path d="M12.3867 1L6.70251 7L1.0183 13" stroke={color} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};
