import React, { useEffect, useMemo, useState } from 'react';
import { Chart } from '../../components/ui/Chart';
import { useTheme } from 'styled-components';
import {
  useProductionRateMaxCapacityQuery,
  useCapacityTrendSubscription,
  CapacityTrend,
} from '../../hooks/graphqlQueries';
import { useOutletContext } from 'react-router-dom';
import { ChartContextType } from '../ChartFilterLineRate';
import { PlotParams } from 'react-plotly.js';

type ChartType = 'LINE' | 'BAR' | 'STACKBAR' | 'OVERLAYBAR';
type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;
type Shape = ArrayElement<PlotParams['layout']['shapes']>;
type Annotation = ArrayElement<PlotParams['layout']['annotations']>;
type BottleneckLine = { shape: Shape; annotation: Annotation };

type BarTemplate = {
  x: string[];
  y: number[];
  dy: number[];
  hover: string[];
  textLabel: string[];
};

function newBars() {
  return {
    x: new Array<string>(),
    y: new Array<number>(),
    dy: new Array<number>(),
    hover: new Array<string>(),
    textLabel: new Array<string>(),
  } as BarTemplate;
}

type PointOptions = {
  x: string;
  y: number;
  yOffset?: number;
  yLabel?: number;
  yHover?: number;
  hover: string;
};

function addPoint(bar: BarTemplate, { x, y, yOffset, yLabel, yHover, hover }: PointOptions) {
  const yRound = Math.round(y * 100) / 100;
  const yOffsetRound = Math.round((yOffset ?? 0) * 100) / 100;

  bar.x.push(x);
  bar.y.push(yRound);
  bar.dy.push(yOffsetRound);
  bar.textLabel.push((yLabel ?? y).toFixed(2));
  bar.hover.push(`<b>${hover}</b>: ${(yHover ?? yLabel ?? y).toFixed(2)}`);
}

function mountBottleneckLine(bottleneck?: { name: string; value: number }, color?: string): BottleneckLine | undefined {
  if (!bottleneck?.value || !color) return;
  const formattedValue = Math.round(bottleneck.value * 100) / 100;
  return {
    shape: {
      type: 'line' as const,
      x0: 0,
      x1: 1,
      y0: formattedValue,
      y1: formattedValue,
      xref: 'paper' as const,
      line: {
        color,
        dash: 'dash' as const,
        width: 2,
      },
    },
    annotation: {
      x: 0,
      y: 1.2,
      showarrow: false,
      xref: 'paper',
      yref: 'paper',
      text: `Gargalo é ${bottleneck.name}`,
      hovertext: `<b>Gargalo (pç/min)</b>: ${formattedValue}<br>`,
      font: {
        color,
      },
    },
  };
}

function mountMaxCapacityCage(values?: CapacityTrend[], color?: string): Shape[] | undefined {
  if (!values || !color) return undefined;
  return values.map((value, index) => ({
    type: 'rect' as 'line' | 'rect',
    x0: index - 0.25,
    x1: index + 0.25,
    y0: 0,
    y1: value.capacityLimit,
    xref: 'x' as 'x' | 'paper',
    line: {
      color,
      dash: 'dot' as 'dot' | 'dash',
      width: 2,
    },
  }));
}

const mountStackBarChart = (
  color: { previous: string; decrease: string; increase: string; bottleneck: string; maxValue: string },
  trends: CapacityTrend[],
  bottleneck: number
) => {
  const nominal: BarTemplate = newBars();
  const previous: BarTemplate = newBars();
  const bottleneckLimit: BarTemplate = newBars();
  const positiveTrend: BarTemplate = newBars();
  const negativeTrend: BarTemplate = newBars();
  const actual: number[] = [];

  for (const trend of trends) {
    const before = trend.capacityBefore;
    const now = trend.capacityAfter;
    const limit = trend.capacityLimit;
    actual.push(now);

    addPoint(bottleneckLimit, {
      x: trend.machineName,
      y: bottleneck,
      hover: 'Capac. gargalo (pç/min)',
    });

    addPoint(nominal, {
      x: trend.machineName,
      y: limit,
      hover: 'Capac. nominal (pç/min)',
    });

    addPoint(previous, {
      x: trend.machineName,
      y: Math.min(before, now),
      yHover: before,
      hover: 'Capac. turno (pç/min)',
    });

    if (now > before) {
      addPoint(positiveTrend, {
        x: trend.machineName,
        y: now - before,
        yLabel: now,
        yOffset: before,
        hover: 'Capac. real (pç/min)',
      });
    } else if (now < before) {
      addPoint(negativeTrend, {
        x: trend.machineName,
        y: before - now,
        yLabel: before,
        yHover: now,
        yOffset: now,
        hover: 'Capac. real (pç/min)',
      });
    }
  }

  const chartComposition = [
    {
      x: nominal.x,
      y: new Array(nominal.x.length).fill(0), // This one is just for the hover label
      text: [],
      textposition: 'auto',
      name: 'Capac. nominal',
      width: 0.3,
      hoverData: nominal.hover,
      hoverLabel: {
        bgcolor: '',
        bordercolor: '',
        font: {
          color: '',
        },
      },
      chartType: 'BAR' as ChartType,
      marker: { color: color.maxValue },
      color: color.maxValue,
    },
    {
      x: bottleneckLimit.x,
      y: new Array(bottleneckLimit.x.length).fill(0), // This one is just for the hover label
      text: [],
      textposition: 'auto',
      name: 'Capac. gargalo',
      width: 0.3,
      hoverData: bottleneckLimit.hover,
      hoverLabel: {
        bgcolor: '',
        bordercolor: '',
        font: {
          color: '',
        },
      },
      chartType: 'BAR' as ChartType,
      marker: { color: color.bottleneck },
      color: color.bottleneck,
    },
    {
      x: previous.x,
      y: previous.y,
      text: previous.textLabel,
      textposition: 'outside',
      name: 'Capac. turno',
      width: 0.3,
      hoverData: previous.hover,
      hoverLabel: {
        bgcolor: '',
        bordercolor: '',
        font: {
          color: '',
        },
      },
      chartType: 'BAR' as ChartType,
      marker: { color: color.previous },
      color: color.previous,
    },
    {
      x: positiveTrend.x,
      y: positiveTrend.y, // Yes this should be inverted
      dy: positiveTrend.dy,
      text: positiveTrend.textLabel,
      textposition: 'auto',
      name: 'Ganho capac. (pç/min)',
      width: 0.3,
      hoverData: positiveTrend.hover,
      hoverLabel: {
        bgcolor: '',
        bordercolor: '',
        font: {
          color: '',
        },
      },
      chartType: 'BAR' as ChartType,
      marker: { color: color.increase },
      color: color.increase,
    },
    {
      x: negativeTrend.x,
      y: negativeTrend.y, // Yes this should be inverted
      dy: negativeTrend.dy,
      text: negativeTrend.textLabel,
      textposition: 'auto',
      name: 'Perda capac. (pç/min)',
      width: 0.3,
      hoverData: negativeTrend.hover,
      hoverLabel: {
        bgcolor: '',
        bordercolor: '',
        font: {
          color: '',
        },
      },
      chartType: 'BAR' as ChartType,
      marker: { color: color.decrease },
      color: color.increase,
    },
  ];

  return {
    exportData: [
      ['maquina', ...previous.x],
      ['turno', ...previous.y],
      ['real', ...actual],
    ],
    chartData: chartComposition,
  };
};

export const ProductionRateChart: React.FC = () => {
  const { filters, setExportData } = useOutletContext<ChartContextType>();
  const [waitingForSubscription, setWaitingForSubscription] = useState(false); // Just the fetching wasn't enough
  const theme = useTheme();

  const productionChartColor = useMemo(() => {
    return {
      bottleneck: theme.colors.chart.customChart.productionRateChart.bottleneck,
      increase: theme.colors.chart.customChart.productionRateChart.increase,
      decrease: theme.colors.chart.customChart.productionRateChart.decrease,
      previous: theme.colors.chart.customChart.productionRateChart.previous,
      maxValue: theme.colors.chart.customChart.productionRateChart.maxValue,
    };
  }, [theme]);

  const commonFilter = useMemo(() => {
    return {
      lineId: filters?.line?.id ?? '',
      period: filters?.period ?? 5,
    };
  }, [filters]);

  const [maxCapacityQuery] = useProductionRateMaxCapacityQuery({
    pause: filters?.line === undefined,
    requestPolicy: 'network-only',
    variables: { lineId: commonFilter.lineId },
  });

  const [capacityTrend] = useCapacityTrendSubscription({
    pause: filters?.line === undefined,
    variables: { lineId: commonFilter.lineId, overMinutes: commonFilter.period },
  });

  useEffect(() => {
    // We just wait if there was a previous value here and we want to
    // show the user that a new one is coming.
    if (filters?.line === undefined) return;
    setWaitingForSubscription(true);
  }, [filters?.period]);

  useEffect(() => {
    setWaitingForSubscription(false);
  }, [capacityTrend.data]);

  const isLoading =
    maxCapacityQuery.fetching ||
    capacityTrend.fetching ||
    capacityTrend.stale ||
    waitingForSubscription ||
    filters?.isLoading === true;

  const data = useMemo(() => {
    const trends = capacityTrend.data?.capacityTrend ?? [];
    const bottleneck = {
      name: maxCapacityQuery.data?.line?.nominalBottleneck?.name ?? 'Não definido',
      value: (maxCapacityQuery.data?.line?.nominalBottleneck?.maxSpeed ?? 0) * 60,
    };

    const data = mountStackBarChart(productionChartColor, trends, bottleneck.value);
    const cage = mountMaxCapacityCage(trends, productionChartColor.maxValue);
    const bottleneckLine = mountBottleneckLine(bottleneck, productionChartColor.bottleneck);
    if (!cage || !bottleneckLine || !capacityTrend.data) {
      return { shapes: [], annotations: [], values: [], export: [] };
    } else {
      return {
        shapes: [...cage, bottleneckLine.shape],
        annotations: [bottleneckLine.annotation],
        values: data.chartData,
        export: data.exportData,
      };
    }
  }, [maxCapacityQuery, capacityTrend, productionChartColor]);

  useEffect(() => {
    setExportData(data.export);
  }, [data]);

  /**
   * This chart will keep going back to the previous state of the ui because
   * https://github.com/plotly/react-plotly.js/issues/171
   */
  return (
    <Chart
      chartType="STACKBAR"
      data={data.values}
      shapes={data.shapes}
      annotations={data.annotations}
      hoverMode="x unified"
      loading={isLoading}
      xaxis={{ type: 'category' }}
    />
  );
};
