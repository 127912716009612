import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div<{ area: string; width: string }>`
  display: grid;
  grid-area: ${(props) => props.area};
  width: ${(props) => props.width};
  max-width: 80vw;
`;

export const StyledLabel = styled.label`
  height: 20px;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding-left: 5px;
  color: ${(props) => props.theme.colors.input.color};
`;

export const StyledInput = styled.textarea<{ height: string }>`
  width: 100%;
  height: ${(props) => props.height};
  resize: none;

  padding-left: 20px;
  padding-top: 10px;

  background: ${(props) => props.theme.colors.input.background};
  border-radius: ${(props) => props.theme.colors.input.borderRadius};
  border: 2px solid ${(props) => props.theme.colors.input.background};

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-size: 16px;

  &:focus {
    outline: none;
    border: 2px solid ${(props) => props.theme.colors.input.border};
  }
`;

type Props = {
  placeholder?: string;
  label?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  area?: string;
  width?: string;
  height?: string;
  maxLength?: number;
  dataCy?: string;
};

export const InputMultiline: React.FC<Props> = ({
  placeholder,
  label,
  value,
  area = 'unset',
  width = '270px',
  height = '43px',
  onChange,
  maxLength = 500,
  dataCy,
}: Props) => {
  return (
    <InputContainer area={area} width={width}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledInput
        data-cy={dataCy}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        onChange={onChange}
        height={height}
      />
    </InputContainer>
  );
};
