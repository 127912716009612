import React, { useEffect, useMemo, useState } from 'react';
import { Title } from '../../components/ui/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import styled from 'styled-components';
import { RegisterList } from '../../components/layout/RegisterList';
import { RegisterTable } from '../../components/ui/RegisterTable';
import { useApplicationListQuery, useDeleteApplicationMutation } from '../../hooks/graphqlQueries';
import { useFeedback } from '../../context/feedback';
import { PageOptions } from '../../components/layout/PageOptions';
import { Footer } from '../../components/layout/Footer';
import { downloadFile, makeCSVFromTable } from '../../scripts/exportFiles';
import { format, parseISO } from 'date-fns';
import { getPagination } from '../../scripts/pagination';
import { asString } from 'ts-runtime-typecheck';

type attributeType = {
  name: string;
  attr: string;
};

const attributes: attributeType[] = [
  { name: 'Identificação', attr: 'id' },
  { name: 'Nome', attr: 'name' },
  { name: 'Descrição', attr: 'description' },
];

const Table = styled.div`
  display: grid;
  grid-area: Table;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 1168px) {
    &&:after {
      font-size: 12px;
      padding-top: 5px;
      content: 'Deslize a tabela para os lados';
    }
  }
`;

type PageParameters = { page: number; limit: number };
const initialParameters = { page: 1, limit: 10 };

export const ApplicationListPage: React.FC = () => {
  const navigate = useNavigate();
  const feedback = useFeedback();
  const location = useLocation();
  const routeData = location.state as { pagination: { page: number; limit: number } };

  const [parameters, setParameters] = useState<PageParameters>(routeData?.pagination ?? initialParameters);
  const [isExportData, setIsExportData] = useState(false);
  const dataExportLimit = parseInt(asString(process.env['DATA_EXPORT_LIMIT']));
  const [appsQuery, refetch] = useApplicationListQuery({
    requestPolicy: 'network-only',
    variables: { ...getPagination(parameters.page, parameters.limit, { dataExportLimit, isExportData }) },
  });

  const [, deleteApplication] = useDeleteApplicationMutation();
  const applications = useMemo(() => appsQuery.data?.applications?.value ?? [], [appsQuery.data]);

  useEffect(() => {
    refetch();
  }, [parameters]);

  const data = useMemo(
    () =>
      applications.map((app) => {
        return {
          table: {
            id: app.id,
            name: app.name,
            description: app.description ?? '',
          },
          value: { ...app },
        };
      }),
    [applications]
  );

  const [tableData, setTableData] = useState<typeof data>(data);
  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');

  useEffect(() => {
    if (isExportData) {
      downloadFile(
        `aplicacoes-${thisDate}`,
        makeCSVFromTable(
          attributes,
          data.map((v) => v.table)
        )
      );
      setIsExportData(false);

      if (applications.length === dataExportLimit)
        feedback.message({
          animation: 'Error',
          title: 'Limite de dados atingido!',
          subtitle: `Não é possível exportar mais que ${dataExportLimit} linhas de dados.`,
        });
    } else setTableData(data);
  }, [data]);

  return (
    <RegisterList>
      <Title>Aplicações cadastradas</Title>
      <Table>
        <RegisterTable
          attributes={attributes}
          content={tableData}
          onDelete={(app) => {
            feedback.confirmation({
              title:
                'Deseja mesmo excluir essa aplicação? Essa operação é irreversível e irá exluir também todos os dados associados a aplicação.',
              onHandle: (result) => {
                if (result) {
                  deleteApplication({ id: app['id']?.toString() ?? '' })
                    .then(() => {
                      feedback.message({
                        animation: 'Confetti',
                        title: 'Aplicação deletada com sucesso',
                      });

                      parameters.page === 1 ? refetch() : setParameters({ ...parameters, page: 1 });
                    })
                    .catch(() => {
                      feedback.message({
                        animation: 'Error',
                        title: 'Erro ao deletar aplicação',
                      });
                    });
                }
              },
            });
          }}
          onEdit={(app) => {
            navigate('edit', { state: { data: app, pagination: { ...parameters } } });
          }}
        />
      </Table>
      <Footer>
        <PageOptions>
          <Button color="light" title="Menu cadastros" icon="ArrowLeft" onClick={() => navigate('..')} />
          <Button
            dataCy="register-button"
            color="primary"
            title="Cadastrar"
            icon="CirclePlus"
            iconSize="small"
            onClick={() => navigate('add')}
          />
          <Button title="Exportar" icon="Export" disabled={data.length == 0} onClick={() => setIsExportData(true)} />
        </PageOptions>
        <Pagination
          numberOfItems={parameters.limit}
          onSelectNumber={(number) => setParameters({ limit: number, page: 1 })}
          page={parameters.page}
          totalPages={Math.ceil((appsQuery.data?.applications?.count ?? parameters.limit) / parameters.limit)}
          onPrevious={() => {
            setParameters({ ...parameters, page: parameters.page - 1 });
          }}
          onNext={() => {
            setParameters({ ...parameters, page: parameters.page + 1 });
          }}
        />
      </Footer>
    </RegisterList>
  );
};
