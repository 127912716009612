import React from 'react';
import styled from 'styled-components';

const StyledScrapRegistration = styled.div`
  display: grid;
  grid-area: 'Content';
  grid-template:
    'Title .'
    'Info Info'
    'Desc Desc' max-content
    'Name .'
    'Footer Footer';
  grid-row-gap: 25px;
  grid-column-gap: 25px;
  width: max-content;
  max-width: 95vw;
  margin: 55px auto;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    grid-template:
      'Title'
      'Info'
      'Desc' max-content
      'Name'
      'Footer' max-content;
    max-width: 90vw;
    margin: 55px auto;
    align-items: center;
  }
`;

type Props = {
  children: React.ReactNode;
};
export const ScrapRegistration: React.FC<Props> = ({ children }) => (
  <StyledScrapRegistration>{children}</StyledScrapRegistration>
);
