import { StatesWithColor } from './colorOfState';

export interface IncomingEvent {
  status: StatesWithColor;
  delta: number;
  time: string;
}

export interface Event {
  status: StatesWithColor;
  start: Date;
  end: Date;
}

type GeneralizedStatus = 'ideal' | 'slow' | 'stop' | 'not_monitored';
export const statusMap: Record<StatesWithColor, GeneralizedStatus> = {
  NOT_MONITORED: 'not_monitored',
  PAUSED: 'not_monitored',
  SMALL_STOP: 'slow',
  SLOW: 'slow',
  STARTUP: 'ideal',
  STOP: 'stop',
  NORMAL: 'ideal',
};

/**
 * Join two timeline slices into one.
 */
export function joinTimelineEvents(timeline: Event[], incoming?: Event[]): Event[] {
  if (!incoming) return timeline;
  return [...timeline, ...incoming]
    .sort((a, b) => a.start.getTime() - b.start.getTime())
    .reduce<Event[]>((acc, curr) => {
      const last = acc[acc.length - 1];
      if (last && statusMap[last.status] === statusMap[curr.status]) {
        last.end = curr.end;
      } else acc.push(curr);
      return acc;
    }, []);
}

export function statesToEvents(events?: IncomingEvent[] | null): Event[] | undefined {
  return events?.map((event) => ({
    status: event.status,
    start: new Date(new Date(event.time).getTime() - event.delta * 1000),
    end: new Date(event.time),
  }));
}

export function statesToTimeline(incoming: IncomingEvent[]): Event[] {
  const events = statesToEvents(incoming) ?? [];
  return joinTimelineEvents(events);
}
