import React, { useState, useContext, createContext } from 'react';

import { UserProfile } from '../hooks/graphqlQueries';

type Context = {
  permission?: UserProfile;
  setPermission: React.Dispatch<React.SetStateAction<UserProfile | undefined>>;
};

export const PermissionContext = createContext<Context>({} as never);

export const PermissionProvider: React.FC = ({ children }) => {
  const [permission, setPermission] = useState<UserProfile>();

  return <PermissionContext.Provider value={{ permission, setPermission }}>{children}</PermissionContext.Provider>;
};

export const usePermission = () => {
  const context = useContext(PermissionContext);
  return context;
};
