import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from '../../../Routes';
import { useFeedback } from '../../../context/feedback';
import background from '../../../static/background.jpeg';
import { LoginInput } from '../../ui/LoginInput';
import { Logo } from '../../ui/Logo';
import { useResetPasswordMutation } from '../../../hooks/graphqlQueries';
import { Button } from '../../ui/Button';

export const RecoverPasswordContainer = styled.div`
  display: grid;
  height: 100vh;
  align-items: center;
  justify-items: center;
  background-color: #012353;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  align-content: center;
`;

export const RecoverPasswordForm = styled.div`
  width: 40%;
  display: grid;
  align-self: center;
  justify-content: center;
  align-items: center;
`;

export const RecoverPasswordError = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
`;

export const RecoverPasswordLink = styled.a`
  margin-bottom: 10px;
  padding-top: 1em;
  justify-self: center;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-start;
  text-align: flex-start;

  /* BACK INPUT */
  color: #f8fcff;
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
`;

const MouraLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2em;
`;

export const PINContainer = styled.div`
  width: 40%;
  display: grid;
  grid-template-columns: min-content;
  grid-template-rows: auto;
`;

type passwordConfig = {
  email: string;
  token: string;
  password: string;
};

type Props = {
  children?: React.ReactNode;
  email?: string;
  token?: string;
};

export const RecoverPassword: React.FC<Props> = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const feedback = useFeedback();
  const [{ error, data }, sendResetEmail] = useResetPasswordMutation();

  const emailBrowser = params.get('email');
  const tokenBrowser = params.get('token');

  const [confirmation, setConfirmation] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState(emailBrowser ? emailBrowser : '');
  const [pin, setPin] = useState(tokenBrowser ? tokenBrowser : '');

  let sendNewPasswd: passwordConfig = {
    email: '',
    token: '',
    password: '',
  };

  useEffect(() => {
    if (data?.resetPassword) {
      feedback.message({
        animation: 'Applause',
        title: 'Senha alterada com sucesso',
        subtitle: '',
      });
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      feedback.message({
        animation: 'Error',
        title: 'Ocorreu um problema ao alterar a senha',
        subtitle: 'Tente novamente',
      });
    }
  }, [error]);

  const handleNewPassword = useCallback(async () => {
    setDisableButton(true);
    if (password === confirmation) {
      sendNewPasswd = { email: email, token: pin, password: confirmation };
      await sendResetEmail(sendNewPasswd);
      navigate(routes.signin);
    } else {
      feedback.message({
        animation: 'Error',
        title: 'Os campos de senha estão diferentes!',
        subtitle: 'Verifique as senhas',
      });
    }
    setDisableButton(false);
  }, [password, confirmation, email, pin]);

  return (
    <RecoverPasswordContainer>
      <MouraLogo>
        <Logo style="vertical" />
      </MouraLogo>
      <RecoverPasswordForm>
        <LoginInput
          onChange={(event) => {
            setEmail(event.target.value);
            setDisableButton(false);
          }}
          value={email}
          type="email"
          placeholder="E-mail"
        />
        <LoginInput
          onChange={(event) => {
            setPassword(event.target.value);
            setDisableButton(false);
          }}
          value={password}
          type="password"
          placeholder="Senha"
        />
        <LoginInput
          onChange={(event) => {
            setConfirmation(event.target.value);
            setDisableButton(false);
          }}
          value={confirmation}
          type="password"
          placeholder="Confirmar Senha"
        />
        <PINContainer>
          <LoginInput
            onChange={(event) => {
              setPin(event.target.value);
              setDisableButton(false);
            }}
            value={pin}
            type="password"
            placeholder="Código PIN"
          />
          <Button disabled={disableButton} onClick={handleNewPassword} title="Enviar" color="primary" size="small">
            Salvar
          </Button>
        </PINContainer>
        <RecoverPasswordLink href="#">Esqueci meu PIN</RecoverPasswordLink>
      </RecoverPasswordForm>
    </RecoverPasswordContainer>
  );
};
