import React, { useEffect, useMemo, useState } from 'react';
import { Title } from '../../components/ui/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import { RegisterList } from '../../components/layout/RegisterList';
import { RegisterTable } from '../../components/ui/RegisterTable';
import {
  useDeleteGroupMutation,
  useGroupListQuery,
  WorkShift,
  UserProfile,
  useFactoriesNameQuery,
  useLinesFromFactoryQuery,
} from '../../hooks/graphqlQueries';
import { useFeedback } from '../../context/feedback';

import styled from 'styled-components';
import { Drop } from '../../components/ui/Drop';
import { MultiDropdown } from '../../components/ui/MultiDropdown';
import { t } from 'i18next';
import { usePermission } from '../../context/permission';
import { PageOptions } from '../../components/layout/PageOptions';
import { Footer } from '../../components/layout/Footer';
import { asString } from 'ts-runtime-typecheck';
import { getPagination } from '../../scripts/pagination';
import { format, parseISO } from 'date-fns';
import { downloadFile, makeCSVFromTable } from '../../scripts/exportFiles';

const shiftTimes = [
  { id: WorkShift.ShiftA, name: t(WorkShift.ShiftA) },
  { id: WorkShift.ShiftB, name: t(WorkShift.ShiftB) },
  { id: WorkShift.ShiftC, name: t(WorkShift.ShiftC) },
];

type attributeType = {
  name: string;
  attr: string;
};

const attributes: attributeType[] = [
  { name: 'Nome', attr: 'name' },
  { name: 'Setor', attr: 'sector' },
  { name: 'Unidade', attr: 'factory' },
  { name: 'Linha', attr: 'line' },
  { name: 'Turno', attr: 'shift' },
];

const FilterContainer = styled.div`
  display: grid;
  grid-area: Drop;
  grid-template-columns: repeat(3, minmax(179px, max-content));
  column-gap: 15px;
  row-gap: 15px;
  justify-content: right;
`;

const Table = styled.div`
  display: grid;
  grid-area: Table;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 1168px) {
    &&:after {
      font-size: 12px;
      padding-top: 5px;
      content: 'Deslize a tabela para os lados';
    }
  }
`;

type Filter = {
  factoryId?: string;
  factoryName?: string;
  lineId?: string;
  lineName?: string;
  workShift?: WorkShift[];
};

type PageParameters = { page: number; limit: number };
const pageParameters = { page: 1, limit: 10 };

export const GroupListPage: React.FC = () => {
  const navigate = useNavigate();
  const feedback = useFeedback();
  const profile = usePermission();
  const location = useLocation();
  const routeData = location.state as { pagination: { page: number; limit: number } };

  const [parameters, setParameters] = useState<PageParameters>(routeData?.pagination ?? pageParameters);

  const rescuedFilters = sessionStorage.getItem('GlobalRegistrationFilter');
  const storagedFilters: Filter = rescuedFilters ? JSON.parse(rescuedFilters) : pageParameters;

  const [filters, setFilters] = useState<Filter>(storagedFilters);

  const [factoriesQuery] = useFactoriesNameQuery({ requestPolicy: 'network-only' });
  const [linesQuery] = useLinesFromFactoryQuery({
    pause: filters.factoryId === undefined,
    requestPolicy: 'network-only',
    variables: { factoryId: filters.factoryId ?? '' },
  });
  const [isExportData, setIsExportData] = useState(false);
  const dataExportLimit = parseInt(asString(process.env['DATA_EXPORT_LIMIT']));
  const [groupsQuery, refetch] = useGroupListQuery({
    requestPolicy: 'network-only',
    variables: {
      ...getPagination(parameters.page, parameters.limit, { dataExportLimit, isExportData }),
      factoryId: filters.factoryId,
      lineId: filters.lineId,
      workShift: filters.workShift,
    },
  });
  const groups = useMemo(() => groupsQuery.data?.groups?.value ?? [], [groupsQuery.data]);

  const [, deleteGroup] = useDeleteGroupMutation();

  useEffect(() => {
    sessionStorage.setItem('GlobalRegistrationFilter', JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    refetch();
  }, [parameters]);

  const data = useMemo(
    () =>
    groups.map((group) => {
      return {
        table: {
          name: group.name,
          sector: t(group.sector),
          factory: group.factory?.name ?? '',
          line: group.line?.code ?? '-',
          shift: t(group.workShift),
        },
        value: group,
      };
    }),
    [groups]
  );

  const [tableData, setTableData] = useState<typeof data>(data);
  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');

  useEffect(() => {
    if (isExportData) {
      downloadFile(
        `grupos-${thisDate}`,
        makeCSVFromTable(
          attributes,
          data.map((v) => v.table)
        )
      );
      setIsExportData(false);

      if (groups.length === dataExportLimit)
        feedback.message({
          animation: 'Error',
          title: 'Limite de dados atingido!',
          subtitle: `Não é possível exportar mais que ${dataExportLimit} linhas de dados.`,
        });
    } else setTableData(data);
  }, [data]);

  return (
    <RegisterList>
      <Title>Grupos</Title>
      <FilterContainer>
        <Drop
          name="Unidade"
          value={filters.factoryName}
          options={
            factoriesQuery.data?.factories?.value?.map((factory) => ({ id: factory.id, name: factory.name })) ?? []
          }
          onSelect={(factory) => {
            setParameters({ ...parameters, page: 1 });
            setFilters({ ...filters, factoryId: factory.id, factoryName: factory.name });
          }}
          errorMessage={
            factoriesQuery.data?.factories?.value?.length === 0 ? 'Não existe nenhuma fábrica cadastrada.' : undefined
          }
        />
        <Drop
          name="Linha"
          value={filters.lineName}
          options={linesQuery.data?.lines?.value?.map((line) => ({ id: line.id, name: line.code })) ?? []}
          onSelect={(line) => {
            setParameters({ ...parameters, page: 1 });
            setFilters({ ...filters, lineId: line.id, lineName: line.name });
          }}
          errorMessage={
            linesQuery.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined
          }
        />
        <MultiDropdown
          name="Turno"
          value={filters.workShift ?? []}
          options={shiftTimes}
          onBlur={(shifts) => {
            setFilters({ ...filters, workShift: shifts.map((el) => el.id as WorkShift) });
          }}
          errorMessage={shiftTimes.length === 0 ? 'Não existe nenhum turno cadastrado.' : undefined}
        />
      </FilterContainer>
      <Table>
        <RegisterTable
          attributes={attributes}
          content={tableData}
          onDelete={
            ![UserProfile.Production, UserProfile.Quality].includes(profile.permission as UserProfile)
              ? (group) => {
                  feedback.confirmation({
                    title: 'Deseja mesmo excluir esse grupo?',
                    onHandle: (result) => {
                      if (result) {
                        deleteGroup({ id: group['id']?.toString() ?? '' })
                          .then(() => {
                            feedback.message({
                              animation: 'Confetti',
                              title: 'Grupo deletado com sucesso',
                            });

                            parameters.page === 1 ? refetch() : setParameters({ ...parameters, page: 1 });
                          })
                          .catch(() => {
                            feedback.message({
                              animation: 'Error',
                              title: 'Erro ao deletar grupo',
                            });
                          });
                      }
                    },
                  });
                }
              : undefined
          }
          onEdit={
            ![UserProfile.Production].includes(profile.permission as UserProfile)
              ? (group) => {
                  navigate('edit', {
                    state: {
                      data: {
                        ...group,
                        lineId: group.line?.id,
                        line: group.line?.code,
                        factoryId: group.factory?.id,
                        factory: group.factory?.name,
                      },
                      pagination: { ...parameters },
                    },
                  });
                }
              : undefined
          }
        />
      </Table>
      <Footer>
        <PageOptions>
          <Button color="light" title="Menu cadastros" icon="ArrowLeft" onClick={() => navigate('..')} />
          {![UserProfile.Production, UserProfile.Quality].includes(profile.permission as UserProfile) && (
            <Button
              color="primary"
              title="Criar Grupo"
              icon="CirclePlus"
              iconSize="small"
              onClick={() => navigate('add', { state: { pagination: { ...parameters } } })}
            />
          )}
          <Button title="Exportar" icon="Export" disabled={data.length == 0} onClick={() => setIsExportData(true)} />
        </PageOptions>
        <Pagination
          numberOfItems={parameters.limit}
          onSelectNumber={(number) => setParameters({ limit: number, page: 1 })}
          page={parameters.page}
          totalPages={Math.ceil((groupsQuery.data?.groups?.count ?? parameters.limit) / parameters.limit)}
          onPrevious={() => {
            setParameters({ ...parameters, page: parameters.page - 1 });
          }}
          onNext={() => {
            setParameters({ ...parameters, page: parameters.page + 1 });
          }}
        />
      </Footer>
    </RegisterList>
  );
};
