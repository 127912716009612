import React from 'react';
import styled, { DefaultTheme } from 'styled-components';

const sizes = {
  small: { text: '16px', width: '136px', height: '45px' },
  medium: { text: '16px', width: '185px', height: '45px' },
  large: { text: '16px', width: '377px', height: '45px' },
};
type Size = keyof typeof sizes;
type Color = keyof DefaultTheme['colors']['drop'];

const Container = styled.div`
  position: relative;
  user-select: none;
  width: max-content;
`;

export const StyledLabel = styled.label`
  display: inline-block;
  height: 20px;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 5px;
  padding-left: 5px;
  color: ${(props) => props.theme.colors.input.color};
`;

const Text = styled.div<{ color: Color; size: Size }>`
  width: calc(${(props) => sizes[props.size].width} - 20px);
  height: ${(props) => sizes[props.size].height};
  padding: 0px 10px 0px 10px;

  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-weight: 500;
  line-height: 45px;

  color: ${(props) => props.theme.colors.drop[props.color].color};
  background: ${(props) => props.theme.colors.drop[props.color].background};
  border: 1.5px solid ${(props) => props.theme.colors.drop[props.color].border};

  border-radius: 5px;
  align-items: center;
  justify-content: space-between;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

type Props = {
  label: string;
  value?: string;
  dataCy?: string;
  color?: Color;
  size?: Size;
};

export const TextBox: React.FC<Props> = ({ label, value = '', dataCy, color = 'default', size = 'medium' }) => {
  return (
    <Container data-cy={dataCy}>
      {label && <StyledLabel data-cy="textbox-label">{label}</StyledLabel>}
      <Text color={color} size={size}>
        {value}
      </Text>
    </Container>
  );
};
