{
  "light": {
    "id": "T_001",
    "name": "Default Light",
    "colors": {
      "body": "#E1E6E8",
      "button": {
        "light": {
          "text": "#006EC7",
          "background": "#ffffff",
          "border": "none",
          "boxShadow": "0px 2px 15px 0px rgba(0, 110, 199, 0.6)"
        },
        "lighter": {
          "text": "#006EC7",
          "background": "#ffffff",
          "border": "solid 2px #006EC7",
          "boxShadow": "0px 2px 15px 0px rgba(0, 110, 199, 0.6)"
        },
        "primary": {
          "text": "#ffffff",
          "background": "#006EC7",
          "border": "none",
          "boxShadow": "0px 2px 15px 0px rgba(0, 110, 199, 0.6)"
        },
        "gray": {
          "text": "#ffffff",
          "background": "#788195",
          "border": "none",
          "boxShadow": "0px 2px 15px 0px rgba(120, 129, 149, 0.6)"
        },
        "green": {
          "text": "#ffffff",
          "background": "#1E9852",
          "border": "none",
          "boxShadow": "0px 2px 15px 0px rgba(30, 152, 82, 0.6)"
        },
        "red": {
          "text": "#ffffff",
          "background": "rgba(214, 54, 36, 1)",
          "border": "none",
          "boxShadow": "0px 2px 15px 0px rgba(214, 54, 36, 0.6)"
        }
      },
      "feedback": {
        "background": "#001738",
        "foreground": "#ffffff",
        "text": "#0033ab",
        "subtitle": "#788195"
      },
      "diagram": {
        "title": "#001738",
        "content": {
          "text": "#012353",
          "background": "#FFFFFF"
        },
        "status": {
          "normal": "#1E9852",
          "slow": "#FFBF31",
          "disabled": "#788195",
          "stopped": "#D63624",
          "bottleneck": "#006EC7"
        }
      },
      "card": {
        "background": "#FFFFFF",
        "staticShadow": "rgba(0, 0, 0, 0.25)",
        "hover": {
          "shadow": "rgba(0, 110, 199, 0.6)",
          "border": "#006EC7"
        },
        "icon": {
          "background": "#E7ECEE"
        },
        "title": {
          "color": "#012353"
        },
        "progressBar": {
          "color": "#006EC7",
          "background": "#F8FCFF"
        },
        "value": {
          "color": "#012353"
        }
      },
      "input": {
        "background": "#F8FCFF",
        "color": "#001738",
        "border": "#006EC7",
        "borderRadius": "10px",
        "height": "44px",
        "icon": {
          "color": "#006EC7"
        },
        "valid": {
          "color": "#1AA053",
          "boxShadow": "#1AA053"
        },
        "invalid": {
          "color": "#D63624",
          "boxShadow": "#BA3020"
        },
        "bottomMessage": {
          "error": "#D63624",
          "tip": "#788195"
        },
        "focus": {
          "color": "#001738",
          "boxShadow": "#001738"
        }
      },
      "icon": {
        "foreground": "#012353"
      },
      "navbar": {
        "color": "#012353",
        "background": "#FFFFFF",
        "shade": "#000000"
      },
      "nav": {
        "background": "#E1E6E8"
      },
      "notification": {
        "color": "#D63624",
        "background": "#FFFFFF",
        "scroll": "#006EC7"
      },
      "timeline": {
        "color": "#012353"
      },
      "lineTitle": {
        "color": "#001F4C",
        "hover": "#FFFFFF",
        "background": "#FFFFFF",
        "backgroundHover": "#012353"
      },
      "title": {
        "color": "#012353"
      },
      "sidebar": {
        "color": "#012353",
        "colorHover": "#F8FCFF",
        "background": "#FFFFFF",
        "backgroundHover": "#006EC7"
      },
      "drop": {
        "default": {
          "color": "#001738",
          "background": "none",
          "border": "#001738",
          "item": {
            "color": "#001738",
            "colorHover": "#FFFFFF",
            "background": "#FFFFFF",
            "backgroundHover": "#006EC7"
          },
          "scrollbar": {
            "color": "#006EC7"
          }
        },
        "filled": {
          "color": "#001738",
          "background": "#FFFFFF",
          "border": "none",
          "item": {
            "color": "#001738",
            "colorHover": "#FFFFFF",
            "background": "#FFFFFF",
            "backgroundHover": "#006EC7"
          },
          "scrollbar": {
            "color": "#006EC7"
          }
        },
        "gray": {
          "color": "#788195",
          "background": "none",
          "border": "#788195",
          "item": {
            "color": "#001738",
            "colorHover": "#FFFFFF",
            "background": "#FFFFFF",
            "backgroundHover": "#006EC7"
          },
          "scrollbar": {
            "color": "#006EC7"
          }
        }
      },
      "notificationDrawer": {
        "shadowColor": "#15335c",
        "backgroundColor": "#FFFFFF",
        "HoverbackgroundColor": "#e7ecee",
        "message": {
          "titleColor": "#006EC7",
          "textColor": "#788195",
          "timeColor": "#006EC7"
        },
        "button": {
          "color": "#FFFFFF",
          "backgroundColor": "#012353",
          "HoverbackgroundColor": "#66a8dd"
        }
      },
      "stopTimer": {
        "backgroundColor": "#F8FCFF",
        "borderColor": "#D63624",
        "color": "#D63624",
        "hover": "#ba3020"
      },
      "lineStopTimer": {
        "backgroundColorPlaces": "#2a242488"
      },
      "pageCount": {
        "color": "#001738",
        "background": "#001738",
        "backgroundHover": "#006EC7",
        "icon": {
          "color": "#FFFFFF",
          "colorHover": "#FFFFFF"
        }
      },
      "table": {
        "border": "#006EC7",
        "colorTitle": "#FFFFFF",
        "colorBody": "#001738"
      },
      "popup": {
        "background": "#293c59",
        "opacityBackground": "#293c59ca",
        "centerBackground": "#FFFFFF",
        "closeButton": "#788195",
        "closeButtonHover": "#006EC7"
      },
      "lineStopTimerPopup": {
        "title": "#006ec7",
        "description": "#788195",
        "button": {
          "color": "#ffffff",
          "background": "#006ec7",
          "hover": "#006ec799"
        }
      },
      "checkbox": {
        "color": "#001738"
      },
      "chart": {
        "paperBackgroundColor": "#E1E6E8",
        "plotBackgroundColor": "#E1E6E8",
        "legend": "#012353",
        "yaxis": {
          "color": "#788195",
          "gridcolor": "#012353"
        },
        "xaxis": {
          "color": "#788195",
          "gridcolor": "#012353"
        },
        "hover": {
          "fontColor": "#012353",
          "backgroundColor": "#FFFFFF",
          "borderColor": "#012353"
        },
        "filter": {
          "color": "#001738",
          "iconColor": "#001738"
        },
        "customChart": {
          "scrapChart": {
            "barColor": "#EAA00C",
            "lineColor": "#006ec7"
          },
          "oeeChart": {
            "oee": "#006ec7",
            "availability": "#001738",
            "performance": "#EAA00C"
          },
          "stopChart": {
            "barColor": "#D63624",
            "lineColor": "#006ec7"
          },
          "periodAnalysisChart": {
            "metric": "#001738",
            "title": "#001738",
            "legend": "#9CA3AF"
          },
          "productionRateChart": {
            "bottleneck": "#EAA00C",
            "increase": "#1E9852",
            "decrease": "#D63624",
            "previous": "#001738",
            "maxValue": "#006EC7"
          },
          "productionChart": {
            "accumulated": "#EAA00C",
            "production": "#001738",
            "projection": "#006ec7"
          },
          "cycleTimeChart": {
            "cycleTime": "#006ec7"
          },
          "parameterChart": {
            "value": "#006ec7"
          },
          "capacityChart": {
            "value": "#006ec7"
          }
        }
      }
    },
    "font": "Inter, Arial, Helvetica, sans-serif"
  },
  "dark": {
    "id": "T_002",
    "name": "Default Dark",
    "colors": {
      "body": "#001738",
      "button": {
        "light": {
          "text": "#006EC7",
          "background": "#ffffff",
          "border": "none",
          "boxShadow": "0px 2px 15px 0px rgba(0, 110, 199, 0.6)"
        },
        "lighter": {
          "text": "#006EC7",
          "background": "#ffffff",
          "border": "solid 2px #006EC7",
          "boxShadow": "0px 2px 15px 0px rgba(0, 110, 199, 0.6)"
        },
        "primary": {
          "text": "#ffffff",
          "background": "#006EC7",
          "border": "none",
          "boxShadow": "0px 2px 15px 0px rgba(0, 110, 199, 0.6)"
        },
        "gray": {
          "text": "#ffffff",
          "background": "#788195",
          "border": "none",
          "boxShadow": "0px 2px 15px 0px rgba(120, 129, 149, 0.6)"
        },
        "green": {
          "text": "#ffffff",
          "background": "#1E9852",
          "border": "none",
          "boxShadow": "0px 2px 15px 0px rgba(30, 152, 82, 0.6)"
        },
        "red": {
          "text": "#ffffff",
          "background": "rgba(214, 54, 36, 1)",
          "border": "none",
          "boxShadow": "0px 2px 15px 0px rgba(214, 54, 36, 0.6)"
        }
      },
      "feedback": {
        "background": "#001738",
        "foreground": "#ffffff",
        "text": "#0033ab",
        "subtitle": "#788195"
      },
      "diagram": {
        "title": "#031928",
        "content": {
          "text": "#F8FCFF",
          "background": "#001738"
        },
        "status": {
          "normal": "#1E9852",
          "slow": "#FFBF31",
          "disabled": "#788195",
          "stopped": "#D63624",
          "bottleneck": "#006EC7"
        }
      },
      "card": {
        "background": "#012353",
        "staticShadow": "rgba(0, 0, 0, 0.25)",
        "hover": {
          "shadow": "rgba(0, 110, 199, 0.6)",
          "border": "#006EC7"
        },
        "icon": {
          "background": "#FFFDF7"
        },
        "title": {
          "color": "#F8FCFF"
        },
        "progressBar": {
          "color": "#006EC7",
          "background": "#F8FCFF"
        },
        "value": {
          "color": "#F8FCFF"
        }
      },
      "input": {
        "background": "#F8FCFF",
        "color": "#F8FCFF",
        "border": "#006EC7",
        "borderRadius": "10px",
        "height": "44px",
        "icon": {
          "color": "#006EC7"
        },
        "valid": {
          "color": "#1AA053",
          "boxShadow": "#1AA053"
        },
        "invalid": {
          "color": "#D63624",
          "boxShadow": "#BA3020"
        },
        "bottomMessage": {
          "error": "#D63624",
          "tip": "#788195"
        },
        "focus": {
          "color": "#001738",
          "boxShadow": "#001738"
        }
      },
      "icon": {
        "foreground": "#F8FCFF"
      },
      "navbar": {
        "color": "#F8FCFF",
        "background": "#012353",
        "shade": "#000000"
      },
      "nav": {
        "background": "#061738"
      },
      "notification": {
        "color": "#D63624",
        "background": "#FFFFFF",
        "scroll": "#788195"
      },
      "timeline": {
        "color": "#FFFFFF"
      },
      "lineTitle": {
        "color": "#F8FCFF",
        "hover": "#FFFFFF",
        "background": "#012353",
        "backgroundHover": "#006EC7"
      },
      "title": {
        "color": "#FFFFFF"
      },
      "sidebar": {
        "color": "#F8FCFF",
        "colorHover": "#F8FCFF",
        "background": "#012353",
        "backgroundHover": "#006EC7"
      },
      "drop": {
        "default": {
          "color": "#FFFFFF",
          "background": "none",
          "border": "#FFFFFF",
          "item": {
            "color": "#001738",
            "colorHover": "#FFFFFF",
            "background": "#FFFFFF",
            "backgroundHover": "#006EC7"
          },
          "scrollbar": {
            "color": "#006EC7"
          }
        },
        "filled": {
          "color": "#001738",
          "background": "#FFFFFF",
          "border": "none",
          "item": {
            "color": "#001738",
            "colorHover": "#FFFFFF",
            "background": "#FFFFFF",
            "backgroundHover": "#006EC7"
          },
          "scrollbar": {
            "color": "#006EC7"
          }
        },
        "gray": {
          "color": "#788195",
          "background": "none",
          "border": "#788195",
          "item": {
            "color": "#001738",
            "colorHover": "#FFFFFF",
            "background": "#FFFFFF",
            "backgroundHover": "#006EC7"
          },
          "scrollbar": {
            "color": "#006EC7"
          }
        }
      },
      "notificationDrawer": {
        "shadowColor": "#15335c",
        "backgroundColor": "#FFFFFF",
        "HoverbackgroundColor": "#e7ecee",
        "message": {
          "titleColor": "#006EC7",
          "textColor": "#788195",
          "timeColor": "#006EC7"
        },
        "button": {
          "color": "#FFFFFF",
          "backgroundColor": "#012353",
          "HoverbackgroundColor": "#66a8dd"
        }
      },
      "stopTimer": {
        "backgroundColor": "#F8FCFF",
        "borderColor": "#D63624",
        "color": "#D63624",
        "hover": "#ba3020"
      },
      "lineStopTimer": {
        "backgroundColorPlaces": "#eccece4e"
      },
      "pageCount": {
        "color": "#FFFFFF",
        "background": "#FFFFFF",
        "backgroundHover": "#006EC7",
        "icon": {
          "color": "#006EC7",
          "colorHover": "#FFFFFF"
        }
      },
      "table": {
        "border": "#006EC7",
        "colorTitle": "#FFFFFF",
        "colorBody": "#FFFFFF"
      },
      "popup": {
        "background": "#293c59",
        "opacityBackground": "#293c59ca",
        "centerBackground": "#FFFFFF",
        "closeButton": "#788195",
        "closeButtonHover": "#006EC7"
      },
      "lineStopTimerPopup": {
        "title": "#006ec7",
        "description": "#788195",
        "button": {
          "color": "#ffffff",
          "background": "#006ec7",
          "hover": "#006ec799"
        }
      },
      "checkbox": {
        "color": "#ffffff"
      },
      "chart": {
        "paperBackgroundColor": "#001738",
        "plotBackgroundColor": "#001738",
        "legend": "#FFFFFF",
        "yaxis": {
          "color": "#788195",
          "gridcolor": "#012353"
        },
        "xaxis": {
          "color": "#788195",
          "gridcolor": "#012353"
        },
        "hover": {
          "fontColor": "#012353",
          "backgroundColor": "#FFFFFF",
          "borderColor": "#012353"
        },
        "filter": {
          "color": "#FFFFFF",
          "iconColor": "#FFFFFF"
        },
        "customChart": {
          "scrapChart": {
            "barColor": "#EAA00C",
            "lineColor": "#006ec7"
          },
          "oeeChart": {
            "oee": "#006ec7",
            "availability": "#FFFFFF",
            "performance": "#EAA00C"
          },
          "stopChart": {
            "barColor": "#D63624",
            "lineColor": "#006ec7"
          },
          "periodAnalysisChart": {
            "metric": "#FFFFFF",
            "title": "#FFFFFF",
            "legend": "#9CA3AF"
          },
          "productionRateChart": {
            "bottleneck": "#EAA00C",
            "increase": "#1E9852",
            "decrease": "#D63624",
            "previous": "#E7ECEE",
            "maxValue": "#006EC7"
          },
          "productionChart": {
            "accumulated": "#E7ECEE",
            "production": "#D63624",
            "projection": "#006EC7"
          },
          "cycleTimeChart": {
            "cycleTime": "#006ec7"
          },
          "parameterChart": {
            "value": "#006ec7"
          },
          "capacityChart": {
            "value": "#006ec7"
          }
        }
      }
    },
    "font": "Inter, Arial, Helvetica, sans-serif"
  }
}