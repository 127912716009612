type PageFilters = {
    page: number;
    limit: number;
    filters: object;
}

export function parsePageFilters(storagedFilter: string | null, standardParameter: PageFilters): PageFilters {
    if(!storagedFilter){
      return standardParameter;
    }
    const persistedFilter: PageFilters = JSON.parse(storagedFilter);
    const filters = Object.keys(standardParameter.filters).reduce((result:any, key:string) => {
      if (key in persistedFilter.filters && (persistedFilter.filters[key as keyof typeof persistedFilter.filters] !== undefined && persistedFilter.filters[key as keyof typeof persistedFilter.filters] !== null)) {
        result[key] = persistedFilter.filters[key as keyof typeof persistedFilter.filters];
      } else {
        result[key] = standardParameter.filters[key as keyof typeof standardParameter.filters];
      }
      return result;
    }, {} as PageFilters['filters']);
  
    return { ...persistedFilter, filters };
  }