import { format, parseISO } from 'date-fns';
import { t } from 'i18next';
import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Footer } from '../../components/layout/Footer';
import { PageOptions } from '../../components/layout/PageOptions';
import { ScrapRegistration } from '../../components/layout/ScrapRegistration';
import { Button } from '../../components/ui/Button';
import { Calendar } from '../../components/ui/Calendar';
import { Drop } from '../../components/ui/Drop';
import { Input } from '../../components/ui/Input';
import { InputMultiline } from '../../components/ui/InputMultiline';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import {
  useAllGroupsQuery,
  useLinesFromFactoryQuery,
  useMachinesFromLineQuery,
  useScrapDefectNameQuery,
  useScrapItemTypeQuery,
  useScrapUserNameQuery,
  useUpdateScrapMutation,
  WorkShift,
} from '../../hooks/graphqlQueries';
import { formatStringNumber } from '../../scripts/formatStringNumber';

const Info = styled.div`
  display: grid;
  grid-area: Info;
  grid-template-columns: 1fr 1fr;
  row-gap: 19px;
  column-gap: 19px;
  align-items: end;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
`;

type Scrap = {
  id: string;
  amount: string;
  detailLine?: string;
  detailItem: string;
  detailUser: string;
  detailScrapPossibleFailure: string;
  detailDescription: string;
  detailDetectorGroup: string;
  detailGeneratorGroup: string;
  machineId?: string;
  machineCode?: string;
  lineId?: string;
  lineCode?: string;
  workShift: string;
  date: string;
};

const initialScrap: Scrap = {
  id: '',
  amount: '',
  detailLine: '',
  detailItem: '',
  detailUser: '',
  detailScrapPossibleFailure: '',
  detailDescription: '',
  detailDetectorGroup: '',
  detailGeneratorGroup: '',
  machineId: '',
  machineCode: '',
  lineId: '',
  lineCode: '',
  workShift: '',
  date: '',
};

const shiftTimes = [
  {
    id: WorkShift.ShiftA,
    name: t(WorkShift.ShiftA),
  },
  {
    id: WorkShift.ShiftB,
    name: t(WorkShift.ShiftB),
  },
  {
    id: WorkShift.ShiftC,
    name: t(WorkShift.ShiftC),
  },
];

export const ScrapEditPage: React.FC = () => {
  const navigate = useNavigate();
  const feedback = useFeedback();
  const location = useLocation();

  const [, updateScrap] = useUpdateScrapMutation();
  const routeData = location.state as { data: Scrap; pagination: { page: number; limit: number } };
  const [data] = useState<Scrap>(routeData?.data as Scrap);

  const [parameters, setParameters] = useState<Scrap>(data ?? initialScrap);

  const [groupName] = useAllGroupsQuery();
  const [lineName] = useLinesFromFactoryQuery({
    requestPolicy: 'network-only',
  });
  const [machineName] = useMachinesFromLineQuery({
    pause: parameters.detailLine === undefined,
    requestPolicy: 'network-only',
    variables: { lineId: parameters.detailLine ?? '' },
  });
  const [userName] = useScrapUserNameQuery({
    pause: parameters.detailLine === undefined,
    requestPolicy: 'network-only',
    variables: { idLine: parameters.detailLine ?? '' },
  });
  const [itemName] = useScrapItemTypeQuery({ requestPolicy: 'network-only' });
  const [defectName] = useScrapDefectNameQuery({ requestPolicy: 'network-only' });

  const canRegister = useMemo(() => Object.values(parameters).every((v) => v?.length > 0), [parameters]);

  const handleRegister = () => {
    updateScrap({
      id: parameters.id,
      amount: parseFloat(parameters.amount),
      details: {
        line: parameters.detailLine,
        item: parameters.detailItem,
        scrapPossibleFailure: parameters.detailScrapPossibleFailure,
        user: parameters.detailUser,
        description: parameters.detailDescription,
        detectorGroup: parameters.detailDetectorGroup,
        generatorGroup: parameters.detailGeneratorGroup,
      },
      machineId: parameters.machineId ?? '',
      workShift: parameters.workShift as WorkShift,
      date: parseISO(parameters.date),
    })
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.updateScrap === null)
          feedback.message({ animation: 'Error', title: 'Erro ao editar scrap' });
        else {
          feedback.message({ animation: 'Confetti', title: 'Scrap editado com sucesso' });
          navigate('..', { state: { pagination: routeData?.pagination } });
        }
      })
      .catch(() => {
        feedback.message({ animation: 'Error', title: 'Erro ao editar scrap' });
      });
  };

  return (
    <ScrapRegistration>
      <Title>Editar Scrap</Title>
      <Info>
        <Drop
          dataCy="scrap-group-drop"
          label={'Grupo Detector'}
          name={'Grupo Detector'}
          size="large"
          options={groupName.data?.groups?.value?.map((group) => ({ id: group.id, name: group.name })) ?? []}
          onSelect={(group) => {
            setParameters({
              ...parameters,
              detailDetectorGroup: group.name,
            });
          }}
          value={parameters.detailDetectorGroup}
          errorMessage={groupName.data?.groups?.value?.length === 0 ? 'Não existe grupo cadastrado.' : undefined}
        />
        <Drop
          dataCy="scrap-line-drop"
          label={'Código da Linha'}
          name={'Linha'}
          size="large"
          options={lineName.data?.lines?.value?.map((line) => ({ id: line.id, name: line.code })) ?? []}
          onSelect={(line) => {
            setParameters({
              ...parameters,
              machineId: undefined,
              machineCode: undefined,
              lineId: line.id,
              lineCode: line.name,
              detailLine: line.id,
            });
          }}
          value={parameters.lineCode}
          errorMessage={lineName.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined}
        />
        <Drop
          dataCy="scrap-machine-drop"
          label={'Código da Máquina'}
          name={'Máquina'}
          size="large"
          options={
            machineName.data?.machines?.value?.map((machine) => ({
              id: machine.id,
              name: machine.code,
            })) ?? []
          }
          onSelect={(machine) => {
            setParameters({
              ...parameters,
              machineId: machine.id,
              machineCode: machine.name,
            });
          }}
          value={parameters.machineCode}
          disabled={!parameters.lineId}
          errorMessage={
            machineName.data?.machines?.value?.length === 0 ? 'Não existe nenhuma máquina cadastrada.' : undefined
          }
        />
        <Drop
          dataCy="scrap-item-drop"
          label={'Tipo de Item'}
          name={'Tipo de Item'}
          size="large"
          options={
            itemName.data?.itemTypes?.value?.map((item) => ({
              id: item.id,
              name: item.name,
            })) ?? []
          }
          onSelect={(selected) => {
            setParameters({ ...parameters, detailItem: selected.name });
          }}
          value={parameters.detailItem}
          disabled={!parameters.machineId}
          errorMessage={
            itemName.data?.itemTypes?.value?.length === 0 ? 'Não existe nenhum tipo de item cadastrado.' : undefined
          }
        />
        <Drop
          dataCy="scrap-defectName-drop"
          label={'Defeito'}
          name={'Defeito'}
          size="large"
          options={
            machineName.data?.machines?.value
              ?.find((machine) => machine.id === parameters.machineId)
              ?.possibleDefects?.map((defect) => ({ id: defect, name: defect })) ?? []
          }
          onSelect={(selected) => {
            setParameters({ ...parameters, detailScrapPossibleFailure: selected.name });
          }}
          value={parameters.detailScrapPossibleFailure ?? ''}
          disabled={!parameters.machineId}
          errorMessage={
            defectName.data?.scrapPossibleFailures?.value?.length === 0
              ? 'Não existe nenhum defeito cadastrado.'
              : undefined
          }
        />
        <Drop
          dataCy="scrap-shift-drop"
          label={'Turno'}
          name={'Turno'}
          size="large"
          options={shiftTimes}
          onSelect={(selected) => {
            setParameters({ ...parameters, workShift: selected.id });
          }}
          value={(parameters.workShift as WorkShift) ?? 'NO_SHIFT'}
          errorMessage={shiftTimes?.length === 0 ? 'Não existe nenhum turno cadastrado.' : undefined}
        />
        <Calendar
          label={'Data'}
          placeholder={'Data'}
          width="357px"
          onChange={(date) => {
            const parsedDate = parseISO(date.target.value);
            if (date.target.value.length == 10){
              setParameters({ ...parameters, date: format(parsedDate, 'yyyy-MM-dd') });
            }
          }}
          onBlur={(date) => {
            const parsedDate = parseISO(date.target.value);
            if (date.target.value.length == 10){
              setParameters({ ...parameters, date: parsedDate.toISOString() });
            }
          }}
          value={format(parseISO(parameters.date), 'yyyy-MM-dd')}
        />
        <Input
          label={'Quantidade'}
          type="text"
          width="380px"
          placeholder={'Quantidade'}
          value={parameters.amount}
          onChange={(e) => {
            const valueAsNumber = formatStringNumber(e.target.value);
            setParameters({ ...parameters, amount: valueAsNumber });
          }}
        />
        <Drop
          dataCy="scrap-generator-group-drop"
          label={'Grupo Gerador'}
          name={'Grupo Gerador'}
          size="large"
          options={groupName.data?.groups?.value?.map((group) => ({ id: group.id, name: group.name })) ?? []}
          onSelect={(group) => {
            setParameters({
              ...parameters,
              detailGeneratorGroup: group.name,
            });
          }}
          value={parameters.detailGeneratorGroup}
          errorMessage={groupName.data?.groups?.value?.length === 0 ? 'Não existe grupo cadastrado.' : undefined}
        />
        <Drop
          dataCy="scrap-user-drop"
          label={'Usuário'}
          name={'Usuário'}
          size="large"
          color="filled"
          direction="up"
          onSelect={(selected) => {
            setParameters({ ...parameters, detailUser: selected.name });
          }}
          options={
            userName.data?.line?.hasAccess?.map((user) => ({
              id: user?.id ?? '',
              name: user?.firstName.concat(' ').concat(user?.lastName) ?? '',
            })) ?? []
          }
          disabled={!parameters.machineId}
          value={parameters.detailUser}
          errorMessage={
            userName.data?.line?.hasAccess?.length === 0 ? 'Não existe nenhum usúario cadastrado.' : undefined
          }
        />
      </Info>
      <InputMultiline
        dataCy="scrap-input"
        label={'Descrição'}
        placeholder={'Descrição...'}
        area="Desc"
        width="770px"
        height="150px"
        maxLength={150}
        onChange={(e) => setParameters({ ...parameters, detailDescription: e.target.value })}
        value={parameters.detailDescription ?? undefined}
      />
      <Footer>
        <PageOptions>
          <Button
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button
            dataCy="save-button"
            title="Salvar"
            color="primary"
            disabled={!canRegister}
            onClick={() => handleRegister()}
          />
        </PageOptions>
      </Footer>
    </ScrapRegistration>
  );
};
