import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ApplicationRegistration } from '../../components/layout/ApplicationRegistration';
import { Footer } from '../../components/layout/Footer';
import { PageOptions } from '../../components/layout/PageOptions';
import { Button } from '../../components/ui/Button';
import { Input } from '../../components/ui/Input';
import { MultiDropdown } from '../../components/ui/MultiDropdown';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import { useLineTitleListQuery, useUpdateApplicationMutation } from '../../hooks/graphqlQueries';

const Info = styled.div`
  display: grid;
  grid-area: Info;
  grid-template-columns: 1fr 1fr;
  row-gap: 19px;
  column-gap: 19px;
  align-items: end;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
`;

type Application = {
  id: string;
  name: string;
  description: string;
  secretKey: string;
  line: string[];
  permissions?: string[];
};

export const ApplicationEditPage: React.FC = () => {
  const navigate = useNavigate();
  const feedback = useFeedback();
  const location = useLocation();

  const routeData = location.state as { data: Application; pagination: { page: number; limit: number } };

  const [linesQuery] = useLineTitleListQuery();
  const [, updateApplication] = useUpdateApplicationMutation();

  const lines = useMemo(() => linesQuery.data?.lines?.value ?? [], [linesQuery.data]);

  const [data, setData] = useState<Application>(routeData?.data as Application);

  const canRegister = useMemo(
    () =>
      Object.values(data).every((v) => {
        if (typeof v !== 'boolean') v?.length ?? 0 > 0;
      }),
    [data]
  );

  const handleRegister = () => {
    updateApplication(data)
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.updateApplication === null)
          feedback.message({ animation: 'Error', title: 'Erro ao editar aplicação' });
        else {
          feedback.message({ animation: 'Confetti', title: 'Aplicação editada com sucesso' });
          navigate('..', { state: { pagination: routeData?.pagination } });
        }
      })
      .catch(() => {
        feedback.message({ animation: 'Error', title: 'Erro ao editar aplicacação' });
      });
  };

  return (
    <ApplicationRegistration>
      <Title>Editar Aplicação</Title>
      <Info>
        <Input
          label="Identificação"
          value={data.id ?? ''}
          width="376px"
          disabled={true}
          onChange={() => {
            return;
          }}
        />
        <Input
          label="Nome"
          dataCy="name-field"
          value={data.name ?? ''}
          width="376px"
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        <Input
          label="Descrição"
          dataCy="description-field"
          value={data.description ?? ''}
          width="376px"
          onChange={(e) => setData({ ...data, description: e.target.value })}
        />
        <MultiDropdown
          label="Permissões"
          size={'large'}
          dataCy="permissions-multidropdown"
          name="Linhas"
          value={data.permissions ?? []}
          options={lines.map((line) => ({ id: line.id, name: line.code })) ?? []}
          onBlur={(lines) => {
            setData({
              ...data,
              permissions: lines.map((item) => item.id as string),
            });
          }}
        />
      </Info>
      <Footer>
        <PageOptions>
          <Button
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button
            dataCy="save-button"
            title="Salvar"
            color="primary"
            disabled={canRegister}
            onClick={() => handleRegister()}
          />
        </PageOptions>
      </Footer>
    </ApplicationRegistration>
  );
};
