import { useEffect, useState } from 'react';
import { StatesWithColor } from '../scripts/colorOfState';
import { Workshift } from './graphqlQueries';
import { Event } from './timeline';

interface FutureDataArgs {
  clock: Date;
  workshift?: Workshift;
  idealCycle?: number;
  stopCycle?: number;
  lastEvent?: Event;
}

function predictTheFuture(currentEventAt: Date, lastEventAt: Date, idealCycle?: number, stopCycle?: number): Event {
  const duration = currentEventAt.getTime() - lastEventAt.getTime();
  let status: StatesWithColor = 'NORMAL';
  if (!idealCycle || !stopCycle) status = 'NOT_MONITORED';
  else if (duration >= stopCycle * 1000) status = 'STOP';
  else if (duration >= idealCycle * 1000) status = 'SLOW';
  return { start: lastEventAt, end: currentEventAt, status };
}

/**
 * Returns the next event that will be received, as predicted so far.
 */
export function useFutureData({ clock, idealCycle, stopCycle, lastEvent, workshift }: FutureDataArgs): Event {
  const [futureData, setFutureData] = useState<Event>({
    start: workshift?.startingAt ?? new Date(),
    end: workshift?.endingAt ?? new Date(),
    status: 'NOT_MONITORED',
  });

  useEffect(() => {
    if (!workshift) return;
    const currentTime = new Date();
    const lastEventAt = new Date(lastEvent?.end ?? workshift.startingAt);
    setFutureData(predictTheFuture(currentTime, lastEventAt, idealCycle, stopCycle));
  }, [stopCycle, idealCycle, lastEvent, clock, workshift]);
  return futureData;
}
