import React from 'react';
import { sizes, VectorProps } from './Icon';

export const LossColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      fill="none"
      viewBox="0 0 44 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.698 21.933V.308h-.062A21.516 21.516 0 0 0 6.41 6.634 21.828 21.828 0 0 0 .04 21.933h21.657Z"
        fill="#FFBF31"
      />
      <path
        d="M21.697 21.933H43.23a21.828 21.828 0 0 0-6.347-15.277A21.517 21.517 0 0 0 21.697.308v21.625Z"
        fill="#EAA00C"
      />
      <path
        d="M21.637 21.933v4.937h.062v16.813a21.517 21.517 0 0 0 15.23-6.393 21.83 21.83 0 0 0 6.302-15.357H21.637Z"
        fill="#0033AB"
      />
      <path
        d="M16.858 21.933H.04a21.83 21.83 0 0 0 6.325 15.38 21.517 21.517 0 0 0 15.27 6.37v-21.75h-4.778Z"
        fill="#006EC7"
      />
      <path
        d="M30.324 24.433H12.949c-.658 0-1.29-.264-1.755-.733a2.51 2.51 0 0 1 0-3.535 2.473 2.473 0 0 1 1.755-.732h17.375c.658 0 1.29.263 1.755.732a2.509 2.509 0 0 1 0 3.535 2.473 2.473 0 0 1-1.755.733Z"
        fill="#fff"
      />
    </svg>
  );
};
