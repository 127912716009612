import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Disconnected: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={sizes[size].width}
      height={sizes[size].height}
      fill='none'
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="drop" transform="translate(44.810770, 44.810773)">
          <path fill={color} d="M64.9096686,176.449439 L102.621227,214.162133 L127.68601,189.097844 L157.855894,219.267728 L132.791352,244.332258 L178.046186,289.587092 L203.110735,264.522569 L233.280618,294.692452 L208.215604,319.75651 L245.929005,357.468775 L215.759115,387.638664 L200.674175,372.553724 C164.184635,409.043264 107.835262,413.56889 66.4194827,386.130595 L30.1698893,422.378447 L7.10542736e-15,392.208558 L36.2478616,355.958974 C8.809566,314.543194 13.3351883,258.193818 49.8247286,221.704277 L34.7397793,206.619328 L64.9096686,176.449439 Z M44.940846,14.7709487 L407.607513,377.437611 L377.437614,407.607509 L14.770952,44.9408427 L44.940846,14.7709487 Z M79.9946273,251.874176 C55.0010726,276.867731 55.0010726,317.390275 79.9946273,342.383829 C104.095555,366.484757 142.63606,367.345513 167.768387,344.96608 L170.504295,342.383844 L79.9946273,251.874176 Z M392.208558,3.57402996e-12 L422.378447,30.1698893 L375.463932,77.0861461 C402.902226,118.501926 398.376601,174.851298 361.887061,211.340839 L376.972011,226.425789 L346.802123,256.595677 L165.782787,75.5763413 L195.952675,45.4064533 L211.037614,60.491392 C247.527154,24.0018517 303.87653,19.4762293 345.29231,46.914525 L392.208558,3.57402996e-12 Z M243.943406,88.07904 L241.207513,90.6612907 L330.584541,180.038489 C348.320303,153.120457 353.236844,112.180968 331.717166,90.6612907 C307.616238,66.5603629 269.075733,65.6996071 243.943406,88.07904 Z">
          </path>
        </g>
      </g>
    </svg>
  );
};
