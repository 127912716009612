import React from 'react';
import styled from 'styled-components';

const StyledDashboard = styled.div`
  display: grid;
  grid-area: 'Content';
  grid-template:
    'Title .' max-content
    'Card Card' 100px
    'Diagram Diagram' calc(100vh - 320px)
    'Timeline Timeline' 50px
    / 100%;

  margin: 20px 55px 0px 55px;

  @media (max-width: 560px) {
    grid-template:
      'Title .' max-content
      'Card Card' 100px
      'Diagram Diagram' calc(100vh - 380px)
      'Timeline Timeline' 50px
      / 100%;
    margin: 20px 25px 0px 25px;
  }
`;

type Props = {
  children: React.ReactNode;
};
export const Dashboard: React.FC<Props> = ({ children }) => <StyledDashboard>{children}</StyledDashboard>;
