import React from 'react';
import styled from 'styled-components';
import { Button } from '../../ui/Button';

const StyledConfirmation = styled.div<{ show: boolean }>`
  font-family: ${(props) => props.theme.font};
  position: fixed;
  display: flex;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  top: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  transition: visibility 0.25s, opacity 0.25s linear;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #001738;
  opacity: 0.8;
`;

const Box = styled.div`
  display: grid;
  grid-template:
    'Title Title' max-content
    'No Yes' max-content
    / max-content;

  gap: 35px 8px;
  width: 377px;
  padding-top: 45px;
  padding-bottom: 45px;
  z-index: 2;
  background-color: white;
  border-radius: 15px;

  justify-content: center;
  align-items: center;
  justify-items: center;

  @media (min-width: 400px) {
    width: 375px;
  }
`;

const Title = styled.span`
  grid-area: Title;
  font-size: 28px;
  color: #006ec7;
  font-weight: 600;
  width: 250px;
  text-align: center;
`;

export type Props = {
  title: string;
  visible?: boolean;
  onHandle: (result: boolean) => void;
};
export const Confirmation: React.FC<Props> = ({ title, visible = false, onHandle }) => {
  return (
    <StyledConfirmation show={visible}>
      <Overlay />
      <Box>
        <Title data-cy="confirmation-title">{title}</Title>
        <Button title="SIM" color="lighter" size="small" onClick={() => onHandle(true)} />
        <Button title="NÃO" size="small" onClick={() => onHandle(false)} />
      </Box>
    </StyledConfirmation>
  );
};
