import { AxisXType, AxisYType } from '../components/ui/Chart/Chart';

/**
 * Receives chart data for the X and Y axis and applies the LTTB algorithm.
 * https://github.com/joshcarr/largest-triangle-three-buckets.js
 * @param x An array with all the points in the X axis.
 * @param y An array with all the points in the Y axis.
 * @param width The width of the screen containing the chart, it will be used to set a threshold.
 * @returns An object containing the props x and y with the sampled points.
 */
export function largestTriangleThreeBuckets(x: AxisXType, y: AxisYType, width: number): { x: AxisXType; y: AxisYType } {
  const floor = Math.floor;
  const abs = Math.abs;
  const dataLength = x.length;

  const threshold = floor(width / 10);

  if (threshold >= dataLength || threshold === 0 || y.length !== dataLength) {
    return { x: x, y: y };
  }

  if (typeof x[0] === 'string' || typeof y[0] === 'string') {
    return { x: x, y: y };
  }

  const sampledX: AxisXType = [],
    sampledY: AxisYType = [];
  let sampledIndex = 0;

  // Bucket size. Leave room for start and end data points
  const every = (dataLength - 2) / (threshold - 2);

  let a = 0,
    maxAreaPointX,
    maxAreaPointY,
    maxArea,
    area,
    nextA = 0;

  const firstX = x[a]; // Adds the first point
  const firstY = y[a];
  if (firstX && firstY) {
    sampledX[sampledIndex] = firstX;
    sampledY[sampledIndex++] = firstY;
  }

  for (let i = 0; i < threshold - 2; i++) {
    // Calculate point average for next bucket
    let avgX = 0,
      avgY = 0,
      avgRangeStart = floor((i + 1) * every) + 1,
      avgRangeEnd = floor((i + 2) * every) + 1;
    avgRangeEnd = avgRangeEnd < dataLength ? avgRangeEnd : dataLength;

    const avgRangeLength = avgRangeEnd - avgRangeStart;

    for (; avgRangeStart < avgRangeEnd; avgRangeStart++) {
      const currX = toNumber(x[avgRangeStart]);
      const currY = toNumber(y[avgRangeStart]);
      avgX += currX;
      avgY += currY;
    }
    avgX /= avgRangeLength;
    avgY /= avgRangeLength;

    // Get the range for this bucket
    let rangeOffs = floor((i + 0) * every) + 1;
    const rangeTo = floor((i + 1) * every) + 1;

    const pointAX = toNumber(x[a]),
      pointAY = toNumber(y[a]);

    maxArea = area = -1;

    for (; rangeOffs < rangeTo; rangeOffs++) {
      const currX = toNumber(x[rangeOffs]);
      const currY = toNumber(y[rangeOffs]);

      // Calculate triangle area over three buckets
      area = abs((pointAX - avgX) * (currY - pointAY) - (pointAX - currX) * (avgY - pointAY)) * 0.5;
      if (area > maxArea) {
        maxArea = area;
        maxAreaPointX = x[rangeOffs];
        maxAreaPointY = y[rangeOffs];
        nextA = rangeOffs;
      }
    }

    if (maxAreaPointX && maxAreaPointY) {
      sampledX[sampledIndex] = maxAreaPointX; // Pick this point from the bucket
      sampledY[sampledIndex++] = maxAreaPointY;
    }
    a = nextA;
  }

  const lastX = x[dataLength - 1]; // Add last point
  const lastY = y[dataLength - 1];
  if (lastX && lastY) {
    sampledX[sampledIndex] = lastX;
    sampledY[sampledIndex++] = lastY;
  }

  return { x: sampledX, y: sampledY };
}

/**
 * Receives a value and converts it into a equivalent number.
 * @param value
 * @returns number
 */
function toNumber(value: Date | number | string | undefined) {
  if (typeof value === 'object') return (value as Date).getTime();
  else if (typeof value === 'number') return value;
  else return 0;
}
