import React from 'react';
import styled, { useTheme } from 'styled-components';

const StyledNav = styled.div`
  display: grid;
  grid-template:
    'Top' 90px
    'Content' max-content
    / 100%;
`;

type Props = {
  children: React.ReactNode;
};
export const Nav: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  return (
    <>
      <style>{`body { background-color: ${theme.colors.nav.background}; }`}</style>
      <StyledNav>{children}</StyledNav>
    </>
  );
};
