import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { Icon } from '../../ui/Icon';
import { useOutsideClick } from '../../../hooks/outsideClick';
import { useNotificationsQuery } from '../../../hooks/graphqlQueries';
import { compareDesc, differenceInSeconds, parseISO, formatDistanceToNow } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../Routes';
import { WhenNotify, groupNotification, NotificationItem, GroupedNotificationItem } from './NotificationUtil';
import { formatStopDurationNotification } from '../../../scripts/calendar';

const NOTIFICATION_LIMIT = 20;

const StyledNotification = styled.div`
  display: grid;
  position: relative;
  grid-area: Notification;
  cursor: pointer;
`;

const Indicator = styled.div<{ visible: boolean }>`
  position: absolute;
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  width: 10px;
  height: 10px;
  top: -2px;
  right: -2px;
  border-radius: 100px;
  background-color: ${(props) => props.theme.colors.notification.color};
`;

const NotficationDrawer = styled.div<{ hidden: boolean }>`
  display: ${(props) => (props.hidden ? 'none' : 'grid')};
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  position: absolute;
  grid-template-rows: repeat(3, max-content);
  top: 63.36px;
  width: 245px;
  min-height: 93.45px;
  max-height: 404.6px;
  right: 90px;
  z-index: 7;
  background-color: ${(props) => props.theme.colors.notificationDrawer.backgroundColor};
  justify-content: center;
  box-shadow: 0px 8px 25px ${(props) => props.theme.colors.notificationDrawer.shadowColor};
  border-radius: 5px;
  transition: linear 0.25s;
  grid-template: 'ItensContainer' 1fr 'Button' max-content;

  @media (max-width: 560px) {
    right: 40px;
  }

  @media (max-width: 300px) {
    right: 20px;
  }
`;

const ItensContainer = styled.div`
  display: grid;
  grid-area: ItensContainer;
  width: 245px;
  overflow: hidden;
  max-height: 300px;
  grid-auto-rows: max-content;
  padding-top: 10px;
  padding-bottom: 10px;

  overflow-x: hidden;
  overflow-y: scroll;

  justify-content: center;
  align-items: center;

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.notification.scroll};

  /* Edge, Chrome */
  &&::-webkit-scrollbar {
    width: 12px;
  }

  &&::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.notification.background};
    margin-top: 4px;
    margin-bottom: 4px;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.notification.scroll};
    border-radius: 20px;
    border: 3px solid ${(props) => props.theme.colors.notification.background};
  }
`;

const Item = styled.div`
  display: grid;
  cursor: pointer;
  row-gap: 10px;
  width: 175px;
  padding: 10px 20px 10px 20px;

  &&:hover {
    background-color: ${(props) => props.theme.colors.notificationDrawer.HoverbackgroundColor};
    border-radius: 5px;
  }
`;

const TextWrapStyle = styled.span`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
`;

const Title = styled(TextWrapStyle)<{ alert: boolean }>`
  font-size: 16px;
  line-height: 19px;
  color: ${(props) =>
    props.alert ? props.theme.colors.notification.color : props.theme.colors.notificationDrawer.message.titleColor};
`;

const Text = styled(TextWrapStyle)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.121634px;
  color: ${(props) => props.theme.colors.notificationDrawer.message.textColor};
`;

const EmptyMessage = styled(Text)`
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 20px 10px 20px;
`;

const BottomContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Time = styled(TextWrapStyle)<{ alert: boolean }>`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.121634px;
  color: ${(props) =>
    props.alert ? props.theme.colors.notification.color : props.theme.colors.notificationDrawer.message.timeColor};
`;

const Label = styled.div<{ alert: boolean }>`
  display: inline-block;
  font-family: ${(props) => props.theme.font};
  font-size: 10px;
  font-weight: bold;
  border-radius: 50px;
  padding: 4px;
  color: white;
  background-color: ${(props) =>
    props.alert ? props.theme.colors.notification.color : props.theme.colors.notificationDrawer.message.timeColor};
  overflow: hidden;
  max-width: 170px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const whenNotify = new WhenNotify();

whenNotify
  .addRule((self, newNotifications) => {
    // Checks if the last notification that arrived has already been seen
    const oldFirstNotificationId = self.getOldFirstNotification();
    return (
      !oldFirstNotificationId ||
      (newNotifications[0] !== undefined && newNotifications[0]?.id !== oldFirstNotificationId)
    );
  })
  .addRule((self, newNotifications) => {
    // Checks if each scope has already reached the time interval for a new notification
    const machineNotifications = self.getNotificationByScope();
    return newNotifications.some((value) => {
      if (machineNotifications[value.scope]) {
        const notificationInterval = 180; // in seconds
        const elapsedTime = differenceInSeconds(
          parseISO(value.time),
          parseISO((machineNotifications[value.scope] as NotificationItem).time)
        );
        const isNotify = elapsedTime > notificationInterval;
        if (isNotify) machineNotifications[value.scope] = value;
        return isNotify;
      } else {
        machineNotifications[value.scope] = value;
        return true;
      }
    });
  });

export const Notification: React.FC = () => {
  const theme = useTheme();
  const wrapperBellRef = useRef<HTMLDivElement>(null);
  const wrapperDrawerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const [cursor, setCursor] = useState<string | undefined>();
  const [hidden, setHidden] = useState(true);
  const [hasNewNotification, setHasNewNotification] = useState(false);
  const [visibleNotifications, setVisibleNotifications] = useState<GroupedNotificationItem[]>([]);
  const [notificationsQuery, refetchNotifications] = useNotificationsQuery({
    requestPolicy: 'network-only',
    variables: { limit: NOTIFICATION_LIMIT, cursor: cursor },
  });

  const notifications = useMemo(() => notificationsQuery.data?.notifications?.value ?? [], [notificationsQuery.data]);
  const hasMoreNotifications = useMemo(
    () => notificationsQuery.data?.notifications?.hasNext,
    [notificationsQuery.data]
  );

  const settings = useMemo(
    () => ({
      JUSTIFICATION: { alert: false, target: routes.history.index + '/' + routes.history.stop },
      LINE_STOP: { alert: true, target: routes.stop.index + '/' + routes.stop.justifications },
      MACHINE_ALARM: { alert: true, target: null },
      MONITORING_STATUS: { alert: false, target: routes.registration.index },
      PARAM_LIMIT: { alert: false, target: routes.history.index + '/' + routes.history.parameters },
    }),
    [routes]
  );

  useEffect(() => {
    if (hidden) setCursor(undefined);
  }, [hidden]);

  useEffect(() => {
    const refresh = () => {
      if (hidden) refetchNotifications();
    };

    const interval = setInterval(refresh, 15_000);
    return () => clearInterval(interval);
  }, [notificationsQuery.data, hidden]);

  useOutsideClick([wrapperDrawerRef, wrapperBellRef], () => setHidden(true));
  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      e.stopPropagation();
      const container = e.currentTarget;
      const scrollY = container.scrollHeight - container.scrollTop;
      const scrollOffset = Math.abs(container.offsetHeight - scrollY);

      if (scrollOffset <= 1 && hasMoreNotifications && notifications.length > 0) {
        const lastNotification = notifications[notifications.length - 1];
        setCursor(lastNotification?.id);
      }
    },
    [notificationsQuery]
  );

  useEffect(() => {
    if (hidden) {
      setVisibleNotifications(notifications);
      if (whenNotify.evaluate(notifications)) setHasNewNotification(true);
    } else setVisibleNotifications((current) => [...current.slice(0, current.length - 1), ...notifications]);
  }, [notifications]);

  return (
    <>
      <StyledNotification ref={wrapperBellRef}>
        <Indicator visible={hasNewNotification} />
        <Icon
          name="Bell"
          size="small"
          color={theme.colors.navbar.color}
          onClick={() => {
            setHidden((prevHidden) => !prevHidden);
            setHasNewNotification(false);
            if (visibleNotifications[0]) whenNotify.setOldFirstNotification(visibleNotifications[0]);
          }}
          dataCy="notification"
        />
      </StyledNotification>
      <NotficationDrawer ref={wrapperDrawerRef} hidden={hidden}>
        <ItensContainer onScroll={handleScroll} data-cy="notification-items">
          {visibleNotifications.length > 0 ? (
            groupNotification(visibleNotifications)
              .filter((notif, index, self) => index === self.findIndex((value) => value.id === notif.id)) // Remove any possibility of duplicate notification
              .sort((a, b) => compareDesc(parseISO(a.time), parseISO(b.time)))
              .map((value) => {
                const { target, alert } = settings[value.type];
                const handleClick = () => {
                  navigate(target as string, { state: { id: value.originId } });
                };
                return (
                  <Item key={value.id} onClick={handleClick}>
                    <Title alert={alert}>{`${value.title} ${
                      (value.count ?? 0) > 1 ? '(' + value.count + ')' : ''
                    }`}</Title>
                    <Text>
                      {(value.count ?? 0) > 1
                        ? `${value.title} em ${Array.from(new Set(value.message.split(',')))}`
                        : `${value.message} (duração: ${formatStopDurationNotification(value.metadata.duration ?? 0)})`}
                    </Text>
                    <Time alert={alert}>
                      {formatDistanceToNow(parseISO(value.time), { addSuffix: true, locale: ptBR })}
                    </Time>
                    <BottomContent>
                      {!((value.count ?? 0) > 1) && <Label alert={alert}>{value.scope}</Label>}
                    </BottomContent>
                  </Item>
                );
              })
          ) : (
            <EmptyMessage>Sem notificações</EmptyMessage>
          )}
        </ItensContainer>
      </NotficationDrawer>
    </>
  );
};
