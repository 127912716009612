import * as React from 'react';

export const useIsInputOverflow = (
  ref: React.RefObject<HTMLInputElement>,
  callback?: (hasOverflow: boolean) => void
) => {
  const [isOverflow, setIsOverflow] = React.useState(false);

  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      let hasOverflow;
      const error = 5;
      const scrollWidth = current?.scrollWidth;
      const clientWidth = current?.clientWidth;
      if (scrollWidth && clientWidth) hasOverflow = scrollWidth > clientWidth + error;
      else hasOverflow = false;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};
