import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Stop: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1113 0C4.58883 0 0.111328 4.4775 0.111328 10C0.111328 15.5225 4.58883 20 10.1113 20C15.6338 20 20.1113 15.5225 20.1113 10C20.1113 4.4775 15.6338 0 10.1113 0ZM11.2897 10C11.2897 10 13.9055 12.6158 14.0338 12.7442C14.3597 13.07 14.3597 13.5975 14.0338 13.9225C13.708 14.2483 13.1805 14.2483 12.8555 13.9225C12.7272 13.795 10.1113 11.1783 10.1113 11.1783C10.1113 11.1783 7.49549 13.7942 7.36716 13.9225C7.04133 14.2483 6.51383 14.2483 6.18883 13.9225C5.86299 13.5967 5.86299 13.0692 6.18883 12.7442C6.31633 12.6158 8.93299 10 8.93299 10C8.93299 10 6.31716 7.38417 6.18883 7.25583C5.86299 6.93 5.86299 6.4025 6.18883 6.0775C6.51466 5.75167 7.04216 5.75167 7.36716 6.0775C7.49549 6.205 10.1113 8.82167 10.1113 8.82167C10.1113 8.82167 12.7272 6.20583 12.8555 6.0775C13.1813 5.75167 13.7088 5.75167 14.0338 6.0775C14.3597 6.40333 14.3597 6.93083 14.0338 7.25583C13.9063 7.38417 11.2897 10 11.2897 10Z"
        fill={color}
      />
    </svg>
  );
};
