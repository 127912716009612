import React, { useEffect, useMemo, useState } from 'react';
import { Title } from '../../components/ui/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import styled from 'styled-components';
import { RegisterList } from '../../components/layout/RegisterList';
import { RegisterTable } from '../../components/ui/RegisterTable';
import {
  useDeleteLineMutation,
  useDisableLineMutation,
  useFactoriesNameQuery,
  useLineListQuery,
  UserProfile,
} from '../../hooks/graphqlQueries';
import { useFeedback } from '../../context/feedback';
import { usePermission } from '../../context/permission';
import { attributeType } from '../../components/ui/RegisterTable/RegisterTable';
import { PageOptions } from '../../components/layout/PageOptions';
import { Footer } from '../../components/layout/Footer';
import { Drop } from '../../components/ui/Drop';
import { downloadFile, makeCSVFromTable } from '../../scripts/exportFiles';
import { format, parseISO } from 'date-fns';
import { asString } from 'ts-runtime-typecheck';
import { getPagination } from '../../scripts/pagination';

const Filters = styled.div`
  display: grid;
  grid-area: Drop;
  grid-template-columns: repeat(auto-fit, minmax(179px, max-content));
  column-gap: 20px;
  row-gap: 15px;
  justify-content: right;
`;

const Table = styled.div`
  display: grid;
  grid-area: Table;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 1168px) {
    &&:after {
      font-size: 12px;
      padding-top: 5px;
      content: 'Deslize a tabela para os lados';
    }
  }
`;

type PageParameters = { page: number; limit: number };
const initialParameters = { page: 1, limit: 10 };

type Filter = {
  factoryId?: string;
  factoryName?: string;
};

export const LineListPage: React.FC = () => {
  const navigate = useNavigate();
  const profile = usePermission();
  const feedback = useFeedback();
  const location = useLocation();
  const routeData = location.state as { pagination: { page: number; limit: number } };

  const [parameters, setParameters] = useState<PageParameters>(routeData?.pagination ?? initialParameters);

  const [factoryOptions] = useFactoriesNameQuery();

  const rescuedFilters = sessionStorage.getItem('GlobalRegistrationFilter');
  const storagedFilters: Filter = rescuedFilters ? JSON.parse(rescuedFilters) : initialParameters;
  const [filters, setFilters] = useState<Filter>(storagedFilters);
  const [isExportData, setIsExportData] = useState(false);
  const dataExportLimit = parseInt(asString(process.env['DATA_EXPORT_LIMIT']));
  const [linesQuery, refetch] = useLineListQuery({
    requestPolicy: 'network-only',
    variables: {
      ...getPagination(parameters.page, parameters.limit, { dataExportLimit, isExportData }),
      factoryId: filters.factoryId ?? '',
    },
  });

  const [, disableLine] = useDisableLineMutation();

  const [, deleteLine] = useDeleteLineMutation();
  const lines = useMemo(() => linesQuery.data?.lines?.value ?? [], [linesQuery.data]);

  const attributes: attributeType[] = [
    { name: 'Nome', attr: 'name' },
    { name: 'Código da Linha', attr: 'code' },
    { name: 'Descrição', attr: 'description' },
    { name: 'Planta', attr: 'factory' },
    ...(profile.permission && ![UserProfile.Production, UserProfile.Quality].includes(profile.permission as UserProfile)
      ? [{ name: 'Monitorar', attr: 'active' }]
      : []),
  ];

  useEffect(() => {
    refetch();
  }, [parameters]);

  const data = useMemo(
    () =>
      lines.map((line) => {
        return {
          table: {
            name: line.name,
            code: line.code,
            description: line.description ?? '',
            factory: line.factory?.name ?? '',
            active: String(!line.disabled),
          },
          value: {
            ...line,
            stopsThreshold: Math.round((line.stopsThreshold / 60) * 100) / 100,
          },
        };
      }),
    [lines]
  );

  const [tableData, setTableData] = useState<typeof data>(data);
  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');

  useEffect(() => {
    if (isExportData) {
      downloadFile(
        `linhas-${thisDate}`,
        makeCSVFromTable(
          attributes,
          data.map((v) => v.table)
        )
      );
      setIsExportData(false);

      if (lines.length === dataExportLimit)
        feedback.message({
          animation: 'Error',
          title: 'Limite de dados atingido!',
          subtitle: `Não é possível exportar mais que ${dataExportLimit} linhas de dados.`,
        });
    } else setTableData(data);
  }, [data]);

  return (
    <RegisterList>
      <Title>Linhas cadastradas</Title>
      <Filters>
        <Drop
          name="Fábrica"
          options={
            factoryOptions.data?.factories?.value?.map((factory) => ({
              id: factory.id,
              name: factory.name,
            })) ?? []
          }
          onSelect={(factory) => {
            setParameters({ ...parameters, page: 1 });
            setFilters({
              ...filters,
              factoryName: factory.name,
              factoryId: factory.id,
            });
          }}
          value={filters.factoryName}
          errorMessage={
            factoryOptions.data?.factories?.value?.length === 0 ? 'Não existe nenhuma fábrica cadastrada.' : undefined
          }
        />
      </Filters>
      <Table>
        <RegisterTable
          attributes={attributes}
          content={tableData}
          thisError={(line) => {
            return line['machines']?.length === 0;
          }}
          onMonitor={
            ![UserProfile.Production, UserProfile.Quality].includes(profile.permission as UserProfile)
              ? (line) => {
                  disableLine({
                    id: line['id']?.toString() ?? '',
                    disabled: !(line['disabled'] as boolean) ?? false,
                  })
                    .then((result) => {
                      if (result.data?.disableLine === null) {
                        feedback.message({
                          animation: 'Error',
                          title: 'Erro ao ativar monitoramento da linha',
                        });
                      } else {
                        refetch();
                      }
                    })
                    .catch(() => {
                      feedback.message({
                        animation: 'Error',
                        title: line['disabled']
                          ? 'Erro ao ativar monitoramento da linha'
                          : 'Erro ao desativar monitoramento da linha',
                      });
                    });
                }
              : undefined
          }
          onDelete={
            ![UserProfile.Production, UserProfile.Quality].includes(profile.permission as UserProfile)
              ? (line) => {
                  feedback.confirmation({
                    title:
                      'Deseja mesmo excluir essa linha? Essa operação é irreversível e irá exluir também todos os dados associados a linha.',
                    onHandle: (result) => {
                      if (result) {
                        deleteLine({ id: line['id']?.toString() ?? '' })
                          .then(() => {
                            feedback.message({
                              animation: 'Confetti',
                              title: 'Linha deletada com sucesso',
                            });

                            parameters.page === 1 ? refetch() : setParameters({ ...parameters, page: 1 });
                          })
                          .catch(() => {
                            feedback.message({
                              animation: 'Error',
                              title: 'Erro ao deletar linha',
                            });
                          });
                      }
                    },
                  });
                }
              : undefined
          }
          onEdit={
            ![UserProfile.Production].includes(profile.permission as UserProfile)
              ? (line) => {
                  navigate('edit', { state: { data: line, pagination: { ...parameters } } });
                }
              : undefined
          }
        />
      </Table>
      <Footer>
        <PageOptions>
          <Button color="light" title="Menu cadastros" icon="ArrowLeft" onClick={() => navigate('..')} />
          {![UserProfile.Production, UserProfile.Quality].includes(profile.permission as UserProfile) && (
            <Button
              color="primary"
              title="Cadastrar"
              icon="CirclePlus"
              iconSize="small"
              onClick={() => navigate('add', { state: { pagination: { ...parameters } } })}
            />
          )}
          <Button title="Exportar" icon="Export" disabled={data.length == 0} onClick={() => setIsExportData(true)} />
        </PageOptions>
        <Pagination
          numberOfItems={parameters.limit}
          onSelectNumber={(number) => setParameters({ limit: number, page: 1 })}
          page={parameters.page}
          totalPages={Math.ceil((linesQuery.data?.lines?.count ?? parameters.limit) / parameters.limit)}
          onPrevious={() => {
            setParameters({ ...parameters, page: parameters.page - 1 });
          }}
          onNext={() => {
            setParameters({ ...parameters, page: parameters.page + 1 });
          }}
        />
      </Footer>
    </RegisterList>
  );
};
