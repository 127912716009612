import React, { useEffect, useState } from 'react';
import { Title } from '../components/ui/Title';
import { Calendar } from '../components/ui/Calendar';
import styled from 'styled-components';
import { Button } from '../components/ui/Button';
import { Input } from '../components/ui/Input';
import { Scrap } from '../components/layout/Scrap/Scrap';
import { InputMultiline } from '../components/ui/InputMultiline/InputMultiline';
import { Drop } from '../components/ui/Drop';
import {
  useRegisterScrapMutation,
  useScrapDefectNameQuery,
  WorkShift,
  useMachinesFromLineQuery,
  useWorkshiftAtQuery,
  Workshift,
  useScrapItemTypeQuery,
  useScrapUserNameQuery,
  useLinesFromFactoriesQuery,
  useAllGroupsQuery,
  useGetInfoOfDetectorGroupQuery,
} from '../hooks/graphqlQueries';
import { useFeedback } from '../context/feedback';
import { useNavigate } from 'react-router-dom';
import { format, parseISO, startOfDay } from 'date-fns';
import { formatStringNumber } from '../scripts/formatStringNumber';
import { Footer } from '../components/layout/Footer';
import { PageOptions } from '../components/layout/PageOptions';

const shiftTimes = [
  {
    id: WorkShift.ShiftA,
    name: 'Turno A',
  },
  {
    id: WorkShift.ShiftB,
    name: 'Turno B',
  },
  {
    id: WorkShift.ShiftC,
    name: 'Turno C',
  },
];

const Filters = styled.div`
  display: grid;
  grid-area: Filters;
  grid-template-columns: repeat(auto-fit, minmax(179px, max-content));
  column-gap: 20px;
  row-gap: 15px;
  justify-content: right;
  @media (max-width: 600px) {
    justify-content: center;
  }
`;

const Name = styled.div`
  display: flex;
  grid-area: Name;
  align-items: center;
  justify-content: left;
  @media (max-width: 660px) {
    justify-content: center;
    align-items: center;
  }
`;

type FiltersType = {
  line?: string;
  machine?: string;
  item: string;
  defect: string;
  shift: string;
  date: string;
  quantity: string;
  detectorGroup: string;
  generatorGroup: string;
};

type Parameters = {
  filters: FiltersType;
  description: string;
  name: string;
};

const initialParameters: Parameters = {
  filters: {
    item: '',
    defect: '',
    shift: '',
    date: format(new Date(), 'yyyy-MM-dd'),
    quantity: '',
    detectorGroup: '',
    generatorGroup: '',
  },
  description: '',
  name: localStorage.getItem('xxxx_user') ?? '',
};

const resetParameters = {
  filters: {
    item: '',
    defect: '',
    shift: '',
    quantity: '',
  },
  description: '',
  name: '',
};

export const ScrapPage: React.FC = () => {
  const feedback = useFeedback();
  const navigate = useNavigate();

  const [parameters, setParameters] = useState(initialParameters);
  const [disableRegister, setDisableRegister] = useState(true);
  const [groupName] = useAllGroupsQuery();
  const [lineName] = useLinesFromFactoriesQuery();

  const getInfoOfDetectorGroup = useGetInfoOfDetectorGroupQuery({
    variables: {
      id: parameters.filters.detectorGroup ?? '',
    },
  });

  const shiftFromDetectorGroup = getInfoOfDetectorGroup[0].data?.group?.workShift;

  const [machineName] = useMachinesFromLineQuery({
    pause: parameters.filters.line === undefined,
    requestPolicy: 'network-only',
    variables: {
      lineId:
        getInfoOfDetectorGroup?.[0].data?.group?.lineId && !parameters.filters.line
          ? getInfoOfDetectorGroup?.[0].data?.group?.lineId
          : parameters.filters.line ?? '',
    },
  });

  const [userName] = useScrapUserNameQuery({
    pause: parameters.filters.line === undefined,
    requestPolicy: 'network-only',
    variables: {
      idLine: getInfoOfDetectorGroup?.[0].data?.group?.lineId
        ? getInfoOfDetectorGroup?.[0].data?.group?.lineId
        : parameters.filters.line ?? '',
    },
  });
  const [itemName] = useScrapItemTypeQuery({ requestPolicy: 'network-only' });
  const [defectName] = useScrapDefectNameQuery({ requestPolicy: 'network-only' });
  const [, executeMutationRegisterScrap] = useRegisterScrapMutation();

  const [workshiftQuery, refetchWorkshift] = useWorkshiftAtQuery();

  const handleSave = () => {
    if (errorShiftSelection()) {
      feedback.message({
        animation: 'Error',
        title: 'Erro! Você selecionou uma data ou turno futuro.',
      });
    } else {
      executeMutationRegisterScrap({
        amount: parseFloat(parameters.filters.quantity),
        details: {
          line: parameters.filters.line,
          item: parameters.filters.item,
          scrapPossibleFailure: parameters.filters.defect,
          user: parameters.name,
          description: parameters.description,
          detectorGroup: getInfoOfDetectorGroup?.[0].data?.group?.name,
          generatorGroup: parameters.filters.generatorGroup,
        },
        machineId: parameters.filters.machine ?? '',
        workShift: parameters.filters.shift as WorkShift,
        date: parseISO(parameters.filters.date),
      })
        .then((result) => {
          if (result.error) feedback.apiError(result.error);
          else if (result.data?.createScrap === null) {
            feedback.message({
              animation: 'Error',
              title: 'Erro ao apontar scrap',
            });
          } else {
            const dialog = () => {
              feedback.confirmation({
                title: 'Deseja apontar outro scrap?',
                onHandle: (result) => {
                  if (result) {
                    setParameters({
                      ...resetParameters,
                      filters: { ...parameters.filters, ...resetParameters.filters },
                    });
                  } else {
                    navigate('..');
                  }
                },
              });
            };
            feedback.message({
              animation: 'Confetti',
              title: 'Scrap cadastrado com sucesso',
              onClose: () => dialog(),
            });
          }
        })
        .catch(() => {
          feedback.message({
            animation: 'Error',
            title: 'Erro ao cadastrar scrap',
          });
        });
    }
  };

  function errorShiftSelection() {
    if (!checkFutureShift(parameters.filters.shift, workshiftQuery.data?.workshiftAt)) return false;
    else return true;
  }

  function checkFutureShift(shiftSelected: string, workshift: Workshift | undefined) {
    let isFutureShift = true;
    const selectedFilterDate = parseISO(parameters.filters.date);
    const workShiftDate = startOfDay(parseISO(workshift?.endingAt));

    if (selectedFilterDate < workShiftDate) return false;
    else if (selectedFilterDate > workShiftDate) return true;

    switch (workshift?.name) {
      case WorkShift.ShiftA:
        if (shiftSelected === WorkShift.ShiftA) {
          isFutureShift = false;
        }
        break;
      case WorkShift.ShiftB:
        if (shiftSelected === WorkShift.ShiftB || shiftSelected === WorkShift.ShiftA) {
          isFutureShift = false;
        }
        break;
      case WorkShift.ShiftC:
        isFutureShift = false;
        break;
    }
    return isFutureShift;
  }

  useEffect(() => {
    const timer = setTimeout(() => refetchWorkshift(), 5000);
    return () => clearTimeout(timer);
  }, [workshiftQuery.data]);

  useEffect(() => {
    function checkFilters() {
      for (const key in parameters.filters) {
        if (getInfoOfDetectorGroup?.[0].data?.group?.lineId && parameters.filters.line === '') {
          setParameters({
            ...parameters,
            filters: {
              ...parameters.filters,
              line: getInfoOfDetectorGroup?.[0].data?.group?.lineId,
            },
          });
        }
        if (getInfoOfDetectorGroup?.[0].data?.group?.workShift && parameters.filters.shift === '') {
          setParameters({
            ...parameters,
            filters: {
              ...parameters.filters,
              shift: getInfoOfDetectorGroup?.[0].data?.group?.workShift,
            },
          });
        }
        if (parameters.filters[key as keyof FiltersType] === '') return false;
      }
      return true;
    }

    if (parameters.name.length > 0 && checkFilters()) {
      setDisableRegister(false);
      return;
    }

    setDisableRegister(true);
  }, [parameters]);

  return (
    <Scrap>
      <Title>Apontamento de Scrap</Title>
      <Filters>
        <Drop
          dataCy="scrap-group-drop"
          name="Grupo Detector"
          options={groupName.data?.groups?.value?.map((group) => ({ id: group.id, name: group.name })) ?? []}
          onSelect={(group) => {
            setParameters({
              ...parameters,
              filters: {
                ...parameters.filters,
                detectorGroup: group.id,
                line: group.id === '' ? '' : getInfoOfDetectorGroup?.[0].data?.group?.lineId ?? '',
              },
            });
            errorShiftSelection();
          }}
          value={parameters.filters.detectorGroup}
          errorMessage={groupName.data?.groups?.value?.length === 0 ? 'Não existe nenhum grupo cadastrado.' : undefined}
        />
        <Drop
          dataCy="scrap-line-drop"
          name="Linha"
          options={lineName.data?.lines?.value?.map((line) => ({ id: line.id, name: line.code })) ?? []}
          onSelect={(line) => {
            setParameters({
              ...parameters,
              filters: { ...parameters.filters, line: line.id, machine: undefined },
            });
          }}
          value={
            getInfoOfDetectorGroup?.[0].data?.group?.lineId && !parameters.filters.line
              ? getInfoOfDetectorGroup?.[0].data?.group?.lineId
              : parameters.filters.line
          }
          disabled={!parameters.filters.detectorGroup}
          errorMessage={lineName.data?.lines?.value?.length === 0 ? 'Não existe nenhuma linha cadastrada.' : undefined}
        />
        <Drop
          dataCy="scrap-machine-drop"
          name="Máquina"
          options={
            machineName.data?.machines?.value?.filter(m=> (m.possibleDefects ? m.possibleDefects.length > 0 : false)).map((machine) => ({
              id: machine.id,
              name: machine.code,
            })) ?? []
          }
          onSelect={(machine) => {
            setParameters({ ...parameters, filters: { ...parameters.filters, machine: machine.id } });
          }}
          value={parameters.filters.machine}
          disabled={
            getInfoOfDetectorGroup?.[0].data?.group?.lineId
              ? !getInfoOfDetectorGroup?.[0].data?.group?.lineId
              : !parameters.filters.line
          }
          errorMessage={
            machineName.data?.machines?.value?.length === 0 ? 'Não existe nenhuma máquina cadastrada.' : undefined
          }
        />
        <Drop
          dataCy="scrap-item-drop"
          name="Tipo de Item"
          options={
            itemName.data?.itemTypes?.value?.map((item) => ({
              id: item.id,
              name: item.name,
            })) ?? []
          }
          onSelect={(selected) => {
            setParameters({ ...parameters, filters: { ...parameters.filters, item: selected.name } });
          }}
          value={parameters.filters.item}
          disabled={!parameters.filters.machine}
          errorMessage={
            itemName.data?.itemTypes?.value?.length === 0 ? 'Não existe nenhum tipo de item cadastrado.' : undefined
          }
        />
        <Drop
          dataCy="scrap-defectName-drop"
          name="Defeito"
          options={
            machineName.data?.machines?.value
              ?.find((machine) => machine.id === parameters.filters.machine)
              ?.possibleDefects?.map((defect) => ({ id: defect, name: defect })) ?? []
          }
          onSelect={(selected) => {
            setParameters({ ...parameters, filters: { ...parameters.filters, defect: selected.name } });
          }}
          value={parameters.filters.defect}
          disabled={!parameters.filters.machine}
          errorMessage={
            defectName.data?.scrapPossibleFailures?.value?.length === 0
              ? 'Não existe nenhum defeito cadastrado.'
              : undefined
          }
        />
        <Drop
          dataCy="scrap-shift-drop"
          name="Turno"
          options={shiftTimes}
          onSelect={(selected) => {
            errorShiftSelection();
            setParameters({ ...parameters, filters: { ...parameters.filters, shift: selected.id } });
          }}
          value={parameters.filters.shift ? parameters.filters.shift : shiftFromDetectorGroup}
          disabled={!parameters.filters.machine}
          errorMessage={shiftTimes.length === 0 ? 'Não existe nenhum turno cadastrado.' : undefined}
        />
        <Calendar
          placeholder="Data"
          onChange={(date) => {
            const parsedDate = parseISO(date.target.value);
            if (date.target.value.length == 10){
              setParameters({ ...parameters, filters: { ...parameters.filters, date: format(parsedDate, 'yyyy-MM-dd') } });
            }
          }}
          onBlur={(date) => {
            const parsedDate = parseISO(date.target.value);
            if (date.target.value.length == 10){
              setParameters({ ...parameters, filters: { ...parameters.filters, date: format(parsedDate, 'yyyy-MM-dd') } });
            }
          }}
          value={parameters.filters.date}
        />
        <Input
          type="text"
          width="179px"
          placeholder="Quantidade"
          value={parameters.filters.quantity}
          onChange={(e) => {
            const valueAsNumber = formatStringNumber(e.target.value);
            setParameters({ ...parameters, filters: { ...parameters.filters, quantity: valueAsNumber } });
          }}
        />
        <Drop
          dataCy="scrap-section-generator-drop"
          name="Grupo Gerador"
          options={groupName.data?.groups?.value?.map((group) => ({ id: group.id, name: group.name })) ?? []}
          onSelect={(group) => {
            errorShiftSelection();
            setParameters({ ...parameters, filters: { ...parameters.filters, generatorGroup: group.name } });
          }}
          value={parameters.filters.generatorGroup}
          disabled={!parameters.filters.machine}
          errorMessage={groupName.data?.groups?.value?.length === 0 ? 'Não existe nenhum grupo cadastrado.' : undefined}
        />
      </Filters>
      <InputMultiline
        dataCy="scrap-input"
        placeholder="Descrição..."
        area="Desc"
        width="970px"
        height="150px"
        maxLength={150}
        onChange={(e) => setParameters({ ...parameters, description: e.target.value })}
        value={parameters.description}
      />
      <Name>
        <Drop
          dataCy="scrap-user-drop"
          name="Usuário"
          size="large"
          color="filled"
          direction="up"
          onSelect={(selected) => {
            setParameters({ ...parameters, name: selected.name });
          }}
          options={
            userName.data?.line?.hasAccess?.map((user) => ({
              id: user?.id ?? '',
              name: user?.firstName.concat(' ').concat(user?.lastName) ?? '',
            })) ?? []
          }
          disabled={!parameters.filters.machine}
          value={parameters.name}
          errorMessage={
            userName.data?.line?.hasAccess?.length === 0 ? 'Não existe nenhum usúario cadastrado.' : undefined
          }
        />
      </Name>
      <Footer>
        <PageOptions>
          <Button
            dataCy="scrap-button"
            disabled={disableRegister}
            color={'primary'}
            title="Registrar"
            icon="ArrowLeft"
            onClick={() => handleSave()}
          />
        </PageOptions>
      </Footer>
    </Scrap>
  );
};
