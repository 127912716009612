interface ObjectType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

/**
 * Remove properties from an object.
 * @param {ObjectType} obj - an object.
 * @param {string[]} props - array of properties name to remove.
 * @return returns object with removed properties.
 */
export function removeObjectProperties(obj: ObjectType, props: string[]): ObjectType {
  let resultObject = obj;
  props.map((prop) => {
    const key: string[] = prop.split('.');
    if (key.length > 1) {
      const neww = [[...key.splice(1, key.length - 1)].join('.')];
      const result = removeObjectProperties(resultObject[key[0] as string], neww);

      if (Object.keys(result).length > 0) {
        resultObject[key[0] as string] = result;
      } else {
        const { [key[0] as string]: discardValue, ...newObj } = resultObject;
        discardValue;
        resultObject = newObj;
      }
    } else {
      const { [key[0] as string]: discardValue, ...newObj } = resultObject;
      discardValue;
      resultObject = newObj;
    }
  });
  return resultObject;
}

/**
 * Checks if any object properties are empty.
 * @param {ObjectType} obj - an object.
 * @return return true if anything object properties are empty and false otherwise.
 */
export function haveSomeEmptyProperty(obj: ObjectType): boolean {
  let result = false;
  for (const key in obj) {
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      result = haveSomeEmptyProperty(obj[key]);
      if(result){//[BUGFIX] because if the nested object had any empty properties, the function would not return anything
        return true;
      }
    }

    if (typeof obj[key] !== 'boolean' && (obj[key] === '' 
        || obj[key] === null || obj[key] === undefined 
        || (Array.isArray(obj[key]) && !obj[key].length))) {
      return true;
    }
  }
  return result;
}

type TypeOptions = {
  ignoreFields?: string[];
};

/**
 * Checks that required form fields are not empty.
 * @param {ObjectType} formObject - an object containing form data.
 * @param {{ (result: boolean): void }} callback - callback function to be called when the validation is
 * finished, return true if the validation finds nothing empty field.
 * @param {TypeOptions} options - an object containing options for fields ignored in validation.
 */
export function isEnabledAction(formObject: ObjectType, callback: { (result: boolean): void }, options?: TypeOptions) {
  let resultObj = formObject;
  if (options?.ignoreFields) resultObj = removeObjectProperties(formObject, options.ignoreFields);
  callback(!haveSomeEmptyProperty(resultObj));
}

/**
 * Count attributes with valid values
 * @param {ObjectType} obj - an object
 * @param attributeNames - an object containing fields names for used in validation
 * @returns return number of valid fields
 */
export function countValidAttributes(obj:ObjectType, attributeNames:string[]) {
  let count = 0;
  attributeNames.forEach( key => {
    if(Object.prototype.hasOwnProperty.call(obj, key) && obj[key] !== undefined && obj[key] !== null && !haveSomeEmptyProperty(obj[key])){
      count++;
    }   
  })
  return count;
}
/*
export function countValidAttributes(obj:ObjectType, attributeNames:string[]) {
  let count = 0;
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && attributeNames.includes(key) && obj[key] !== undefined && obj[key] !== null) {
      if(! (Array.isArray(obj[key]) && obj[key].length == 0)){
        count++;
      }
    }
  }
  return count;
}*/