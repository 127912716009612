{
    "password_recovery": "I forgot my password",
    "SHIFT_A": "Shift A",
    "SHIFT_B": "Shift B",
    "SHIFT_C": "Shift C",
    "NO_SHIFT": "Sem turno",
    "ANGLE": "Angle",
    "ANGULAR_SPEED": "Angular speed",
    "AREA": "Area",
    "CURRENT": "Current",
    "DIMENTIONLESS": "Dimentionless",
    "ENERGY": "Energy",
    "LENGTH": "Length",
    "LINEAR_SPEED": "Linear speed",
    "MASS": "Mass",
    "NO_UNIT": "No unit",
    "UNIT": "Unit",
    "POWER": "Power",
    "PRESSURE": "Pressure",
    "TEMPERATURE": "Temperature",
    "TIME": "Time",
    "VOLTAGE": "Voltage",
    "VOLUME": "Volume",
    "ADMIN": "Admin",
    "PROCESS": "Process",
    "PRODUCTION": "Production",
    "SUPERVISOR": "Supervisor",
    "QUALITY": "Quality",
    "NO_PROFILE": "",
    "name": "name",
    "tagType": "tag type",
    "MEASUREMENT": "Measurement",
    "SETPOINT": "Setpoint",
    "NO_TYPE": "No type",
    "ERROR": {
        "IDEAL_BIGGER_THEN_NORMAL": "The normal cycle time must be longer than the ideal cycle.",
        "NORMAL_BIGGER_THEN_SMALL_STOP": "Micro-stop time must be longer than the normal cycle.",
        "SMALL_STOP_BIGGER_THEN_STOP": "Stop time must be longer than the micro stop time.",
        "INFERIOR_GREATER_THEN_SUPERIOR": "The superior limit must be greater then the inferior limit",
        "PERIOD_BIGGER_THAN": "For the selected filters choose a period smaller than | days."
    },
    "NO_SECTOR": "No sector",
    "ASSEMBLY": "Assembly",
    "FINISHING": "Finishing",
    "FORMATION": "Formation",
    "FORCE": "Force",
    "FREQUENCY": "Frequency",
    "ELECTRICAL_RESISTANCE": "Electrical Resistance"
}