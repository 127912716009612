import { useEffect, useState } from 'react';
import { joinTimelineEvents, statesToEvents, Event } from '../scripts/timeline';
import { useTimelineEventSubscription } from './graphqlQueries';
import { useTimelineEventSubscription as  useLiteTimelineEventSubscription} from '../hooks/graphqlQueriesLite';

/**
 * Hook to get the timeline.
 */
export function useTimeline(line: string | null | undefined): [events: Event[], reset: () => void] {
  const [timeline, setTimeline] = useState<Event[]>([]);
  const [{ data, error }] = useTimelineEventSubscription({
    variables: { lineId: line ?? '' },
    pause: line === undefined,
  });

  useEffect(() => {
    if (!data) return;
    const newData = statesToEvents(data.machineStateCreated);
    setTimeline(joinTimelineEvents(timeline, newData));
  }, [data, error]);

  const reset = () => setTimeline([]);
  return [timeline, reset];
}


/**
 * Hook to get the timeline.
 */
 export function useLiteTimeline(): [events: Event[], reset: () => void] {
  const [timeline, setTimeline] = useState<Event[]>([]);
  const [{ data, error }] = useLiteTimelineEventSubscription();

  useEffect(() => {
    if (!data) return;
    const newData = statesToEvents(data.newTimelineEvent);
    setTimeline(joinTimelineEvents(timeline, newData));
  }, [data, error]);

  const reset = () => setTimeline([]);
  return [timeline, reset];
}
