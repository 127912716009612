import React from 'react';
import { sizes, VectorProps } from './Icon';

export const UserColored: React.FC<VectorProps> = ({ size = 'xlarge' }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      fill="none"
      viewBox="0 0 41 44"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.271 31.102v12.125h20.824v-18.11H6.215c-1.576 0-3.088.63-4.203 1.752a6.008 6.008 0 0 0-1.74 4.233Z"
        fill="#006EC7"
      />
      <path
        d="M21.094 43.228h19.52V31.103c0-1.587-.626-3.11-1.74-4.233a5.922 5.922 0 0 0-4.203-1.753H21.094v18.111Z"
        fill="#0033AB"
      />
      <path
        d="M8.844 12.75a12.42 12.42 0 0 0 3.598 8.75 12.242 12.242 0 0 0 8.688 3.625V.375A12.242 12.242 0 0 0 12.442 4a12.42 12.42 0 0 0-3.598 8.75Z"
        fill="#FFBF31"
      />
      <path
        d="M33.417 12.75c0-1.625-.317-3.234-.935-4.736A12.382 12.382 0 0 0 29.82 4a12.28 12.28 0 0 0-3.986-2.683A12.21 12.21 0 0 0 21.13.375v24.75c3.259 0 6.384-1.304 8.688-3.625a12.42 12.42 0 0 0 3.598-8.75Z"
        fill="#EAA00C"
      />
    </svg>
  );
};
