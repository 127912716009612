import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../Routes';
import { useOutsideClick } from '../../../hooks/outsideClick';
import { Icon } from '../Icon';

import styled, { useTheme } from 'styled-components';
import { logout } from '../../../api';
import { useMeQuery } from '../../../hooks/graphqlQueries';
import { useTranslation } from 'react-i18next';

const SidebarShade = styled.div<{ hidden: boolean }>`
  z-index: 6;

  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  background-color: ${(props) => props.theme.colors.navbar.shade};
  opacity: ${(props) => (props.hidden ? 0 : 0.34)};
  visibility: ${(props) => (props.hidden ? 'hidden' : 'visible')};
  transition: linear 0.25s;
`;

const StyledSideBar = styled.div<{ hidden: boolean }>`
  display: grid;
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  position: fixed;

  grid-template-columns: 1fr;
  grid-auto-rows: max-content;
  padding: 0px 20px 0px 10px;
  row-gap: 20px;
  top: 0;
  width: 257px;
  height: 100%;
  right: ${(props) => (props.hidden ? '-257px' : 0)};
  z-index: 7;
  background-color: ${(props) => props.theme.colors.sidebar.background};
  justify-content: center;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.25);
  transition: linear 0.25s;

  @media (max-width: 400px) {
    padding: 0px 20px 0px 10px;
    width: 170px;
  }
`;

const UserContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  top: 100px;
  width: 0;
  height: 45px;
  align-items: center;
  margin-top: 16px;
  margin-left: 8px;
`;

const UserContent = styled.span`
  padding: 10px;
`;

const Item = styled.span`
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 18px;
  width: 100%;
  height: 45px;
  align-items: center;

  &&:hover {
    background-color: ${(props) => props.theme.colors.sidebar.backgroundHover};
    border-radius: 4px;
    cursor: pointer;
  }
`;

const Text = styled.span`
  font-family: Inter;
  font-style: Regular;
  font-size: 16px;
  text-overflow: ellipsis;
  width: 120px;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.sidebar.color};
`;

const IconContent = styled.span`
  padding-left: 30px;
`;

type BoxProps = {
  visible: boolean;
  size?: string;
};
const Box = styled.div<BoxProps>`
  display: ${(props) => (props.visible ? 'grid' : 'none')};
  position: absolute;
  grid-template-rows: repeat(6, max-content);
  row-gap: 5px;
  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #001738;
  z-index: 5;

  width: 200px;
  height: ${(props) => (props.size ? props.size : '55px')};
  padding: 15px;
  padding-right: 50px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.sidebar.color};

  align-items: center;
  justify-content: space-between;

  @media (max-width: 400px) {
    height: ${(props) => (props.size === '55px' ? '45px' : props.size === '15px' ? '10px' : '50px')};
  }
`;

const BoxTitle = styled.span`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: left;
  color: ${(props) => props.theme.colors.sidebar.background};

  @media (max-width: 400px) {
    font-size: 10px;
    word-break: normal;
  }
`;

export const SideBar: React.FC = () => {
  const [meQuery, refetchQueries] = useMeQuery();

  const me = useMemo(() => {
    return meQuery.data?.me;
  }, [meQuery.data]);

  const [hidden, setHidden] = useState(true);
  const [boxVisibility, setBoxVisibility] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const wrapperRef = useRef(null);
  useOutsideClick(
    wrapperRef,
    useCallback(() => setHidden(true), [setHidden])
  );

  useEffect(() => {
    if (!hidden) {
      refetchQueries([
        {
          query: meQuery,
        },
      ]);
    }
  }, [hidden]);

  return (
    <>
      <Icon
        name="Hamburguer"
        color={theme.colors.sidebar.color}
        size="small"
        area="Menu"
        onClick={() => setHidden(false)}
        dataCy="menu-icon"
      />
      <SidebarShade hidden={hidden} />
      <StyledSideBar
        data-cy="sidebar"
        hidden={hidden}
        ref={wrapperRef}
        onMouseLeave={() => {
          setHidden(true);
          setBoxVisibility(false);
        }}
      >
        <UserContainer>
          <UserContent>
            <Icon
              name="User"
              color={theme.colors.sidebar.color}
              size="large"
              area="Menu"
              onClick={() => {
                boxVisibility ? setBoxVisibility(false) : setBoxVisibility(true);
              }}
              dataCy="menu-icon"
            />
            <Box visible={boxVisibility} size={me?.group ? '55px' : '15px'}>
              {me?.profile && <BoxTitle data-cy="box-error">{`Tipo de usuário: ${t(me.profile)}`}</BoxTitle>}
              {me?.group?.name && <BoxTitle data-cy="box-error">{`Grupo: ${me?.group?.name}`}</BoxTitle>}
              {me?.group?.sector && <BoxTitle data-cy="box-error">{`Setor: ${t(me?.group?.sector)}`}</BoxTitle>}
            </Box>
          </UserContent>
          <Text>{me?.firstName}</Text>
        </UserContainer>

        <Item onClick={() => navigate(routes.dashboard)}>
          <IconContent>
            <Icon name="House" color={theme.colors.sidebar.color} size="small" />
          </IconContent>
          <Text>Home</Text>
        </Item>
        <Item onClick={() => navigate(routes.stop.index)}>
          <IconContent>
            <Icon name="Stop" color={theme.colors.sidebar.color} size="small" />
          </IconContent>
          <Text>Paradas</Text>
        </Item>
        {/* <Item onClick={() => navigate(routes.overview)}>
          <IconContent>
            <Icon name="Overview" color={theme.colors.sidebar.color} size="small" />
          </IconContent>
          <Text>Overview</Text>
        </Item> */}
        <Item onClick={() => navigate(routes.charts.index)}>
          <IconContent>
            <Icon name="Bars" color={theme.colors.sidebar.color} size="small" />
          </IconContent>
          <Text>Gráficos</Text>
        </Item>
        <Item data-cy="history-button" onClick={() => navigate(routes.history.index)}>
          <IconContent>
            <Icon name="Paper" color={theme.colors.sidebar.color} size="small" />
          </IconContent>
          <Text>Históricos</Text>
        </Item>
        <Item onClick={() => navigate(routes.scrap)}>
          <IconContent>
            <Icon name="Trashcan" color={theme.colors.sidebar.color} size="small" />
          </IconContent>
          <Text>Scraps</Text>
        </Item>
        <Item data-cy="registration-item" onClick={() => navigate(routes.registration.index)}>
          <IconContent>
            <Icon name="Paper" color={theme.colors.sidebar.color} size="small" />
          </IconContent>
          <Text>Cadastros</Text>
        </Item>

        <Item onClick={() => logout()}>
          <IconContent>
            <Icon name="Exit" color={theme.colors.sidebar.color} size="small" />
          </IconContent>
          <Text>Sair</Text>
        </Item>
      </StyledSideBar>
    </>
  );
};
