export type OEEParams = {
  /**
   * All time of a work shift, not including breaks, straight up 8 hours, for
   * example. In seconds.
   */
  totalTime: number;
  /**
   * It's recommended to use use the “true” Ideal Cycle Time
   * (https://www.oee.com/faq.html), which is the maximum theoretical speed of
   * your process. Many companies have 'budget' or 'standard' speeds that are
   * slower than this maximum. These numbers are useful for production planning,
   * but if used in the OEE calculation they will hide the true capacity of your
   * process. This will artificially raise your OEE score, while hiding loss and
   * slowing improvement. In seconds.
   */
  idealCycleTime: number;
  /**
   * All events that stop planned production long enough where it makes sense to
   * track a reason for being down (typically several minutes).
   */
  stopTime: number;
  /**
   * Total number of confirmed accepted parts by the estabilished quality
   * standards adopted by the company. It uses the First Pass Yield idea, in
   * that it defines Good Parts as parts that successfully pass through the
   * manufacturing process the first time without needing any rework
   */
  acceptedCount: number;
  /**
   * Total number of defective parts, both discarded as scrap and that were
   * reworked.
   */
  rejectedCount: number;
};

export type OEEResult = {
  /**
   * It is simply Planned Production Time less Stop Time
   */
  runTime: number;
  /**
   * Percent of good parts in the production shift.
   */
  quality: number;
  /**
   * Availability is calculated as the ratio of Run Time to Planned Production
   * Time
   */
  availability: number;
  /**
   * It takes into account anything that causes the manufacturing process to run
   * at less than the maximum possible speed when it is running.
   */
  performance: number;
  /**
   * It's the product of quality, availability and performance.
   */
  oee: number;
};

type SimplifiedOEEParams = {
  /**
   * Total number of confirmed accepted parts by the stabilished quality
   * standards adopted by the company. It uses the First Pass Yield idea, in
   * that it defines Good Parts as parts that successfully pass through the
   * manufacturing process the first time without needing any rework
   */
  acceptedCount: number;
  /**
   * It's recommended to use use the “true” Ideal Cycle Time
   * (https://www.oee.com/faq.html), which is the maximum theoretical speed of
   * your process. Many companies have 'budget' or 'standard' speeds that are
   * slower than this maximum. These numbers are useful for production planning,
   * but if used in the OEE calculation they will hide the true capacity of your
   * process. This will artificially raise your OEE score, while hiding loss and
   * slowing improvement. In seconds.
   */
  idealCycleTime: number;
  /**
   * All time of a work shift, not including breaks, straight up 8 hours, for
   * example. In seconds.
   */
  totalTime: number;
};

/**
 * OEE takes into account all losses, resulting in a measure of truly productive
 * manufacturing time. It is calculated as the product of Quality, Availability,
 * and Performance. If the equations for Availability, Performance, and Quality
 * are simplified to their simplest terms the result is:
 *
 * OEE = (Accepted Count * Ideal Cycle Time) / Planned Production Time
 *
 * @param params Params to calculate solely the oee KPI. It's a shortcut to oee
 * that doesn't take into account other factors such as quality. It's a good
 * ideal if you don't have all information to a complete off calculation.
 * @returns The oee value.
 */
export function simplifiedOee(params: SimplifiedOEEParams): number {
  const { acceptedCount, idealCycleTime, totalTime } = params;

  // Limit checks for the values
  if (totalTime < 0) throw new Error('totalTime must be equal or greater than 0');
  if (acceptedCount < 0) throw new Error('acceptedCount must be greater or equal to 0');
  if (idealCycleTime <= 0) throw new Error('idealCycleTime must be greater than 0');

  return totalTime > 0 ? (acceptedCount * idealCycleTime) / totalTime : 0;
}

/**
 * The oee calculation is based on the information provided by the following
 * link https://www.oee.com/calculating-oee.html. Notice that the TEEP is a
 * value calculated using the OEE value and can be used to improve the planned
 * production time, time in which the production is in fact happening.
 *
 * Here we briefly explain the calculation and significance of each metric,
 * it's a summary of the content of the link.
 *
 * - Availability: It takes into account all events that stop planned
 * production long enough where it makes sense to track a reason for being down
 * (typically several minutes). Availability is calculated as the ratio of Run
 * Time to Planned Production Time.
 * - Run Time: it is simply Planned Production Time less Stop Time, where Stop
 * Time is defined as all time where the manufacturing process was intended to
 * be running but was not due to Unplanned Stops (e.g., Breakdowns) or Planned
 * Stops (e.g., Changeovers).
 * - Performance: it takes into account anything that causes the manufacturing
 * process to run at less than the maximum possible speed when it is running
 * (including both Slow Cycles and Small Stops). Performance is the ratio of
 * Net Run Time to Run Time, where Net Run Time is the product of the Ideal
 * Cycle Time and the total count of produced parts (both good and defective).
 * - Quality: It takes into account manufactured parts that do not meet quality
 * standards, including parts that need rework. Remember, OEE Quality is similar
 * to First Pass Yield, in that it defines Good Parts as parts that successfully
 * pass through the manufacturing process the first time without needing any
 * rework. Quality is calculated as the ratio of Good Parts to Total Parts.
 * - OEE: OEE takes into account all losses, resulting in a measure of truly
 * productive manufacturing time. It is calculated as the product of
 * Availability, Performance and Quality.
 * - TEEP: "Total Effective Equipment Performance" is a measure of Schedule
 * Loss. It isn't used in the OEE calculation, but is a byproduct of it. The
 * TEEP can be used to improve Schedule Loss, which includes all time that
 * should be excluded from OEE analysis because there is no intention of running
 * production during this time (e.g., plant shutdowns, breaks/lunches, or
 * periods where there are no orders).
 *
 * @param params All the parameters needed to calculate the extended OEE,
 * which uses availability, performance and quality.
 * @returns The oee value, all partial metrics, such as availability,
 * performance and quality. The teep value is also included.
 */
export function oee(params: OEEParams): OEEResult {
  const { totalTime, idealCycleTime, stopTime, acceptedCount, rejectedCount } = params;

  // Limit checks for the values
  if (totalTime < 0) throw new Error('totalTime must be greater or equal to 0');
  if (stopTime < 0) throw new Error('stopTime must be greater or equal to 0');
  if (rejectedCount < 0) throw new Error('rejectedCount must be greater or equal to 0');
  if (acceptedCount < 0) throw new Error('acceptedCount must be greater or equal to 0');
  if (idealCycleTime <= 0) throw new Error('idealCycleTime must be greater than 0');

  const runTime = totalTime - stopTime;
  const totalCount = acceptedCount + rejectedCount;

  // Nothing happened yet in the shift, there is nothing to calculate
  if (totalCount === 0 || runTime <= 0) return { oee: 0, availability: 0, performance: 0, quality: 0, runTime: 0 };

  const quality = Math.min(acceptedCount / totalCount, 1);
  const availability = Math.min(runTime / totalTime, 1);
  const performance = (idealCycleTime * totalCount) / runTime;
  const oee = quality * availability * performance;

  return { runTime, quality, availability, performance, oee };
}
