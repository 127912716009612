import React from 'react';
import styled from 'styled-components';
import { LoaderLogo, MouraLogo } from '../../layout/Loading/Loading';
import { Logo } from '../Logo';

const sizes = {
  small: { minWidth: '770px' },
  medium: { minWidth: '1000px' },
  large: { minWidth: '1190px' },
};
type Size = keyof typeof sizes;
/*
const Container = styled.div`
  position: relative;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #006ec7;
    border: 3px solid #006ec7;
    border-radius: 100px;
  }

  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  color: ${(props) => props.theme.colors.table.colorBody};
  text-align: center;
`;
*/
const Container = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  color: ${(props) => props.theme.colors.table.colorBody};
  text-align: center;
`;

const TableContainer = styled.div`
  display: grid;
  position: relative;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
  @media (max-width: 1000px) {
    height: 80%;
  }
`;

const StyledTable = styled.table<{ size: Size }>`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-size: 16px;
  text-align: center;
  min-width: ${(props) => sizes[props.size].minWidth};

  border: 1px solid ${(props) => props.theme.colors.table.border};
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0px;
`;

const StyledHeader = styled.tr`
  line-height: 29px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.table.colorTitle};
  background-color: ${(props) => props.theme.colors.table.border};

  th {
    padding: 0px 15px 0px 15px;
    font-weight: normal;
  }
`;

const StyledRow = styled.tr`
  height: 55px;
  word-break: keep-all;

  td {
    padding: 0px 15px 0px 15px;
    text-align: center;
    max-width: 225px;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  td:hover div {
    visibility: visible;
  }
`;

const StyledDescription = styled.div`
  visibility: hidden;
  position: absolute;

  width: max-content;

  font-family: ${(props) => props.theme.font};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.sidebar.background};

  padding: 0 25px 0 25px;
  margin-top: 25px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.colors.sidebar.color};
`;

const StyledMessage = styled.td.attrs({
  colSpan: 100,
})`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-size: 16px;
  color: ${(props) => props.theme.colors.table.colorBody};
  height: 200px;
  text-align: center;
  justify-content: center;
`;

export type AttributeType = {
  name: string;
  attr: string;
  dataCy: string;
};

type Props = {
  attributes: AttributeType[];
  content: { [key: string]: string | number | boolean | string[] | null }[];
  size?: Size;
  loading?: boolean;
  dataCy?: string;
};
export const HistoryTable: React.FC<Props> = ({ attributes, content, size = 'small', loading = false, dataCy }) => {
  return (
    <Container>
      <TableContainer>
        <StyledTable size={size} data-cy={dataCy}>
          <thead>
            <StyledHeader>
              {attributes.map((value) => {
                return (
                  <th key={value.attr} data-cy={value.dataCy}>
                    {value.name}
                  </th>
                );
              })}
            </StyledHeader>
          </thead>
          <tbody>
            {(content === undefined || content?.length === 0) && (
              <tr>
                <StyledMessage>Você ainda não tem nenhum histórico. Em breve estará disponível aqui :)</StyledMessage>
              </tr>
            )}

            {content !== undefined &&
              content?.map((el, index) => {
                return (
                  <StyledRow key={index}>
                    {attributes.map((value) => {
                      return (
                        <td key={value.attr} data-cy={value.dataCy}>
                          <StyledDescription>{el[value.attr]}</StyledDescription>
                          {el[value.attr]}
                        </td>
                      );
                    })}
                  </StyledRow>
                );
              })}
          </tbody>
        </StyledTable>
      </TableContainer>
      {loading && (
          <LoaderLogo data-cy="loading">
            <MouraLogo>
              <Logo style="onlyIcon" />
            </MouraLogo>
          </LoaderLogo>
        )}
    </Container>
  );
};
