import React, { useEffect, useMemo, useState } from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import Plot, { PlotParams } from 'react-plotly.js';
import { LoaderLogo, MouraLogo } from '../../layout/Loading/Loading';
import { Logo } from '../Logo';
import { largestTriangleThreeBuckets } from '../../../scripts/decimation';
import { format } from 'date-fns';
import { Drop } from '../Drop';
import { Unit } from '../../../hooks/graphqlQueries';
import { t } from 'i18next';

const Container = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
`;

const ChartContainer = styled.div`
  display: grid;
  position: relative;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
  @media (max-width: 1000px) {
    height: 80%;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  padding: 0 0px 0 0px;
  height: -15px;
  width: 97.8%;
  top: calc(0% + 45px);
  z-index: 1;
  @media (max-width: 1000px) {
    left: calc(0% + 35px);
    top: calc(100% - 450px);
    width: 40%;
    justify-content: flex-start;
  }
`;

type ChartType = 'LINE' | 'BAR' | 'STACKBAR' | 'OVERLAYBAR';
export type AxisXType = number[] | string[] | Date[];
export type AxisYType = number[] | string[];
export type AxisTextType = string[] | undefined;

export type ChartData = {
  x: AxisXType;
  y: AxisYType;
  /**
   * Line name.
   */
  name: string;
  /**
   * Hover customization message.
   */
  hoverData?: string | string[];
  /**
   * Marker customizations.
   */
  marker?: {
    color?: string;
    size?: number;
  };
  /**
   * Line customizations.
   */
  line?: {
    color?: string;
  };

  hoverLabel?: {
    bgcolor?: string;
    bordercolor?: string;
    font?: {
      color?: string;
    };
  };

  text?: string[];
  textPosition?: string;
  showLegend?: boolean;

  width?: number;
  yaxis?: string;

  chartType: ChartType;
  unit?: Unit;
};

const chartTypeSelect: { [key in ChartType]: 'bar' | 'scatter' } = {
  LINE: 'scatter',
  BAR: 'bar',
  STACKBAR: 'bar',
  OVERLAYBAR: 'bar',
};

type OptionsFilterByType = { id: string; name: string };

type AxisType = 'category' | 'linear' | 'date' | 'log' | 'multicategory';

export type Props = {
  /**
   * An array of type Data containing the values for x and y.
   */
  data: ChartData[];

  /**
   * Chart type.
   */
  chartType: ChartType;

  /**
   * Plotly shapes to draw arbitrary shapes on the chart.
   */
  shapes?: PlotParams['layout']['shapes'];

  /**
   * Plotly annotations to draw arbitrary annotations on the chart.
   */
  annotations?: PlotParams['layout']['annotations'];

  /**
   * How hover labels are shown.
   */
  hoverMode?: 'x' | 'y' | 'x unified' | 'y unified';

  optionFilterByInitial?: OptionsFilterByType;

  optionsFilterBy?: OptionsFilterByType[];

  selectFilterBy?: (filterBy: OptionsFilterByType) => void;

  xaxis?: {
    type?: AxisType;
  };

  loading?: boolean;

  hasUnit?: boolean;
  capacity?: boolean;
};

export const Chart: React.FC<Props> = ({
  data: incomingData,
  annotations,
  shapes,
  hoverMode,
  chartType,
  optionFilterByInitial,
  optionsFilterBy,
  selectFilterBy,
  xaxis,
  loading,
  hasUnit,
  capacity,
}: Props) => {
  const theme: DefaultTheme = useTheme();
  const userLanguage = window.navigator.language;

  const [selected, setSelected] = useState<OptionsFilterByType | undefined>(optionFilterByInitial);
  const [figure, setFigure] = useState<Partial<PlotParams>>();
  const shouldShowItems = incomingData.length > 0 && incomingData?.[0]?.x?.length !== 0;
  const barmode = useMemo(() => {
    if (chartType === 'STACKBAR') return 'stack';
    else if (chartType === 'OVERLAYBAR') return 'overlay';
    else return 'group';
  }, [chartType]);

  useEffect(() => {
    const data = incomingData.map((curve) => {
      const modified = applyDecimationIf(chartType === 'LINE', curve, hasUnit, capacity);

      return {
        x: modified.x,
        y: modified.y,
        name: modified.name,
        text: modified.text,
        textPosition: modified.textPosition,
        width: modified.width ?? 0.1,
        hovertemplate: modified.hoverData,
        showlegend: modified.showLegend,
        hoverlabel: {
          namelength: 0,
          font: { color: modified.hoverLabel?.font?.color ?? theme.colors.chart.hover.fontColor },
          bgcolor: modified.hoverLabel?.bgcolor ?? theme.colors.chart.hover.backgroundColor,
          bordercolor: modified.hoverLabel?.bordercolor ?? theme.colors.chart.hover.borderColor,
        },
        type: chartTypeSelect[modified?.chartType],
        yaxis: modified?.yaxis,
        mode: 'lines+markers' as const,
        marker: { size: 5, width: 0.05, ...modified.marker },
        line: { color: modified?.line?.color, width: 3, ...modified.line },
      };
    });
    setFigure((prev) => ({ ...prev, data }));
  }, [incomingData]);

  useEffect(() => {
    setFigure((prev) => ({ ...prev, layout: { ...prev?.layout, shapes } }));
  }, [shapes]);

  useEffect(() => {
    setFigure((prev) => ({ ...prev, layout: { ...prev?.layout, annotations } }));
  }, [annotations]);

  useEffect(() => {
    setFigure((prev) => ({ ...prev, layout: { ...prev?.layout, barmode } }));
  }, [barmode]);

  useEffect(() => {
    setFigure((prev) => ({ ...prev, layout: { ...prev?.layout, hovermode: hoverMode } }));
  }, [hoverMode]);

  useEffect(() => {
    setFigure((prev) => ({
      ...prev,
      layout: {
        ...prev?.layout,
        xaxis: {
          tickfont: {
            size: 12,
          },
          color: theme.colors.chart.xaxis.color,
          gridcolor: theme.colors.chart.xaxis.gridcolor,
          ...xaxis,
          fixedrange: !shouldShowItems,
        },
      },
    }));
  }, [xaxis, shouldShowItems]);

  useEffect(() => {
    const layout = {
      paper_bgcolor: theme.colors.chart.paperBackgroundColor,
      plot_bgcolor: theme.colors.chart.plotBackgroundColor,
      showlegend: true,
      uniformtext: {
        minsize: 8,
        mode: 'hide',
      },
      legend: {
        orientation: 'h' as const,
        yanchor: 'bottom' as const,
        y: 1.3,
        tracegroupgap: 1,
        font: {
          color: theme.colors.chart.legend,
          size: 12,
        },
        title: {
          font: {
            color: theme.colors.chart.legend,
            size: 12,
          },
          text: 'Legenda: ',
        },
      },
      margin: {
        l: 40,
        r: 70,
        t: 110,
        b: 120,
        pad: 10,
      },
      yaxis: {
        tickfont: { size: 12 },
        color: theme.colors.chart.yaxis.color,
        gridcolor: theme.colors.chart.yaxis.gridcolor,
        fixedrange: !shouldShowItems,
      },
      yaxis2: {
        tickfont: { size: 12 },
        overlaying: 'y' as const,
        side: 'right' as const,
        color: theme.colors.chart.yaxis.color,
        showgrid: false,
        range: [0, 100],
        fixedrange: !shouldShowItems,
      },
    };
    setFigure((prev) => ({ ...prev, layout: { ...prev?.layout, ...layout } }));
  }, [theme, shouldShowItems]);

  useEffect(() => {
    setFigure((prev) => ({
      ...prev,
      config: {
        ...prev?.config,
        responsive: true,
        displaylogo: false,
        displayModeBar: true,
        locale: userLanguage,
        modeBarButtonsToRemove: ['lasso2d', 'select2d'],
      },
    }));
  }, [userLanguage]);

  return (
    <Container>
      {shouldShowItems && optionsFilterBy && (
        <ItemContainer>
          <Drop
            dataCy="shift-drop"
            name=""
            size="medium"
            options={optionsFilterBy ?? []}
            required
            onSelect={(value) => {
              setSelected((prev) => {
                if (prev?.id === value.id) return undefined;
                if (selectFilterBy) selectFilterBy(value);
                return value;
              });
            }}
            value={selected?.name}
          />
        </ItemContainer>
      )}

      <ChartContainer>
        <Plot
          data={figure?.data ?? []}
          layout={figure?.layout ?? {}}
          config={figure?.config ?? {}}
          style={{ width: '100%', height: '100%' }}
          useResizeHandler={true}
        />
      </ChartContainer>

      {loading && (
        <LoaderLogo data-cy="loading">
          <MouraLogo>
            <Logo style="onlyIcon" />
          </MouraLogo>
        </LoaderLogo>
      )}
    </Container>
  );
};

function applyDecimationIf(condition: boolean, data: ChartData, hasUnit?: boolean, capacity?: boolean): ChartData {
  if (!condition) return data;
  const fmt = (v: Date | number | string) => format(new Date(v), 'dd/MM/yyyy HH:mm:ss.S');
  const { x, y } = largestTriangleThreeBuckets(data.x, data.y, window.innerWidth);
  let hoverData = x.map((c, i) => `<b>${data.name}</b>: ${y[i]}%<br>${fmt(c)}<br><b>(dados ajustados)</b>`);
  if (hasUnit)
    hoverData = x.map(
      (c, i) => `<b>${data.name}</b>: ${y[i]} ${t(data.unit as Unit)}<br>${fmt(c)}<br><b>(dados ajustados)</b>`
    );
  if (capacity) hoverData = x.map((c, i) => `<b>${data.name}</b>: ${y[i]}<br>${fmt(c)}<br><b>(dados ajustados)</b>`);
  return { ...data, x, y, hoverData };
}
