import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import ReactFlow, { ConnectionMode, Controls, ReactFlowInstance } from 'react-flow-renderer';
import { DefaultNode } from './MachineNode';
import { DefaultEdge } from './MachineEdge';
import { StatesWithColor } from '../../../scripts/colorOfState';
import { useEffect } from 'react';

const Container = styled.div<{ area: string }>`
  width: 100%;
  height: 100%;
  grid-area: ${(props) => props.area};
`;

type Props = {
  area?: string;
  lineId?: string;
  adjust?: boolean;
  conveyorData: {
    id: string;
    status: StatesWithColor;
    origin: string;
    target: string;
    speed: number;
    handleFrom: string;
    handleTo: string;
  }[];
  machineData: {
    id: string;
    time?: string;
    clock: Date;
    disabled?: boolean;
    timeLastItem?: string;
    itemTypeCode?: string;
    itemTypeName?: string;
    name: string;
    status: StatesWithColor;
    shiftStartAt: Date;
    bottleneckId: string;
    bottleneckIdealCycle: number;
    bottleneckProduction: number;
    isBottleneck: boolean;
    production: number;
    stopCount: number;
    stopTime: number;
    positionX: number;
    positionY: number;
    idealCycle: number;
    stopCycle: number;
  }[];
};

export const MachineDiagram: React.FC<Props> = ({ area = 'Diagram', ...props }: Props) => {
  const [reactFlow, setReactFlow] = useState<ReactFlowInstance>();
  const cardElements = useMemo(() => {
    return props.machineData.map((machine) => ({
      id: machine.id,
      type: 'defaultType',
      position: {
        x: machine.positionX,
        y: machine.positionY,
      },
      data: {
        time: machine.time,
        clock: machine.clock,
        timeLastItem: machine.timeLastItem,
        itemTypeCode: machine.itemTypeCode,
        itemTypeName: machine.itemTypeName,
        name: machine.name,
        status: machine.status,
        disabled: machine.disabled,
        production: machine.production,
        stopTime: machine.stopTime,
        shiftStartAt: machine.shiftStartAt,
        isBottleneck: machine.isBottleneck,
        bottleneckIdealCycle: machine.bottleneckIdealCycle,
        bottleneckProduction: machine.bottleneckProduction,
        stopCycle: machine.stopCycle,
        idealCycle: machine.idealCycle,
      },
    }));
  }, [props.machineData]);

  const linkElements = useMemo(() => {
    return props.conveyorData.map((conveyor) => ({
      id: conveyor.id,
      type: 'defaultType',
      source: conveyor.origin,
      target: conveyor.target,
      sourceHandle: conveyor.handleFrom,
      targetHandle: conveyor.handleTo,
      animated: conveyor.speed > 0,
      data: { speed: conveyor.speed, state: conveyor.status },
    }));
  }, [props.conveyorData]);

  const nodeTypes = useMemo(() => ({ defaultType: DefaultNode }), []);
  const edgeTypes = useMemo(() => ({ defaultType: DefaultEdge }), []);

  useEffect(() => {
    const timeout = setTimeout(() => reactFlow?.fitView({ duration: 300 }), 3000);
    return () => clearTimeout(timeout);
  }, [props.lineId, props.adjust]);

  return (
    <Container area={area}>
      <ReactFlow
        attributionPosition="bottom-left"
        nodesConnectable={false}
        nodesDraggable={false}
        connectionMode={ConnectionMode.Loose}
        zoomOnScroll={false}
        onInit={(reactFlowInstance: ReactFlowInstance) => setReactFlow(reactFlowInstance)}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        defaultNodes={cardElements}
        defaultEdges={linkElements}
      >
        <Controls showInteractive={false} />
      </ReactFlow>
    </Container>
  );
};
