import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ApplicationRegistration } from '../../components/layout/ApplicationRegistration';
import { Footer } from '../../components/layout/Footer';
import { PageOptions } from '../../components/layout/PageOptions';
import { Button } from '../../components/ui/Button';
import { Input } from '../../components/ui/Input';
import { MultiDropdown } from '../../components/ui/MultiDropdown';
import { Title } from '../../components/ui/Title';
import { useFeedback } from '../../context/feedback';
import { useLineTitleListQuery, useRegisterApplicationMutation } from '../../hooks/graphqlQueries';

const Info = styled.div`
  display: grid;
  grid-area: Info;
  grid-template-columns: 1fr 1fr;
  row-gap: 19px;
  column-gap: 19px;
  align-items: end;

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
  }
`;

type Application = {
  name: string;
  description: string;
  secretKey: string;
  line: string[];
  permissions?: string[];
};

function createData() {
  return {
    line: [],
    name: '',
    description: '',
    secretKey: '',
  };
}

export const ApplicationRegistrationPage: React.FC = () => {
  const navigate = useNavigate();
  const feedback = useFeedback();
  const location = useLocation();

  const routeData = location.state as { pagination: { page: number; limit: number } };

  const [linesQuery] = useLineTitleListQuery();
  const [, registerApplication] = useRegisterApplicationMutation();

  const [data, setData] = useState<Application>(createData());

  const canRegister = useMemo(() => Object.values(data).every((v) => v.length > 0), [data]);

  const handleRegister = () => {
    const dialog = () => {
      feedback.confirmation({
        title: 'Deseja cadastrar outra aplicação?',
        onHandle: (result) => {
          result ? setData(createData()) : navigate('..', { state: { pagination: routeData?.pagination } });
        },
      });
    };

    registerApplication(data)
      .then((result) => {
        if (result.error) feedback.apiError(result.error);
        else if (result.data?.createApplication === null)
          feedback.message({ animation: 'Error', title: 'Erro ao cadastrar aplicação' });
        else
          feedback.message({
            animation: 'Confetti',
            title: 'Aplicação cadastrada com sucesso',
            onClose: () => dialog(),
          });
      })
      .catch(() => {
        feedback.message({ animation: 'Error', title: 'Erro ao cadastrar aplicação' });
      });
  };

  return (
    <ApplicationRegistration>
      <Title>Cadastrar Aplicação</Title>
      <Info>
        <Input
          label="Nome"
          dataCy="name-field"
          value={data.name}
          width="376px"
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        <Input
          label="Descrição"
          dataCy="description-field"
          value={data.description}
          width="376px"
          onChange={(e) => setData({ ...data, description: e.target.value })}
        />
        <Input
          label="Secret Key"
          dataCy="key-field"
          value={data.secretKey}
          width="376px"
          onChange={(e) => setData({ ...data, secretKey: e.target.value })}
        />
        <MultiDropdown
          label="Permissões"
          dataCy="permissions-multidropdown"
          size={'large'}
          name="Linhas"
          value={data.line ?? []}
          options={linesQuery.data?.lines?.value?.map((line) => ({ id: line.id, name: line.code })) ?? []}
          onBlur={(lines) => {
            setData({
              ...data,
              line: lines.map((item) => item.id as string),
              permissions: lines.map((item) => item.id as string),
            });
          }}
        />
      </Info>
      <Footer>
        <PageOptions>
          <Button
            title="Voltar"
            icon="ArrowLeft"
            color="light"
            onClick={() => navigate('..', { state: { pagination: routeData?.pagination } })}
          />
          <Button
            dataCy="register-button"
            title="Cadastrar"
            color="primary"
            disabled={!canRegister}
            onClick={() => handleRegister()}
          />
        </PageOptions>
      </Footer>
    </ApplicationRegistration>
  );
};
