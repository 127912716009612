import React, { useEffect, useMemo, useState } from 'react';
import { Overview } from '../components/layout/Overview';
import styled from 'styled-components';
import { Title } from '../components/ui/Title';
import { Button } from '../components/ui/Button';
import { routes } from '../Routes';
import { useNavigate } from 'react-router-dom';
import { Drop } from '../components/ui/Drop';
import { OverviewTable } from '../components/ui/OverviewTable';
import { Pagination } from '../components/ui/Pagination';
import {
  Line,
  OverviewTimelineEvents,
  useFactoriesNameQuery,
  useOverviewLinesQuery,
  useOverviewQuery,
  useTimelineOverviewSubscription,
  useWorkshiftAtQuery,
} from '../hooks/graphqlQueries';
import { Timeline } from '../components/business/Timeline';
import { addSeconds, parseISO } from 'date-fns';
import { cmpWorkshift, isWithinWorkshift } from '../scripts/workshiftInterval';
import { Event, joinTimelineEvents, statesToEvents } from '../scripts/timeline';
import { arraySorting } from '../scripts/arraySorting';
import { PageOptions } from '../components/layout/PageOptions';
import { Footer } from '../components/layout/Footer';

const Unit = styled.div`
  grid-area: Unit;
  display: flex;
  align-items: center;
  justify-content: right;
`;

const ContainerTables = styled.div`
  display: grid;
  grid-area: Table;
  grid-template-columns: repeat(3, 400px);
  column-gap: 50px;
  @media (max-width: 1300px) {
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(2, 400px);
  }
  @media (max-width: 850px) {
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(1, minmax(220px, 400px));
  }
`;

const ContainerTable = styled.div`
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};
  span {
    line-height: 30px;
  }
`;

export const OverviewPage: React.FC = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [factories] = useFactoriesNameQuery();
  const [currentFactory, setCurrentFactory] = useState(() => localStorage.getItem('selected-factory-overview'));
  const [factory, setFactoryName] = useState(localStorage.getItem('selected-factory-name-overview'));
  const [variables, setVariables] = useState({ offset: 0, factoryId: '' });

  const [linesQuery] = useOverviewLinesQuery({
    requestPolicy: 'network-only',
    variables,
  });

  const [overview, refetchOverview] = useOverviewQuery({
    requestPolicy: 'network-only',
    variables: { factoryId: currentFactory ?? '' },
  });

  const [workshiftQuery, refetchWorkshift] = useWorkshiftAtQuery();
  const workshift = useMemo(() => workshiftQuery.data?.workshiftAt, [workshiftQuery.data]);

  const [clock, setClock] = useState(new Date());

  const [timelineSubscription] = useTimelineOverviewSubscription({ variables });

  const [timelineData, setTimelineData] = useState([] as { id: string; events: Event[] }[]);

  useEffect(() => {
    const timelineEvents = timelineSubscription.data?.overviewLineStateCreated as OverviewTimelineEvents[];
    if (!timelineEvents) return;

    for (const line of timelineEvents) {
      const index = timelineData?.findIndex((el) => el.id === line.lineId);
      const currTimeline = timelineData[index]?.events ?? [];
      const newEvents = statesToEvents(line.events) ?? [];

      if (index < 0) {
        timelineData.push({ id: line.lineId, events: joinTimelineEvents([], newEvents) });
        setTimelineData([...timelineData]);
      } else if (workshift && line.events[0]) {
        const eventInWorkshift = cmpWorkshift(line.events[0].time, workshift);
        const timelineInWorkshift = currTimeline[0] && isWithinWorkshift(currTimeline[0].start, workshift);

        if (eventInWorkshift < 0) return;

        if (timelineInWorkshift) {
          timelineData[index] = { id: line.lineId, events: joinTimelineEvents(currTimeline, newEvents) };
        } else if (!timelineInWorkshift) {
          timelineData[index] = { id: line.lineId, events: joinTimelineEvents([], newEvents) };
        }

        setTimelineData([...timelineData]);
      }
    }
  }, [timelineSubscription.data]);

  useEffect(() => {
    const newVariables = { ...variables };
    if (factory !== variables.factoryId) newVariables.factoryId = currentFactory ?? '';
    if ((page - 1) * 6 !== variables.offset) newVariables.offset = (page - 1) * 6;

    if (newVariables.factoryId !== variables.factoryId || newVariables.offset !== variables.offset) {
      setVariables({ ...newVariables });
    }
  }, [timelineData]);

  useEffect(() => {
    const timer = setInterval(() => setClock(new Date()), 1000);
    return () => clearInterval(timer);
  }, [clock]);

  useEffect(() => {
    const timer = setTimeout(() => refetchWorkshift(), 5000);
    return () => clearTimeout(timer);
  }, [workshiftQuery.data]);

  useEffect(() => {
    const timer = setTimeout(() => refetchOverview(), 300000);
    return () => clearTimeout(timer);
  }, [overview.data]);

  useEffect(() => {
    setTimelineData([]);
  }, [workshift?.name]);

  function handleSelectFactory(factory: { id: string; name: string }) {
    localStorage.setItem('selected-factory-overview', factory.id);
    localStorage.setItem('selected-factory-name-overview', factory.name);
    setCurrentFactory(factory.id);
    setFactoryName(factory.name);
  }

  const productionLines = linesQuery.data?.lines?.value ?? [];
  const overviewData = overview.data?.overview ?? [];

  return (
    <Overview>
      <Title>Overview</Title>
      <Unit>
        <Drop
          name="UN. Geradora"
          options={
            factories.data?.factories?.value?.filter((fact) => {
              const enabledLines = fact.lines?.filter((line) => line.disabled === false);
              if (enabledLines && enabledLines.length > 0) {
                return { id: fact.id, name: fact.name };
              }
              return false;
            }) ?? []
          }
          onSelect={(unit) => {
            if (unit.id !== factory) {
              handleSelectFactory(unit);
              setCurrentFactory(unit.id);
              setPage(1);
              setTimelineData([]);
            }
          }}
          value={factory?.toString()}
        />
      </Unit>

      {/**
         * linesQuery.data?.lines?.value
          ?.filter((line) => {
            const overviewData = overview.data?.overview;
            if (!overviewData) return false;
            return overviewData.find((overviewResult) => overviewResult.lineId === line.id) !== undefined;
          })
         */}

      <ContainerTables>
        {overviewData
          ?.map((overviewOfLine) => productionLines.find((line) => line.id === overviewOfLine.lineId))
          ?.filter((line): line is Line => line !== undefined)
          ?.sort((a, b) => arraySorting(a.code, b.code))
          .map((line) => {
            return (
              <ContainerTable key={line.id}>
                <OverviewTable {...(overviewData.find((el) => el.lineId === line.id) ?? { lineCode: line.code })} />
                {workshift && (
                  <Timeline
                    area="Timeline"
                    events={timelineData.find((el) => el.id === line.id)?.events ?? []}
                    shift={{ start: parseISO(workshift.startingAt), end: addSeconds(parseISO(workshift.endingAt), 1) }}
                  />
                )}
              </ContainerTable>
            );
          })}
      </ContainerTables>
      <Footer>
        <PageOptions>
          <Button title="Voltar para Home" color="light" icon="ArrowLeft" onClick={() => navigate(routes.dashboard)} />
        </PageOptions>
        <Pagination
          page={page}
          totalPages={Math.ceil(overviewData.length / 6)}
          onPrevious={() => {
            setPage(page - 1);
            setTimelineData([]);
          }}
          onNext={() => {
            setPage(page + 1);
            setTimelineData([]);
          }}
        />
      </Footer>
    </Overview>
  );
};
