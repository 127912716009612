import React, { useState, useMemo, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { getUserTheme } from './scripts/global';
import { Nav } from './components/layout/Nav';
import { NavBar } from './components/business/NavBar';
import { Provider } from 'urql';
import { createUrqlClient } from './api';
import { render } from 'react-dom';
import { FeedbackProvider } from './context/feedback';
import { asString } from 'ts-runtime-typecheck';
import './i18n';

import themes from './static/themes.json';
import { Routes, Route, Outlet, Navigate } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import {
  SelectChart,
  ScrapChart,
  StopChart,
  OEEChart,
  ProductionRateChart,
  ProductionChart,
  CycleTimeChart,
  PeriodAnalysisChart,
  ParameterChart,
  CapacityChart,
} from './pages/Charts';
import { DashboardPage } from './pages/Dashboard';
import { SignInPage } from './pages/SignIn';
import { ScrapPage } from './pages/Scrap';
import { SelectHistory } from './pages/History/SelectHistory';
import {
  LineRegistrationPage,
  LineListPage,
  SelectRegistration,
  DefectRegistrationPage,
  DefectListPage,
  StopListPage,
  StopRegistrationPage,
  LossRegistrationPage,
  LossListPage,
  ReasonRegistrationPage,
  ReasonListPage,
  MachineListPage,
  MachineRegistrationPage,
  ItemTypeListPage,
  ItemTypeRegistrationPage,
} from './pages/Registration/index';
import { UserListPage } from './pages/Registration/UserList';
import { UserRegistrationPage } from './pages/Registration/UserRegistration';
import { UserEditPage } from './pages/Registration/UserEdit';
import { ScrapHistoryPage } from './pages/History/ScrapHistory';
import { StopHistoryPage } from './pages/History/StopHistory';
import { useTokenDance } from './hooks/tokenDance';
import { StopsPage } from './pages/StopsPage';
import { StopJustificationPage } from './pages/StopJustification';
import { ChartFilterInterval } from './pages/ChartFilterInterval';
import { LineHistoryPage } from './pages/History/LineHistory';
import { SmallStopHistoryPage } from './pages/History/SmallStopHistory';
import { MachineEditPage } from './pages/Registration/MachineEdit';
import { ParameterHistoryPage } from './pages/History/ParameterHistory';
import { RecoverPassword } from './components/layout/RecoverPassword';
import { ProductionCycleHistoryPage } from './pages/History/ProductionCyclesHistory';
import { NotFound } from './pages/NotFound';
import { MachineHistoryPage } from './pages/History/MachineHistory';
import { ParameterListPage } from './pages/Registration/ParameterList';
import { ParameterRegistrationPage } from './pages/Registration/ParameterRegistration';
import { ItemTypeEditPage } from './pages/Registration/ItemTypeEdit';
import { ChartFilterLineRate } from './pages/ChartFilterLineRate';
import { ChartFilterMulti } from './pages/ChartFilterMulti';
import { LineEditPage } from './pages/Registration/LineEdit';
import { ApplicationListPage } from './pages/Registration/ApplicationList';
import { ApplicationRegistrationPage } from './pages/Registration/ApplicationRegistration';
import { ApplicationEditPage } from './pages/Registration/ApplicationEdit';
import { FactoryListPage } from './pages/Registration/FactoryList';
import { FactoryRegistrationPage } from './pages/Registration/FactoryRegistration';
import { FactoryEditPage } from './pages/Registration/FactoryEdit';
import { ChartFilterLine } from './pages/ChartFilterLine';
import { PermissionProvider } from './context/permission';
import { GroupListPage } from './pages/Registration/GroupList';
import { GroupRegistrationPage } from './pages/Registration/GroupRegistration';
import { ScrapListPage } from './pages/Registration/ScrapList';
import { ScrapEditPage } from './pages/Registration/ScrapEdit';
import { OverviewPage } from './pages/Overview';
import { ProductionHistoryPage } from './pages/History/ProductionHistory';
import { UseVersionCheck } from './hooks/versionCheck';

const API_HTTPS = asString(process.env['API_HTTPS']);
const API_WSS = asString(process.env['API_WSS']);
const INDEX_HTML_DEPLOYMENT_URL = asString(process.env['FRONTEND_URL']);

export const routes = {
  dashboard: '/',
  signin: '/signin',
  recoverPassword: '/recoverpasswd',
  scrap: '/scrap',
  overview: '/overview',
  stop: {
    index: '/stop',
    justifications: 'justification',
  },
  charts: {
    index: '/charts',
    oee: 'oee',
    production: 'production',
    productionRate: 'productionRate',
    capacity: 'capacity',
    stop: 'stop',
    scrap: 'scrap',
    cycleTime: 'cycleTime',
    periodAnalysis: 'periodAnalysis',
    parameter: 'parameter',
  },
  history: {
    index: '/history',
    line: 'line',
    machine: 'machine',
    stop: 'stop',
    microStop: 'micro-stop',
    scrap: 'scrap',
    productionCycles: 'productionCycles',
    production: 'production',
    parameters: 'parameters',
  },
  registration: {
    index: '/registration',
    add: 'add',
    edit: 'edit',
    lines: 'lines',
    machines: 'machine',
    users: 'users',
    defects: 'defect',
    stops: 'stop',
    categoryLabels: 'category-labels',
    reasonLabels: 'reason-labels',
    lossLabels: 'loss-labels',
    parameters: 'parameters',
    types: 'type',
    applications: 'applications',
    factories: 'factories',
    groups: 'groups',
    scraps: 'scraps',
  },
};

const noMatchRoute = '*';
const wildcardRoutes = {
  registration: routes.registration.index + '/*',
  charts: routes.charts.index + '/*',
  history: routes.history.index + '/*',
  stops: routes.stop.index + '/*',
  defectRegistration: routes.registration.defects + '/*',
  stopCategoryRegistration: routes.registration.stops + '/*',
  lineRegistration: routes.registration.lines + '/*',
  userRegistration: routes.registration.users + '/*',
  lossRegistration: routes.registration.lossLabels + '/*',
  reasonRegistration: routes.registration.reasonLabels + '/*',
  machineRegistration: routes.registration.machines + '/*',
  parameterRegistration: routes.registration.parameters + '/*',
  typeRegistration: routes.registration.types + '/*',
  applicationRegistration: routes.registration.applications + '/*',
  factoriesRegistration: routes.registration.factories + '/*',
  groupsRegistration: routes.registration.groups + '/*',
  scrapRegistration: routes.registration.scraps + '/*',
};

type WithNavLayoutProps = { setTheme: (theme: keyof typeof themes) => void };
const WithNavLayout: React.FC<WithNavLayoutProps> = ({ setTheme }) => {
  const { isAuthenticated } = useTokenDance({ repeatAfter: 120_000 });

  if (!isAuthenticated) {
    return <Navigate to={routes.signin} />;
  } else {
    return (
      <Nav>
        <NavBar setTheme={setTheme} />
        <Outlet />
      </Nav>
    );
  }
};

export const App: React.FC = () => {
  const [theme, setTheme] = useState<keyof typeof themes>(getUserTheme());
  const urqlClient = useMemo(() => createUrqlClient(API_HTTPS, API_WSS), []);
  useEffect(() => localStorage.setItem('theme', theme), [theme]);
  const { isNewVersionAvailable } = UseVersionCheck({ deploymentUrl:INDEX_HTML_DEPLOYMENT_URL, scriptId:'app_script', cicleTime:300_000});//300_000

  useEffect(() => {
    if (isNewVersionAvailable) {
      console.log("New version available, reloading...");
      window.location.reload();
    } else {
      //console.log("No new version available");
    }
  }, [isNewVersionAvailable])

  return (
    <Provider value={urqlClient}>
      <ThemeProvider theme={themes[theme]}>
        <PermissionProvider>
          <FeedbackProvider>
            <BrowserRouter>
              <Routes>
                <Route path={routes.signin} element={<SignInPage />} />
                <Route path={routes.recoverPassword} element={<RecoverPassword />} />
                <Route element={<WithNavLayout setTheme={setTheme} />}>
                  <Route path={routes.dashboard} element={<DashboardPage />} />
                  <Route path={routes.scrap} element={<ScrapPage />} />
                  <Route path={wildcardRoutes.stops}>
                    <Route index element={<StopsPage />} />
                    <Route path={routes.stop.justifications} element={<StopJustificationPage />} />
                  </Route>
                  <Route path={routes.overview} element={<OverviewPage />} />
                  <Route path={wildcardRoutes.registration}>
                    <Route index element={<SelectRegistration />} />
                    <Route path={wildcardRoutes.defectRegistration}>
                      <Route index element={<DefectListPage />} />
                      <Route path={routes.registration.add} element={<DefectRegistrationPage />} />
                      <Route path={routes.registration.edit} element={<DefectRegistrationPage />} />
                      <Route path={noMatchRoute} element={<NotFound />} />
                    </Route>
                    <Route path={wildcardRoutes.lineRegistration}>
                      <Route index element={<LineListPage />} />
                      <Route path={routes.registration.add} element={<LineRegistrationPage />} />
                      <Route path={routes.registration.edit} element={<LineEditPage />} />
                    </Route>
                    <Route path={wildcardRoutes.userRegistration}>
                      <Route index element={<UserListPage />} />
                      <Route path={routes.registration.add} element={<UserRegistrationPage />} />
                      <Route path={routes.registration.edit} element={<UserEditPage />} />
                    </Route>
                    <Route path={wildcardRoutes.stopCategoryRegistration}>
                      <Route index element={<StopListPage />} />
                      <Route path={routes.registration.add} element={<StopRegistrationPage />} />
                      <Route path={routes.registration.edit} element={<StopRegistrationPage />} />
                    </Route>
                    <Route path={wildcardRoutes.lossRegistration}>
                      <Route index element={<LossListPage />} />
                      <Route path={routes.registration.add} element={<LossRegistrationPage />} />
                      <Route path={routes.registration.edit} element={<LossRegistrationPage />} />
                    </Route>
                    <Route path={wildcardRoutes.reasonRegistration}>
                      <Route index element={<ReasonListPage />} />
                      <Route path={routes.registration.add} element={<ReasonRegistrationPage />} />
                      <Route path={routes.registration.edit} element={<ReasonRegistrationPage />} />
                    </Route>
                    <Route path={wildcardRoutes.machineRegistration}>
                      <Route index element={<MachineListPage />} />
                      <Route path={routes.registration.add} element={<MachineRegistrationPage />} />
                      <Route path={routes.registration.edit} element={<MachineEditPage />} />
                    </Route>
                    <Route path={wildcardRoutes.parameterRegistration}>
                      <Route index element={<ParameterListPage />} />
                      <Route path={routes.registration.add} element={<ParameterRegistrationPage />} />
                      <Route path={routes.registration.edit} element={<ParameterRegistrationPage />} />
                    </Route>
                    <Route path={wildcardRoutes.typeRegistration}>
                      <Route index element={<ItemTypeListPage />} />
                      <Route path={routes.registration.add} element={<ItemTypeRegistrationPage />} />
                      <Route path={routes.registration.edit} element={<ItemTypeEditPage />} />
                    </Route>
                    <Route path={wildcardRoutes.applicationRegistration}>
                      <Route index element={<ApplicationListPage />} />
                      <Route path={routes.registration.add} element={<ApplicationRegistrationPage />} />
                      <Route path={routes.registration.edit} element={<ApplicationEditPage />} />
                    </Route>
                    <Route path={wildcardRoutes.factoriesRegistration}>
                      <Route index element={<FactoryListPage />} />
                      <Route path={routes.registration.add} element={<FactoryRegistrationPage />} />
                      <Route path={routes.registration.edit} element={<FactoryEditPage />} />
                    </Route>
                    <Route path={wildcardRoutes.groupsRegistration}>
                      <Route index element={<GroupListPage />} />
                      <Route path={routes.registration.add} element={<GroupRegistrationPage />} />
                      <Route path={routes.registration.edit} element={<GroupRegistrationPage />} />
                    </Route>
                    <Route path={wildcardRoutes.scrapRegistration}>
                      <Route index element={<ScrapListPage />} />
                      <Route path={routes.registration.edit} element={<ScrapEditPage />} />
                    </Route>
                  </Route>
                  <Route path={wildcardRoutes.charts}>
                    <Route index element={<SelectChart />} />
                    <Route element={<ChartFilterLineRate />}>
                      <Route path={routes.charts.productionRate} element={<ProductionRateChart />} />
                    </Route>
                    <Route element={<ChartFilterMulti />}>
                      <Route path={routes.charts.parameter} element={<ParameterChart />} />
                    </Route>
                    <Route element={<ChartFilterInterval />}>
                      <Route path={routes.charts.scrap} element={<ScrapChart />} />
                      <Route path={routes.charts.stop} element={<StopChart />} />
                      <Route path={routes.charts.oee} element={<OEEChart />} />
                      <Route path={routes.charts.cycleTime} element={<CycleTimeChart />} />
                      <Route path={noMatchRoute} element={<NotFound />} />
                      <Route path={routes.charts.production} element={<ProductionChart />} />
                      <Route path={routes.charts.capacity} element={<CapacityChart />} />
                    </Route>
                    <Route element={<ChartFilterLine />}>
                      <Route path={routes.charts.periodAnalysis} element={<PeriodAnalysisChart />} />
                    </Route>
                    <Route path={noMatchRoute} element={<NotFound />} />
                  </Route>
                  <Route path={wildcardRoutes.history}>
                    <Route index element={<SelectHistory />} />
                    <Route path={routes.history.scrap} element={<ScrapHistoryPage />} />
                    <Route path={routes.history.stop} element={<StopHistoryPage />} />
                    <Route path={routes.history.line} element={<LineHistoryPage />} />
                    <Route path={routes.history.machine} element={<MachineHistoryPage />} />
                    <Route path={routes.history.microStop} element={<SmallStopHistoryPage />} />
                    <Route path={routes.history.parameters} element={<ParameterHistoryPage />} />
                    <Route path={routes.history.productionCycles} element={<ProductionCycleHistoryPage />} />
                    <Route path={routes.history.production} element={<ProductionHistoryPage />} />
                    <Route path={noMatchRoute} element={<NotFound />} />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </FeedbackProvider>
        </PermissionProvider>
      </ThemeProvider>
    </Provider>
  );
};

render(<App />, document.getElementById('app'));
