import { createClient, dedupExchange, errorExchange, fetchExchange, subscriptionExchange } from 'urql';
import { createClient as createWSClient, Sink } from 'graphql-ws';

/**
 * Uses the localStorage to store the auth token and checks if the token exists
 * so that the user is authenticated.
 * @returns True if the user is logged in
 */
export function isLoggedIn(): boolean {
  return localStorage.getItem('accessToken') !== null;
}

/**
 * Forces a logout by removing the token from localStorage
 */
export function logout(): void {
  localStorage.removeItem('accessToken');
  window.location.reload();
}

export function createUrqlClient(httpUrl: string, wsUrl: string) {
  const wsClient = createWSClient({
    url: wsUrl,
    retryAttempts: Infinity,
    shouldRetry: () => true,
    keepAlive: 20000,
    connectionParams: async () => {
      const token = localStorage.getItem('accessToken');
      if (token) return { authorization: token };
      return {};
    },
  });

  return createClient({
    url: httpUrl,
    fetchOptions: () => {
      const token = localStorage.getItem('accessToken');
      if (token) return { headers: { authorization: `Bearer ${token}` } };
      return {};
    },
    exchanges: [
      dedupExchange,
      errorExchange({
        onError: (error) => {
          console.error(error);
          return error;
        },
      }),
      fetchExchange,
      subscriptionExchange({
        enableAllOperations: true,
        forwardSubscription: (operation) => ({
          subscribe: (sink: Sink) => ({
            unsubscribe: wsClient.subscribe(operation, sink),
          }),
        }),
      }),
    ],
  });
}
