import { useEffect, useState } from 'react';
import { CombinedError } from 'urql';
import { useFeedback } from '../context/feedback';
import { usePermission } from '../context/permission';
import { useRefreshMutation } from './graphqlQueries';

export type TokenDanceReturn = {
  isAuthenticated: boolean;
  error?: CombinedError;
};

export type TokenDanceOptions = {
  repeatAfter: number;
};

export function useTokenDance({ repeatAfter }: TokenDanceOptions): TokenDanceReturn {
  const feedback = useFeedback();
  const profile = usePermission();

  const [{ error, data }, authRefresh] = useRefreshMutation();
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    return accessToken !== null && refreshToken !== null;
  });

  useEffect(() => {
    const accessToken = data?.refresh?.token;
    const refreshToken = data?.refresh?.refreshToken;
    const permission = data?.refresh?.profile;

    !profile.permission && profile.setPermission(permission);

    if (error) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      setIsAuthenticated(false);
      feedback.apiError(error);
    } else if (accessToken !== undefined && refreshToken !== undefined) {
      localStorage.setItem('accessToken', accessToken);
      localStorage.setItem('refreshToken', refreshToken);
      setIsAuthenticated(true);
    }
  }, [data, error]);

  useEffect(() => {
    const checkToken = () => {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');

      if (accessToken !== null && refreshToken !== null) {
        authRefresh({ accessToken, refreshToken });
      } else {
        setIsAuthenticated(false);
      }
    };
    checkToken();
    const timer = setInterval(() => checkToken(), repeatAfter);

    return () => clearInterval(timer);
  }, []);

  return { isAuthenticated, error };
}
