import React from 'react';
import { sizes, VectorProps } from './Icon';

export const House: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.02816 17.8197V11.5697H13.1948V17.8197H18.4032V9.48633H21.5282L11.1115 0.111328L0.694824 9.48633H3.81982V17.8197H9.02816Z"
        fill={color}
      />
    </svg>
  );
};
