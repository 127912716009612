import React, { useEffect, useMemo, useState } from 'react';
import { Title } from '../../components/ui/Title';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '../../components/ui/Button';
import { Pagination } from '../../components/ui/Pagination';
import styled from 'styled-components';
import { RegisterList } from '../../components/layout/RegisterList';
import { RegisterTable } from '../../components/ui/RegisterTable';
import { useDeleteLossMutation, useLossesQuery, UserProfile } from '../../hooks/graphqlQueries';
import { useFeedback } from '../../context/feedback';
import { usePermission } from '../../context/permission';
import { PageOptions } from '../../components/layout/PageOptions';
import { Footer } from '../../components/layout/Footer';
import { format, parseISO } from 'date-fns';
import { getPagination } from '../../scripts/pagination';
import { asString } from 'ts-runtime-typecheck';
import { downloadFile, makeCSVFromTable } from '../../scripts/exportFiles';

type attributeType = {
  name: string;
  attr: string;
};

const attributes: attributeType[] = [
  { name: 'Nome', attr: 'name' },
  { name: 'Descrição', attr: 'description' },
];

const Table = styled.div`
  display: grid;
  grid-area: Table;

  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 500;
  color: ${(props) => props.theme.colors.table.colorBody};

  @media (max-width: 1168px) {
    &&:after {
      font-size: 12px;
      padding-top: 5px;
      content: 'Deslize a tabela para os lados';
    }
  }
`;

type PageParameters = { page: number; limit: number };
const initialParameters = { page: 1, limit: 10 };

export const LossListPage: React.FC = () => {
  const navigate = useNavigate();
  const feedback = useFeedback();
  const profile = usePermission();
  const location = useLocation();
  const routeData = location.state as { pagination: { page: number; limit: number } };

  const [parameters, setParameters] = useState<PageParameters>(routeData?.pagination ?? initialParameters);
  const [isExportData, setIsExportData] = useState(false);
  const dataExportLimit = parseInt(asString(process.env['DATA_EXPORT_LIMIT']));
  const [lossesQuery, refetch] = useLossesQuery({
    requestPolicy: 'network-only',
    variables: { ...getPagination(parameters.page, parameters.limit, { dataExportLimit, isExportData }) },
  });

  const [, deleteLoss] = useDeleteLossMutation();
  const losses = useMemo(() => lossesQuery.data?.stopTags?.value ?? [], [lossesQuery.data]);

  useEffect(() => {
    refetch();
  }, [parameters.page]);

  const data = useMemo(
    () =>
      losses.map((loss) => {
        return {
          table: {
            name: loss.name,
            description: loss.description ?? '',
          },
          value: loss,
        };
      }),
    [losses]
  );

  const [tableData, setTableData] = useState<typeof data>(data);
  const thisDate = format(parseISO(new Date().toISOString()), 'dd-MM-yyyy-HH-mm-ss');

  useEffect(() => {
    if (isExportData) {
      downloadFile(
        `perdas-${thisDate}`,
        makeCSVFromTable(
          attributes,
          data.map((v) => v.table)
        )
      );
      setIsExportData(false);

      if (losses.length === dataExportLimit)
        feedback.message({
          animation: 'Error',
          title: 'Limite de dados atingido!',
          subtitle: `Não é possível exportar mais que ${dataExportLimit} linhas de dados.`,
        });
    } else setTableData(data);
  }, [data]);

  return (
    <RegisterList>
      <Title>Perdas cadastradas</Title>
      <Table>
        <RegisterTable
          attributes={attributes}
          content={tableData}
          onDelete={
            ![UserProfile.Production, UserProfile.Quality].includes(profile.permission as UserProfile)
              ? (loss) => {
                  feedback.confirmation({
                    title:
                      'Deseja mesmo excluir essa perda? Essa operação é irreversível e irá exluir também todos os dados associados a perda.',
                    onHandle: (result) => {
                      if (result) {
                        deleteLoss({ id: loss['id']?.toString() ?? '' })
                          .then(() => {
                            feedback.message({
                              animation: 'Confetti',
                              title: 'Perda deletada com sucesso',
                            });

                            parameters.page === 1 ? refetch() : setParameters({ ...parameters, page: 1 });
                          })
                          .catch(() => {
                            feedback.message({
                              animation: 'Error',
                              title: 'Erro ao deletar perda',
                            });
                          });
                      }
                    },
                  });
                }
              : undefined
          }
          onEdit={
            ![UserProfile.Production].includes(profile.permission as UserProfile)
              ? (loss) => {
                  navigate('edit', { state: { data: loss, pagination: { ...parameters } } });
                }
              : undefined
          }
        />
      </Table>
      <Footer>
        <PageOptions>
          <Button color="light" title="Menu cadastros" icon="ArrowLeft" onClick={() => navigate('..')} />
          {![UserProfile.Production, UserProfile.Quality].includes(profile.permission as UserProfile) && (
            <Button
              color="primary"
              title="Cadastrar"
              icon="CirclePlus"
              iconSize="small"
              onClick={() => navigate('add', { state: { pagination: { ...parameters } } })}
            />
          )}
          <Button title="Exportar" icon="Export" disabled={data.length == 0} onClick={() => setIsExportData(true)} />
        </PageOptions>
        <Pagination
          numberOfItems={parameters.limit}
          onSelectNumber={(number) => setParameters({ limit: number, page: 1 })}
          page={parameters.page}
          totalPages={Math.ceil((lossesQuery.data?.stopTags?.count ?? parameters.limit) / parameters.limit)}
          onPrevious={() => {
            setParameters({ ...parameters, page: parameters.page - 1 });
          }}
          onNext={() => {
            setParameters({ ...parameters, page: parameters.page + 1 });
          }}
        />
      </Footer>
    </RegisterList>
  );
};
