import { useEffect, useState } from 'react';

/**
 * @param interval The interval in milliseconds.
 * @returns The current time, updated every interval milliseconds.
 */
export function useClock(interval: number) {
  const [clock, setClock] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => setClock(new Date()), interval);
    return () => clearInterval(timer);
  }, [clock]);
  return clock;
}
