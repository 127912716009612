import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon, IconNames } from '../Icon';

export const SelectionCardLayout = styled.div`
  display: grid;
  grid-area: Card;
  grid-template-columns: repeat(3, max-content);
  gap: 20px;

  @media (max-width: 965px) {
    grid-template-columns: repeat(2, max-content);
  }

  @media (max-width: 620px) {
    grid-template-columns: max-content;
  }
`;

export const SelectionCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  width: 278px;
  height: 120px;

  border: 3px solid transparent;
  box-sizing: border-box;
  border-radius: 5px;

  padding-left: 19px;
  padding-right: 19px;

  background: ${(props) => props.theme.colors.card.background};
  filter: drop-shadow(0px 7px 20px ${(props) => props.theme.colors.card.staticShadow});
  transition: ease-out 0.3s;

  &&:hover {
    cursor: pointer;
    border: 3px solid ${(props) => props.theme.colors.card.hover.border};
    box-shadow: 0px 2px 15px ${(props) => props.theme.colors.card.hover.shadow};
    transition: ease-out 0.3s;
  }
`;

export const SelectionIcon = styled.div`
  display: flex;
  width: 62px;
  height: 62px;
  border-radius: 100px;
  background-color: ${(props) => props.theme.colors.card.icon.background};
  align-items: center;
  justify-content: center;
`;

export const SelectionLabel = styled.span.attrs(props=>({
  style:{
    color: props.theme.colors.card.title.color,
  }
  }))`
  max-width: 165px;
  font-family: ${(props) => props.theme.font};
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
`;

export const CardContainer = styled.div.attrs(props => ({
  style: {
    backgroundColor: props.theme.colors.card.background,
    filter: `drop-shadow(0px 7px 20px ${props.theme.colors.card.staticShadow})`,
  }
  }))
  `
  padding-top: 15.35px;
  padding-left: 14.71px;
  box-sizing: border-box;
  width: 180px;
  height: 70px;
  border-radius: 5px;
`;

const CardTitle = styled.span.attrs(props=>({
  style:{
    color: props.theme.colors.card.title.color,
  }
  }))`
  grid-area: Title;
  font-family: ${(props) => props.theme.font};
  font-size: 16px;
  font-weight: 500;
`;

const CardValue = styled.span.attrs(props=>({
  style:{
    color: props.theme.colors.card.value.color,
  }
  }))`
  font-size: 18px;
  font-weight: 500;
  font-family: ${(props) => props.theme.font};
  
`;

const CardUnit = styled(CardValue)`
  font-size: 12px;
`;

const CardSeparator = styled(CardTitle)`
  &::before {
    content: '/';
  }

  padding-left: 5px;
  padding-right: 5px;
`;

type CardProgressProps = { value?: number };
const CardProgress = styled.div.attrs<CardProgressProps>(props => ({
  style:{
    width: (props.value ? props.value * 100 : 0)+'%',
    backgroundColor: props.theme.colors.card.progressBar.color,
  }
  }))<CardProgressProps>`
  
  height: 100%;
  
  transition: width 0.5s ease-in-out;
  border-radius: 8px 0px 0px 8px;
`;

const CardProgressBar = styled.div`
  width: 108px;
  height: 10px;

  background: ${(props) => props.theme.colors.card.progressBar.background};
  box-shadow: inset 1px 1px 8px rgba(0, 31, 77, 0.4);
  border-radius: 8px;
  overflow: hidden;
`;

const WithTitleLayout = styled.div`
  display: grid;
  grid-template:
    'Title' max-content
    'Content' max-content / max-content;
`;

type ContentLayoutProps = { noGaps?: boolean };
const ContentLayout = styled.div<ContentLayoutProps>`
  grid-area: Content;
  align-items: baseline;
  display: inline-flex;
  flex-direction: row;
  gap: ${(props) => (props.noGaps ? 0 : 5)}px;
`;

type ContainerProps = { children: React.ReactNode };
export const BaseCard: FC<ContainerProps> = ({ children }) => {
  return <CardContainer>{children}</CardContainer>;
};

type ValueCardProps = { title: string; value?: number | null; unit: string };
export const SimpleValueCard: FC<ValueCardProps> = ({ title, value, unit }) => {
  const { i18n } = useTranslation();
  const localizedValue = useMemo(() => {
    if (value === undefined || value === null) return '-';
    const format: Intl.NumberFormatOptions = { maximumFractionDigits: 2 };
    return value.toLocaleString(i18n.language, format);
  }, [value, i18n.language]);

  return (
    <BaseCard>
      <WithTitleLayout>
        <CardTitle>{title}</CardTitle>
        <ContentLayout>
          <CardValue>{localizedValue}</CardValue>
          <CardUnit>{unit}</CardUnit>
        </ContentLayout>
      </WithTitleLayout>
    </BaseCard>
  );
};

type TextCardProps = { title: string; text?: string | null };
export const SimpleTextCard: FC<TextCardProps> = ({ title, text }) => {
  return (
    <BaseCard>
      <WithTitleLayout>
        <CardTitle>{title}</CardTitle>
        <ContentLayout>
          <CardValue>{text}</CardValue>
        </ContentLayout>
      </WithTitleLayout>
    </BaseCard>
  );
};

type ProgressCardProps = { title: string; value?: number | null };
export const ProgressCard: FC<ProgressCardProps> = ({ title, value }) => {
  const asPercentage = useMemo(() => {
    if (value === undefined || value === null) return '- %';
    return `${Math.round(value * 100)}%`;
  }, [value]);
  return (
    <BaseCard>
      <WithTitleLayout>
        <CardTitle>{title}</CardTitle>
        <ContentLayout>
          <CardProgressBar>
            <CardProgress value={value ?? undefined} />
          </CardProgressBar>
          <CardValue>{asPercentage}</CardValue>
        </ContentLayout>
      </WithTitleLayout>
    </BaseCard>
  );
};

type ComparisonCardProps = {
  title: string;
  left: { value?: number | null; unit: string };
  right: { value?: number | null; unit: string };
};
export const ComparisonCard: FC<ComparisonCardProps> = ({ title, left, right }) => {
  const { i18n } = useTranslation();
  const localizeValue = useCallback(
    (value?: number | null) => {
      // From https://stackoverflow.com/a/37909363
      if (value === undefined || value === null) return '-\u00A0';
      const format: Intl.NumberFormatOptions = { maximumFractionDigits: 2 };
      return value.toLocaleString(i18n.language, format);
    },
    [i18n.language]
  );

  const localizedLeft = useMemo(() => localizeValue(left.value), [left]);
  const localizedRight = useMemo(() => localizeValue(right.value), [right]);

  return (
    <BaseCard>
      <WithTitleLayout>
        <CardTitle>{title}</CardTitle>
        <ContentLayout noGaps>
          <CardValue>{localizedLeft}</CardValue>
          <CardUnit>{left.unit}</CardUnit>
          <CardSeparator />
          <CardValue>{localizedRight}</CardValue>
          <CardUnit>{right.unit}</CardUnit>
        </ContentLayout>
      </WithTitleLayout>
    </BaseCard>
  );
};

export type SelectionCardProps = { title: string; icon: IconNames; onClick: () => void; dataCy?: string };
export const SelectionCard: FC<SelectionCardProps> = ({ title, icon, onClick, dataCy }) => {
  return (
    <SelectionCardContainer data-cy={dataCy} onClick={onClick}>
      <SelectionIcon>
        <Icon name={icon} size="xlarge" />
      </SelectionIcon>
      <SelectionLabel>{title}</SelectionLabel>
    </SelectionCardContainer>
  );
};
