import React from 'react';
import { sizes, VectorProps } from './Icon';

export const Export: React.FC<VectorProps> = ({ size = 'small', color }) => {
  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2598 9.52555H8.50976V0.358887H6.67643V9.52555H3.92643L7.5931 13.1922L11.2598 9.52555ZM0.259766 15.0256V16.8589H14.9264V15.0256H0.259766Z"
        fill={color}
      />
    </svg>
  );
};
