import React, { useEffect, useMemo, useState } from 'react';
import { Chart } from '../../components/ui/Chart';
import {
  WorkShift,
  useParameterValueChartQuery,
  ParameterValueChartQueryResponse,
  MachineParameter,
  Unit,
} from '../../hooks/graphqlQueries';
import { format, parseISO } from 'date-fns';
import { ChartContextType } from '../ChartFilterInterval';
import { useOutletContext } from 'react-router-dom';
import produce from 'immer';
import { ChartData } from '../../components/ui/Chart/Chart';
import { t } from 'i18next';
type ChartType = 'LINE' | 'BAR' | 'STACKBAR';

const mountLineChart = (stateData: ParameterValueChartQueryResponse[]) => {
  const exportX: Array<string> = [];
  const exportY: Array<number> = [];
  const exportUnit: Array<string> = [];
  const exportParameter: Array<string> = [];

  const chartComposition: ChartData[] = [];

  if (stateData) {
    stateData.map((data) => {
      const formatedData = data.values.reduce(
        (acc, curr) => {
          acc.dates.push(parseISO(curr.date));
          acc.values.push(curr.value);
          exportX.push(format(parseISO(curr.date), 'dd/MM/yyyy HH:mm:ss'));
          exportY.push(curr.value);
          exportUnit.push(t(data.unit as Unit));
          exportParameter.push(data.name);
          return acc;
        },
        { dates: [], values: [] } as {
          dates: Date[];
          values: number[];
        }
      );

      chartComposition.push({
        x: formatedData.dates,
        y: formatedData.values,
        name: data.name,
        chartType: 'LINE' as ChartType,
        unit: data.unit,
      });
    });
  }

  return {
    exportData: [
      ['time', ...exportX],
      ['value', ...exportY],
      ['unidade', ...exportUnit],
      ['parameter', ...exportParameter],
    ],
    chartData: chartComposition,
  };
};

export const ParameterChart: React.FC = () => {
  const { filter, setExportData, isFiltersValid } = useOutletContext<ChartContextType>();
  const [machineParameter, setMachineParameter] = useState<MachineParameter[]>([]);

  const [stateQuery] = useParameterValueChartQuery({
    //pause: machineParameter === (undefined || []),
    pause: !isFiltersValid.general,
    requestPolicy: 'network-only',
    variables: {
      machineParameter: machineParameter,
      itemTypeId: filter?.itemType?.id,
      workShifts: (filter?.multipleShifts?.map((s) => s.id) as WorkShift[]) ?? [],
      startDate: filter?.startDate,
      endDate: filter?.endDate,
      minValue: Number(filter?.minCount?.name),
      maxValue: Number(filter?.maxCount?.name),
    },
  });
  const parameterValue = useMemo(() => stateQuery.data?.parameterValueChart, [stateQuery]);
  const data = useMemo(() => mountLineChart(parameterValue as ParameterValueChartQueryResponse[]), [parameterValue]);

  function updateMachineParameters(machineParameters: MachineParameter[]) {
    const update = produce(machineParameters, (draft) => {
      draft.length = 0;
      filter.badges.map((badge) => {
        draft.push({
          machine: badge.machine.name,
          machineId: badge.machine.id,
          parameterId: badge.parameters,
        });
      });
    });

    setMachineParameter(update);
  }

  function clearMachineParameters(machineParameters: MachineParameter[]) {
    const clear = produce(machineParameters, (draft) => {
      draft.length = 0;
    });

    setMachineParameter(clear);
  }

  useEffect(() => {
    setExportData(data.exportData);
  }, [data]);

  useEffect(() => {
    if (filter.badges?.length > 0) updateMachineParameters(machineParameter);
    if (!filter.badges || filter.badges?.length === 0) clearMachineParameters(machineParameter);
  }, [filter.badges]);

  return (
    <Chart chartType={'LINE'} data={data.chartData} hasUnit loading={stateQuery.fetching || filter?.loading === true} />
  );
};
